import admin_vue from "@/components/Admin/admin_vue"
import { field_item_list_t, column_item_list_t, row_item_list_t } from "@/utils/type_def"

import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    userid: number
    config_fix: string
}



export default defineComponent({
    components: {
    },
    extends: admin_vue,

    props: {
        config_fix: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        userid: {
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        }




    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {

        return {
            props: <IProps>props // 代码补全用的,

            // 自定义的

        }
    },

    data: function() {
        return {
            query_filter_item_list: <field_item_list_t>[],
            row_opt_btn_config: <row_item_list_t>[],
            column_list: <column_item_list_t>[],

            url_args_ex: {
                userid: 0
            },
            default_sort: {
                prop: "log_time",
                order: "desc"
            }



        }
    },
    computed: {




    },

    watch: {


    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_mounted() {


        },


        on_created() {

            this.$data.url_args_ex.userid = this.props.userid || common.get_query_args().userid
            this.$data.query_filter_item_list = this.get_query_filter_item_list()

            this.$data.row_opt_btn_config = this.get_row_opt_btn_config()
            this.$data.column_list = this.get_column_list()

        },

        get_row_opt_btn_config(): row_item_list_t {
            return []
        },




        get_query_filter_item_list(): field_item_list_t {
            return []
        },

        // 配置列信息
        get_column_list(): column_item_list_t {
            return [{
                label: "时间",
                prop: "log_time",
                sortable: true
            }, {
                label: "类型",
                prop: "name"
            }, {
                label: "内容",
                prop: "info"
            }]

        }


    }

})


/*
    data() {
        return {

            url_args_ex: {
                userid: 0,
            },
            default_sort: {
                "prop": "log_time",
                "order": "desc",
            },



            query_filter_item_list: [],
            row_opt_btn_config: [],
            column_list: [],
        };
    },

    mounted: function() {
    },
    created: function() {
        (<m>this).on_created();
    },

    computed: {
    },

    props: {
        config_fix: {
            type: String,
            required: false,
            "default": function() {
                return "";
            },
        },
        userid: {
            type: Number,
            required: false,
            default: function() {
                return 0;
            }
        },


    }

})
export default class m extends admin_vue {
    // 初始数据可以直接声明为实例的属性



    get_row_opt_btn_config(): row_item_list_t {
        return [
        ];
    }






    get_query_filter_item_list(): field_item_list_t {
        return [];
    }

    //配置列信息
    get_column_list(): column_item_list_t {
        return [{
            label: "时间",
            prop: "log_time",
            sortable: true,
        }, {
            label: "类型",
            prop: "name",
        }, {
            label: "内容",
            prop: "info",
        }];
    }


    on_mounted() {


    }



    on_created() {

        this.$data.query_filter_item_list = this.get_query_filter_item_list();

        this.$data.row_opt_btn_config = this.get_row_opt_btn_config();
        this.$data.column_list = this.get_column_list();

    }



}
*/
