import admin_vue from "@/components/Admin/admin_vue"

import platform_list from "@/views/daren_info/platform_list.vue"
import score_list from "@/views/daren_info/score_list.vue"

import opt_log_list from "@/views/daren_info/opt_log_list.vue"
import { defineComponent, markRaw } from "vue"
import { field_item_list_t } from "@/utils/type_def"
import tag_list from "./tag_list.vue"

import task_list from "@/views/daren_info/task_list.vue"
import common from "/@/utils/common"

interface IProps {
    data: any,

}



export default defineComponent({
    name: "AmdinDarenInfo",
    components: {

        "tag-list": tag_list
    },
    extends: admin_vue,

    props: {
        data: Object



    },
    // event
    emits: ["close"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            active_tab_name: "task_list",
            cur_tab_component: <any>markRaw(task_list),
            base_info: <any>{},
            row: {
                userid: 0,
                tag_list: []
            }

        }
    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_mounted() {
        },

        on_created() {
            this.set_data(this.props.data)


        },
        set_data(data: any) {
            const me = this
            me.$data.base_info = data.base_info
            me.$data.row = {
                userid: data.base_info.userid,
                tag_list: data.tag_list
            }

        },
        reload() {
            const me = this
            common.do_ajax("/daren/get_daren_info", {
                userid: this.props.data.base_info.userid
            }, function(resp: any) {
                me.set_data(resp.data)
            })
        },


        on_close() {
            this.$emit("close", {})
        },
        on_select_tab(tabname: string) {
            this.select_tab(tabname)
        },
        select_tab(tab_name: string) {
            if (tab_name == "task_list") {
                this.$data.cur_tab_component = markRaw(task_list)
            } else if (tab_name == "opt_log_list") {
                this.$data.cur_tab_component = markRaw(opt_log_list)
            } else if (tab_name == "score_list") {
                this.$data.cur_tab_component = markRaw(score_list)
            } else if (tab_name == "platform_list") {
                this.$data.cur_tab_component = markRaw(platform_list)
            }
        },

        on_refresh() {
            const { userid } = this.props.data.base_info
            common.do_ajax("/daren_ex/refresh_daren", {
                userid: userid
            }, function() {
                common.notify({
                    title: "刷新用户数据",
                    message: "刷新完成"
                })
            })

        },
        on_edit_real_name() {
            const { userid, real_name } = this.props.data.base_info
            var field_list: field_item_list_t = [{
                field_type: "text",
                field_name: "real_name",
                label: "姓名",
                value: real_name
            }]
            var data: any = {}
            var me = this
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                submit: function() {
                    common.do_ajax("/daren/opt_daren", {
                        opt_type: "set",
                        userid: userid,
                        real_name: data.real_name
                    }, function() {
                        me.reload()

                    })

                    dlg.close()
                }
            })




        },

        show_dp_info(base_info: any) {
            common.dlg.show_dp_info(base_info.dp_id)
        },

        exchange_wx_bind() {
            var me = this

            var cur_userid = this.props.data.base_info.userid

            common.dlg.admin_select_user("daren", function(userid: any) {
                if (cur_userid != userid) {
                    common.do_ajax("/daren_ex2/exchange_wx_bind", {
                        userid1: cur_userid,
                        userid2: userid
                    }, function(resp: any) {
                        if (resp.code == 0) {
                            me.reload()
                            common.notify({ message: "交换成功" })
                        } else {
                            common.alert(resp.info)
                        }
                    })
                } else {
                    common.alert("是同一个人,无需绑定")
                }

            })
        },

        wx_unbind() {
            var me = this

            var cur_userid = this.props.data.base_info.userid

            common.confirm("取消微信绑定?! ", function() {
                common.do_ajax("/daren_ex2/wx_unbind", {
                    userid: cur_userid
                }, function() {
                    me.reload()
                })

            })
        }


    }

})
