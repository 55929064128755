import { defineComponent } from "vue"


interface IProps {
    name: string
}



export default defineComponent({
    components: {
    },

    props: {

        name: {
            type: String,
            required: true
        }


    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
        }
    },
    computed: {

        icon_class(): string {
            if (this.props.name) {
                return `iconfont ${this.props.name}`
            } else {
                return "iconfont"
            }
        }



    },

    watch: {


    },
    created() {
        this.on_created()
    },
    mounted() {
        // this.on_mounted();
    },
    methods: {
        on_created() {
        }
    }

})

