import admin_vue from "@/components/Admin/admin_vue"

import { defineComponent, markRaw } from "vue"
import common from "/@/utils/common"
import { field_item_list_t, column_item_list_t } from "@/utils/type_def"


interface IProps {
    row: any
    use_type: number
    tag_set_type: string
    system_flag: number

}



export default defineComponent({
    components: {
    },
    extends: admin_vue,

    props: {
        row: Object,

        use_type: {
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        },
        tag_size: {
            type: String,
            required: false,
            default: function() {
                return "mini"
            }
        },
        is_can_edit: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        // 系统标签
        system_flag: {
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        },
        // 标签是谁的 ,task| daren
        tag_set_type: {
            type: String,
            required: false,
            default: function() {
                return "daren"
            }
        },

        // 只有系统标签
        only_system_flag: {
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        }




    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            tag_list: <Array<any>>[]
        }
    },
    watch: {
        row: function(new_val: any) {
            this.$data.tag_list = new_val.tag_list
        }
    },

    created() {

        this.$data.tag_list = this.props.row.tag_list
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_created() {
        },
        on_mounted() {
        },
        get_tag_type(tag: any) {
            console.log("--------", common.copy_obj(tag))
            if (tag.tag_effact_flag == 0) {
                return "danger"
            } else {
                return "success"
            }
        },

        on_close(tag: any, index: any) {

            const me = this
            const { row } = this.props

            if (common.in_array(me.props.use_type, [0])) {// 用户标签

                let name_str = ""
                if (row.name) {
                    name_str = `[${row.name}] 的 `
                }

                common.confirm(`要删除${name_str}[ ${tag.tag_name}] 标签 `, function() {
                    common.do_ajax("/ajax_deal/user_tag_del", {
                        userid: me.get_userid(),
                        tag_id: tag.tag_id,
                        tag_set_type: me.props.tag_set_type
                    }, function(resp: any) {
                        me.props.row.tag_list = resp.data.tag_list
                        me.$data.tag_list = resp.data.tag_list
                    })
                })
            } else {
                const new_tag_list: any = []
                me.$data.tag_list.forEach(function(item: any, key: any) {
                    if (key != index) {
                        new_tag_list.push(item)
                    }
                })
                me.$data.tag_list = new_tag_list
                me.props.row.tag_list = new_tag_list
            }



        },
        get_userid() {


            let ret = ""
            if (this.props.tag_set_type == "task") {
                ret = this.props.row.task_id
            } else if (this.props.tag_set_type == "city_task") {
                ret = this.props.row.city_task_id
            } else {
                ret = this.props.row.userid
            }
            return `${ret}`
        },

        edit_tag(_callback?: any) {
            const me = this

            const opt_tag = function(title: any, tag_name: any, tag_type: any, tag_id: any = 0) {
                const type_id_config: any = {
                    primary: 1,
                    success: 2,
                    info: 3,
                    warning: 4,
                    danger: 5
                }
                tag_type = type_id_config[tag_type]

                const field_list: field_item_list_t = [
                    {
                        field_type: "text",
                        field_name: "tag_name",
                        label: "标签名称",
                        value: tag_name
                    },
                    {
                        field_type: "select",
                        field_name: "tag_type",
                        label: "样式",
                        opt_list: [{
                            label: "重要",
                            value: 1,
                            tag_type: "primary"

                        }, {
                            label: "正常",
                            value: 2,
                            tag_type: "success"
                        }, {
                            label: "一般",
                            value: 3,
                            tag_type: "info"

                        }, {
                            label: "提醒",
                            value: 4,
                            tag_type: "warning"

                        }, {
                            label: "强烈提醒",
                            value: 5,
                            tag_type: "danger"
                        }],
                        opt_component: markRaw(defineComponent({
                            props: {
                                option: Object
                            },
                            template: "<el-tag  :type=\"option.tag_type\"  > {{option.label}} </el-tag> "
                        })),

                        value: tag_type
                    }
                ]

                const data = {}

                var dlg = common.dlg.admin_show_key_table(field_list, data, {
                    title: title,
                    width: "400px",
                    submit: function(data: any, _btn_config: any) {
                        const type_config: any = {
                            1: "primary",
                            2: "success",
                            3: "info",
                            4: "warning",
                            5: "danger"

                        }
                        data.tag_type = type_config[data.tag_type]
                        common.do_ajax("/ajax_deal/admin_tag_add", {
                            tag_id: tag_id,
                            use_type: me.props.use_type,
                            ...data
                        }, function() {
                            ajax_dlg.reload()
                        })

                        dlg.close()
                    }

                })
            }

            const column_list: column_item_list_t = [
                {
                    label: "来自",
                    prop: "adminid",
                    formater: function(value: any, _row: any) {
                        if (value == 0) {
                            return "<font color=\"blue\">公共</font>"
                        } else {
                            return "<font color=\"green\">我的</font>"
                        }
                    }

                },
                {
                    label: "标签",
                    prop: "tag_name",
                    component: markRaw(defineComponent({
                        props: {
                            row: Object
                        },
                        template: "<el-tag  :type=\"row.tag_type\"  > {{row.tag_name}} </el-tag> "
                    }))
                },
                {
                    label: "操作",
                    prop: "_opt",
                    component: markRaw(defineComponent({
                        props: {
                            row: Object
                        },
                        methods: {
                            on_edit: function() {
                                const { row }: any = this.$props
                                opt_tag("修改标签", row.tag_name, row.tag_type, row.tag_id)
                            },
                            on_del: function() {
                                const { row }: any = this.$props
                                common.confirm(`要删除标签:[${row.tag_name}]吗?`, function() {

                                    common.do_ajax("/ajax_deal/admin_tag_del", {
                                        tag_id: row.tag_id
                                    }, function() {
                                        ajax_dlg.reload()
                                    })
                                })
                            }
                        },
                        template: "<div> <el-button v-if=\"row.adminid>0\"  icon=\"el-icon-edit\" type=\"text\" title=\"编辑\" @click=\"on_edit\" > </el-button>  <el-button v-if=\"row.adminid>0\"  icon=\"el-icon-delete\" type=\"text\" title=\"删除\" @click=\"on_del\" > </el-button>  </div > "
                    }))
                }
            ]

            const select_callback = function(select_item: any) {

                if (me.props.use_type == 0) {// 用户标签

                    common.do_ajax("/ajax_deal/user_tag_add", {
                        userid: me.get_userid(),
                        tag_id: select_item.tag_id,
                        tag_set_type: me.props.tag_set_type
                    }, function(resp: any) {
                        me.props.row.tag_list = resp.tag_list
                        me.$data.tag_list = resp.tag_list

                    })
                } else {

                    console.log("boby-----select", select_item)
                }

            }
            const query_filter_item_list: any = []


            var ajax_dlg = common.dlg.admin_ajax_table_select(
                "/ajax_deal/get_admin_tag_list", {
                    tag_set_type: me.props.tag_set_type,
                    use_type: me.props.use_type
                }, query_filter_item_list, column_list, select_callback
            )

        },

        add_tag() {
            const me = this
            const column_list: column_item_list_t = [{
                label: "标签",
                prop: "tag_name",
                component: markRaw(defineComponent({
                    props: {
                        row: Object
                    },
                    template: "<el-tag  :type=\"row.tag_effact_flag==0?'danger':'success' \"  > {{row.tag_name}} </el-tag> "
                }))


            }, {
                label: "定义",
                prop: "tag_from_type",
                formater: function(value: any, _row: any) {
                    if (value == 0) {
                        return "<font color=\"blue\">系统</font>"
                    } else {
                        return "<font color=\"green\">自定义</font>"
                    }
                }

            }]
            const select_callback = function(select_item_list: Array<any>) {
                if (common.in_array(me.props.use_type, [0])) {// 用户标签
                    const tag_id_list = select_item_list.map(function(item) {
                        return item.tag_id
                    }).join(",")

                    common.do_ajax("/ajax_deal/user_tag_add", {
                        userid: me.get_userid(),
                        tag_id: tag_id_list,
                        tag_set_type: me.props.tag_set_type
                    }, function(resp: any) {
                        me.props.row.tag_list = resp.data.tag_list
                        me.$data.tag_list = resp.data.tag_list

                    })

                } else {
                    // console.log('boby---select', select_item_list);
                    me.$data.tag_list = select_item_list
                    me.props.row.tag_list = select_item_list
                }


            }
            const query_filter_item_list: Array<any> = []
            var ajax_dlg = common.dlg.admin_ajax_table_select("/ajax_deal/get_admin_tag_list", {
                use_type: me.props.use_type,
                system_flag: me.props.system_flag,
                tag_set_type: me.props.tag_set_type
            }, query_filter_item_list, column_list, select_callback, {
                display_type: "select",
                title: "选择标签",
                select_type: "multi_select",
                use_checkbox_select_in_ajax_multi_select_flag: true,
                on_row_click: function(row: any) {
                    (<any>(<any>ajax_dlg.$refs["ajax_table"]).$refs["admin_table"]).$refs.table["toggleRowSelection"](row)
                },


                other_btn_list: [

                    /*
                      ... (this.props.only_system_flag ? [] : [{
                      icon: "",
                      text: " 管理标签",
                      type: "warning",
                      click: function() {
                      me.edit_tag(function() {
                      ajax_dlg.reload();
                      });
                      }
                      }])
                    */
                ]

            })



        }
    }


})
