import { login, loginParam, getRouterList, getUser } from "/@/api/layout/index"
import { ILayout, IMenubarStatus, ITagsList, IMenubarList, ISetting } from "/@/type/store/layout"
import { ActionContext } from "vuex"
import router from "/@/router/index"
import { allowRouter } from "/@/router/index"
import { generatorDynamicRouter, get_router_map } from "/@/router/asyncRouter"
import changeTheme from "/@/utils/changeTheme"
import { RouteLocationNormalizedLoaded } from "vue-router"
import { getToken, setToken } from "/@/utils/auth"
import common from "/@/utils/common"
import { getLocal } from "/@/utils"

const setting = Object.assign(getLocal<ISetting>("setting"),
    { theme: 2, showTags: false })

const state: ILayout = {
    menubar: {
        status: setting.menubarStatus || (document.body.offsetWidth < 768 ? IMenubarStatus.PHN : IMenubarStatus.PCE),
        menuList: [],
        isPhone: document.body.offsetWidth < 768,
        menu_search_key: "",
        show_menu_search_flag: false,
        show_navbar: false
    },
    tool_bar_opt_config: [],
    // 用户信息
    userInfo: {
        name: "",
        role: []
    },
    // 标签栏
    tags: {
        tagsList: [],
        cachedViews: []
    },
    token: {
        ACCESS_TOKEN: getToken()
    },
    setting: {
        theme: setting.theme !== undefined ? setting.theme : 0,
        showTags: setting.showTags !== undefined ? setting.showTags : true,
        menubarStatus: setting.menubarStatus || (document.body.offsetWidth < 768 ? IMenubarStatus.PHN : IMenubarStatus.PCE)
    },
    isLoading: false,

    name: "",
    sub_master_flag: 0,
    no_read_message_count: 0,
    telphone: "",
    adminid: 0,
    account_role: 0,
    power_role: 0,
    target_account_role: 0,
    avatar: "",
    menu_list: [],
    url_desc_power: {},
    url_desc_power_value: {},
    url_to_id_map: {},
    menu_type: "main", // main, teacher, student
    menu_userid: 0, // 学生/老师id
    menu_user_nick: "", // 学生/老师 nick
    roles: [],
    admin_url: "",
    admin_level_group_list: [], // 前缀
    admin_level: [], // 前缀
    admin_level_str: "" // 组织层级

}
const mutations = {
    changeCollapsed(state: ILayout): void {
        if (state.menubar.isPhone) {
            state.menubar.status = state.menubar.status === IMenubarStatus.PHN ? IMenubarStatus.PHE : IMenubarStatus.PHN
        } else {
            state.menubar.status = state.menubar.status === IMenubarStatus.PCN ? IMenubarStatus.PCE : IMenubarStatus.PCN
        }
        state.setting.menubarStatus = state.menubar.status
        localStorage.setItem("setting", JSON.stringify(state.setting))

    },
    changeDeviceWidth(_state: ILayout): void {
        /*
        if (document.body.offsetWidth < 768) {
            state.menubar.isPhone = true
            state.menubar.status = IMenubarStatus.PHN
        } else {
            state.menubar.isPhone = false
            state.menubar.status = IMenubarStatus.PCE
        }
        */
    },
    // 切换导航，记录打开的导航
    changeTagNavList(state: ILayout, cRouter: RouteLocationNormalizedLoaded): void {
        if (!state.setting.showTags) return // 判断是否开启多标签页
        if (cRouter.meta.hidden && !cRouter.meta.activeMenu) return // 隐藏的菜单如果不是子菜单则不添加到标签
        const index = state.tags.tagsList.findIndex(v => v.path === cRouter.path)
        state.tags.tagsList.forEach(v => v.isActive = false)
        // 判断页面是否打开过
        if (index !== -1) {
            state.tags.tagsList[index].isActive = true
            return
        }
        const tagsList: ITagsList = {
            name: cRouter.name as string,
            title: cRouter.meta.title as string,
            path: cRouter.path,
            isActive: true
        }
        state.tags.tagsList.push(tagsList)
    },
    removeTagNav(state: ILayout, obj: { tagsList: ITagsList, cPath: string }): void {
        const index = state.tags.tagsList.findIndex(v => v.path === obj.tagsList.path)
        if (state.tags.tagsList[index].path === obj.cPath) {
            state.tags.tagsList.splice(index, 1)
            const i = index === state.tags.tagsList.length ? index - 1 : index
            state.tags.tagsList[i].isActive = true
            mutations.removeCachedViews(state, { name: obj.tagsList.name, index })
            router.push({ path: state.tags.tagsList[i].path })
        } else {
            state.tags.tagsList.splice(index, 1)
            mutations.removeCachedViews(state, { name: obj.tagsList.name, index })
        }
    },
    removeOtherTagNav(state: ILayout, tagsList: ITagsList): void {
        const index = state.tags.tagsList.findIndex(v => v.path === tagsList.path)
        state.tags.tagsList.splice(index + 1)
        state.tags.tagsList.splice(0, index)
        state.tags.cachedViews.splice(index + 1)
        state.tags.cachedViews.splice(0, index)
        router.push({ path: tagsList.path })
    },
    removeAllTagNav(state: ILayout): void {
        state.tags.tagsList.splice(0)
        state.tags.cachedViews.splice(0)
        router.push({ path: "/redirect/" })
    },
    // 添加缓存页面
    addCachedViews(state: ILayout, obj: { name: string, noCache: boolean }): void {
        if (!state.setting.showTags) return // 判断是否开启多标签页
        if (obj.noCache || state.tags.cachedViews.includes(obj.name)) return
        state.tags.cachedViews.push(obj.name)
    },
    // 删除缓存页面
    removeCachedViews(state: ILayout, obj: { name: string, index: number }): void {
        // 判断标签页是否还有该页面
        if (state.tags.tagsList.map(v => v.name).includes(obj.name)) return
        state.tags.cachedViews.splice(obj.index, 1)
    },
    login(state: ILayout, token = ""): void {
        state.token.ACCESS_TOKEN = token
        setToken(token)
        const { query } = router.currentRoute.value
        router.push(typeof query.to_url === "string" ? query.to_url : "/")
    },
    logout(state: ILayout): void {
        state.token.ACCESS_TOKEN = ""
        setToken("")
        history.go(0)
    },
    setRoutes(state: ILayout, data: Array<IMenubarList>): void {
        state.menubar.menuList = data
    },
    concatAllowRoutes(state: ILayout): void {
        allowRouter.reverse().forEach(v => state.menubar.menuList.unshift(v))
    },

    TOGGLE_TOOL_BAR_OPT_CONFIG: (state: ILayout, tool_bar_opt_config: any) => {
        state.tool_bar_opt_config = tool_bar_opt_config
    },

    getUser(state: ILayout, data: any): void {
        console.log("222", data)
        if (!data.adminid) {
            mutations.logout(state)
        } else {
            state.userInfo.name = data.name

            common.path_url_map = {}
            common.first_path = ""

            const { menu_list } = data
            const router_map: any = get_router_map({ list: menu_list })




            const url_to_id_map = data.url_powerid_map
            state.name = data.name
            state.admin_url = data.admin_url
            state.adminid = data.adminid
            state.avatar = data.avatar
            state.account_role = data.account_role
            state.sub_master_flag = data.sub_master_flag
            state.menu_list = data.menu_list
            state.url_desc_power = data.url_desc_power
            state.url_desc_power_value = data.url_desc_power_value
            state.admin_level_group_list = data.admin_level_group_list
            state.admin_level = data.admin_level
            state.admin_level_str = data.admin_level_str
            state.url_to_id_map = url_to_id_map

            // 默认路由
            router_map["children"].push(...[{
                name: "Dashboard",
                path: "/",
                meta: {
                    title: "main",
                    icon: ""
                },
                hidden: true,
                redirect: "/index/index"
            }, {
                path: "/:pathMatch(.*)*",
                name: "NotFound",

                component: (() => import("/@/views/ErrorPage/404.vue")) as unknown as () => Promise<typeof import("*.vue")>,
                hidden: true,
                meta: {
                    title: "NotFound",
                    icon: ""
                },
                redirect: {
                    name: "404"
                }

            }, {
                path: "/main_page/mobile",
                name: "移动端",
                component: (() => import("/@/views/main_page/mobile.vue")) as unknown as () => Promise<typeof import("*.vue")>,
                hidden: true

            }])




            mutations.setRoutes(state, router_map["children"])

        }


        // state.userInfo.role = userInfo.role
    },
    // 修改主题
    changeTheme(state: ILayout, num: number): void {
        if (num === state.setting.theme) return
        if (typeof num !== "number") num = state.setting.theme
        changeTheme(num)
        state.setting.theme = num
        // common.alert_json(state.setting);
        // localStorage.setItem('setting', JSON.stringify(state.setting))
    },
    changeTagsSetting(state: ILayout, showTags: boolean): void {
        state.setting.showTags = showTags
        // localStorage.setItem('setting', JSON.stringify(state.setting))

        if (showTags) {
            const index = state.tags.tagsList.findIndex(v => v.path === router.currentRoute.value.path)
            if (index !== -1) {
                state.tags.tagsList.forEach(v => v.isActive = false)
                state.tags.tagsList[index].isActive = true
            } else {
                mutations.changeTagNavList(state, router.currentRoute.value)
            }
        }
    }
}
const actions = {
    async login(context: ActionContext<ILayout, IState>, param: loginParam): Promise<void> {
        const res = await login(param)
        const token = (<any>res.data.data).session_id
        context.commit("login", token)
    },
    async getUser(context: ActionContext<ILayout, IState>): Promise<void> {
        const res = await getUser()
        const userInfo = res.data
        context.commit("getUser", userInfo)
    },
    async GenerateRoutes(): Promise<void> {
        const res = await getRouterList()
        const { Data } = res.data
        generatorDynamicRouter(Data)
    },
    async set_tool_bar_opt_config(context: ActionContext<ILayout, IState>, tool_bar_opt_config: any): Promise<void> {


        context.commit("TOGGLE_TOOL_BAR_OPT_CONFIG", tool_bar_opt_config)

    }

}
const layoutState = {
    namespaced: true,
    state,
    mutations,
    actions
}





export default layoutState
