
import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    pic_width: number,
    pic_height: number,
    index: number,
    list: [],
}



export default defineComponent({
    components: {
    },

    props: {

        url: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        index: {
            type: Number,
            required: true,
            default: function() {
                return 0
            }
        },
        total_count: {
            type: Number,
            required: true,
            default: function() {
                return 0
            }
        },
        pic_width: {
            type: Number,
            required: false,
            default: function() {
                return 200
            }
        },

        pic_height: {
            type: Number,
            required: false,
            default: function() {
                return 100
            }

        },
        list: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        }




    },
    // event
    emits: ["opt"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            show_flag: false,
            video: "",
            dialogVisible: false
        }
    },

    computed: {
        pic_style: function(): any {
            return {
                width: `${this.props.pic_width}px`,
                height: `${this.props.pic_height}px`
            }
        },
        card_style: function(): any {
            return {
                width: `${this.props.pic_width}px`,
                height: `${this.props.pic_height}px`,
                display: "inline-block",
                padding: "0px"
            }

        },
        card_body_style: function(): any {
            return {
                width: `${this.props.pic_width}px`,
                height: `${this.props.pic_height}px`,
                display: "inline-block",
                padding: "0px"
            }

        },

        transition_div_style: function(): any {
            return {
                position: "relative",
                top: `${-(this.props.pic_height / 2 + 35)}px`,
                "margin-left": `${this.props.pic_width / 2 - 50}px`
            }
        }
    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_created() {
        },
        on_mounted() {
        },
        in_phone() {
            return common.in_phone()
        },

        on_over() {
            this.$data.show_flag = true
        },
        on_leave() {
            this.$data.show_flag = false
        },
        opt(opt_type: string) {
            const me = this
            const post_func = function() {
                me.$emit("opt", {
                    index: me.props.index,
                    opt_type: opt_type
                })

            }
            if (opt_type == "del") {
                common.confirm("要删除图片吗", function(_val: any) {
                    post_func()
                })
            } else {
                post_func()
            }
        },
        isImg(item: string) {

            const splitItem = (`${item}`).split(".")
            if (splitItem[splitItem.length - 1] == "mp4") {
                return "video"
            } else {
                return "img"
            }
        },
        imgList() {
            const list: any = []

            this.props.list.map((item: any) => {
                if (this.isImg(item) == "img") {
                    list.push(item)
                }
            })
            return list
        },
        preview_video(this: any, item: any) {
            this.dialogVisible = true
            this.video = item
        }


    }

})


