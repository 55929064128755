import dayjs from "dayjs"

import { field_item_date_t } from "/@/utils/type_def"
import { defineComponent } from "vue"
import tag_list from "@/views/Others/tag_list.vue"
import field_ex_config from "@/views/Others/field_ex_config.vue"



interface IProps {

    item: any
    data: any
}



export default defineComponent({
    components: {
        "tag-list": tag_list,
        "field-ex-config": field_ex_config
    },

    props: {

        item: {
            type: Object,
            required: true
        },

        data: {
            type: Object,
            required: true
        }


    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
        }
    },
    computed: {



    },

    watch: {


    },
    created() {
        // this.on_created();
    },
    mounted() {
        // this.on_mounted();
    },
    methods: {
        get_date_format_str(format: any) {
            let format_str: string = format ? format : "YYYY-MM-DD HH:mm:ss"

            // 支持vue2 版本的yyyy-MM-dd
            format_str = format_str.replace(/yyyy-MM-dd/, "YYYY-MM-DD")
            return format_str

        },
        date_change(item: field_item_date_t, val: any) {



            if (!val) {
                this.props.data[item.field_name!] = ""
            } else {
                this.props.data[item.field_name!] = dayjs(val).format(this.get_date_format_str(item.format))
            }
            if (item.on_change) {
                item.on_change(val, null)
            }

        },

        cascader_change(v: any, item: any) {
            this.$props.data[item.field_name] = v
        },
        gen_autocomlete_query_search(item: any) {
            const createFilter = (queryString: string) => {
                return (opt_item: any) => {
                    return (
                        opt_item.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
                    )
                }
            }
            const querySearch = (queryString: string, cb: any) => {

                // return item.opt_list;
                // const results = queryString
                //     ? item.opt_list.filter(createFilter(queryString))
                //     : item.opt_list
                // call callback function to return suggestions
                cb(item.opt_list)
            }
            return querySearch

        }
    }

})
