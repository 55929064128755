// @eslint-disable


import { defineComponent } from "vue"

import common from "@/utils/common"
import { ElMessageBox } from "element-plus"
import _ from "lodash"
interface IProps {
    data: any,

}



export default defineComponent({
    name: "AmdinDarenInfo",
    components: {

    },


    props: {
        data: {
            type: Object

        }
    },
    // event
    emits: ["close", "update_list"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {

        return {
            dialogVisible: false,
            editReasonShow: false,
            commit_info: "",
            new_commit_info: "",
            approval_info: "",
            new_approval_info: "",
            save_approval_status: <number | string>"",
            props_data: <any>{},
            disable: false,
            newData: <any>{
                加分项: [],
                扣分项: []
            },
            // eslint-disable-next-line
            data: <any>{
                加分项: [],
                扣分项: []
            },
            tableData: <any>[
                {
                    content: "符合品牌/商户常规需求（粉丝）",
                    direction: "必要因素",
                    last_select: 0,
                    different_select: 0,
                    score: -1,
                    standard: ""
                }
            ]
        }

    },
    created() {
        this.props_data = this.$props.data
        this.approval_info = this.props_data.approval_info
        this.commit_info = this.props_data.commit_info
        // this.disable = this.props_data.approval_status === '0'
        this.disable = common.get_query_args().approval_status === "0"

        common.do_ajax(
            "/daren_rate/get_rate_config",
            {
                rate_id: this.props_data.rate_id || "",
                userid: +this.props_data.userid
            },
            (res: any) => {
                console.log(res)

                const { code, data } = res
                if (code === 0) {
                    this.tableData = this.tableData.concat(data.list)
                    data.list.map((item: any) => {
                        //   if (list.indexOf(item.content) == -1) {
                        //     list.push(item.content)
                        //   }

                        if (item.last_select == 1) {
                            if (item.content == "加分项" || item.content == "扣分项") {
                                this.$data.data[item.content].push(item.id)
                                this.newData[item.content].push(item.id)
                            } else {
                                this.$data.data[item.content] = item.id
                                this.newData[item.content] = item.id
                            }
                        }
                    })


                    console.log(this.data)
                }
            }
        )
    },
    mounted() {
    },
    methods: {
        no_pass() {
            this.dialogVisible = true
        },
        radioFn() {
            this.editReasonShow = !_.isEqual(this.newData, this.data)
            //   this.editReasonShow = JSON.stringify(this.newData) !== JSON.stringify(this.data)
        },
        objectSpanMethod(obj: any) {
            const { row, column, rowIndex, columnIndex } = obj
            if (rowIndex === 0) {
                if (columnIndex === 1) {
                    return [1, 2]
                }
            }
            // 对第一列 第二列 进行合并
            if (columnIndex === 1 || columnIndex === 0) {
                // 当 当前行与上一行内容相同时 返回0 0 意味消除
                if (
                    rowIndex > 0 &&
                    row[column.property] === this.tableData[rowIndex - 1][column.property]
                ) {
                    return {
                        rowspan: 0,
                        colspan: 0
                    }
                } else {
                    let rows = 1
                    // 反之 查询相同的内容有多少行 进行合并
                    for (let i = rowIndex; i < this.tableData.length - 1; i++) {
                        if (
                            row[column.property] === this.tableData[i + 1][column.property]
                        ) {
                            rows++
                        }
                    }
                    // 返回相同内容的行数
                    return {
                        rowspan: rows,
                        colspan: 1
                    }
                }
            }
        },
        close() {
            this.$emit("close")
        },
        successFn() {
            this.save_approval_status = 1
            this.save()
        },
        reasonFn() {
            if (!this.new_approval_info) {
                ElMessageBox.alert("请输入拒绝原因")
                return
            }
            this.save_approval_status = 2
            this.save()
        },
        save() {
            const _this: any = this
            // console.log(Object.froms(_this.data))
            console.log(Object.values(_this.data).join(","))
            console.log(_this.$props)
            debugger
            if (this.props_data.approval_status === "0" && !this.disable) {
                _this.$message({
                    message: "请等待审核完成后，再提交",
                    type: "error"
                })
                return
            }
            if (this.editReasonShow && !this.new_commit_info && this.props_data.rate_id) {
                _this.$message({
                    message: "请填写修改原因",
                    type: "error"
                })
                return
            }
            common.do_ajax(
                "/daren_rate/opt_daren_rate",
                {
                    rate_id: this.props_data.rate_id || "",
                    opt_type: this.disable
                        ? "approval"
                        : this.props_data.rate_id
                            ? "edit"
                            : "add", // 'add', //edit,
                    rate_config_id: Object.values(_this.data).join(","),
                    approval_info: this.new_approval_info,
                    userid: _this.$props.data.userid,
                    commit_info: this.new_commit_info,
                    approval_status: this.save_approval_status
                    //   score: '',
                },
                (res: any) => {
                    const { code } = res
                    if (code == 0) {
                        _this.$message({
                            message: "操作成功",
                            type: "success"
                        })
                        this.$emit("update_list")
                    }
                }
            )
        }

    }

})

// @eslint-enable
