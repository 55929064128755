import admin_vue from "@/components/Admin/admin_vue"
import { admin_alioss_upload } from "@/admin_component"
import field_ex_config from "./field_ex_config.vue"

import { field_item_list_t } from "@/utils/type_def"
import tag_list from "./tag_list.vue"
import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    row: any
    data: any
}



export default defineComponent({
    components: {
        "tag-list": tag_list,
        fieldExConfig: field_ex_config,
        adminUpload: admin_alioss_upload

    },
    extends: admin_vue,

    props: {
        row: Object,
        data: Object
    },
    // event
    emits: ["close", "update_list"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props
        }
    },

    data: function() {
        return {
            mainOptionList: [],
            childOptionList: [],
            config: [],
            field_ex_config: "",
            optionList: [],
            timeOptionList: [],
            stepsActive: 1,
            task_id: "",
            form: {
                shop_addr: "",
                shop_name: "",
                shop_id: "",
                main_id: "",
                task_time_id: ""
            },
            timeForm: {
                opt_type: "add",
                dp_order_id: "",
                shopping_time: "",
                shopping_end_time: "",
                need_user_count: "",
                enter_end_time: ""
            },
            pic_file_list: [],
            taskForm: {
                task_name: "",
                enable_flag: 0,
                pic_file_list: [],
                daren_group_type: 0,
                platform_type: 1,
                min_fans_count: "0",
                min_reward: "",
                max_reward: "",
                browse_limit: "0",
                enter_request: "视频(20S)图片(>=9)文字(200+)",
                daren_score_limit: "0",
                tips_file_url: "",
                breif: "",
                content_select_type: 0,
                settle_account_type: 1
            },
            rows: {
                main_shop_id: "",
                shop_id: "",
                from_dx_order: false // 是否从点星订单的入口创建
            },
            file_name_fix: "",
            tips_file_url: "",
            tips_file_name: ""
        }
    },

    created() {
        this.on_created()
    },
    mounted() {
    },
    methods: {
        on_created(this: any) {
            const me = this
            me.field_list = []

            if (me.$props.row != undefined) {
                me.$data.rows = me.$props.row

                me.taskForm.task_name = me.$data.rows.dp_shop_name
                if (me.$data.rows.dp_shop_default_pic_url != "") {
                    me.taskForm.pic_file_list = [me.$data.rows.dp_shop_default_pic_url]
                }
                me.taskForm.min_fans_count = me.$data.rows.min_fans_count
                me.taskForm.enter_request = me.$data.rows.requirement
                me.timeForm.dp_order_id = me.$data.rows.order_id
                me.timeForm.need_user_count = me.$data.rows.need_user_count
                me.timeForm.enter_end_time = common.date_format(me.$data.rows.shopping_start_time - 86400)
                me.timeForm.report_end_time = me.$data.rows.content_submit_time
                this.getMainShopList(me, me.$data.rows.main_shop_id)
            } else {
                this.getMainShopList(me)
            }

        },
        exConfig(str: string) {
            this.field_ex_config = str
        },

        getTaskId() {
            var me = this
            common.do_ajax("/daren_ex/get_task_id", {
                shop_id: this.$data.form.shop_id,
                city_task_id: this.props.row.city_task_id
            }, function(resp: any) {
                if (resp.code === 0) {
                    me.$data.task_id = resp.data.task_id
                }
            })
        },

        getChildShop() {
            const me = this
            if (me.$props.row != undefined) {
                return true
            }

            common.do_ajax("/daren/shop_list", {
                main_shop_id: this.rows.main_shop_id,
                order: "desc",
                page_count: 100000
            }, function(resp: any) {
                if (resp.code === 0) {
                    console.log(resp)
                    me.childOptionList = <any>[
                        {
                            shop_id: "0",
                            shop_name: "请选择"
                        },
                        ...resp.data.list
                    ]
                }
            })
        },

        setMainShop() {


            if (!this.rows.main_shop_id || this.rows.main_shop_id === "0") {
                common.alert("请选择主店铺名称")
                return
            }
            if (!this.rows.shop_id || this.rows.shop_id === "0") {
                common.alert("请选择子店铺")
                return
            }
            common.do_ajax("/daren_ex/shop_set_main_shop_id", {
                main_shop_id: this.rows.main_shop_id,
                shop_id: this.rows.shop_id
            }, (_res: any) => {
                this.stepsActive += 1
            })
        },

        task_save() {
            const me: any = this
            const post_data = common.copy_obj(me.taskForm)
            var img_url = me.taskForm.pic_file_list[0] ?? ""
            const img = new Image()
            img.src = img_url

            post_data["task_pic_width"] = img.width
            post_data["task_pic_height"] = img.height
            post_data["opt_type"] = "add"
            post_data["main_shop_id"] = me.rows.main_shop_id
            post_data["shop_id"] = me.rows.shop_id
            post_data["field_ex_config"] = me.field_ex_config
            post_data["task_pic_url"] = me.taskForm.pic_file_list.join(",")
            post_data["need_user_count"] = me.timeForm.need_user_count
            post_data["enter_end_time"] = me.timeForm.enter_end_time

            const exConfig = me.field_ex_config ? JSON.parse(me.field_ex_config).filter((item: any) => item.type == "shop_date" && item.label) : []
            if (!me.taskForm.browse_limit) {
                common.alert("请填写30天浏览量")
                return
            }
            if (!me.taskForm.budget) {
                common.alert("请填写佣金预算")
                return
            }
            if (!me.timeForm.need_user_count) {
                common.alert("请填写招募人数")
                return
            }
            if (exConfig.length == 0) {
                common.alert("请选择到店日期")
                return
            }
            me.timeForm.shopping_time = exConfig[0].dateRange[0]
            console.log("post_data-----------", post_data)
            common.do_ajax("/daren_ex/opt_city_task", post_data, function(resp: any) {
                if (resp.code === 0) {
                    me.$emit("close")
                    me.$emit("update_list")
                    me.$message({
                        message: "创建成功",
                        type: "success"
                    })
                }
            })
        },

        setSteps(this: any) {
            this.stepsActive += 1
        },

        async confirmTask(this: any) {
            this.task_save()
        },

        confirm(this: any) {
            const fnObj: any = {
                1: this.setMainShop,
                2: this.confirmTask
            }
            fnObj[this.stepsActive]()
        },

        getMainShopList(that: any, main_shop_id = 0) {
            common.do_ajax("/user_manage/get_user_list", {
                type: "main_shop",
                main_type: + that.rows.city_code,
                page_count: "100000"
            }, function(resp: any) {
                if (resp.code === 0) {
                    that.mainOptionList = [
                        {
                            id: "0",
                            nick: "请选择"
                        },
                        ...resp.data.list
                    ]
                    that.rows.main_shop_id = `${main_shop_id}`
                }
            })
        },

        // 新增主店铺
        addMainShop() {
            const me: any = this

            var city_code = me.$data.rows.city_code != undefined ? me.$data.rows.city_code : ""
            var main_shop_name = me.$data.rows.shop_name != undefined ? me.$data.rows.shop_name : ""

            const field_list: field_item_list_t = [{

                field_type: "text",
                field_name: "main_shop_name",
                label: "主店铺名称",
                style: {
                },
                value: main_shop_name
            }, {
                field_type: "select",
                field_name: "city_code",
                ajax_url: "/daren_ex/picker_city_option_list",
                ajax_url_args: {
                    city_code_flag: 1
                },
                multiple: false,
                disabled: city_code != "",
                value: city_code,
                label: "城市",
                show_item_all: false, // [全部] 不显示
                style: {
                }
            }]
            const data = {}

            const title = "添加"
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: title,
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    if (!data.main_shop_name) {
                        common.alert("请填写主店铺名称")
                        return
                    }
                    data["opt_type"] = "add"
                    common.do_ajax("/daren_ex/opt_main_shop", data, function(_resp: any) {
                        me.getMainShopList(me, _resp.data.main_shop_id)
                    })
                    dlg.close()
                }
            })

        },

        // 新增子店铺
        addChildShop() {
            const me: any = this

            if (!me.rows.main_shop_id || me.rows.main_shop_id === "0") {
                common.alert("请先选择主店铺名称")
                return
            }
            const field_list: field_item_list_t = [
                {
                    field_type: "text",
                    field_name: "shop_name",
                    label: "店铺名称",
                    value: me.rows.shop_name,
                    style: {
                    }
                }, {

                    field_type: "text",
                    field_name: "address",
                    label: "店铺地址",
                    value: me.rows.address,
                    style: {
                    }
                }, {

                    field_type: "text",
                    field_name: "dp_shop_jump_url",
                    label: "店铺店铺url",
                    value: me.rows.dp_shop_jump_url,
                    style: {
                    },

                    placeholder: "类似:http://www.dianping.com/shop/H9Ge41xUt5RNnivt"

                }]
            const data = {}
            console.log(me.$props.row)
            const title = "添加"
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: title,
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    if (!data.shop_name) {
                        common.alert("请填写店铺名称")
                        return
                    }
                    if (!data.address) {
                        common.alert("请填写店铺地址")
                        return
                    }

                    data["opt_type"] = "add"
                    data["main_shop_id"] = me.rows.main_shop_id
                    // data["shop_id"] = me.rows.shop_id;
                    common.do_ajax("/daren/opt_shop", data, function(resp: any) {
                        if (resp.code === 0) {
                            me.rows.shop_name = data.shop_name
                            me.rows.address = data.address
                            me.getChildShop()
                        }
                    })
                    dlg.close()
                }
            })
        },

        // 修改子店铺
        editShop() {
            var me: any = this
            var field_list: field_item_list_t = [
                {
                    field_type: "text",
                    field_name: "shop_name",
                    label: "店铺名称",
                    value: me.rows.shop_name,
                    style: {
                        width: "400px"
                    }
                }, {

                    field_type: "text",
                    field_name: "address",
                    label: "店铺地址",
                    value: me.rows.address,
                    style: {
                        width: "400px"
                    }
                }]
            var data = {}
            console.log(me.props.row)
            var title = "添加"
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: title,
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    if (!data.shop_name) {
                        common.alert("请填写店铺名称")
                        return
                    }
                    if (!data.address) {
                        common.alert("请填写店铺地址")
                        return
                    }

                    data["opt_type"] = "set"
                    data["shop_id"] = me.rows.shop_id
                    common.do_ajax("/daren/opt_shop", data, function(resp: any) {
                        if (resp.code === 0) {
                            me.rows.shop_name = data.shop_name
                            me.rows.address = data.address
                        }
                    })
                    dlg.close()
                }
            })
        },

        on_close() {
            this.$emit("close", {})
        }

    }

})
