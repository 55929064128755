import { comment } from "postcss"
import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
}



export default defineComponent({
    components: {
    },

    props: {
        style: [Object, String]


    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            show_filter_popover: false
        }
    },
    computed: {




    },

    watch: {
        show_filter_popover(new_val: any) {
            // console.log("FFFF", new_val);
        }


    },
    created() {
        this.on_created()
    },
    mounted() {
        // this.on_mounted();
    },
    methods: {
        on_created() {
        }
    }

})

