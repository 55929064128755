import util from "./sys_util"

import { computed, defineComponent, h, markRaw, reactive, ref } from "vue"
import { column_item_t } from "./type_def"
import enum_map from "./enum_map"
// import admin_table from "@/components/Admin/table"
import common from "./common"
import { getToken } from "./auth"
import router from "../router"
var common_ex = {

    // 数据请求地址　请求参数　字段内容　下载文件名称　下载类型
    // down_type '下载类型0当前页，1所有',
    download_excel(visit_url: any, visit_paras: any, column_list: any, title: any = "", down_type: any = 0) {
        const column_config: any = {}

        column_list.forEach(function(item: any) {

            if (item.visible !== false) {
                console.log(item.label, item)
                const one_column: any = {
                    label: item.label,
                    label_title: item.label_title,
                    prop: item.prop
                }

                if (item.enum_type !== undefined) {
                    one_column["enum_type"] = item.enum_type
                } else if (item.filter_enum_type !== undefined) {
                    if (util.isString(item.filter_enum_type)) {
                        one_column["enum_type"] = item.filter_enum_type
                    } else if (util.isFunction(item.filter_enum_type)) {
                        console.log("filter_enum_type", item.filter_enum_type)
                    }
                }
                // component
                // if (item.component !== undefined) {
                // if (item.prop.indexOf('__') !== -1) {//除法运算
                if (item.formater !== undefined) {// 自定义显示

                }
                if (item.opt_list) {
                    const tmp_opt: any = {}
                    item.opt_list.forEach(function(opt: any) {
                        tmp_opt[opt.value] = opt.label
                    })

                    one_column["opt_list"] = tmp_opt
                }

                if (item.other_option) {
                    const tmp_other: any = {}
                    item.other_option.forEach(function(opt: any) {
                        tmp_other[opt.value] = opt.label
                    })

                    one_column["other_option"] = tmp_other
                }

                column_config[item.prop] = one_column
            }
        })

        console.log("download_excel_column:", column_config)
        // return false;
        const page_url = window.location.href

        visit_paras["var_session_id"] = getToken()

        common.do_ajax("/ajax_deal3/create_make_excel_job", {
            page_url: page_url,
            visit_url: visit_url,
            visit_paras: JSON.stringify(visit_paras),
            title: title,
            column_config: JSON.stringify(column_config),
            down_type: down_type
        }, function(resp: any) {
            if (resp.ret == 0) {
                common.auto_close_alert("success", "正在生成下载文件...")
            }
        })

    },

    gen_tree_field_render_header(_label: string, _page_vue: any) {

        /*

        var tree_field_render_header = function(createElement: CreateElement) {


            var table_tree_field_title = Vue.extend({
                props: {
                    title: String,
                },
                data: function() {
                    return {
                        show_all_flag: false
                    };
                },
                computed: {

                },
                methods: {
                    switch_flag() {
                        this.show_all_flag = !this.show_all_flag;
                        (<admin_table>page_vue.$refs.admin_table).tree_show(this.show_all_flag);

                    }
                },
                template: "<span>{{title}} <a  class=\" el-button--text \" @click=\"switch_flag\">{{show_all_flag?\"收缩\":\"展开\"}} </a> </span>",

            });
            return createElement(table_tree_field_title, {
                props: {
                    title: label
                }
            }, []);

        };
        return tree_field_render_header;
        */
    },
    gen_column_number_item(label: any, prop: any, sortable: boolean, click_callback?: any, config?: boolean | Object, label_title: any = null, width: any = null): column_item_t {
        let default_show = true
        let check_enable_click = function(_row: any) { return true }
        if (util.isBoolean(config)) {
            default_show = <boolean>config
        } else {
            const obj_config = Object.assign(
                {
                    default_show: true,
                    label_title: null,
                    width: null,
                    sortable: false,
                    check_enable_click: function(_row: any) { return true }
                }
                , config)
            label_title = label_title || obj_config.label_title
            width = width || obj_config.width
            default_show = obj_config.default_show == undefined ? default_show : obj_config.default_show
            check_enable_click = obj_config.check_enable_click
            sortable = obj_config.sortable
        }



        if (click_callback) {
            return {
                label: label, prop: prop,
                align: "right",
                sortable: sortable,
                default_show: default_show,
                label_title: label_title,
                min_width: width,
                component: markRaw(common.row_opt_gen_simgle_text(
                    function(row: any) {

                        if (row[prop] === undefined) {
                            return ""
                        }
                        return Math.floor(row[prop] * 100) / 100
                    }, {
                        click_callback: click_callback,
                        prop: prop,
                        check_enable_click: check_enable_click
                    }))
            }
        } else {
            return {
                label: label, prop: prop,
                align: "right",
                sortable: sortable,
                default_show: default_show,
                label_title: label_title,
                min_width: width,
                formater: function(value: any, item: any) {
                    item[prop] = parseInt(value)
                    if (isNaN(item[prop])) {
                        item[prop] = 0
                    }

                    return item[prop]
                }
            }
        }
    },



    gen_column_id_text_item(
        label: string, userid_prop: string, text_prop: string, config: any = {}
    ): column_item_t {
        const obj_config = Object.assign(
            {
                on_update: function() { },
                filter_enum_type: "",
                show_invite_flag: false,
                click_temlplate: undefined,
                click_callback: function(_row: any) { }

            }
            , config)

        obj_config.show_flag = ref(0)
        obj_config.reset_flag = false

        return {
            label: label,
            render_header: function() {

                const comp = defineComponent({
                    props: {
                        title: String
                    },
                    data: function() {
                        return {
                            show_flag: obj_config.show_flag
                        }
                    },
                    computed: {

                    },
                    methods: {
                        switch_flag(e: any) {
                            this.show_flag = (this.$data.show_flag + 1) % 2
                            e.stopPropagation()
                        }
                    },
                    template: "<span>{{title}} <a  class=\" el-button--text \" @click=\"switch_flag\">{{[\"name\", \"id\" ][show_flag]}} </a> </span>"
                })
                return h(comp, {
                    title: label
                })
            },
            width: "150px",
            prop: text_prop,
            sortable: config.sortable === false ? config.sortable : true,
            filter_enum_type: obj_config.filter_enum_type,
            filter_enum_multiple: false,
            fixed: config.fixed ? config.fixed : false,
            visible: config.visible === false ? config.visible : true,

            component: markRaw(common.row_opt_gen_simgle_text(
                function(row: any) {
                    if (obj_config.show_flag.value == 1) {
                        return row[userid_prop]

                    } else if (row[userid_prop]) {
                        return row[text_prop]
                    } else {
                        return row[text_prop]
                    }

                }, {

                    reset_flag: obj_config.reset_flag,
                    click_callback: function(row: any) {
                        obj_config.click_callback(row)
                    },
                    check_enable_click: obj_config.check_enable_click || function(row: any) {
                        return row[userid_prop] > 0
                    },

                    click_temlplate: obj_config.click_temlplate,
                    prop: userid_prop
                }))
        }
    },


    gen_column_daren_item(label: string, userid_prop: string, text_prop: string, config: any = {}): column_item_t {
        config.click_callback = config.click_callback || function(row: any) {
            common.dlg.show_daren_info(row[userid_prop])
        }
        return common_ex.gen_column_id_text_item(label, userid_prop, text_prop, config)

    },


    gen_column_bd_shop(label: string, userid_prop: string, text_prop: string, config: any = {}): column_item_t {
        config.click_callback = function(row: any) {
            console.log("bd_shop:", row)
            common.dlg.show_bd_shop_info(row[userid_prop])
        }
        return common_ex.gen_column_id_text_item(label, userid_prop, text_prop, config)

    },

    gen_column_task_time_item(label: string, userid_prop: string, text_prop: string, config: any = {}): column_item_t {
        config.click_callback = function(row: any) {

            common.dlg.show_task_time_info(row[userid_prop])
        }
        return common_ex.gen_column_id_text_item(label, userid_prop, text_prop, config)

    },




    do_batch_list(data_list: any, step: any, step_callback: (item: any, do_next: () => void) => void, config: any = {}) {


        const obj_config = Object.assign(
            {
                show_dlg: false,
                end_callback: function() { }
            }, config)


        const batch_info = common.get_batch_info(data_list, step)
        console.log("batch_info:", batch_info)

        const field_list = [{
            field_type: "label",
            field_name: "count",
            label: "id",
            value: batch_info.count
        }, {

            field_type: "text",
            field_name: "cur_count",
            label: "完成个数",
            value: 0
        }]


        let cur_index = -1
        const { list } = batch_info
        const data: any = reactive({})


        let dlg: any = null
        if (obj_config.show_dlg) {
            dlg = common.dlg.admin_show_key_table(field_list, data, {

                title: "批量分割处理",
                width: "400px",
                submit_text: "--",
                submit: function(_data: any, _btn_config: any) {
                }
            })
        }

        var do_next = function() {
            cur_index++
            if (cur_index >= list.length) {
                obj_config.end_callback(batch_info)
                // end
                if (dlg) {
                    dlg.close()
                }
            } else {
                const item = list[cur_index]
                data.cur_count = item.start_index
                step_callback(item, do_next)
            }
        }
        do_next()
    },

    get_url_desc_power_value(power_config: any, root_as_empty = true): Array<any> {
        if (root_as_empty && common.check_is_root_admin()) {
            return []
        }

        let power_config_ex: any = {}
        if (util.isObject(power_config)) {
            power_config_ex = {
                url: power_config.url,
                power_flag: power_config.power_flag
            }
        } else { // string
            power_config_ex = {
                url: null,
                power_flag: power_config
            }
        }

        if (!power_config_ex.url) power_config_ex.url = common.get_location_path()
        if (!power_config_ex.power_flag) power_config_ex.power_flag = "opt_download"
        const { url_to_id_map } = common.get_store_layout()

        if (!url_to_id_map) {
            return []
        }

        const { url_desc_power_value } = common.get_store_layout()
        if (!url_desc_power_value) {
            return []
        }
        const power_id = url_to_id_map[power_config_ex.url]
        if (!power_id) {
            return []
        }


        const url_power_desc_name_map = url_desc_power_value[power_id]

        if (util.isObject(url_power_desc_name_map)) {
            const value = url_power_desc_name_map[power_config_ex.power_flag]
            if (util.isArray(value)) {
                return value
            } else {
                return []
            }
        } else {
            return []
        }
    },

    page_store_set_key(key: string, value: any, fix = "", url = "", save_now_flag?: boolean) {
        const save_to_server = function() {
            const data = window.localStorage.getItem(store_key)
            const check_key = `last_save_info_${store_key}`
            if (data != (<any>window)[check_key]) {
                (<any>window)[check_key] = data

                const url = "/route__php_tars_admin/common/opt_page_store"
                /*
                if (common.check_env_is_dev()) {
                    url = "/page_common/opt_page_store ";
                }
                */
                common.do_ajax(url, {
                    opt_type: "set",
                    key: store_key,
                    data: data
                })
            }
        }
        var store_key = common.get_page_store_key(fix, url)

        if (key.length > 0) {
            const store = common.get_page_store(fix, url)
            if (JSON.stringify(value) != JSON.stringify(store[key])) {
                store[key] = value
                const json_str = JSON.stringify(store)
                window.localStorage.setItem(store_key, json_str)
                if (save_now_flag) {
                    save_to_server()
                } else {
                    setTimeout(save_to_server, 1000)
                }

            }
        } else {
            window.localStorage.setItem(store_key, JSON.stringify(value))
            save_to_server()
        }

    },


    websocket(url: any, s: any, protocols?: any): any {
        let ws: WebSocket | any
        const window_: any = window
        if (protocols) {
            ws = window_["MozWebSocket"] ? new window_["MozWebSocket"](url, protocols) : window_["WebSocket"] ? new WebSocket(url, protocols) : null
        } else {
            ws = window_["MozWebSocket"] ? new window_["MozWebSocket"](url) : window_["WebSocket"] ? new WebSocket(url) : null
        }

        const settings: any = {
            open: function() { },
            close: function() { },
            message: function() { },
            error: function(_e: any) { },
            options: {},
            events: {}
        }

        Object.assign(settings, s)
        ws.onerror = settings.error
        ws.onopen = settings.open
        ws.onclose = settings.close
        ws.onmessage = function(e: any) {
            const m = JSON.parse(e.data)
            const h = settings.events[m.type]
            if (h) h.call(this, m)
        }

        ws["_send"] = ws.send
        ws["send"] = function(type: any, data?: any): void {
            let m: any = { type: type }
            m = Object.assign(m, Object.assign({}, settings.options, m))
            if (data) m["data"] = data
            const out_str = JSON.stringify(m)
            // alert(out_str);
            return this["_send"](out_str)
        }
        return ws
    },
    page_store_get_key(key: string, default_value: any, fix = "", url = ""): any {
        const store_key = common.get_page_store_key(fix, url)
        let store = common.get_page_store(fix, url)


        const now = (new Date()).getTime() / 1000

        if (
            (!store.hasOwnProperty("_last_update_time")
                || store["_last_update_time"] < now - 600
                || store["_last_update_time"] > now + 10)

            || ((!store.hasOwnProperty("_current_account")
                || store["_current_account"] != common.get_account())
            )

        ) { // 最后更新时间
            // 同步

            var url = `${common.get_api_url()}/route__php_tars_admin/common/opt_page_store`
            /*
            if (common.check_env_is_dev()) {
                var url = common.get_api_url() + "/cc/page_common/opt_page_store";
            }

            */



            $.ajax({
                url: url,
                data: {
                    opt_type: "get",
                    key: store_key
                },
                beforeSend: function(xhr: any) {
                    xhr.setRequestHeader("Session-Id", getToken())
                },

                method: "post",
                async: false,
                success: function(resp) {
                    // console.log("LLLL");
                    try {
                        store = JSON.parse(resp["data"])

                        if (!util.isObject(store)) {
                            store = {}
                        }
                    } catch (e) {
                        store = {}
                    }
                    store["_last_update_time"] = (new Date()).getTime() / 1000
                    store["_current_account"] = common.get_account()
                    window.localStorage.setItem(store_key, JSON.stringify(store))
                }
            })
        }

        if (key.length > 0) {
            let ret = store[key]
            if (ret === undefined) {
                ret = default_value
            }
            return ret
        } else {
            return store
        }
    },

    date_format: function(unixtime: any, fmt?: any) {
        if (!unixtime) {
            return "无"
        }
        let date_v: any = null
        if (fmt === undefined) {
            fmt = "yyyy-MM-dd"
        } else if (fmt === true) {
            fmt = "yyyy-MM-dd hh:mm:ss"
        }

        if (typeof unixtime == "object") {
            date_v = unixtime
        } else {
            var d = new Date()
            var localOffset = d.getTimezoneOffset() * 60
            date_v = new Date((unixtime + localOffset + 3600 * 8) * 1000)
        }

        const o: any = {
            "M+": date_v.getMonth() + 1, // 月份
            "d+": date_v.getDate(), // 日
            "h+": date_v.getHours(), // 小时
            "m+": date_v.getMinutes(), // 分
            "s+": date_v.getSeconds(), // 秒
            "q+": Math.floor((date_v.getMonth() + 3) / 3), // 季度
            S: date_v.getMilliseconds() // 毫秒
        }
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (`${date_v.getFullYear()}`).substr(4 - RegExp.$1.length))
        for (const k in o)
            if (new RegExp(`(${k})`).test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)))
        return fmt
    },


    get_enum_option_list(field_name: any, add_all_item: boolean, item_list?: Array<any>): any {
        let opt_list: any = {}
        if ((<any>enum_map)[field_name]) {
            opt_list = (<any>enum_map)[field_name].desc_map
        }


        const ret_list: Array<any> = []
        if (add_all_item) {
            ret_list.push({
                label: "[全部]",
                value: -1
            })
        }
        if (!item_list || item_list.length == 0) {
            for (const key in opt_list) {
                ret_list.push({
                    label: opt_list[key],
                    value: parseInt(key)
                })
            }
        } else {

            item_list.forEach(function(key: any) {
                if (opt_list[key] !== undefined) {
                    ret_list.push({
                        label: opt_list[key],
                        value: parseInt(key)
                    })
                }
            })
        }
        console.log("enum_option_list", ret_list)
        if (ret_list.length == 0) {
            return undefined
        } else {
            return ret_list
        }
    },


    load_js_list(js_list: any, callback: any) {
        let cur_index = 0

        // 加载脚本
        const loadScript = function(url: any, callback: any) {
            const script: any = document.createElement("script")
            script.type = "text/javascript"
            if (script["readyState"]) { // IE
                script["onreadystatechange"] = function() {
                    if (script["readyState"] == "loaded" || script["readyState"] == "complete") {
                        script["onreadystatechange"] = null
                        callback()
                    }
                }
            } else { // Others
                script.onload = function() {
                    callback()
                }
            }
            script.src = url
            document.getElementsByTagName("head")[0].appendChild(script)
        }

        // 按顺序加载文件
        var do_next_funcion = function() {
            if (cur_index >= js_list.length) {
                callback()
                return
            }
            const js_file = js_list[cur_index]

            loadScript(js_file, function() {
                cur_index++
                do_next_funcion()
            })
        }
        do_next_funcion()
    },

    wopen(url: string, open_self_window: any = false) {
        if (open_self_window == "new_window") {
            open_self_window = false
        } else {
            if (common.in_phone()) {
                open_self_window = true
            }
        }

        let full_url = ""
        const url_arr = url.split("?", 2)
        const path = url_arr[0]
        if (url[0] == "/") { // 是本地url
            full_url = common.path_url_map[path]
            if (full_url && url_arr[1]) { // 加上参数
                full_url += `?${url_arr[1]}`

            }
        }


        if (open_self_window) {
            if (url[0] == "/") { // 是本地url
                if (router.currentRoute.value.path == path) { // 同一个url
                    router.push("/blank") // 用空白页过度..
                    setTimeout(function() {
                        router.push(url)
                    }, 0)


                } else {
                    // 找实际跳转的地址
                    if (full_url) {
                        window.open(full_url, "_self")
                    } else {
                        router.push(url)
                    }
                }

            } else {
                window.open(url, "_self")
            }
        } else if (url[0] == "/") { // 是本地url

            if (full_url) {
                window.open(full_url)
            } else {
                const obj_url = `${window.location.href.split("#")[0]}#${url}`
                window.open(obj_url)
            }

        } else {
            window.open(url)
        }
    },



    check_url_desc_power(power_config: any): boolean {
        let power_config_ex: any = {}
        if (util.isArray(power_config)) {
            power_config_ex = {
                url: power_config[0],
                power_flag: power_config[1]
            }
        } else if (util.isObject(power_config)) {
            power_config_ex = {
                url: power_config.url,
                power_flag: power_config.power_flag
            }
        } else { // string

            power_config_ex = {
                url: null,
                power_flag: power_config
            }
        }
        if (common.check_is_root_admin()) {
            return true
        }

        if (!power_config_ex.url) power_config_ex.url = common.get_location_path()

        if (!power_config_ex.power_flag) power_config_ex.power_flag = "opt_download"

        const { url_to_id_map } = common.get_store_layout()
        if (!url_to_id_map) {
            return false
        }

        const { url_desc_power } = common.get_store_layout()
        if (!url_desc_power) {
            return false
        }

        const power_id = url_to_id_map[power_config_ex.url]
        if (!power_id) {
            return false
        }


        const url_power_list = url_desc_power[power_id]


        if (util.isArray(url_power_list)) {
            let find_flag = false
            url_power_list.forEach(function(power: any, _i: any) {
                if (power == power_config_ex.power_flag) {
                    find_flag = true
                }
            })
            return find_flag
        } else {
            return false
        }
    },

    row_opt_gen_simgle_text(gen_text_func: any, config: any = null, tip_info_callback: any = null, prop: any = "", show_null_flag = false): any {

        let click_callback: any = null
        let check_enable_click: any = function() { return true }
        let reset_flag: any = null
        let click_temlplate: any = null
        if (util.isFunction(config)) {
            click_callback = config
        } else {
            const obj_config = Object.assign(
                {
                    click_callback: function() { },
                    tip_info_callback: null,
                    prop: "",
                    check_enable_click: function(_row: any) { return true },
                    show_null_flag: false,
                    reset_flag: false,
                    click_temlplate: null
                }, config)
            click_callback = click_callback || obj_config.click_callback
            tip_info_callback = tip_info_callback || obj_config.tip_info_callback
            prop = prop || obj_config.prop
            check_enable_click = obj_config.check_enable_click
            reset_flag = obj_config.reset_flag
            click_temlplate = obj_config.click_temlplate
            show_null_flag = obj_config.show_null_flag


        }

        var template = "<span> "
            + "<span  v-if=\"enable_click && !click_temlplate \" "
            + "  style=\"  cursor: pointer; \" class=\"el-button--text\" @click=on_click   v-html=\"html_str\"> </span> "

            + "<span  v-else-if=\"enable_click && click_temlplate \"> "

            + "    <span v-html=\"html_str\"> </span> "

            + "    <span    "
            + "      style=\"  cursor: pointer; \" class=\"el-button--text\" @click=on_click   v-html=\"click_temlplate\"> "
            + "    </span> "
            + " </span> "

            + " <span v-else  v-html=\"html_str\"> </span> "
            + " </span>"

        if (tip_info_callback) {

            var template = "<span><a style=\"  cursor: pointer; \" class=\"el-button--text\" :title=\"tip_content\" @click=on_click  v-html=\"html_str\" ></a> </span>"
            /*
            var item_html = "<span  slot=\"reference\" style=\"  cursor: pointer; \" class=\"el-button--text\"  >{{html_str}} </span>";
            template = "<el-popover placement=\"top-start\" width=\"200\" trigger=\"hover\" > <div v-html=\"tip_content\"> </div>  " + item_html + " </el-popover>";
            */

        }
        // console.log(template);


        return defineComponent({
            props: {
                row: Object,
                col: Object,
                column: Object
            },
            setup(props) {

                const html_str = computed(() => {
                    const str = $.trim(gen_text_func(props.row, prop, reset_flag))
                    if (show_null_flag) {
                        return str
                    } else if (str === "") {
                        return "--"
                    } else {
                        return str
                    }

                })

                const tip_content = computed(() => {
                    return tip_info_callback(props.row, prop)
                })
                const enable_click = computed(() => {
                    return check_enable_click(props.row, prop)
                })
                const on_click = function() {
                    if (click_callback) {
                        click_callback(props.row, props.col, prop)
                    }
                }



                return {
                    html_str,
                    enable_click,
                    tip_content,
                    on_click,
                    click_temlplate
                }

            },
            /*
            computed: {
                html_str: function(ctx) {
                    var props: any = this.$props;
                    var str = $.trim(gen_text_func(props.row, prop, this.reset_flag));
                    if (show_null_flag) {
                        return str;
                    } else {
                        if (str === "") {
                            return "--";
                        } else {
                            return str;
                        }

                    }
                },
                enable_click: function() {
                    return check_enable_click(this.$props.row);
                },
                tip_content: function() {
                    return tip_info_callback(this.$props.row, prop);
                }

            },
            */
            /*
            methods: {
                on_click: function() {
                    if (click_callback) {
                        var props: any = this.$props;
                        click_callback(props.row, this.$props.col, prop);
                    }
                }
            },
            */
            template: template

        })
    }

}
export default common_ex
