import admin_vue from "@/components/Admin/admin_vue"
import DateTimePicker from "@/components/DateTimePicker.vue"
import common from "@/utils/common"
import { defineComponent } from "vue"


interface IProps {
    config: Array<any>,
    row: string,
}



export default defineComponent({
    components: {
        DateTimePicker
    },
    extends: admin_vue,


    props: {

        config: {
            type: Array,
            default: function() {
                return []
            }
        },
        row: String

    },
    // event
    emits: ["input"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            in_phone: common.in_phone(),
            in_mobile_page: common.check_in_mobile_page(),
            config_list: <Array<any>>[{
                field_name: "field_ex1_value",
                name: "字段一",
                type: "shop_date",
                label: "到店时间",
                placeholder: "",
                require: true
            }, {
                field_name: "field_ex2_value",
                name: "字段二",
                type: "text",
                label: "",
                placeholder: "",
                require: false
            }, {
                field_name: "field_ex3_value",
                name: "字段三",
                type: "text",
                label: "",
                placeholder: "",
                require: false
            }, {
                field_name: "field_ex4_value",
                name: "字段四",
                type: "text",
                label: "",
                placeholder: "",
                require: false
            }],
            type_list: [
                {
                    label: "文字",
                    value: "text"
                },
                {
                    label: "到店日期",
                    value: "shop_date"
                }
            ],
            require_list: [
                {
                    label: "非必填",
                    value: false
                },
                {
                    label: "必填",
                    value: true
                }
            ],
            date: ""
        }
    },
    computed: {
        moblie_class() {

            if (common.check_in_mobile_page()) {
                return "in-mobile-page"
            } else {
                return "not-in-mobile-page"
            }

        }
    },
    watch: {
        date: {
            handler: function(new_val: any, _old_val: any) {
                console.log(new_val)
            }
        }
    },
    created: function() {
        console.log("this.props", this.props)
        let row: any = ""
        if (this.props.row) {
            row = JSON.parse(this.props.row)
            this.$data.config_list = this.props.config
            row.map((item: any, k: any) => {
                this.$data.config_list.splice(k, 1, item)
            })
            console.log("KKKK 1111", row, this.$data.config_list)
        }
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        modelValueFn(this: any, data: any) {
            console.log("arr1111", data)
            this.$data.config_list[data.index].dateRange = data.date
            this.inputList()
        },
        on_created() {
        },
        on_mounted() {
        },
        inputTimeList(event: any, target: any) {
            this.$data.config_list.map((item: any) => {
                if (item.type == "shop_date") {
                    item[target] = event
                    if (item["startTime"] && item["endTime"]) {

                        const start = item["startTime"].split(":")
                        const end = item["endTime"].split(":")

                        let startH = start[0]
                        const endH = end[0]

                        const startM = start[1]
                        const endM = end[1]

                        const copyS = startH
                        const copyD = endH
                        const hArr = []
                        const mArr = []
                        for (let i = 0; i <= (copyD - copyS); i++) {
                            hArr.push(startH++)
                        }
                        if (item["startTime"] == item["endTime"]) {
                            mArr.push(startM)
                        }
                        item["hArr"] = hArr
                        item["mArr"] = mArr
                    } else {
                        item["hArr"] = []
                        item["mArr"] = []
                    }
                }
            })
            this.$emit("input", JSON.stringify(this.$data.config_list))
        },
        inputList() {
            let disabledDate: any = []
            const disabled_list = this.$data.config_list.filter(item => {
                return (item.type == "shop_date" && item.disabledDate && item.disabledDate.length)
            })
            if (disabled_list.length) {
                disabledDate = disabled_list[0].disabledDate.map((item: any) => {
                    return common.date_format(new Date(item), "yyyy-MM-dd")
                })
                this.$data.config_list.map(item => {
                    if (item.type == "shop_date") {
                        item.disabledDate = disabledDate
                    }
                })
            }
            console.log("config_list", this.$data.config_list)
            this.$emit("input", JSON.stringify(this.$data.config_list))
        }


    }

})
