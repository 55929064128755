import { defineComponent } from "vue"
import { field_item_list_t } from "/@/utils/type_def"

import field_item from "./field_item.vue"
import common from "/@/utils/common"


interface IProps {
    field_list: field_item_list_t,
    data: any,
    default_span_num: Number;
}



export default defineComponent({

    name: "FieldList",
    components: {
        "field-item": field_item
    },

    props: {

        field_list: {
            type: Array,
            required: true
        },

        data: {
            type: Object,
            required: true
        },
        default_span_num: {
            type: Number,
            required: false,
            default: function() {
                return 24
            }

        }



    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            in_phone: common.in_phone()
        }
    },
    computed: {

    },

    watch: {


    },
    created() {
        // this.on_created();
    },
    mounted() {
        // this.on_mounted();
    },
    methods: {

    }

})

/*
import Vue from 'vue'
import Component from 'vue-class-component'

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({

  props: {
    name: {
      type: String,
      required: true
    },
  },
  computed: {
    icon_class() {
      if (this.$props.name ) {
        return 'iconfont ' + this.$props.name;
      } else {
        return 'iconfont'
      }
    }
  }
})
export default class extends Vue {

}
*/
