import admin_row_option_list from "./components/Admin/row_opt_list.vue"

import admin_select_tree from "./components/Admin/admin_select_tree.vue"
import admin_table from "./components/Admin/table.vue"
import admin_enum_select from "./components/Admin/enum_select.vue"
import admin_user_select from "./components/Admin/user_select.vue"
import admin_date_picker from "./components/Admin/date_picker.vue"
import query_inputlist from "./components/Admin/query_inputlist.vue"
import admin_ajax_table_select from "./components/Admin/ajax_table_select.vue"
import admin_config_item_table from "./components/Admin/config_item_table.vue"
import admin_icon from "./components/Admin/icon.vue"
import admin_admin_group from "./components/Admin/admin_group_select.vue"
import admin_cascader from "./components/Admin/cascader.vue"
import admin_shop_group from "./components/Admin/shop_group_select.vue"
import admin_pic_list from "./components/Admin/pic_list.vue"
import admin_preview_list from "./components/Admin/admin_preview_list.vue"
import admin_alioss_upload from "./components/Admin/alioss_upload.vue"
import admin_autocomplete from "./components/Admin/autocomplete.vue"

import admin_upload from "./components/Admin/upload.vue"

import admin_van_select from "./components/vant/van_select.vue"
import admin_van_list from "./components/vant/van_list.vue"
import admin_van_sort from "./components/vant/van_sort.vue"
import admin_menu_fold from "./components/vant/menu_fold.vue"
import admin_van_navbar from "./components/vant/van_navbar.vue"
import admin_van_filter from "./components/vant/van_filter.vue"
import admin_van_datetime from "./components/vant/van_datetime.vue"
import common from "./utils/common"


export function bind_component(app: any) {
    app.component("AdminTable", admin_table)
    app.component("AdminRowOptionList", admin_row_option_list)
    app.component("AdminEnumSelect", admin_enum_select)
    app.component("AdminUserSelect", admin_user_select)
    app.component("AdminDatePicker", admin_date_picker)
    app.component("AdminAjaxTableSelect", admin_ajax_table_select)
    app.component("AdminQueryInputList", query_inputlist)
    app.component("AdminConfigItemTable", admin_config_item_table)
    app.component("AdminIcon", admin_icon)
    app.component("AdminGroup", admin_admin_group)
    app.component("AdminShopGroup", admin_shop_group)
    app.component("AdminCascader", admin_cascader)

    app.component("AdminPicList", admin_pic_list)
    app.component("AdminPreviewList", admin_preview_list)
    app.component("AdminAliossUpload", admin_alioss_upload)
    app.component("AdminUpload", admin_upload)
    app.component("AdminAutocomplete", admin_autocomplete)
    app.component("AdminVanSelect", admin_van_select)
    app.component("AdminVanList", admin_van_list)
    app.component("AdminVanSort", admin_van_sort)
    app.component("AdminMenuFold", admin_menu_fold)
    app.component("AdminVanNavbar", admin_van_navbar)
    app.component("AdminVanFilter", admin_van_filter)
    app.component("AdminVanDatetime", admin_van_datetime)

    // 自定义指令, 没有权限就不显示
    app.directive("permission", {
        mounted(el: any, binding: any) {
            if (!common.check_url_desc_power(binding.value)) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        }
    })

}
export {
    admin_enum_select,
    admin_user_select,
    admin_table,
    admin_row_option_list,
    admin_date_picker,
    admin_config_item_table,
    admin_icon,
    admin_alioss_upload,
    admin_pic_list,
    admin_preview_list,
    admin_autocomplete,
    admin_select_tree,
    admin_shop_group,
    admin_cascader,

    admin_van_select,
    admin_van_list,
    admin_van_sort,
    admin_menu_fold,
    admin_van_navbar,
    admin_van_filter,
    admin_van_datetime
}
