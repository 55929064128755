import { defineComponent } from "vue"

interface IProps {
    value: Array<any>,
}

// @Component 修饰符注明了此类为一个 Vue 组件
export default defineComponent({
    components: {
    },
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props
        }
    },

    data() {
        return {
            video: "",
            dialogVisible: false
        }
    },

    computed: {

    },

    created() {

    },

    methods: {
        isImg(item: string) {
            const splitItem = item.split(".")
            if (splitItem[splitItem.length - 1] == "mp4") {
                return "video"
            } else {
                return "img"
            }
        },
        imgList() {
            const list: any = []

            this.props.value.map((item: any) => {
                if (this.isImg(item) == "img") {
                    list.push(item)
                }
            })
            return list
        },
        preview_video(this: any, item: any) {
            this.dialogVisible = true
            this.video = item
        }
    }

})
