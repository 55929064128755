import { defineComponent, markRaw, nextTick } from "vue"
import { ElButton, ElButtonGroup, ElCheckbox, ElCheckboxGroup, ElOption, ElOptionGroup, ElRadio, ElRadioGroup, ElSelect } from "element-plus"
import common from "/@/utils/common"
import sys_util from "/@/utils/sys_util"


interface IProps {

    modelValue: any
    opt_component: any
    item_style: any
    opt_style: any
    value_is_number: boolean
    display_type: string
    can_change_multiple: boolean
    remote: boolean
    loading: boolean
    field_name: string
    multiple: boolean
    ajax_url: string
    deal_ajax_resp_for_opt_list: Function
    ajax_url_args: Object
    show_item_all: boolean

    opt_list: Array<any>
    item_list: Array<any>
    enum_type: string
    other_option: Array<any>
    on_change: Function
    on_focus: Function
}


export default defineComponent({
    components: {
        ElSelect,
        ElCheckboxGroup,
        ElCheckbox,
        ElRadio,
        ElButtonGroup,
        ElOption,
        ElOptionGroup,
        ElRadioGroup,
        ElButton
    },

    props: {
        opt_component: {
            type: Object,
            required: false,
            default: function() {
                return null
            }

        },

        ajax_url_args: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },
        ajax_url: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        remote: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        deal_ajax_resp_for_opt_list: {
            type: Function,
            required: false,
            default: function(resp: any) {
                const opt_list: Array<any> = [];

                (<Array<any>>resp.data.list).forEach(function(item) {
                    opt_list.push({
                        label: item.label, value: item.value
                    })
                })
                return opt_list
            }
        },

        value_is_number: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }

        },
        item_style: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },
        opt_style: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },

        display_type: {
            type: String,
            required: false,
            default: function() {
                return "select"
            }
        },

        field_name: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        on_change: {
            type: Function,
            required: false,
            default: function() {
                return null
            }
        },
        on_focus: {
            type: Function,
            required: false,
            default: function(_val: any) {
                console.log("focus")
            }
        },

        modelValue: [Array, Number, String],
        enum_type: String,
        can_change_multiple: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },
        multiple: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },

        readonly: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },

        placeholder: {
            type: String,
            required: false,
            default: function() {
                return "请选择"
            }
        },


        show_item_all: { // 全部 -选项
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        item_list: {
            type: Array,
            required: false,
            default: function() {
                return undefined
            }
        },


        opt_list: {
            type: Array,
            required: false,
            default: function() {
                return undefined
            }
        },
        span_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },


        other_option: {
            type: Array,
            required: false,
            default: function() {
                return undefined
            }
        },



        // 备注个数
        tip_list: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },
        disabled: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }

        }


    },
    // event
    emits: ["update:modelValue", "query", "remoteMethod"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props,

            // 自定义的
            create_menu_flag: false


        }
    },

    data: function() {
        return {
            value: <any>0,
            option_list: <Array<any>>[],
            last_value: <Array<any>>[],
            multiple_inter: false

        }

    },
    computed: {

        desc_class: function() {

            if (common.check_in_mobile_page()) {
                return "mobile-enum-select-item"
            } else {
                return ""
            }

        },
        item_style_value: function(): any {
            if (!this.props.item_style.width) {
                this.props.item_style.width = "120px"
            }
            return this.props.item_style
        },
        raw_opt_component(): any {
            return markRaw(this.props.opt_component)
        }




    },

    watch: {
        value: function(new_val, old_val) {
            if (this.props.value_is_number) {
                if (!sys_util.isArray(new_val)) {
                    if (isNaN(new_val)) {
                        new_val = []
                    }
                }
            }

            this.check_value(new_val, old_val)
        },

        ajax_url_args: function() {
            if (this.props.ajax_url) {
                this.load_from_ajax()
            }

        },
        opt_list: function(_new_val: any, _old_val: any) {
            this.init()
        },
        item_list: function() {
            this.init()
        },

        enum_type: function() {
            this.init()
        },
        modelValue: function() {
            this.set_value_select()
        }


    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },

    methods: {
        remoteMethod(query: any) {
            this.$emit("remoteMethod", query)
        },

        get_value_item(value: any) {
            if (this.props.value_is_number) {
                return parseInt(value)
            } else {
                return value
            }

        },
        get_multiple_inter() {
            if (this.props.display_type == "checkbox") {
                return true
            }

            if (this.props.can_change_multiple) {
                return common.page_store_get_key(`field-select-multiple-${this.props.field_name}`, this.props.multiple)
            } else {
                return this.props.multiple

            }
        },

        get_multiple() {
            return this.$data.multiple_inter

        },

        set_value_select() {

            const { modelValue } = this.props
            let value: any = null
            if (this.get_multiple()) { // 多选
                if (sys_util.isString(modelValue)) {
                    value = common.str_as_int_array(modelValue)
                } else if (sys_util.isArray(modelValue)) {
                    value = common.as_int_array(modelValue)
                    // 数组,检查是否一致,不然不更新, 更新会造成死循环 :<
                    if (common.check_same_array(value, this.$data.value)) {
                        return
                    }

                } else if (sys_util.isNumber(modelValue)) {
                    value = [modelValue]
                } else {
                    value = []
                }
                this.$data.value = value


            } else {
                if (sys_util.isString(modelValue)) {
                    value = this.get_value_item(modelValue)
                } else if (sys_util.isArray(modelValue)) {
                    value = this.get_value_item(modelValue[0])
                } else if (sys_util.isNumber(modelValue)) {
                    value = modelValue
                }
                this.$data.value = value
            }


        },

        get_all_item_value() {
            if (this.props.value_is_number) {
                return -1
            } else {
                return ""
            }

        },
        // 从ajax 中加载
        load_from_ajax() {

            const me = this
            common.do_ajax(this.props.ajax_url, this.props.ajax_url_args, function(resp: any) {
                // var opt_list: Array<any> = [];
                const value_arr: Array<any> = []

                // 可以传来方法处理数据
                me.$data.option_list = me.props.deal_ajax_resp_for_opt_list(resp)
                if (me.props.other_option) {
                    me.$data.option_list = me.props.other_option.concat(me.$data.option_list)
                }

                if (me.props.show_item_all) {
                    (<Array<any>>me.$data.option_list).unshift({
                        label: "[全部]",
                        value: me.get_all_item_value()
                    })
                }


                me.set_change_multi_select_item()

                console.log("lllllllllllllll", me.$data.option_list)
                // (<Array<any>>resp.list).forEach(function(item) {
                //     opt_list.push({
                //         label: item.label, value: item.value,
                //     });
                //     value_arr.push(item.value);
                // });
                // me.$data.option_list = opt_list;
                me.$data.option_list.forEach(function(item: any) {
                    value_arr.push(item.value)
                })

                if (!me.get_multiple_inter()) { // 不是多选
                    let reload_flag = false
                    if (value_arr.length > 0) {
                        if (value_arr.findIndex(function(v) {
                            return me.$data.value == v

                        }) == -1) {
                            me.$data.value = value_arr[0]
                            reload_flag = true
                        }
                    } else {
                        me.$data.value = 0
                        reload_flag = true
                    }
                    if (reload_flag) {
                        nextTick(function() {
                            me.query()
                        })
                    }
                }

            })

        },
        init() {

            this.$data.multiple_inter = this.get_multiple_inter()

            if (this.props.ajax_url) { // 定义异步加载
                this.load_from_ajax()
            } else if (this.props.opt_list) { // 只定义列表
                if (!this.props.item_list) {
                    this.$data.option_list = common.copy_obj(this.props.opt_list)
                    if (this.props.show_item_all) {
                        (<Array<any>>this.$data.option_list).unshift({
                            label: "[全部]",
                            value: this.get_all_item_value()
                        })
                    }

                } else {
                    const map: any = {}
                    this.props.item_list.forEach(function(key: any) {
                        map[key] = true
                    })
                    this.$data.option_list = []

                    for (const key in this.props.opt_list) {
                        const item = this.props.opt_list[key]
                        if (map[item["value"]] === true) {
                            this.$data.option_list.push(item)
                        }
                    }

                }

            } else {
                this.$data.option_list = common.get_enum_option_list(this.props.enum_type,
                    this.props.show_item_all, this.props.item_list)
                if (this.props.other_option) {
                    this.$data.option_list = this.props.other_option.concat(this.$data.option_list)
                }
            }


            this.set_change_multi_select_item()
            console.log("opt_list-----------", JSON.stringify(this.$data.option_list))
            this.set_value_select()

        },
        set_change_multi_select_item() {

            if (this.props.can_change_multiple && this.$data.option_list.length > 3) {
                if (this.$data.multiple_inter) {
                    this.$data.option_list.push({
                        label: "[切换为单选]",
                        value: -10
                    })
                } else {
                    this.$data.option_list.push({
                        label: "[切换为多选]",
                        value: -10
                    })
                }
            }

        },
        on_created() {
            this.init()

        },


        on_visible_change(flag: any) {

            if (flag == true) { // show
                this.create_menu_flag = true
                this.$data.last_value = this.$data.value
            } else if (flag == false && this.create_menu_flag) {
                this.create_menu_flag = false
                if (this.get_multiple()) {
                    let noti_flag = false
                    console.log(this.$data.last_value, this.$data.value)
                    if (this.$data.last_value.length == this.$data.value.length) {
                        const map: any = {}
                        this.$data.last_value.forEach(function(key: any) {
                            map[key] = true
                        })
                        this.$data.value.forEach(function(key: any) {
                            if (map[key] === undefined) {
                                noti_flag = true
                            }
                        })
                    } else {
                        noti_flag = true
                    }
                    if (noti_flag) {
                        if (this.$data.value.length == 0) {
                            this.$data.value = [-1]
                            nextTick(() => {
                                this.query()
                            })
                        } else {
                            this.query()
                        }
                    }

                } else if (this.$data.last_value != this.$data.value) {
                    this.query()
                }
            }
        },

        query() {

            if (this.props.on_change) {
                this.props.on_change(this.$data.value)
            }
            this.$emit("query", {
                field_name: this.props.field_name
            })
        },

        check_value(new_val: Array<number> | number, old_val: Array<number> | number) {


            let query_flag = false
            if (this.props.enum_type == "boolean" && this.props.show_item_all == false) {
                if (this.props.modelValue != new_val) {
                    this.$emit("update:modelValue", new_val)
                    this.query()
                }
                return

            }


            if (sys_util.isString(new_val)) {
            } else if (!sys_util.isObject(new_val) && isNaN(<any>new_val)) {

                // this.$data.value = [];
                // console.error("3333333333");
                return


            } else if (sys_util.isObject(new_val) || sys_util.isArray(new_val)) {

                if (common.in_array(-10, <any>new_val)) {// 切换为单选/多选
                    this.change_multi_select_config()
                }
                const all_value_item = -1
                if ((<Array<number>>new_val).length > 1) {
                    if (sys_util.isArray(old_val) && (<Array<any>>old_val).indexOf(all_value_item) !== -1) { // 原来有选全部
                        var all_item_index = (<Array<number>>new_val).indexOf(all_value_item)
                        if (all_item_index !== -1) { // 现在还有全部
                            (<Array<number>>new_val).splice(all_item_index, 1)
                            this.$data.value = new_val
                        }
                    } else {
                        var all_item_index = (<Array<number>>new_val).indexOf(all_value_item)
                        if (all_item_index !== -1) {
                            new_val = [all_value_item]
                            this.$data.value = new_val;
                            // TODO
                            (<any>this.$refs.select).blur()
                            query_flag = true

                        }

                    }
                }
            } else if (new_val == -10) {// 切换为单选/多选
                this.change_multi_select_config()
            }

            this.$emit("update:modelValue", new_val)
            if (query_flag) {
                this.query()
            }
        },

        on_mounted() {

        },

        btn_click(select_change: any) {
            if (this.get_multiple()) {

            } else {
                this.$data.value = select_change
                this.$emit("update:modelValue", select_change)
                this.query()
            }
        },
        get_btn_type(item: any) {
            if (this.get_multiple()) {
                return this.$data.value.indexOf(item.value) !== -1 ? "primary" : null
            } else {
                return this.$data.value == item.value ? "primary" : null
            }
        },
        check_set_all() {
            if (this.props.show_item_all) {
                let value: any = null
                if (this.get_multiple()) {
                    value = [-1]
                } else {
                    value = -1
                }
                this.$emit("update:modelValue", value)
            }


        },
        on_remve_tag() {
            nextTick(() => {
                nextTick(() => {
                    if (this.$data.value.length == 0) {
                        this.check_set_all()
                    }
                    this.query()
                })
            })
        },
        change_multi_select_config() {
            common.page_store_set_key(`field-select-multiple-${this.props.field_name}`, !this.$data.multiple_inter)
            window.location.reload()
        },
        in_phone() {
            return common.in_phone()
        },

        radio_or_chackbox(value: any) {
            this.props.on_change(value)
        },

        focus(val: any) {
            this.props.on_focus(val)
        },
        do_click() {
            if (<any>this.$refs.select) {
                (<any>this.$refs.select).focus()
            }
        },
        stop_click(e: any) {

            e.stopPropagation()
        }

    }

})
