import { defineComponent } from "vue"
import { ElMessageBox } from "element-plus"
import common from "/@/utils/common"
import sys_util from "/@/utils/sys_util"


interface IProps {
    modelValue: any
    multiple: boolean
    accept: string
    limit_file_size: number
    limit_file_width_height: Array<any>
    ajax_url: string
    public_flag: boolean
    file_name_fix: any
    show_upload_process: boolean
    on_success: Function
    upload_height: number
    upload_width: number
}




export default defineComponent({
    components: {
    },

    props: {
        show_btn: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        show_remove_btn: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },

        ajax_url: {
            type: String,
            default: "/alioss/get_alioss_upload_token"
        },
        multiple: {
            type: Boolean,
            default: false
        },
        list_type: {
            type: String,
            default: "text"
        },
        limit: {
            type: Number,
            default: 1
        },

        // modelValue: String || Array,
        modelValue: {
            type: [String, Array],
            required: false,
            default: function() {
                return ""
            }

        },
        limit_file_size: { // 限制单个文件大小，单位M
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        },

        limit_file_width_height: { // 限制单个图片宽高  [宽，高]
            type: Array,
            required: false,
            default: function() {
                return []
            }
        },

        upload_width: { //
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        },
        upload_height: { //
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        },

        accept: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        show_upload_process: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },
        show_file_list: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },
        public_flag: {
            type: Boolean,
            required: true
        },
        file_name_fix: {
            type: [String, Function],
            required: true
        },
        on_error: {
            type: [Function],
            required: false,
            default: function() {
                return function(err: any, _file: any) {

                    console.log("on_error", arguments)
                    ElMessageBox.alert(`KKXXX原因:${err.message}`, "上传失败", {
                        type: "error"
                    })
                }

            }
        },
        on_success: {
            type: [Function],
            required: false,
            default: null
        },
        // 文件超出个数限制时的钩子
        on_exceed: {
            type: [Function],
            required: false,
            default: function() {
                return function(_fileList: any, aa: any) {
                    console.log("aaaaaa", _fileList, aa)
                    ElMessageBox.alert("超过了最大允许上传文件个数！", {
                        type: "error"
                    })
                }

            }
        }
    },
    // event
    emits: ["update:modelValue"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {

        return {
            slot_flag: true,// 插槽显示标志，当单文件开始上传时候，自动隐藏插槽．多文件时候不隐藏．
            upload_process_visible: false,
            upload_process_percentage: 0,
            post_data: {},
            bucket_info: {},
            fileList: (<any>this)["getFileList"]()

        }

    },

    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_created() {
        },
        on_mounted() {
        },



        getFileList() {
            const f_list: Array<any> = []

            if (this.props.modelValue != undefined && this.props.multiple) {

                this.props.modelValue.split(",").forEach(function(item: any) {
                    if (item != "") {
                        f_list.push({
                            name: "",
                            url: item
                        })
                    }
                })
            }

            console.log("alioss_upload.ts--getFileList", f_list)
            return f_list
        },

        handleRemove(file: any, fileList: any) {
            console.log("aaaaaaaaaaaaaa", file)
            if (file && file.status === "success") {// 表示上传成功
                const me = this
                me["fileList"] = []
                console.log("handelRemove", fileList)
                if (me.props.multiple) {

                    fileList.forEach(function(item: any, i: any) {
                        console.log(item, i)
                        me["fileList"].push({
                            name: item.name,
                            url: item.url
                        })
                    })

                }

                me.setInputVal()
            }
        },

        handlePreview(file: any) {
            console.log("alioss_upload handlePreview file:", file)
        },

        before_upload(file: File) {

            const me = this

            // 检查文件类型
            if (me.props.accept != "" && me.props.accept != "*") {
                // 全部转为小写
                const file_ext = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase()
                if (me.props.accept.toLowerCase().indexOf(file_ext) == -1) {
                    ElMessageBox({
                        message: `上传文件只能是${me.props.accept}格式!`,
                        type: "warning"
                    })
                    return false
                }
            }

            // 检查文件大小
            if (me.props.limit_file_size > 0 && (file.size / 1024 / 1024) > me.props.limit_file_size) { // 限制文件大小，单位 M
                ElMessageBox({
                    message: `上传文件大小不能超过 ${me.props.limit_file_size}MB!`,
                    type: "warning"
                })
                return false
            }

            me.ajax_and_upload(file)
            return false
        },
        // 检查尺寸，并上传
        check_width_heidht_and_upload(file: any) {
            const me = this
            new Promise(function(resolve, reject) {
                const _URL = window["URL"] || window["webkitURL"]
                const img = new Image()
                img.onload = function() {
                    const valid = img.width == me.props.limit_file_width_height[0] && img.height == me.props.limit_file_width_height[1]
                    valid ? resolve(null) : reject()
                }
                img.src = _URL.createObjectURL(file)
            }).then(() => {
                // 通过验证，做上传
                me.ajax_and_upload(file)
            }, () => {
                // 不通过验证
                ElMessageBox({
                    message: `上传尺寸必须是${me.props.limit_file_width_height[0]}*${me.props.limit_file_width_height[1]}!`,
                    type: "warning"
                })
            })
        },

        ajax_and_upload(file: any) {
            const me = this

            console.log("ajax_url", me.props.ajax_url)
            common.do_ajax(me.props.ajax_url, {
                public_flag: this.props.public_flag ? 1 : 0
            }, function(resp: any) {
                me.do_upload(file, resp.data)
                console.log("alioss_upload before_upload resp:", resp)
            })
            console.log("before_upload", file)
        },



        el_upload_on_success(response: any, file: any, fileList: any) {
            console.log("el_upload_on_success", response, file, fileList)
        },

        do_upload(file: File, resp: any) {
            const me = this

            const upload_function = function(key: any, file: any) {

                const url = "https://gosspublic.alicdn.com/aliyun-oss-sdk-6.1.0.min.js"

                common.load_js_list([url], function() {

                    // 定义上传方法
                    async function multipartUpload() {
                        const client = new (<any>window)["OSS"](resp.config)
                        try {
                            if (file.size < 1024 * 1024 * 1) {
                                const result = await client.put(key, file, {
                                    progress: async function(percent: any) {
                                        console.log("xxxx", percent)
                                        me.$data.upload_process_percentage = parseInt(percent)
                                    }
                                })
                                //
                                var { url } = result
                                var m_info = {
                                    name: "",// 不给名字，应为系统没保存名字
                                    url: url
                                }
                            } else {
                                if (me.props.show_upload_process) {
                                    me.$data.upload_process_percentage = 0
                                    me.$data.upload_process_visible = true
                                }

                                const result = await client.multipartUpload(key, file, {
                                    progress: async function(p: any, checkpoint: any) {
                                        if (!me.props.multiple && me.props.show_upload_process) {
                                            // 不是多文件上传,并且要显示上传进度，自动隐藏插槽
                                            me.$data.slot_flag = false
                                        }
                                        console.log("boby----p", p)
                                        console.log("boby--切片--c", checkpoint)
                                        me.$data.upload_process_percentage = Math.ceil(p * 100)
                                    }
                                })

                                const tmp_url = result.res.requestUrls[0]

                                me.$data.upload_process_visible = false
                                me.$data.upload_process_percentage = 0
                                // 上传成功后显示插槽
                                me.$data.slot_flag = true
                                var url = tmp_url.substring(0, tmp_url.indexOf("?"))
                                var m_info = {
                                    // name: result.name,
                                    name: "",// 不给名字，应为系统没保存名字
                                    url: url
                                }
                            }

                            console.log("222222222222233333333", url)

                            if (me.props.multiple) {// 多文件上传
                                me.$data.fileList.push(m_info)
                                me.setInputVal()
                            } else {
                                me.$emit("update:modelValue", url)
                            }

                            if (sys_util.isFunction(me.props.on_success)) {
                                me.props.on_success(url)
                            }

                        } catch (e) {
                            console.log("hthththhththt-----------", e)
                        }
                    }
                    multipartUpload()
                })
            }

            const upload_file = (file: any) => {

                let key = ""
                const { file_name_fix } = this.props
                if (sys_util.isFunction(file_name_fix)) {
                    file_name_fix(file, upload_function)
                } else {
                    const filename = file.name
                    const ext_file_name = filename.substring(filename.indexOf("."))// 后缀名

                    key = `${file_name_fix}${(new Date()).getTime()}${ext_file_name.toLocaleLowerCase()}`
                    upload_function(key, file)
                }
            }

            if (me.props.upload_height) {
                common.compress(file, {
                    upload_height: me.props.upload_height,
                    upload_width: me.props.upload_width
                }, (file: any) => {
                    upload_file(file)
                })
            } else {
                upload_file(file)
            }
        },

        setInputVal() {
            const me = this
            let urls_str = ""
            if (me.props.multiple) {
                me.$data.fileList.forEach(function(item: any) {
                    urls_str += `${item.url},`
                })

                urls_str = urls_str.substring(0, urls_str.length - 1)
            } else {
                urls_str = me.$data.fileList
            }
            me.$emit("update:modelValue", urls_str)
        },

        show_file() {

            common.do_ajax("/alioss/get_alioss_download", {
                public_flag: this.props.public_flag ? 1 : 0,
                file_url: this.props.modelValue
            }, function(resp: any) {
                window.open(resp.url)
            })
        },

        remove_file() {
            this.$emit("update:modelValue", "")
        }


    }

})
