import admin_vue from "@/components/Admin/admin_vue"
import { isArray, isEmpty } from "lodash"
import { defineComponent } from "vue"
import { admin_pic_list, admin_alioss_upload } from "/@/admin_component"
import common from "/@/utils/common"
interface IProps {
    row: {
    }
}

export default defineComponent({
    components: {
        "admin-pic-list": admin_pic_list,
        "admin-alioss-upload": admin_alioss_upload
    },
    extends: admin_vue,
    props: {
        row: {
            type: Object,
            required: true,
            default() {
                return {
                    pic_type: "1",
                    first_video: "",
                    new_event_content: "",
                    new_event_pic_list: [],
                    coupon_list: [],
                    menu_list: [],
                    liked_list: [],
                    liked_all_count: "",
                    liked_title: ""
                }
            }
        }
    },
    emits: ["close", "update_list"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props // 代码补全用的,
        }
    },

    data: function() {
        return {
            data_row: {
                first_video: "",
                new_event_content: "",
                pic_type: "1",
                star_detail: "",
                new_event_pic_list: [],
                coupon_list: [],
                menu_list: [],
                liked_list: [],
                liked_all_count: "",
                liked_title: "",
                shop_open_time: "",
                shop_tags: "",
                reply_cnt: 0,
                shop_tag: "",
                shop_address: "",
                per_price: 0,
                star: ""
            }
        }
    },

    computed: {
    },
    watch: {
    },
    created() {
        const me: any = this
        this.$data.data_row = me.$props.row
        console.log("me.$props.row", me.$props.row)
        console.log(isEmpty(this.$data.data_row.coupon_list))
        console.log(this.$data.data_row.coupon_list)

        if (!this.$data.data_row.star_detail) {
            this.$data.data_row.star_detail = "口味：4.7 环境：4.7 服务：4.7"
        }
        if (!this.$data.data_row.star) {
            this.$data.data_row.star = "4.7"
        }
        if (!this.$data.data_row.per_price) {
            this.$data.data_row.per_price = 100
        }
        if (!this.$data.data_row.shop_address) {
            this.$data.data_row.shop_address = "上海市"
        }
        if (!this.$data.data_row.shop_tag) {
            this.$data.data_row.shop_tag = "其他"
        }
        if (!this.$data.data_row.reply_cnt) {
            this.$data.data_row.reply_cnt = 350
        }

        if (!this.$data.data_row.shop_tags) {
            this.$data.data_row.shop_tags = "有包厢,可停车,安心餐厅"
        }
        if (!this.$data.data_row.shop_open_time) {
            this.$data.data_row.shop_open_time = "营业中 10:00-22:00"
        }

        if (!this.$data.data_row.pic_type) {
            this.$data.data_row.pic_type = "1"
        }
        if (isEmpty(this.$data.data_row.coupon_list)) {
            me.$data.data_row.coupon_list = [{
                pic_list: "",
                title: "",
                price: "",
                final_price: "",
                sale_cnt: ""
            }]
        } else {
            this.$data.data_row.coupon_list = isArray(me.$props.row.coupon_list) ? me.$props.row.coupon_list : JSON.parse(me.$props.row.coupon_list)

        }
        if (isEmpty(this.$data.data_row.menu_list)) {
            me.$data.data_row.menu_list = [{
                pic_list: "",
                name: "",
                liked: ""
            }]
        } else {
            this.$data.data_row.menu_list = isArray(me.$props.row.menu_list) ? me.$props.row.menu_list : JSON.parse(me.$props.row.menu_list)
        }

        if (isEmpty(this.$data.data_row.liked_list)) {
            me.$data.data_row.liked_list = [{
                name: "网友推荐菜1",
                count: "100"
            }, {
                name: "网友推荐菜2",
                count: "98"
            }, {
                name: "网友推荐菜3",
                count: "198"
            }]
        } else {
            this.$data.data_row.liked_list = isArray(me.$props.row.liked_list) ? me.$props.row.liked_list : JSON.parse(me.$props.row.liked_list)
        }

        console.log(me.$data.data_row.coupon_list)
        console.log("row==================", this.$data.data_row)
    },
    mounted() {
    },
    methods: {
        modelValueChange(even: any, target: any) {
            const me: any = this
            if (target == "first_video") {
                me.$data.data_row[target] = even
            } else {
                me.$data.data_row[target] = JSON.stringify(even)
            }
        },
        picListChange(even: any, item: any) {
            console.log(even)
            item.pic_list = JSON.stringify(even)
        },
        delItem(target: any, index: any) {
            const me: any = this
            me.$data.data_row[target].splice(index, 1)
        },
        addCoupon() {
            const me: any = this
            me.$data.data_row.coupon_list.push({
                pic_list: "",
                title: "",
                price: "",
                final_price: "",
                sale_cnt: ""
            })
        },
        addMenu() {
            const me: any = this
            me.$data.data_row.menu_list.push({
                pic_list: "",
                name: "",
                liked: ""
            })
        },
        addLikedList() {
            const me: any = this
            me.$data.data_row.liked_list.push({
                name: "",
                count: ""
            })
        },
        isEmpty(str: any) {
            return isEmpty(str)
        },
        onSubmit() {
            const me: any = this
            console.log(me.$data.data_row)
            const data = Object.assign({}, me.$data.data_row)
            data.coupon_list = JSON.stringify(data.coupon_list)
            data.menu_list = JSON.stringify(data.menu_list)
            data.liked_list = JSON.stringify(data.liked_list)
            // data.pic_list = JSON.stringify(data.pic_list);
            me.do_ajax("/merchant/opt_merchant_show", data, function(resp: any) {
                if (resp.code != 0) {
                    common.alert(resp.info)
                } else {
                    me.$emit("close")
                    me.$emit("update_list")
                }
            })
        },
        onClose() {
            const me: any = this
            me.$emit("close")
        }
    }
})
