import { defineComponent, nextTick } from "vue"
import { isArray, isDate } from "lodash"


import common from "/@/utils/common"
import sys_util from "/@/utils/sys_util"

interface IProps {
    modelValue: any
    show_opt_date_type: boolean
    opt_date_type_list: Array<any>

    on_change: Function
}




export default defineComponent({

    props: {
        modelValue: Object,
        show_opt_date_type: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        opt_date_type_list: {
            type: Array,
            required: false,
            default: function() {
                return null
            }
        },
        on_change: {
            type: Function,
            required: false,
            default: function() {
                return null
            }
        },

        picker_options: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }

        }


    },
    // event
    emits: ["update:modelValue", "query"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props,

            // 自定义的
            old_args: {
                start_time: "",
                end_time: "",
                opt_date_type: <any>"",
                date_type: ""

            },

            check_select_opt_date_type: false,
            opt_date_type_opt_list: <Array<any>>[]
        }
    },

    data: function() {
        return {
            start_time: <any>"",
            end_time: "",
            opt_date_type: 0,
            date_type: "",
            date_type_config: <any>{}

        }
    },
    computed: {




    },

    watch: {
        modelValue() {
            const me = this

            this.check_select_opt_date_type = true
            me.init_from_select_value()
            nextTick(() => {
                nextTick(() => { // 让 opt_date_type 的 watch 回调完成
                    this.check_select_opt_date_type = false

                })
            })

        },
        opt_date_type(new_val, old_val) {
            const me = (this)


            if (this.check_select_opt_date_type == false) { // 不是初始化调用的
                if (old_val == 0 || old_val == 100) { // 时间段 -> 其他 需要 调整格式
                    if (!(new_val == 0 || new_val == 100)) { //
                        this.$data.start_time = this.$data.start_time[0]
                        me.old_args.start_time = this.$data.start_time
                    }
                }

                if (this.$data.opt_date_type != 0 && this.$data.opt_date_type != 100) {
                    this.$data.start_time = common.date_get_today()
                    me.old_args.start_time = this.$data.start_time
                }
            }





            if (new_val != me.old_args.opt_date_type) {
                console.log(" ==watch opt_date_type")
                nextTick(() => {
                    me.emit_change("opt_date_type")
                })
            }

        },

        date_type(new_val) {

            const me = this
            if (new_val != me.old_args.date_type) {
                me.emit_change("date_type")
            }
        },

        end_time(new_val) {
            const me = this
            if (new_val != me.old_args.end_time) {
                me.emit_change("end_time")
            }
        },


        start_time(new_val) {
            this.do_watch_start_time(new_val)
        }

    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        do_watch_start_time(new_val: any, query_flag = true) {
            const me = this
            console.log("K000", new_val)

            if (sys_util.isObject(new_val) && !sys_util.isArray(new_val)) {
                const date_fmt = this.$data.opt_date_type == 0 ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd"

                new_val = common.date_format(new_val, date_fmt)
                console.log("K000 set ", new_val)
                this.$data.start_time = new_val
                return
            }

            console.log("K000 notify", new_val)
            // typeof new_val);
            const old_start_time = me.old_args.start_time
            if (new_val) {
                if (sys_util.isArray(new_val) && sys_util.isArray(old_start_time)) {
                    // if (new_val[0] != old_start_time[0] || new_val[1] != old_start_time[1]) {
                    me.emit_change("start_time", query_flag)
                    // }

                } else if (new_val != me.old_args.start_time) {
                    me.emit_change("start_time", query_flag)
                }
            }

        },
        init_from_select_value() {

            const { modelValue } = this.props
            this.$data.opt_date_type = modelValue.opt_date_type * 1
            this.$data.date_type = modelValue.date_type

            // alert(this.$data.date_type);
            // 不提交query 事件
            this.old_args.opt_date_type = this.$data.opt_date_type
            // HACK
            nextTick(() => {
                if (this.$data.opt_date_type == 0) {
                    var value: Array<string>
                    if (modelValue.start_time) {
                        if (!sys_util.isArray(modelValue.start_time)) {
                            value = [modelValue.start_time, modelValue.end_time]
                        } else {
                            value = this.$data.start_time = modelValue.start_time
                        }
                        // 2018-01-01
                        if (sys_util.isString(value[0]) && value[0].length == 10) {
                            value[0] = `${value[0]} 00:00:00`
                        }

                        if (sys_util.isString(value[1]) && value[1].length == 10) {
                            value[1] = `${common.date_format("yyyy-MM-dd", common.strtotime(value[0]) + 86400)} 00:00:00`
                        }
                        this.$data.start_time = value
                    }
                } else if (this.$data.opt_date_type == 100) {

                    var value: Array<string>
                    if (modelValue.start_time) {
                        if (!sys_util.isArray(modelValue.start_time)) {
                            value = [modelValue.start_time, modelValue.end_time]
                        } else {
                            value = this.$data.start_time = modelValue.start_time
                        }
                        // 2018-01-01
                        if (sys_util.isString(value[0]) && value[0].length > 10) {
                        }

                        if (sys_util.isString(value[1]) && value[1].length == 10) {
                            // value[1] = common.date_format("yyyy-MM-dd", common.strtotime(value[0]) + 86400) + " 00:00:00";
                        }
                        this.$data.start_time = value
                    }


                } else {
                    this.$data.start_time = modelValue.start_time
                }

                this.$data.end_time = modelValue.end_time
                this.$data.date_type = modelValue.date_type

                this.old_args.opt_date_type = this.$data.opt_date_type
                this.old_args.date_type = this.$data.date_type
                this.old_args.start_time = this.$data.start_time
                this.old_args.end_time = this.$data.end_time


            })


            if (sys_util.isObject(modelValue.date_type_config)) {
                this.$data.date_type_config = modelValue.date_type_config
            }


        },

        on_created() {

            const conf: any = {
                100: "按时段",
                1: "按天",
                2: "按周",
                3: "按月",
                0: "按时段(分钟)"
            }
            const me = this
            this.opt_date_type_opt_list = []
            let tmp_list = [100, 1, 2, 3, 0]


            if (this.props.opt_date_type_list) {
                tmp_list = this.props.opt_date_type_list
            }
            tmp_list.forEach(function(k) {
                me.opt_date_type_opt_list.push({
                    label: conf[k],
                    value: k
                })
            })

            this.init_from_select_value()
        },



        on_mounted() {

        },
        do_prev_next(flag: any) {
            const { opt_date_type } = this.$data
            let start_time = common.strtotime(this.$data.start_time)
            console.log("opt_begin ", opt_date_type, this.$data.start_time, start_time, flag)
            if (opt_date_type == 1) { // 天
                start_time = (start_time - 86400 * flag)
            } else if (opt_date_type == 2) { // 周
                start_time = (start_time - 7 * 86400 * flag)
            } else if (opt_date_type == 3) { // 月
                if (flag == 1) { // pre
                    start_time = (start_time - 86400 * flag)
                } else {
                    start_time = (start_time + 32 * 86400)
                }
            }

            this.$data.start_time = common.date_format(start_time, "yyyy-MM-dd")

            console.log("opt_end ", start_time, this.$data.start_time)
            this.reset_date()
        },
        do_prev() {
            this.do_prev_next(1)
        },

        do_next() {
            this.do_prev_next(-1)
        },

        check_date_type_visable() {
            const config_count = Object.getOwnPropertyNames(this.$data.date_type_config).length
            return config_count > 1
        },
        check_diff_time(old_value: any, value: any) {
            let ret = false
            if (old_value == null) {
                return false
            }
            if (typeof (value) != typeof (old_value)) {
                ret = true
            }
            if (!ret) {
                if (sys_util.isArray(value)) {
                    ret = (value[0] != old_value[0]) || (value[1] != old_value[1])
                } else {
                    ret = value != old_value
                }
            }
            console.log("check_diff_time", value, old_value, ret)
            return ret
        },

        emit_change(field_name: any, query_flag = true) {
            const data = this.$data
            this.reset_date()
            console.log(this.old_args.opt_date_type, data.opt_date_type)
            const diff_opt_date_type = this.old_args.opt_date_type !== "" && (this.old_args.opt_date_type != data.opt_date_type)
            const diff_date_type = this.old_args.date_type !== "" && (this.old_args.date_type != data.date_type)
            const diff_start_time = this.old_args.start_time !== "" && (this.check_diff_time(this.old_args.start_time, data.start_time))
            const diff_end_time = this.old_args.end_time !== "" && (this.old_args.end_time != data.end_time)
            console.log("end time diff :", this.old_args.start_time, data.start_time, "xxxxxxxxxxxxx")
            if (diff_opt_date_type || diff_date_type || diff_start_time || diff_end_time) {
                console.log("KKKK data_time query ", diff_opt_date_type, diff_date_type, diff_start_time, diff_end_time)

                var out_data = this.$data
                if (isArray(this.$data.start_time) && isDate(this.$data.start_time[0])) {
                    out_data = Object.assign(out_data, {
                        start_time: [
                            common.date_format(out_data.start_time[0]),
                            common.date_format(out_data.start_time[1])
                        ]
                    })
                }
                if (this.props.on_change) {
                    this.props.on_change(out_data)
                }

                this.$emit("update:modelValue", out_data)
                if (query_flag) {
                    this.$emit("query", { field_name: "field_date", sub_file_name: field_name })
                }

            }

            this.old_args.opt_date_type = data.opt_date_type
            this.old_args.date_type = data.date_type
            this.old_args.start_time = data.start_time
            this.old_args.end_time = data.end_time

        },
        reset_date() {
            const { opt_date_type } = this.$data
            let start_time = 0
            if (opt_date_type != 0 && opt_date_type != 100) {

                if (sys_util.isArray(this.$data.start_time)) {
                    start_time = common.strtotime(this.$data.start_time[0])
                } else {
                    start_time = common.strtotime(this.$data.start_time)
                }

            }
            let end_time = 0
            if (opt_date_type == 0) { // 时间段(分钟)

                if (!sys_util.isArray(this.$data.start_time)) {
                    var old_opt_date_type: any = this.old_args.opt_date_type
                    var end_time_str = this.$data.end_time
                    if (old_opt_date_type == 3) {
                        end_time_str = common.date_get_month(common.strtotime(this.$data.start_time), 1)
                    } else if (old_opt_date_type == 1) {
                        end_time_str = common.date_format(common.strtotime(this.$data.start_time) + 86400, "yyyy-MM-dd")
                    } else if (old_opt_date_type == 2) {
                        end_time_str = common.date_format(common.strtotime(this.$data.start_time) + 7 * 86400, "yyyy-MM-dd")
                    }
                    this.$data.start_time = [`${this.$data.start_time} 00:00:00`, `${end_time_str} 00:00:00`]
                } else {

                    /*
                    if (this.$data.start_time[0].length == 10) { //date => date_time
                        alert("xxx");
                        this.$data.start_time = [
                            this.$data.start_time[0] + " 00:00:00",
                            this.$data.start_time[1] + " 00:00:00",
                        ];
                    }
                    */
                }

                return
            } else if (opt_date_type == 100) {//
                if (!sys_util.isArray(this.$data.start_time)) {
                    var old_opt_date_type: any = this.old_args.opt_date_type
                    var end_time_str = this.$data.end_time
                    if (old_opt_date_type == 3) {
                        end_time_str = common.date_get_month(common.strtotime(this.$data.start_time), 1)
                    } else if (old_opt_date_type == 1) {
                        end_time_str = common.date_format(common.strtotime(this.$data.start_time) + 86400, "yyyy-MM-dd")
                    } else if (old_opt_date_type == 2) {
                        end_time_str = common.date_format(common.strtotime(this.$data.start_time) + 7 * 86400, "yyyy-MM-dd")
                    }


                    // 日期模式,少一天
                    end_time_str = common.date_format(common.strtotime(end_time_str) - 86400)
                    //
                    this.$data.start_time = [this.$data.start_time, end_time_str]
                }

                return


            } else if (opt_date_type == 1) {// 当天
                end_time = start_time + 86400
            } else if (opt_date_type == 2) {// 当周
                const opt_date = new Date(start_time * 1000)
                let week = opt_date.getDay()
                if (week == 0) {
                    week = 7
                }
                start_time = (start_time - (week - 1) * 86400)
                end_time = start_time + 7 * 86400

            } else if (opt_date_type == 3) {// 当月
                start_time = common.strtotime(common.date_format(start_time, "yyyy-MM-01"))
                const date_v = new Date(start_time * 1000)
                const year = date_v.getFullYear()
                const month = date_v.getMonth() + 1
                const d = new Date(year, month, 0)
                end_time = start_time + (d.getDate() - 1) * 86400

            } else if (opt_date_type == 7 || opt_date_type == 15 || opt_date_type == 30) {// 最近
                const now = (new Date()).getTime() / 1000
                end_time = now
                start_time = now - opt_date_type * 86400
            }

            this.$data.start_time = common.date_format(start_time, "yyyy-MM-dd")
            this.$data.end_time = common.date_format(end_time, "yyyy-MM-dd")
            console.log("xxx", this.$data.start_time, this.$data.end_time)


        },
        set_value(value: any) {
            this.$data.start_time = value
            this.do_watch_start_time(value, false)
        }




    }


})
