export enum IMenubarStatus {
    PCE, // 电脑展开
    PCN, // 电脑合并
    PHE, // 手机展开
    PHN // 手机合并
}
export interface ISetting {
    theme: number
    showTags: boolean
    menubarStatus: IMenubarStatus
}
export interface IToken {
    ACCESS_TOKEN: string
}
export interface ILayout {
    // 左侧导航栏
    menubar: {
        status: IMenubarStatus
        menuList: Array<IMenubarList>
        isPhone: boolean
        menu_search_key: string
        show_menu_search_flag: boolean
        show_navbar: boolean,
    }
    tool_bar_opt_config: any
    // 用户信息 
    userInfo: {
        name: string,
        role: Array<string>
    }
    // 标签栏
    tags: {
        tagsList: Array<ITagsList>
        cachedViews: Array<string>
    }
    token: IToken
    setting: ISetting
    isLoading: boolean

    name: string,
    no_read_message_count: number,
    telphone: string,
    adminid: number,
    account_role: number,
    power_role: number,
    target_account_role: number,
    avatar: string,
    menu_list: Array<any>,
    url_desc_power: any,
    url_desc_power_value: any,
    url_to_id_map: any,
    menu_type: string,
    menu_userid: number, //
    menu_user_nick: string, //
    roles: any, //
    admin_url: string,
    admin_level_group_list: any, // 前缀
    admin_level: any, // 前缀
    admin_level_str: string, // 组织层级
    sub_master_flag: any, //


}
export interface IMenubarList {
    parentId?: number | string
    id?: number | string
    name: string
    path: string
    redirect?: string | { name: string }
    hidden?: boolean,
    meta: {
        icon: string
        title: string
        permission?: Array<string>
        activeMenu?: string // 路由设置了该属性，则会高亮相对应的侧边栏
        noCache?: boolean // 页面是否不缓存
        hidden?: boolean // 是否隐藏路由
        alwaysShow?: boolean // 当子路由只有一个的时候是否显示当前路由
    }
    component: (() => Promise<typeof import("*.vue")>) | string
    children?: Array<IMenubarList>
}

export interface ITagsList {
    name: string
    title: string
    path: string
    isActive: boolean
}
