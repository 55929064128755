<template>
    <div>
        <el-date-picker
            v-model="start_time"
            :type="dateType"
            :disabled-date="pickerOptions.disabledDate"
            :editable="false"
            placeholder="到店起始日期"
            @change="changeFn"
        />
        <span>-</span>
        <el-date-picker
            v-model="end_time"
            :type="dateType"
            :editable="false"
            :disabled-date="pickerOptions1.disabledDate"
            placeholder="到店结束日期"
            @change="changeFn"
        />
    </div>
</template>


<script>
import { defineComponent } from "vue"
export default defineComponent({
    name: "List",
    props: {
        dateType: {
            type: String,
            default: "date"
        },
        modelValue: {
            type : Array,
            default: function() {
                return ["", ""]
            }
        },
        index: {
            type:Number
        }
    },
    emits:[ "changeModelValue" ],
    data() {
        return {
            start_time: "",
            end_time: ""
        }
    },
    computed: {
        pickerOptions() {
            let me = this
            return {
                disabledDate: (time) => {
                    if (this.end_time) {
                        return (
                            time.getTime() >= +new Date(this.end_time) + 1000 * 60 * 60 * 24
                        )
                    }
                }
            }
        },
        pickerOptions1() {
            let me = this
            return {
                disabledDate: (time) => {
                    if (this.start_time) {
                        return (
                            time.getTime() <= +new Date(this.start_time) - 1000 * 60 * 60 * 24
                        )
                    }
                }
            }
        }
    },

    watch: {
        // start_time: {
        //   handler: function (nv) {
        //     // let date = {
        //     //   start_time: nv ? new Date(nv) : '',
        //     //   end_time:
        //     //     this.dateType === 'datetime'
        //     //       ? this.end_time
        //     //         ? Math.ceil(+new Date(this.end_time) / 1000)
        //     //         : ''
        //     //       : this.end_time
        //     //       ? new Date(this.end_time)
        //     //       : '',
        //     // }
        //     // this.$emit('update:modelValue', [date.start_time, date.end_time])
        //     console.log(this.modelValue)
        //     this.$emit('changeModelValue', this.modelValue)
        //   },
        //   immediate: true,
        // },
        end_time: {
            handler: function(nv) {
                //     let date = {
                //       start_time: this.start_time ? new Date(this.start_time) : '',
                //       end_time:
                //         this.dateType === 'datetime'
                //           ? nv
                //             ? Math.ceil(+new Date(nv) / 1000)
                //             : ''
                //           : nv
                //           ? new Date(nv)
                //           : '',
                //     }
                //     this.$emit('update:modelValue', [date.start_time, date.end_time])
                // this.$emit('changeModelValue', this.modelValue)
            },
            immediate: true
        },
        modelValue: {
            handler: function(nv, ov) {
                console.info(`nvvvvvvvvvvvvv${nv}`)
                this.start_time = nv[0]
                this.end_time = nv[1]
            },
            immediate: true
        }
    },
    created() {},
    methods: {
        changeFn() {
            //   console.log('modelValue', this.modelValue)

            this.$emit("changeModelValue", {
                date: [this.start_time, this.end_time],
                index: this.index
            })
        },
        limitsDate() {
            return {
                disabledDate: (time) => {
                    if (this.end_time) {
                        return (
                            time.getTime() >= +new Date(this.end_time) + 1000 * 60 * 60 * 24
                        )
                    }
                }
            }
        },
        limitsDate1() {
            return {
                disabledDate: (time) => {
                    if (this.start_time) {
                        return (
                            time.getTime() <= +new Date(this.start_time) - 1000 * 60 * 60 * 24
                        )
                    }
                }
            }
        }
    }
})
</script>

<style lang="scss" scoped>
</style>
