import admin_vue from "@/components/Admin/admin_vue"

import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    row: any,
    data: any,

}



export default defineComponent({
    components: {
    },
    extends: admin_vue,

    props: {


        row: Object,
        data: Object



    },
    // event
    emits: ["close"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            active_tab_name: "task_list",
            tableData: [],
            contentTableData: <Array<any>>[

            ],
            tableSelectData: <any>[],
            contentSelectTableData: <any>[]


        }
    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_created() {
            const me = this
            console.log("this", this)
            console.log("props", this.props)
            console.log("shopping props", this.props.data.list)
            console.log("tableData", me.$data.tableData)
            me.$data.tableData = this.props.data.list


        },
        on_mounted() {
        },

        handleSelectionChange(item: Array<any>) {
            console.log(item)
            this.$data.tableSelectData = item
        },
        handleTimeSelectionChange(item: Array<any>) {
            console.log(item)
            this.$data.contentSelectTableData = item
        },


        on_edit(row: any, index: any, type = "edit") {
            const _this = this
            const field_list = [{
                field_type: "date",
                field_name: "shopping_time",
                label: "到店时间",
                value_format: "yyyy-MM-dd HH:mm",
                format: "yyyy-MM-dd HH:mm",
                type: "datetime",
                style: {
                    width: "200px"
                },
                span_num: 12,
                on_change: function(_val: string) {
                    // var end_time = val.substring(11, 13) + ":59";
                    // data.shopping_end_time = end_time;
                },
                value: row.shopping_time
            },
            {
                field_type: "text",
                field_name: "need_user_count",
                label: "招募人数",
                style: {
                    width: "200px"
                },
                span_num: 24,
                value: row.need_user_count


            }, {
                field_type: "date",
                field_name: "enter_end_time",
                label: "报名截止时间",
                format: "yyyy-MM-dd",
                value_format: "yyyy-MM-dd",
                type: "date",
                style: {
                    width: "200px"
                },
                span_num: 24,
                value: row.enter_end_time.substr(0, 10)
            }, {
                field_type: "text",
                field_name: "report_end_time",
                label: "作业提交截止时间,到店时间后(几个)小时",
                style: {
                    width: "200px"
                },
                value: Math.floor((common.strtotime(row.report_end_time) - common.strtotime(row.shopping_time)) / 3600),
                span_num: 24

            }]
            const data: any = {}
            const title = type === "add" ? "新增" : "编辑"
            var dlg = common.dlg.admin_show_key_table_new(field_list, data, {
                title: `${title}到店时间`,
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    if (!data.enter_end_time) {
                        common.alert("请选择 报名截止时间")
                        return
                    }
                    data.report_end_time = common.date_format(common.strtotime(row.shopping_time) + 3600 * data.report_end_time, true)
                    if (type === "add") {
                        _this.contentTableData.push(Object.assign({}, row, data))
                    } else {
                        _this.contentTableData.splice(index, 1, Object.assign({}, row, data))
                    }
                    dlg.close()
                }
            })


        },

        on_del(row: any, index: any) {
            const _this = this
            common.confirm(`要删除值:[${row.shopping_time}]吗?`, function() {
                _this.contentTableData.splice(index, 1)
            })
        },

        addTime() {
            const _this = this
            const field_list = [{
                field_type: "date",
                field_name: "shopping_time",
                label: "到店时间",
                value_format: "yyyy-MM-dd HH:mm",
                format: "yyyy-MM-dd HH:mm",
                type: "datetime",
                style: {
                    width: "200px"
                },
                span_num: 12,
                on_change: function(_val: string) {
                    // var end_time = val.substring(11, 13) + ":59";
                    // data.shopping_end_time = end_time;
                }
            },
            {
                field_type: "text",
                field_name: "need_user_count",
                label: "招募人数",
                value: 1,
                style: {
                    width: "200px"
                },
                span_num: 24

            }, {
                field_type: "date",
                field_name: "enter_end_time",
                label: "报名截止时间",
                format: "yyyy-MM-dd",
                value_format: "yyyy-MM-dd",
                type: "date",
                span_num: 24,
                style: {
                    width: "200px"
                }
            }, {
                field_type: "text",
                field_name: "report_end_time",
                label: "作业提交截止时间,到店时间后(几个)小时",
                value: "24",
                style: {
                    width: "200px"
                },
                span_num: 24

            }]
            const data: any = {}

            var dlg = common.dlg.admin_show_key_table_new(field_list, data, {
                title: "新增到店时间",
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    data.opt_type = "add"
                    if (!data.shopping_time) {
                        common.alert("请选择 到店时间")
                        return
                    }

                    if (!data.enter_end_time) {
                        common.alert("请选择 报名截止时间")
                        return
                    }

                    data.report_end_time = common.date_format(common.strtotime(data.shopping_time) + 3600 * data.report_end_time, true)

                    _this.contentTableData.push(data)

                    dlg.close()
                }

            })

        },

        onSubmit() {
            if (!this.tableSelectData.length) {
                common.alert("请先选择店铺地址")
                return
            }
            if (!this.contentTableData.length) {
                common.alert("请先新增到店时间点")
                return
            }
            const paramsList: any = []
            const _this = this
            this.tableSelectData.map((item: any) => {
                this.contentTableData.map((citem: any) => {
                    paramsList.push({
                        // ...item,
                        city_task_id: this.props.row.city_task_id,
                        shop_id: item.id,
                        ...citem
                    })
                })
            })
            console.log(paramsList)
            common.do_batch_list(paramsList, 1, function(item: any, do_next: any) {
                console.log(item)
                const data = item.data[0]
                // data.shopping_end_time = common.strtotime((<string>data.shopping_time).substring(0, 11) + data.shopping_end_time);
                data.shopping_time = common.strtotime(data.shopping_time)
                data.enter_end_time = common.strtotime(data.enter_end_time) + 86400 - 1
                data.report_end_time = common.strtotime(data.report_end_time)
                // var id = item.data[0];
                common.do_ajax("/daren_ex/opt_task_item",
                    data,
                    function(resp: any) {
                        if (resp.code === 0) {
                            do_next()
                        }
                    })
            }, {
                show_dlg: true,
                end_callback: function() {
                    _this.on_close()
                }
            })
        },

        on_close() {
            this.$emit("close", {})
        }


    }

})

/*
import Vue from 'vue'
import Component from 'vue-class-component'
import tag_list from "./tag_list"
import money_list from "@/views/daren_info/money_list"
import platform_list from "@/views/daren_info/platform_list"
import money_to_account_list from "@/views/daren_info/money_to_account_list"

import opt_log_list from "@/views/daren_info/opt_log_list"
import score_list from "@/views/daren_info/score_list"
import common from '@/utils/common';
import table from '@/components/Admin/table'
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  "name": "amdin-daren-info",
  data: function () {
    return {
      active_tab_name: "task_list",
      tableData: [],
      contentTableData: [

      ],
      tableSelectData: [],
      contentSelectTableData: [],
    };
  },

  props: {
    row: Object,
    data: Object,
  },
  components: {
    "tag-list": tag_list,
  },

  created: function () {
    (<select_shopping>this).on_created();
  },


})
export default class select_shopping extends Vue {
  on_created() {
    var me = this;
    console.log('this', this)
    console.log('props', this.props)
    console.log('shopping props', this.props.data.list)
    console.log('tableData', me.$data.tableData);
    me.$data.tableData = this.props.data.list;
    // console.log('');

    // me.$data.base_info = this.props.data.base_info;
    me.$data.row = {
      // userid: this.props.data.base_info.userid,
      // tag_list: this.props.data.tag_list,
    }
    this.getTaskTime(me)

  }

  getTaskTime(that: any) {
    // common.do_ajax("/daren/task_time_list", {
    //     "page_num": 1,
    //     "page": 1,
    //     "page_count": 10000,
    //     "city_task_id": 2,
    // }, function (resp: any) {
    //     if(resp.code === 0) {
    //         that.contentTableData = resp.data.list;
    //         console.log(resp);
    //     }
    // })
  }

  handleSelectionChange(, item: Array<any>) {
    console.log(item);
    this.tableSelectData = item
  }
  handleTimeSelectionChange(, item: Array<any>) {
    console.log(item)
    this.contentSelectTableData = item
  }


  on_edit(, row: any, index: any, type: string = 'edit') {
    const _this = this;
    var field_list = [{
      "field_type": "date",
      "field_name": "shopping_time",
      "label": "到店时间",
      value_format: "yyyy-MM-dd HH:mm",
      format: "yyyy-MM-dd HH:mm",
      type: "datetime",
      style: {
        width: "200px",
      },
      span_num: 12,
      on_change: function (val: string) {
        // var end_time = val.substring(11, 13) + ":59";
        // data.shopping_end_time = end_time;
      },
      value: row.shopping_time,
    },
    {
      "field_type": "text",
      "field_name": "need_user_count",
      "label": "招募人数",
      style: {
        width: "200px",
      },
      span_num: 24,
      value: row.need_user_count,


    }, {
      "field_type": "date",
      "field_name": "enter_end_time",
      "label": "报名截止时间",
      format: "yyyy-MM-dd",
      value_format: 'yyyy-MM-dd',
      type: "date",
      style: {
        width: "200px",
      },
      span_num: 24,
      value: row.enter_end_time.substr(0, 10),
    }, {
      "field_type": "text",
      "field_name": "report_end_time",
      "label": "作业提交截止时间,到店时间后(几个)小时",
      style: {
        width: "200px",
      },
      value: Math.floor((common.strtotime(row.report_end_time) - common.strtotime(row.shopping_time)) / 3600),
      span_num: 24,

    }];
    var data: any = {};
    var title = type === 'add' ? '新增' : '编辑'
    var dlg = common.dlg.admin_show_key_table_new(field_list, data, {
      title: title + "到店时间",
      width: "600px",
      "submit": function (data: any, _btn_config: any) {
        if (!data.enter_end_time) {
          common.alert("请选择 报名截止时间");
          return;
        }
        data.report_end_time = common.date_format(common.strtotime(row.shopping_time) + 3600 * data.report_end_time, true);
        if (type === 'add') {
          _this.contentTableData.push(Object.assign({}, row, data))
        } else {
          _this.contentTableData.splice(index, 1, Object.assign({}, row, data))
        }
        dlg.close();
      },
    });


  }

  on_del(, row: any, index: any) {
    const _this = this;
    common.confirm("要删除值:[" + row.shopping_time + "]吗?", function () {
      _this.contentTableData.splice(index, 1)
    });
  }

  addTime() {
    const _this = this;
    var field_list = [{
      "field_type": "date",
      "field_name": "shopping_time",
      "label": "到店时间",
      value_format: "yyyy-MM-dd HH:mm",
      format: "yyyy-MM-dd HH:mm",
      type: "datetime",
      style: {
        width: "200px",
      },
      span_num: 12,
      on_change: function (val: string) {
        // var end_time = val.substring(11, 13) + ":59";
        // data.shopping_end_time = end_time;
      }
    },
    {
      "field_type": "text",
      "field_name": "need_user_count",
      "label": "招募人数",
      value: 1,
      style: {
        width: "200px",
      },
      span_num: 24,

    }, {
      "field_type": "date",
      "field_name": "enter_end_time",
      "label": "报名截止时间",
      format: "yyyy-MM-dd",
      value_format: "yyyy-MM-dd",
      type: "date",
      span_num: 24,
      style: {
        width: "200px",
      }
    }, {
      "field_type": "text",
      "field_name": "report_end_time",
      "label": "作业提交截止时间,到店时间后(几个)小时",
      value: "24",
      style: {
        width: "200px",
      },
      span_num: 24,

    }];
    var data: any = {};

    var dlg = common.dlg.admin_show_key_table_new(field_list, data, {
      title: "新增到店时间",
      width: "600px",
      "submit": function (data: any, _btn_config: any) {
        data.opt_type = "add";
        if (!data.shopping_time) {
          common.alert("请选择 到店时间");
          return;
        }

        if (!data.enter_end_time) {
          common.alert("请选择 报名截止时间");
          return;
        }

        // data.shopping_time = common.strtotime(data.shopping_time);
        // data.enter_end_time = common.strtotime(data.enter_end_time) + 86400 - 1;
        data.report_end_time = common.date_format(common.strtotime(data.shopping_time) + 3600 * data.report_end_time, true);

        _this.contentTableData.push(data);
        // data.city_task_id = '';
        // data.task_id = row.task_id;
        // console.log(data)
        // common.do_ajax("/daren_ex/opt_task_item", data, function () {
        //     // ajax_dlg.reload();
        // });

        dlg.close();
      },

    });

  }

  onSubmit() {
    if (!this.tableSelectData.length) {
      common.alert("请先选择店铺地址");
      return;
    }
    if (!this.contentTableData.length) {
      common.alert("请先新增到店时间点");
      return;
    }
    let paramsList: any = [];
    let _this = this;
    this.tableSelectData.map((item: any) => {
      this.contentTableData.map((citem: any) => {
        paramsList.push({
          // ...item,
          city_task_id: this.props.row.city_task_id,
          shop_id: item.id,
          ...citem
        })
      })
    })
    console.log(paramsList);
    common.do_batch_list(paramsList, 1, function (item: any, do_next: any) {
      console.log(item);
      let data = item.data[0];
      // data.shopping_end_time = common.strtotime((<string>data.shopping_time).substring(0, 11) + data.shopping_end_time);
      data.shopping_time = common.strtotime(data.shopping_time);
      data.enter_end_time = common.strtotime(data.enter_end_time) + 86400 - 1;
      data.report_end_time = common.strtotime(data.report_end_time);
      // var id = item.data[0];
      common.do_ajax("/daren_ex/opt_task_item",
        data,
        function (resp: any) {
          if (resp.code === 0) {
            do_next();
          }
        })
    }, {
      show_dlg: true,
      end_callback: function () {
        _this.on_close();
      }
    })
  }

  on_close() {
    this.$emit("close", {});
  }


}

*/
