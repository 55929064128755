
<template>
    <van-pull-refresh
        v-model="refreshing"
        @refresh="on_refresh"
    >
        <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="one_page?&apos;&apos; :&apos;没有更多了&apos;"
            :style="style"
            @load="on_load"
        >
            <slot :data_list="list" />
        </van-list>
    </van-pull-refresh>
</template>

<script>
import m from "./van_list.ts"
export default m
</script>

