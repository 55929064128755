import common from "./common"

var test_cmd = {

    // 开启服务器日志
    open_server_log() {

        common.do_ajax("/login/set_send_server_log_to_client", {}, function() {
            common.notify({
                title: "",
                message: "开启成功"
            })
            window.location.reload()
        })

    }
}

export default test_cmd
