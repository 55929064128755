import { isFunction, isObject } from "lodash"
import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    args: any
    url: string
    item_key_field_name: string, // 唯一标识的id 名称
    deal_ajax_result_func?: Function,

}



export default defineComponent({
    name: "AdminVanList",
    components: {
    },

    props: {

        args: {
            type: Object,
            required: true
        },
        url: String,

        one_page: Boolean,
        style: [Object, String],

        item_key_field_name: {
            type: String,
            required: false,
            default: ""
        },
        deal_ajax_result_func: {

            type: Function,
            required: false,
            default: undefined
        }


    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props,

            // 自定义的
            key_set: new Set()

        }
    },

    data: function() {
        return {
            page: 1,
            list: <Array<any>>[],
            loading: false,
            finished: false,
            refreshing: false,
            load_key: ""
        }
    },
    computed: {




    },

    watch: {

        args() {
            this.query()
        }

    },
    created() {
        this.on_created()
    },
    mounted() {
        // this.on_mounted();
    },
    methods: {
        on_created() {
            // this.query();

        },
        on_load() {

            if (this.$data.refreshing) {
                this.$data.refreshing = false
            }


            var key = `${Math.random()}_${Math.random()}`
            this.$data.load_key = key
            var args = common.copy_obj(this.$props.args)
            if (args.sort_args) {
                args = Object.assign(args, args.sort_args)
                delete args.sort_args
            }


            args.page = this.$data.page

            common.do_ajax(this.props.url, args, (resp: any) => {
                if (key == this.$data.load_key) { // 有重入
                    var data_list: any = resp.data.list
                    if (isFunction(this.deal_ajax_result_func)) {
                        const ret: any = this.deal_ajax_result_func(resp)
                        if (ret == "do_with_call_back") {
                            return
                        }
                        if (isObject(ret)) { // 有返回数据
                            data_list = (<any>ret).list
                        }

                    }

                    (<Array<any>>data_list).forEach((item) => {
                        if (this.props.item_key_field_name != "") {
                            // 过滤重复值
                            var key_value = item[this.props.item_key_field_name]
                            if (!this.key_set.has(key_value)) {
                                this.$data.list.push(item)
                                this.key_set.add(key_value)
                            }
                        } else {
                            this.$data.list.push(item)
                        }
                    })





                    this.$data.page++
                    if (this.$props.one_page || (<Array<any>>resp.data.list).length == 0) {
                        this.$data.finished = true
                    }


                    this.$data.loading = false
                }
            })


        },

        on_refresh() {
            this.$data.page = 1
            this.$data.finished = false
            this.$data.loading = false
            this.$data.list = []
            this.key_set.clear()
            this.on_load()
        },
        query() {
            this.on_refresh()
        }

    }

})
