import { isProxy, toRaw } from "vue"
import util from "./sys_util"
var array = {
    // 比较两个数组是否相等
    equals(arr1: any, arr2: any) {
        if (isProxy(arr1)) {
            arr1 = toRaw(arr1)
        }

        if (isProxy(arr2)) {
            arr2 = toRaw(arr2)
        }


        if (!util.isArray(arr1) || !util.isArray(arr2)) {
            return false
        }

        if (arr1.length != arr2.length)
            return false
        for (let i = 0, l = arr1.length; i < l; i++) {
            // Check if we have nested arrays
            if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
                // recurse into the nested arrays
                if (!array.equals(arr1[i], arr2[i]))
                    return false
            }
            else if (arr1[i] != arr2[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false
            }
        }
        return true
    },

    in_array(value: any, arr: Array<any>) {
        let flag = false
        for (let i = 0; i < arr.length; i = i + 1) {
            if (value == arr[i]) {
                flag = true
                break
            }
        }
        return flag

    },

    str_as_int_array(str: string, split_char = ",") {
        if (str === null || str === undefined) {
            return []
        }

        if (str.trim() == "") {
            return []
        }
        return this.as_int_array(str.split(split_char))

    },

    str_as_array(str: string, split_char = ",") {
        if (str === null || str === undefined) {
            return []
        }
        if (str.trim() == "") {
            return []
        }
        return str.split(split_char)

    },



    as_int_array(arr: Array<any>) {
        const ret_arr: Array<number> = []
        arr.forEach(function(v, _i) {
            if (util.isNumber(v)) {
                ret_arr.push(v)
            } else if (util.isString(v)) {
                v = v.trim()
                if (v.length > 0) {
                    ret_arr.push(parseInt(v))
                }
            }
        })
        return ret_arr
    },
    get_field_list_from_array(arr: Array<any>, field_name: any) {
        const ret_arr: Array<number> = []
        arr.forEach(function(v, _i) {
            ret_arr.push(v[field_name])
        })
        return ret_arr
    },

    get_field_list_str_from_array(arr: Array<any>, field_name: any) {
        return this.get_field_list_from_array(arr, field_name).join(",")
    },

    array_as_map(arr: Array<any>, func: (item: any) => any) {
        const ret_map: any = {}
        arr.forEach(function(item) {
            ret_map[func(item)] = item
        })
        return ret_map
    }


}
export default array
