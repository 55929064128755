import { defineComponent, nextTick } from "vue"


interface IProps {
    modelValue: any,
    field_list: Array<any>,
}



export default defineComponent({
    components: {
    },

    props: {
        modelValue: Object,
        field_list: Array
    },
    // event
    emits: ["update:modelValue", "change"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props,

            // 自定义的
            order_icon_conf: {
                "": "iconpaixu",
                asc: "iconpaixu2",
                desc: "iconpaixu1"
            }

        }
    },

    data: function() {
        return {
            show_sort_popover: false,
            columns: <Array<any>>[
                {
                    values: <Array<any>>[],
                    defaultIndex: 2
                },
                {
                    values: <Array<any>>[{
                        label: "不排序",
                        value: ""
                    }, {
                        label: "升序",
                        value: "asc"
                    }, {
                        label: "降序",
                        value: "desc"
                    }],
                    defaultIndex: 0
                }
            ]

        }
    },
    computed: {

        icon_class() {
            return `iconfont ${(<any>this.order_icon_conf)[this.props.modelValue.order]}` || "iconpaixu"
        }




    },

    watch: {
    },
    created() {
        this.on_created()
    },
    mounted() {
        // this.on_mounted();
    },
    methods: {
        picker_confirm(value_list: any) {
            this.$data.show_sort_popover = false
            var value = {
                sort: value_list[0].value,
                order: value_list[1].value
            }
            this.$emit("update:modelValue", value)
            nextTick(() => {
                this.$emit("change", value)
            })
        },
        on_created() {
            this.columns[0].values = this.props.field_list
            this.set_default_index()
        },
        set_default_index() {
            var sort = this.props.modelValue.sort
            this.columns[0].defaultIndex = this.columns[0].values.findIndex((item: any) => {
                return item.value == sort
            })
            var order = this.props.modelValue.order
            this.columns[1].defaultIndex = this.columns[1].values.findIndex((item: any) => {
                return item.value == order
            })


        }



    }


})
