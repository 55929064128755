import Cookies from "js-cookie"



function get_tokenkey() {
    if (import.meta.env.DEV) {
        return "console_session_id_dev"
    } else {
        return "console_session_id"
    }

}


export function getToken() {
    let ret = Cookies.get(get_tokenkey())
    if (!ret) {
        ret = Cookies.get("PHPSESSID")
    }
    if (!ret) {
        ret = ""
    }

    console.log("==================getToken:", ret)
    return ret
}

export function setToken(token: string) {
    console.log("==================setToken:", token)
    return Cookies.set(get_tokenkey(), token, { domain: ".mydaren.cn" })
}

export function setCookie(key: string, value: string) {
    return Cookies.set(key, value, { domain: ".mydaren.cn" })
}

export function getCookie(key: string) {
    return Cookies.get(key)
}


export function removeToken() {
    Cookies.remove("PHPSESSID")
    return Cookies.remove(get_tokenkey(), { domain: ".mydaren.cn" })
}
