// import common from './common';

const noti_message = {

    loading_message_handle_config: <any>{

    },

    set_loading_message_handle(key: string, handle?: (message: any) => void) {
        this.loading_message_handle_config[key] = handle
    },

    do_loading_message(message: any) {
        const handle = this.loading_message_handle_config[message.message_handle]
        if (handle) {
            handle(message)
        }
    }


}

export default noti_message
