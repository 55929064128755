import tag_list from "./tag_list"
import follow_list from "@/views/bd_info/follow_list.vue"
import assigned_list from "@/views/bd_info/assigned_list.vue"
import order_list from "@/views/bd_info/order_list.vue"
import opt_log_list from "@/views/bd_info/opt_log_list.vue"

import common from "@/utils/common"
import admin_vue from "@/components/Admin/admin_vue"
import { field_item_list_t } from "@/utils/type_def"
import { defineComponent, markRaw } from "vue"

interface IProps {
    data: any,
}

export default defineComponent({
    name: "BdShopInfo",
    components: {
        "tag-list": tag_list
    },
    extends: admin_vue,

    props: {
        data: Object
    },
    
    emits: ["close", "update_info"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props
        }
    },

    data: function() {
        return {
            active_tab_name: "follow_list",
            cur_tab_component: <any>markRaw(follow_list),
            base_info: <any>{}
        }
    },
    created() {
        this.on_created()
    },
    mounted() {
    },
    methods: {
        on_created() {
            console.log("onononononon")
            console.log(this.props.data)

            this.set_data(this.props.data)
        },

        set_data(data: any) {
            const me = this
            me.$data.base_info = data.base_info
        },
        on_edit() {
            const row: any = this.$data.base_info
            if (!row.shop_open_time || row.shop_open_time === "无") {
                row["shop_open_time"] = ""
            }
            var data: any = {}
            var me = this
            var field_list: field_item_list_t = [
                {
                    label: "店名",
                    field_type: "text",
                    field_name: "shop_name",
                    value: row.shop_name,
                    rules: { required: false, message: "请输入店名", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "KP职位",
                    field_type: "select",
                    field_name: "kp_postion",
                    enum_type: "kp_postion",
                    value: row.kp_postion,
                    style: {
                        width: "100%"
                    },
                    rules: { required: false, message: "请输入KP职位", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "姓名",
                    field_type: "text",
                    field_name: "kp_name",
                    value: row.kp_name,
                    rules: { required: false, message: "请输入姓名", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "联系手机号",
                    field_type: "text",
                    field_name: "kp_mobile",
                    value: row.kp_mobile,
                    rules: { required: false, message: "请输入联系手机号", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "微信号",
                    field_type: "text",
                    field_name: "kp_wx_id",
                    value: row.kp_wx_id,
                    rules: { required: false, message: "请输入微信号", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "开店时间",
                    field_type: "date",
                    field_name: "shop_open_time",
                    type: "date",
                    value: row.shop_open_time,
                    format: "yyyy-MM-dd",
                    value_format: "yyyy-MM-dd",
                    rules: { required: false, message: "请输入开店时间", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    style: {
                        width: "100%"
                    },
                    span_num: 8
                },
                {
                    label: "门店数",
                    field_type: "text",
                    field_name: "shop_count",
                    value: row.shop_count,
                    rules: { required: false, message: "请输入门店数", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "面积",
                    field_type: "text",
                    field_name: "shop_area",
                    value: row.shop_area,
                    rules: { required: false, message: "请输入面积", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "座位数",
                    field_type: "text",
                    field_name: "shop_chair_count",
                    value: row.shop_chair_count,
                    rules: { required: false, message: "请输入座位数", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "房租成本",
                    field_type: "text",
                    field_name: "rent",
                    value: row.rent,
                    rules: { required: false, message: "请输入房租成本", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "毛利情况",
                    field_type: "text",
                    field_name: "gross_profit",
                    value: row.gross_profit,
                    rules: { required: false, message: "请输入毛利情况", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "服务员人数",
                    field_type: "text",
                    field_name: "worker_count",
                    value: row.worker_count,
                    rules: { required: false, message: "请输入服务员人数", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "营销预算",
                    field_type: "text",
                    field_name: "marketing_budget",
                    value: row.marketing_budget,
                    rules: { required: false, message: "请输入营销预算", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 8
                },
                {
                    label: "是否亏损",
                    field_type: "select",
                    field_name: "loss_flag",
                    enum_type: "loss_flag",
                    value: row.loss_flag,
                    rules: { required: false, message: "请输入营销预算", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    style: {
                        width: "100%"
                    },
                    span_num: 8
                },
                {
                    label: "线上营销认知",
                    field_type: "select",
                    field_name: "online_marketing_level",
                    enum_type: "online_marketing_level",
                    value: row.online_marketing_level,
                    rules: { required: false, message: "请输入线上营销认知", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    style: {
                        width: "100%"
                    },
                    span_num: 8
                },
                {
                    label: "选址及商圈分析",
                    field_type: "text",
                    field_name: "shop_addr_reason",
                    value: row.shop_addr_reason,
                    rules: { required: false, message: "请输入选址及商圈分析", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 16
                },
                {
                    label: "之前的从业经历",
                    field_type: "text",
                    field_name: "old_work_info",
                    value: row.old_work_info,
                    rules: { required: false, message: "请输入之前的从业经历", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 16
                },
                {
                    label: "做过的营销动作",
                    field_type: "text",
                    field_name: "old_online_marketing_action",
                    value: row.old_online_marketing_action,
                    rules: { required: false, message: "请输入做过的营销动作", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 16
                },
                {
                    label: "线上营销目标",
                    field_type: "text",
                    field_name: "online_marketing_goal",
                    value: row.online_marketing_goal,
                    rules: { required: false, message: "请输入线上营销目标", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 16
                },
                {
                    label: "门店营销点（比如口味、性价比、服务）",
                    field_type: "text",
                    field_name: "marketing_key",
                    value: row.marketing_key,
                    rules: { required: false, message: "请输入门店营销点", trigger: "blur" },
                    on_change: function(_value: any) {
                    },
                    span_num: 16
                }
            ]

            var dlg = common.dlg.admin_show_key_table_new(field_list, data, {
                title: "编辑",
                width: "1000px",
                submit: function(data: any, _btn_config: any) {
                    data["opt_type"] = "set"
                    data["bd_shop_id"] = row.bd_shop_id
                    common.do_ajax("/bd_deal/opt_shop", data, function() {
                        dlg.close()
                        me.$emit("update_info")
                        me.on_refresh()
                    })

                }


            })
        },

        on_close() {
            this.$emit("close", {})
        },
        on_select_tab(tab_name: any) {
            this.select_tab(tab_name)
        },
        select_tab(tab_name: string) {
            if (tab_name == "follow_list") {
                this.$data.cur_tab_component = markRaw(follow_list)
            } else if (tab_name == "opt_log_list") {
                this.$data.cur_tab_component = markRaw(opt_log_list)
            } else if (tab_name == "assigned_list") {
                this.$data.cur_tab_component = markRaw(assigned_list)
            } else if (tab_name == "order_list") {
                this.$data.cur_tab_component = markRaw(order_list)
            }
        },

        on_refresh() {
            var me: any = this
            var bd_shop_id = this.props.data.base_info.bd_shop_id
            common.do_ajax("/bd_info/get_bd_shop_info", {
                bd_shop_id
            }, function(resp: any) {
                me.$data.base_info = resp.data.base_info
            })

        },
        show_dp_shop(base_info: any) {
            common.dlg.show_dp_shop_info(base_info.shop_info.dp_shop_jump_url)
        }

    }

})