export default { 

account_role : {
	desc_map : <any>{
		0 : "未设置",
		1 : "bd",
		2 : "达人组",
		3 : "运营",
		100 : "城市合伙人",
		1001 : "研发"
	},
	v2s_map : {
		1 : "bd",
		2 : "dm",
		3 : "dyy",
		100 : "city_dm",
		1001 : "develop"
	},
	s2v_map : {
		bd : "1",
		dm : "2",
		dyy : "3",
		city_dm : "100",
		develop : "1001"
	},
	V_0:0,
	V_1:1,
	V_BD:1,
	V_2:2,
	V_DM:2,
	V_3:3,
	V_DYY:3,
	V_100:100,
	V_CITY_DM:100,
	V_1001:1001,
	V_DEVELOP:1001,

},

activity_type : {
	desc_map : <any>{
		1 : "lavazza"
	},
	V_1:1,

},

admin_domain_type : {
	desc_map : <any>{
		3 : "新版vue3后台",
		5 : "斜杠之星",
		13 : "达人秀秀"
	},
	v2s_map : {
		3 : "vue3",
		5 : "star",
		13 : "console"
	},
	s2v_map : {
		vue3 : "3",
		star : "5",
		console : "13"
	},
	V_3:3,
	V_VUE3:3,
	V_5:5,
	V_STAR:5,
	V_13:13,
	V_CONSOLE:13,

},

admin_group_tongji_level : {
	desc_map : <any>{
		0 : "组员",
		1 : "主管",
		2 : "经理",
		3 : "总监",
		4 : "部门",
		10 : "组织"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,
	V_10:10,

},

admin_level : {
	desc_map : <any>{
		0 : "组员",
		1 : "主管",
		2 : "经理",
		3 : "总监",
		4 : "部门",
		10 : "全部"
	},
	v2s_map : {
		10 : "all"
	},
	s2v_map : {
		all : "10"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,
	V_10:10,
	V_ALL:10,

},

admin_project_type : {
	desc_map : <any>{
		0 : "达人秀秀",
		1 : "斜杠之星",
		11 : "商户平台"
	},
	v2s_map : {
		0 : "common",
		1 : "star",
		11 : "sh"
	},
	s2v_map : {
		common : "0",
		star : "1",
		sh : "11"
	},
	V_0:0,
	V_COMMON:0,
	V_1:1,
	V_STAR:1,
	V_11:11,
	V_SH:11,

},

approval_flag : {
	desc_map : <any>{
		0 : "未审批",
		1 : "通过",
		2 : "不通过"
	},
	V_0:0,
	V_1:1,
	V_2:2,

},

approval_status : {
	desc_map : <any>{
		1 : "审批中",
		2 : "已通过",
		3 : "已驳回",
		4 : "已撤销",
		6 : "通过后撤销",
		7 : "已删除",
		10 : "已支付"
	},
	v2s_map : {
		2 : "pass"
	},
	s2v_map : {
		pass : "2"
	},
	V_1:1,
	V_2:2,
	V_PASS:2,
	V_3:3,
	V_4:4,
	V_6:6,
	V_7:7,
	V_10:10,

},

approval_type : {
	desc_map : <any>{
		1000 : "BD合同"
	},
	v2s_map : {
		1000 : "BD_ORDER"
	},
	s2v_map : {
		BD_ORDER : "1000"
	},
	V_1000:1000,
	V_BD_ORDER:1000,

},

audit_result_status : {
	desc_map : <any>{
		0 : "未设置",
		1 : "正常接单",
		2 : "限制接单"
	},
	V_0:0,
	V_1:1,
	V_2:2,

},

banner_jump_type : {
	desc_map : <any>{
		1 : "网页H5",
		2 : "小程序任务",
		3 : "小程序资料页",
		4 : "小程序教程页"
	},
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,

},

banner_type : {
	desc_map : <any>{
		1 : "达人小程序",
		2 : "商户小程序",
		3 : "官网",
		4 : "达人积分商城"
	},
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,

},

batch_page_type : {
	desc_map : <any>{
		1 : "点评达人",
		11 : "抖音团购商家案例",
		12 : "抖音团购达人"
	},
	V_1:1,
	V_11:11,
	V_12:12,

},

bd_order_daren_sale_type : {
	desc_map : <any>{
		0 : "无",
		1 : "渠道",
		2 : "刊例"
	},
	V_0:0,
	V_1:1,
	V_2:2,

},

bd_order_sale_type : {
	desc_map : <any>{
		0 : "无",
		1 : "代运营营销",
		2 : "达人营销"
	},
	V_0:0,
	V_1:1,
	V_2:2,

},

bd_shop_status : {
	desc_map : <any>{
		0 : "未签合同",
		10 : "已签合同",
		20 : "合同完成",
		50 : "回流公海"
	},
	v2s_map : {
		0 : "no_order",
		10 : "has_order",
		20 : "order_complete",
		50 : "publish"
	},
	s2v_map : {
		no_order : "0",
		has_order : "10",
		order_complete : "20",
		publish : "50"
	},
	V_0:0,
	V_NO_ORDER:0,
	V_10:10,
	V_HAS_ORDER:10,
	V_20:20,
	V_ORDER_COMPLETE:20,
	V_50:50,
	V_PUBLISH:50,

},

bd_shop_will_level : {
	desc_map : <any>{
		0 : "未知",
		10 : "无意向",
		20 : "意向",
		30 : "承诺"
	},
	V_0:0,
	V_10:10,
	V_20:20,
	V_30:30,

},

boolean : {
	desc_map : <any>{
		0 : "否",
		1 : "是"
	},
	v2s_map : {
		0 : "FALSE",
		1 : "TRUE"
	},
	s2v_map : {
		FALSE : "0",
		TRUE : "1"
	},
	V_0:0,
	V_FALSE:0,
	V_1:1,
	V_TRUE:1,

},

common_flow : {
	desc_map : <any>{
		1 : "更换手机",
		2 : "切换手机",
		3 : "转移订单",
		4 : "更改课时",
		5 : "绑定推荐人"
	},
	v2s_map : {
		1 : "change_phone",
		2 : "switch_phone",
		3 : "transfer_order",
		4 : "change_base_class",
		5 : "bind_referrer"
	},
	s2v_map : {
		change_phone : "1",
		switch_phone : "2",
		transfer_order : "3",
		change_base_class : "4",
		bind_referrer : "5"
	},
	V_1:1,
	V_CHANGE_PHONE:1,
	V_2:2,
	V_SWITCH_PHONE:2,
	V_3:3,
	V_TRANSFER_ORDER:3,
	V_4:4,
	V_CHANGE_BASE_CLASS:4,
	V_5:5,
	V_BIND_REFERRER:5,

},

content_type : {
	desc_map : <any>{
		1 : "图文",
		2 : "视频",
		11 : "图文，视频皆可"
	},
	V_1:1,
	V_2:2,
	V_11:11,

},

contract_type : {
	desc_map : <any>{
		1 : "大众点评",
		4 : "小红书",
		5 : "抖音",
		6 : "携程",
		7 : "饿了么"
	},
	v2s_map : {
		1 : "dp",
		4 : "xiaohongshu_simple",
		5 : "douyin_simple",
		6 : "xiecheng",
		7 : "ele"
	},
	s2v_map : {
		dp : "1",
		xiaohongshu_simple : "4",
		douyin_simple : "5",
		xiecheng : "6",
		ele : "7"
	},
	V_1:1,
	V_DP:1,
	V_4:4,
	V_XIAOHONGSHU_SIMPLE:4,
	V_5:5,
	V_DOUYIN_SIMPLE:5,
	V_6:6,
	V_XIECHENG:6,
	V_7:7,
	V_ELE:7,

},

daren_contract_status : {
	desc_map : <any>{
		0 : "待审核",
		10 : "已审核",
		20 : "已拒绝",
		30 : "已签约",
		40 : "已解约",
		99 : "审核不通过"
	},
	V_0:0,
	V_10:10,
	V_20:20,
	V_30:30,
	V_40:40,
	V_99:99,

},

daren_group_type : {
	desc_map : <any>{
		0 : "自由行",
		1 : "组队"
	},
	V_0:0,
	V_1:1,

},

daren_log_type : {
	desc_map : <any>{
		0 : "任务相关日志",
		1 : "平台信息"
	},
	v2s_map : {
		0 : "task",
		1 : "platform"
	},
	s2v_map : {
		task : "0",
		platform : "1"
	},
	V_0:0,
	V_TASK:0,
	V_1:1,
	V_PLATFORM:1,

},

daren_register_status : {
	desc_map : <any>{
		0 : "未提交电话号码",
		1 : "未提交用户信息",
		2 : "未审核",
		3 : "未通过审核",
		4 : "正式用户(通过审核)"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,

},

daren_show_tag_type : {
	desc_map : <any>{
		1 : "美食",
		2 : "摄影",
		3 : "时尚",
		4 : "丽人",
		5 : "医美",
		6 : "酒旅",
		7 : "购物",
		8 : "健身"
	},
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,
	V_5:5,
	V_6:6,
	V_7:7,
	V_8:8,

},

daren_task_approval_type : {
	desc_map : <any>{
		1 : "后台达人组审核",
		10 : "商户小程序-商户审核"
	},
	V_1:1,
	V_10:10,

},

daren_task_type : {
	desc_map : <any>{
		0 : "达人探店",
		1 : "神秘客"
	},
	V_0:0,
	V_1:1,

},

douyin_daren_status : {
	desc_map : <any>{
		2 : "未通过",
		10 : "邀约中",
		11 : "已接受",
		12 : "已回绝",
		13 : "已删除"
	},
	V_2:2,
	V_10:10,
	V_11:11,
	V_12:12,
	V_13:13,

},

dp_rank_type : {
	desc_map : <any>{
		1 : "热门榜",
		2 : "好评榜",
		3 : "口味榜",
		4 : "环境榜",
		5 : "服务榜",
		6 : "人气榜"
	},
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,
	V_5:5,
	V_6:6,

},

dx_content_type : {
	desc_map : <any>{
		1 : "点评",
		2 : "笔记"
	},
	V_1:1,
	V_2:2,

},

dx_daren_receive_status : {
	desc_map : <any>{
		1 : "图文笔记-接单中",
		2 : "图文笔记-暂停接单",
		3 : "视频笔记-接单中",
		4 : "视频笔记-暂停接单"
	},
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,

},

dx_daren_status : {
	desc_map : <any>{
		0 : "未签约",
		1 : "已签约",
		2 : "待同意",
		3 : "已失效",
		4 : "已拒绝",
		5 : "已解约"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,
	V_5:5,

},

dx_data_type : {
	desc_map : <any>{
		1 : "总量数据",
		2 : "增量数据"
	},
	V_1:1,
	V_2:2,

},

dx_order_daren_status : {
	desc_map : <any>{
		0 : "待到店体验",
		1 : "内容创作中",
		2 : "内容待发布",
		3 : "内容已发布",
		9999 : "被更换"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,
	V_9999:9999,

},

dx_order_status : {
	desc_map : <any>{
		20 : "待接单",
		30 : "已接单",
		40 : "进行中",
		50 : "已完成",
		60 : "已终止",
		80 : "已终止，服务机构变更",
		90 : "已评价",
		101 : "已失效"
	},
	V_20:20,
	V_30:30,
	V_40:40,
	V_50:50,
	V_60:60,
	V_80:80,
	V_90:90,
	V_101:101,

},

dx_order_status_0827 : {
	desc_map : <any>{
		10 : "待接单",
		20 : "已接单",
		30 : "待到店体验",
		40 : "内容创作中",
		50 : "内容待发布",
		60 : "内容已发布",
		70 : "已终止",
		80 : "已失效"
	},
	V_10:10,
	V_20:20,
	V_30:30,
	V_40:40,
	V_50:50,
	V_60:60,
	V_70:70,
	V_80:80,

},

dx_order_task_status : {
	desc_map : <any>{
		41 : "订单任务已终止",
		42 : "订单待接单",
		43 : "订单未分配达人",
		44 : "订单已分配达人"
	},
	V_41:41,
	V_42:42,
	V_43:43,
	V_44:44,

},

dx_performance_type : {
	desc_map : <any>{
		1 : "内容数",
		2 : "浏览数",
		3 : "获赞数",
		4 : "评论数",
		6 : "粉丝数",
		9 : "互动数",
		10 : "全站浏览数",
		21 : "已签约达人数",
		22 : "可接单达人数",
		23 : "图文笔记接单中达人数",
		24 : "视频笔记接单中达人数"
	},
	v2s_map : {
		1 : "content",
		2 : "browse",
		3 : "liked",
		4 : "reply",
		6 : "fans",
		9 : "interact",
		10 : "browse_all",
		21 : "contract",
		22 : "accept_trade"
	},
	s2v_map : {
		content : "1",
		browse : "2",
		liked : "3",
		reply : "4",
		fans : "6",
		interact : "9",
		browse_all : "10",
		contract : "21",
		accept_trade : "22"
	},
	V_1:1,
	V_CONTENT:1,
	V_2:2,
	V_BROWSE:2,
	V_3:3,
	V_LIKED:3,
	V_4:4,
	V_REPLY:4,
	V_6:6,
	V_FANS:6,
	V_9:9,
	V_INTERACT:9,
	V_10:10,
	V_BROWSE_ALL:10,
	V_21:21,
	V_CONTRACT:21,
	V_22:22,
	V_ACCEPT_TRADE:22,
	V_23:23,
	V_24:24,

},

dx_server_category : {
	desc_map : <any>{
		2 : "丽人",
		3 : "休闲娱乐",
		4 : "生活服务",
		206 : "运动健身",
		209 : "酒店",
		217 : "旅游",
		226 : "餐饮",
		289 : "教育培训",
		379 : "购物",
		385 : "会议宴会",
		388 : "结婚",
		389 : "亲子",
		390 : "爱车",
		450 : "医疗",
		600 : "家装",
		1853 : "K歌",
		1854 : "电影演出赛事",
		1861 : "宠物",
		1862 : "其他",
		2327 : "非标住宿",
		2506 : "交通设施"
	},
	V_2:2,
	V_3:3,
	V_4:4,
	V_206:206,
	V_209:209,
	V_217:217,
	V_226:226,
	V_289:289,
	V_379:379,
	V_385:385,
	V_388:388,
	V_389:389,
	V_390:390,
	V_450:450,
	V_600:600,
	V_1853:1853,
	V_1854:1854,
	V_1861:1861,
	V_1862:1862,
	V_2327:2327,
	V_2506:2506,

},

feedback_followed_bad : {
	desc_map : <any>{
		0 : "未私信",
		1 : "已私信"
	},
	V_0:0,
	V_1:1,

},

feedback_followed_bad_result : {
	desc_map : <any>{
		0 : "未回复",
		1 : "回复未删除",
		2 : "已删除"
	},
	V_0:0,
	V_1:1,
	V_2:2,

},

feedback_tag_type : {
	desc_map : <any>{
		0 : "默认标签",
		1 : "菜品标签"
	},
	V_0:0,
	V_1:1,

},

filed_type : {
	desc_map : <any>{
		0 : "布尔",
		1 : "整数",
		2 : "字符串",
		3 : "枚举"
	},
	v2s_map : {
		0 : "boolean",
		1 : "int",
		2 : "string",
		3 : "enum"
	},
	s2v_map : {
		boolean : "0",
		int : "1",
		string : "2",
		enum : "3"
	},
	V_0:0,
	V_BOOLEAN:0,
	V_1:1,
	V_INT:1,
	V_2:2,
	V_STRING:2,
	V_3:3,
	V_ENUM:3,

},

flow_check_flag : {
	desc_map : <any>{
		0 : "未审核",
		1 : "通过",
		2 : "不通过",
		3 : "驳回",
		4 : "自动通过"
	},
	v2s_map : {
		0 : "no_check",
		1 : "pass",
		2 : "no_pass",
		3 : "return_back",
		4 : "audo_pass"
	},
	s2v_map : {
		no_check : "0",
		pass : "1",
		no_pass : "2",
		return_back : "3",
		audo_pass : "4"
	},
	V_0:0,
	V_NO_CHECK:0,
	V_1:1,
	V_PASS:1,
	V_2:2,
	V_NO_PASS:2,
	V_3:3,
	V_RETURN_BACK:3,
	V_4:4,
	V_AUDO_PASS:4,

},

flow_check_type : {
	desc_map : <any>{
		1 : "<",
		2 : "<=",
		3 : "==",
		4 : ">",
		5 : ">=",
		6 : "!=",
		10 : "在",
		11 : "不在",
		20 : "在区间",
		21 : "不在区间"
	},
	v2s_map : {
		1 : "lt",
		2 : "le",
		3 : "eq",
		4 : "gt",
		5 : "ge",
		6 : "ne",
		10 : "in",
		11 : "not_in",
		20 : "between",
		21 : "not_between"
	},
	s2v_map : {
		lt : "1",
		le : "2",
		eq : "3",
		gt : "4",
		ge : "5",
		ne : "6",
		in : "10",
		not_in : "11",
		between : "20",
		not_between : "21"
	},
	V_1:1,
	V_LT:1,
	V_2:2,
	V_LE:2,
	V_3:3,
	V_EQ:3,
	V_4:4,
	V_GT:4,
	V_5:5,
	V_GE:5,
	V_6:6,
	V_NE:6,
	V_10:10,
	V_IN:10,
	V_11:11,
	V_NOT_IN:11,
	V_20:20,
	V_BETWEEN:20,
	V_21:21,
	V_NOT_BETWEEN:21,

},

flow_function : {
	desc_map : <any>{
		1 : "[通用]检查申请人角色",
		2 : "[请假]检查天数",
		3 : "[通用]判断申请人是否是主管",
		4 : "[通用]判断合同类型",
		5 : "[例子流]省份",
		6 : "[例子流]渠道",
		7 : "[例子流]进入时间",
		8 : "[通用]判断超管权限",
		20 : "[退费]处理角色",
		21 : "[退费]当前退费是否需要质检审批",
		30 : "[更改课时]判断合同课时数",
		40 : "[发票寄送]判断发票抬头类型是否为个人发票",
		41 : "[发票寄送]根据合同名称，检测是否为需要第三方来开具发票",
		50 : "[员工异动]判断前后小组上级是否一致",
		51 : "[员工异动]新兵营审批",
		52 : "[员工异动]判断调动人员是否为主管以上级别",
		100 : "[通用]审批设置审批人条件分支",
		101 : "pass节点,做桥梁用"
	},
	v2s_map : {
		1 : "check_admin_role",
		2 : "check_qingjia_day",
		3 : "check_is_l2_master",
		4 : "check_contract_type",
		5 : "check_privince",
		6 : "check_origin",
		7 : "check_add_time",
		8 : "check_admin_root",
		20 : "order_refund_check_deal_account_role",
		21 : "order_refund_money_need_check",
		30 : "check_order_class_total",
		40 : "check_invoice_title_type",
		41 : "check_invoice_name_for_other",
		50 : "check_upgroup_is_change",
		51 : "check_new_group_flow",
		52 : "check_join_user_is_l2",
		100 : "common_flow_set_check_admin",
		101 : "common_use_pass"
	},
	s2v_map : {
		check_admin_role : "1",
		check_qingjia_day : "2",
		check_is_l2_master : "3",
		check_contract_type : "4",
		check_privince : "5",
		check_origin : "6",
		check_add_time : "7",
		check_admin_root : "8",
		order_refund_check_deal_account_role : "20",
		order_refund_money_need_check : "21",
		check_order_class_total : "30",
		check_invoice_title_type : "40",
		check_invoice_name_for_other : "41",
		check_upgroup_is_change : "50",
		check_new_group_flow : "51",
		check_join_user_is_l2 : "52",
		common_flow_set_check_admin : "100",
		common_use_pass : "101"
	},
	V_1:1,
	V_CHECK_ADMIN_ROLE:1,
	V_2:2,
	V_CHECK_QINGJIA_DAY:2,
	V_3:3,
	V_CHECK_IS_L2_MASTER:3,
	V_4:4,
	V_CHECK_CONTRACT_TYPE:4,
	V_5:5,
	V_CHECK_PRIVINCE:5,
	V_6:6,
	V_CHECK_ORIGIN:6,
	V_7:7,
	V_CHECK_ADD_TIME:7,
	V_8:8,
	V_CHECK_ADMIN_ROOT:8,
	V_20:20,
	V_ORDER_REFUND_CHECK_DEAL_ACCOUNT_ROLE:20,
	V_21:21,
	V_ORDER_REFUND_MONEY_NEED_CHECK:21,
	V_30:30,
	V_CHECK_ORDER_CLASS_TOTAL:30,
	V_40:40,
	V_CHECK_INVOICE_TITLE_TYPE:40,
	V_41:41,
	V_CHECK_INVOICE_NAME_FOR_OTHER:41,
	V_50:50,
	V_CHECK_UPGROUP_IS_CHANGE:50,
	V_51:51,
	V_CHECK_NEW_GROUP_FLOW:51,
	V_52:52,
	V_CHECK_JOIN_USER_IS_L2:52,
	V_100:100,
	V_COMMON_FLOW_SET_CHECK_ADMIN:100,
	V_101:101,
	V_COMMON_USE_PASS:101,

},

flow_select_adminid_function : {
	desc_map : <any>{
		0 : "指定人",
		3000 : "[员工异动]得到部门负责人",
		3001 : "[员工异动]得到目标部门负责人",
		3002 : "[员工异动]得到目标主管",
		3003 : "[员工异动]得到目标经理",
		3004 : "[员工异动]得到目标总监"
	},
	v2s_map : {
		3000 : "get_role_admin",
		3001 : "get_target_role_admin",
		3002 : "get_target_group_l2_adminmaster",
		3003 : "get_target_group_l3_adminmaster",
		3004 : "get_target_group_l4_adminmaster"
	},
	s2v_map : {
		get_role_admin : "3000",
		get_target_role_admin : "3001",
		get_target_group_l2_adminmaster : "3002",
		get_target_group_l3_adminmaster : "3003",
		get_target_group_l4_adminmaster : "3004"
	},
	V_0:0,
	V_3000:3000,
	V_GET_ROLE_ADMIN:3000,
	V_3001:3001,
	V_GET_TARGET_ROLE_ADMIN:3001,
	V_3002:3002,
	V_GET_TARGET_GROUP_L2_ADMINMASTER:3002,
	V_3003:3003,
	V_GET_TARGET_GROUP_L3_ADMINMASTER:3003,
	V_3004:3004,
	V_GET_TARGET_GROUP_L4_ADMINMASTER:3004,

},

flow_status : {
	desc_map : <any>{
		0 : "无",
		1 : "进行中",
		2 : "通过",
		3 : "不通过"
	},
	v2s_map : {
		0 : "no_start",
		1 : "start",
		2 : "pass",
		3 : "no_pass"
	},
	s2v_map : {
		no_start : "0",
		start : "1",
		pass : "2",
		no_pass : "3"
	},
	V_0:0,
	V_NO_START:0,
	V_1:1,
	V_START:1,
	V_2:2,
	V_PASS:2,
	V_3:3,
	V_NO_PASS:3,

},

flow_type : {
	desc_map : <any>{
		1004 : "合同完成",
		1005 : "项目预算修改信息",
		1006 : "项目预算-添加项目"
	},
	v2s_map : {
		1004 : "order_complete",
		1005 : "project_change_info",
		1006 : "project_add"
	},
	s2v_map : {
		order_complete : "1004",
		project_change_info : "1005",
		project_add : "1006"
	},
	V_1004:1004,
	V_ORDER_COMPLETE:1004,
	V_1005:1005,
	V_PROJECT_CHANGE_INFO:1005,
	V_1006:1006,
	V_PROJECT_ADD:1006,

},

flow_uplevel_type : {
	desc_map : <any>{
		2 : "主管审批",
		3 : "经理审批",
		4 : "总监审批"
	},
	v2s_map : {
		2 : "level2_approval",
		3 : "level3_approval",
		4 : "level4_approval"
	},
	s2v_map : {
		level2_approval : "2",
		level3_approval : "3",
		level4_approval : "4"
	},
	V_2:2,
	V_LEVEL2_APPROVAL:2,
	V_3:3,
	V_LEVEL3_APPROVAL:3,
	V_4:4,
	V_LEVEL4_APPROVAL:4,

},

follow_type : {
	desc_map : <any>{
		1 : "实地签到",
		2 : "异地签到",
		3 : "线上拜访"
	},
	V_1:1,
	V_2:2,
	V_3:3,

},

gender : {
	desc_map : <any>{
		0 : "未设置",
		1 : "男",
		2 : "女"
	},
	V_0:0,
	V_1:1,
	V_2:2,

},

kdb_appeal_status : {
	desc_map : <any>{
		0 : "审核中",
		1 : "全部举报通过",
		3 : "举报失败",
		35 : "其他状态",
		41 : "部分通过"
	},
	V_0:0,
	V_1:1,
	V_3:3,
	V_35:35,
	V_41:41,

},

kdb_feedback_source : {
	desc_map : <any>{
		1 : "买单评价",
		2 : "门店评价",
		10 : "普通评论",
		20 : "霸王餐",
		30 : "团购",
		40 : "免费试评价",
		99 : "其他评价"
	},
	V_1:1,
	V_2:2,
	V_10:10,
	V_20:20,
	V_30:30,
	V_40:40,
	V_99:99,

},

kdb_feedback_type : {
	desc_map : <any>{
		0 : "美团",
		1 : "点评"
	},
	V_0:0,
	V_1:1,

},

kdb_launch_status : {
	desc_map : <any>{
		1 : "推广中",
		2 : "已结束",
		3 : "已暂停",
		6 : "已删除",
		7 : "草稿",
		15 : "已下线",
		17 : "无推广中创意"
	},
	V_1:1,
	V_2:2,
	V_3:3,
	V_6:6,
	V_7:7,
	V_15:15,
	V_17:17,

},

kdb_launch_type : {
	desc_map : <any>{
		1 : "推广通",
		2 : "智能展位"
	},
	v2s_map : {
		1 : "CPC",
		2 : "CPM"
	},
	s2v_map : {
		CPC : "1",
		CPM : "2"
	},
	V_1:1,
	V_CPC:1,
	V_2:2,
	V_CPM:2,

},

kp_postion : {
	desc_map : <any>{
		0 : "未设置",
		1 : "老板",
		2 : "总经理",
		3 : "总监",
		100 : "其他"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,
	V_100:100,

},

level_desc : {
	desc_map : <any>{
		0 : "未分级",
		10 : "S",
		20 : "A",
		30 : "B",
		40 : "C"
	},
	v2s_map : {
		10 : "s",
		20 : "a",
		30 : "b",
		40 : "c"
	},
	s2v_map : {
		s : "10",
		a : "20",
		b : "30",
		c : "40"
	},
	V_0:0,
	V_10:10,
	V_S:10,
	V_20:20,
	V_A:20,
	V_30:30,
	V_B:30,
	V_40:40,
	V_C:40,

},

main_task_status : {
	desc_map : <any>{
		0 : "进行中",
		1 : "已完成",
		2 : "已取消"
	},
	v2s_map : {
		0 : "task_doing",
		1 : "task_finish",
		2 : "task_cancel"
	},
	s2v_map : {
		task_doing : "0",
		task_finish : "1",
		task_cancel : "2"
	},
	V_0:0,
	V_TASK_DOING:0,
	V_1:1,
	V_TASK_FINISH:1,
	V_2:2,
	V_TASK_CANCEL:2,

},

merchant_bind_status : {
	desc_map : <any>{
		0 : "未绑定",
		1 : "待审核",
		2 : "已绑定"
	},
	V_0:0,
	V_1:1,
	V_2:2,

},

miniprogram_flag : {
	desc_map : <any>{
		0 : "幸哉微信公众号",
		1 : "小程序-幸哉达人",
		2 : "后台小程序",
		3 : "小程序-幸哉商户"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,

},

money_change_reason_type : {
	desc_map : <any>{
		1 : "增加-任务完成",
		2 : "减少-佣金发放"
	},
	V_1:1,
	V_2:2,

},

online_marketing_level : {
	desc_map : <any>{
		0 : "未设置",
		10 : "不熟悉",
		20 : "了解",
		30 : "比较熟悉",
		40 : "很熟悉"
	},
	V_0:0,
	V_10:10,
	V_20:20,
	V_30:30,
	V_40:40,

},

opt_date_type : {
	desc_map : <any>{
		1 : "天",
		2 : "周",
		3 : "月",
		4 : "季度"
	},
	v2s_map : {
		1 : "day",
		2 : "week",
		3 : "month",
		4 : "quarter"
	},
	s2v_map : {
		day : "1",
		week : "2",
		month : "3",
		quarter : "4"
	},
	V_1:1,
	V_DAY:1,
	V_2:2,
	V_WEEK:2,
	V_3:3,
	V_MONTH:3,
	V_4:4,
	V_QUARTER:4,

},

opt_type : {
	desc_map : <any>{
		1 : "新增",
		2 : "更新",
		3 : "删除"
	},
	v2s_map : {
		1 : "add",
		2 : "set",
		3 : "del"
	},
	s2v_map : {
		add : "1",
		set : "2",
		del : "3"
	},
	V_1:1,
	V_ADD:1,
	V_2:2,
	V_SET:2,
	V_3:3,
	V_DEL:3,

},

order_status : {
	desc_map : <any>{
		0 : "待确认",
		1 : "待提现",
		2 : "已删除",
		3 : "已提现"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,

},

platform_type : {
	desc_map : <any>{
		1 : "大众点评",
		2 : "小红书",
		3 : "抖音",
		4 : "微博",
		5 : "B站",
		6 : "携程",
		7 : "饿了么",
		8 : "快手"
	},
	v2s_map : {
		1 : "DIANPING",
		2 : "XIAOHONGSHU",
		3 : "DOUYIN",
		4 : "WEIBO",
		5 : "BILIBILI",
		6 : "CTRIP",
		7 : "ELE",
		8 : "KUAISHOU"
	},
	s2v_map : {
		DIANPING : "1",
		XIAOHONGSHU : "2",
		DOUYIN : "3",
		WEIBO : "4",
		BILIBILI : "5",
		CTRIP : "6",
		ELE : "7",
		KUAISHOU : "8"
	},
	V_1:1,
	V_DIANPING:1,
	V_2:2,
	V_XIAOHONGSHU:2,
	V_3:3,
	V_DOUYIN:3,
	V_4:4,
	V_WEIBO:4,
	V_5:5,
	V_BILIBILI:5,
	V_6:6,
	V_CTRIP:6,
	V_7:7,
	V_ELE:7,
	V_8:8,
	V_KUAISHOU:8,

},

power_group_admin_level : {
	desc_map : <any>{
		0 : "无归属",
		1 : "组员",
		2 : "主管",
		3 : "经理",
		4 : "总监",
		5 : "部门"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,
	V_5:5,

},

project_use_money_type : {
	desc_map : <any>{
		0 : "占位枚举"
	},
	V_0:0,

},

qywx_user_status : {
	desc_map : <any>{
		1 : "已激活",
		2 : "已禁用",
		4 : "未激活",
		5 : "退出企业"
	},
	V_1:1,
	V_2:2,
	V_4:4,
	V_5:5,

},

rate_type : {
	desc_map : <any>{
		1 : "大众点评"
	},
	V_1:1,

},

sale_rule_type : {
	desc_map : <any>{
		1 : "省心包",
		2 : "传播任务"
	},
	V_1:1,
	V_2:2,

},

score_change_reason_type : {
	desc_map : <any>{
		1 : "增加-任务完成",
		2 : "减少-任务报名",
		3 : "增加-报名任务不通过",
		4 : "取消报名"
	},
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,

},

set_boolean : {
	desc_map : <any>{
		0 : "未设置",
		1 : "是",
		2 : "否"
	},
	V_0:0,
	V_1:1,
	V_2:2,

},

settle_account_type : {
	desc_map : <any>{
		1 : "正常结算",
		2 : "按流量结算"
	},
	v2s_map : {
		1 : "normal",
		2 : "browse"
	},
	s2v_map : {
		normal : "1",
		browse : "2"
	},
	V_1:1,
	V_NORMAL:1,
	V_2:2,
	V_BROWSE:2,

},

settle_status : {
	desc_map : <any>{
		1 : "待提现",
		2 : "提现中",
		3 : "提现成功",
		4 : "提现失败"
	},
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,

},

shop_model : {
	desc_map : <any>{
		10 : "加盟",
		20 : "直营"
	},
	V_10:10,
	V_20:20,

},

sub_task_status : {
	desc_map : <any>{
		0 : "报名中",
		1 : "待分配",
		2 : "待到店",
		3 : "作业进行中",
		4 : "已完成",
		5 : "已取消"
	},
	v2s_map : {
		0 : "task_enter",
		1 : "task_to_be_assigned",
		2 : "task_to_be_implemented",
		3 : "task_doing",
		4 : "task_finish",
		5 : "task_cancel"
	},
	s2v_map : {
		task_enter : "0",
		task_to_be_assigned : "1",
		task_to_be_implemented : "2",
		task_doing : "3",
		task_finish : "4",
		task_cancel : "5"
	},
	V_0:0,
	V_TASK_ENTER:0,
	V_1:1,
	V_TASK_TO_BE_ASSIGNED:1,
	V_2:2,
	V_TASK_TO_BE_IMPLEMENTED:2,
	V_3:3,
	V_TASK_DOING:3,
	V_4:4,
	V_TASK_FINISH:4,
	V_5:5,
	V_TASK_CANCEL:5,

},

table_field_type : {
	desc_map : <any>{
		0 : "文本",
		1 : "数字",
		2 : "日期",
		3 : "枚举",
		100 : "达人",
		101 : "后台账号",
		102 : "老师",
		103 : "CC",
		104 : "CR"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,
	V_100:100,
	V_101:101,
	V_102:102,
	V_103:103,
	V_104:104,

},

tag_effact_flag : {
	desc_map : <any>{
		0 : "负面",
		1 : "正面"
	},
	V_0:0,
	V_1:1,

},

tag_from_type : {
	desc_map : <any>{
		0 : "系统标签",
		1 : "自定义标签"
	},
	V_0:0,
	V_1:1,

},

tag_type : {
	desc_map : <any>{
		1 : "形象",
		2 : "性别",
		3 : "擅长",
		4 : "风格",
		20 : "达人资料库标签",
		1000 : "履约"
	},
	v2s_map : {
		20 : "daren_database"
	},
	s2v_map : {
		daren_database : "20"
	},
	V_1:1,
	V_2:2,
	V_3:3,
	V_4:4,
	V_20:20,
	V_DAREN_DATABASE:20,
	V_1000:1000,

},

task_status : {
	desc_map : <any>{
		0 : "报名中",
		1 : "待上传",
		2 : "待审核",
		3 : "待修改",
		4 : "已完成",
		5 : "待发布",
		6 : "待确认",
		7 : "链接待修改",
		8 : "商家审核",
		9 : "商家不通过",
		10 : "报名失败",
		11 : "任务失败",
		12 : "阅读量待上传",
		13 : "阅读量待审核",
		20 : "已结算",
		21 : "结算审核不通过"
	},
	v2s_map : {
		0 : "task_enter",
		1 : "task_to_be_implemented",
		2 : "task_to_be_approvaled",
		3 : "task_to_be_edited",
		4 : "task_finish",
		5 : "task_publish",
		6 : "task_confirm",
		7 : "task_dp_to_be_edited",
		8 : "task_merchant_to_be_approvaled",
		9 : "task_merchant_to_be_edited",
		10 : "task_enter_fail",
		11 : "task_fail",
		12 : "browse_upload",
		13 : "browse_approval",
		20 : "task_settle",
		21 : "task_settle_approval_fail"
	},
	s2v_map : {
		task_enter : "0",
		task_to_be_implemented : "1",
		task_to_be_approvaled : "2",
		task_to_be_edited : "3",
		task_finish : "4",
		task_publish : "5",
		task_confirm : "6",
		task_dp_to_be_edited : "7",
		task_merchant_to_be_approvaled : "8",
		task_merchant_to_be_edited : "9",
		task_enter_fail : "10",
		task_fail : "11",
		browse_upload : "12",
		browse_approval : "13",
		task_settle : "20",
		task_settle_approval_fail : "21"
	},
	V_0:0,
	V_TASK_ENTER:0,
	V_1:1,
	V_TASK_TO_BE_IMPLEMENTED:1,
	V_2:2,
	V_TASK_TO_BE_APPROVALED:2,
	V_3:3,
	V_TASK_TO_BE_EDITED:3,
	V_4:4,
	V_TASK_FINISH:4,
	V_5:5,
	V_TASK_PUBLISH:5,
	V_6:6,
	V_TASK_CONFIRM:6,
	V_7:7,
	V_TASK_DP_TO_BE_EDITED:7,
	V_8:8,
	V_TASK_MERCHANT_TO_BE_APPROVALED:8,
	V_9:9,
	V_TASK_MERCHANT_TO_BE_EDITED:9,
	V_10:10,
	V_TASK_ENTER_FAIL:10,
	V_11:11,
	V_TASK_FAIL:11,
	V_12:12,
	V_BROWSE_UPLOAD:12,
	V_13:13,
	V_BROWSE_APPROVAL:13,
	V_20:20,
	V_TASK_SETTLE:20,
	V_21:21,
	V_TASK_SETTLE_APPROVAL_FAIL:21,

},

tongji_type : {
	desc_map : <any>{
		1 : "人员",
		3 : "日期"
	},
	v2s_map : {
		1 : "account",
		3 : "date"
	},
	s2v_map : {
		account : "1",
		date : "3"
	},
	V_1:1,
	V_ACCOUNT:1,
	V_3:3,
	V_DATE:3,

},

user_wx_type : {
	desc_map : <any>{
		0 : "幸哉公众号",
		1 : "幸哉达人小程序",
		2 : "幸哉商户小程序",
		3 : "后台小程序"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,

},

wm_order_check_service_flag : {
	desc_map : <any>{
		0 : "正常",
		1 : "送餐时间异常"
	},
	V_0:0,
	V_1:1,

},

wm_order_status : {
	desc_map : <any>{
		4 : "骑手已取餐",
		8 : "已完成",
		9 : "已取消"
	},
	V_4:4,
	V_8:8,
	V_9:9,

},

wm_platform_type : {
	desc_map : <any>{
		1 : "美团",
		2 : "饿了么",
		10 : "开店宝-餐饮",
		11 : "开店宝-非餐"
	},
	v2s_map : {
		1 : "mei_tuan",
		2 : "eleme",
		10 : "kai_dian_bao",
		11 : "kai_dian_bao_no_food"
	},
	s2v_map : {
		mei_tuan : "1",
		eleme : "2",
		kai_dian_bao : "10",
		kai_dian_bao_no_food : "11"
	},
	V_1:1,
	V_MEI_TUAN:1,
	V_2:2,
	V_ELEME:2,
	V_10:10,
	V_KAI_DIAN_BAO:10,
	V_11:11,
	V_KAI_DIAN_BAO_NO_FOOD:11,

},

wm_prepare_status : {
	desc_map : <any>{
		0 : "未出餐",
		1 : "出餐中",
		2 : "已出餐",
		3 : "未上报出餐"
	},
	V_0:0,
	V_1:1,
	V_2:2,
	V_3:3,

},

wm_shipping_service : {
	desc_map : <any>{
		1010 : "会员配送",
		1030 : "正常配送",
		1040 : "全城送"
	},
	V_1010:1010,
	V_1030:1030,
	V_1040:1040,

},

wm_user_apply_res_type : {
	desc_map : <any>{
		0 : "无",
		2 : "用户取消订单,确认退款",
		6 : "订单取消自动确认退款",
		7 : "用户取消退款"
	},
	V_0:0,
	V_2:2,
	V_6:6,
	V_7:7,

},

};