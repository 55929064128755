import admin_vue from "@/components/Admin/admin_vue"
import { defineComponent, markRaw } from "vue"
import task_time_list from "@/views/daren_info/task_time_list.vue"
import assigned_task_time_list from "@/views/daren_info/assigned_task_time_list.vue"
import common from "@/utils/common"
import { field_item_list_t, column_item_list_t, row_item_list_t } from "@/utils/type_def"

interface IProps {
    task_time_id: number,
    data: any,
}

export default defineComponent({
    name: "AmdinDarenInfo",
    components: {
    },
    extends: admin_vue,

    props: {
        task_time_id: Number,
        data: Object



    },
    // event
    emits: ["close"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {

            active_tab_name: "task_time_list",
            cur_tab_component: <any>markRaw(task_time_list),
            base_info: <any>{},
            row: {
                task_time_id: 0
            }
        }
    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_mounted() {
        },

        on_created() {
            this.set_data(this.props.data)
        },

        set_data(data: any) {
            const me = this
            me.$data.base_info = this.props.data.base_info
            me.$data.row = {
                task_time_id: this.$data.base_info.task_time_id
            }
        },

        on_close() {
            this.$emit("close", {})
        },

        on_select_tab(tabname: string) {
            this.select_tab(tabname)
        },

        select_tab(tab_name: string) {
            if (tab_name == "task_time_list") {
                this.$data.cur_tab_component = task_time_list
            }
        }
    }
})

