import admin_vue from "@/components/Admin/admin_vue"

import { field_item_list_t } from "@/utils/type_def"
import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    row: any,
    data: any,
}



export default defineComponent({
    components: {
    },
    extends: admin_vue,

    props: {
        row: Object,
        data: Object

    },
    // event
    emits: ["close"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            optionList: <Array<any>>[],
            timeOptionList: <Array<any>>[],
            // task_id: '',
            form: {
                shop_addr: "",
                shop_name: "",
                shop_id: "",
                task_time_id: ""
            }
        }
    },
    created() {
        this.on_created()
    },
    mounted() {
        // this.on_mounted();
    },
    methods: {
        on_created() {
            const me = this
            console.log("this", this)
            console.log("props", this.props)
            console.log("shopping props", this.props.data)

            // console.log('tableData', me.$data.tableData);
            // me.$data.tableData = this.props.data.list;
            // // console.log('');

            // // me.$data.base_info = this.props.data.base_info;
            // me.$data.row = {
            //     // userid: this.props.data.base_info.userid,
            //     // tag_list: this.props.data.tag_list,
            // }
            this.getTaskTime(me)

        },

        close() {
            this.$emit("close")
        },
        confirm() {
            const _this = this
            common.do_ajax("/daren_ex/change_shopping_time", {
                userid: _this.props.row.userid,
                task_time_id: _this.form.task_time_id
            }, function(resp: any) {
                if (resp.code === 0) {
                    _this.$emit("close")
                    console.log(resp)
                }
            })
        },

        getTaskTime(that: any) {

            common.do_ajax("/daren/shop_list", {
                page_num: 1,
                page: 1,
                sort: "shop_id",
                order: "desc",
                page_count: 10000,
                main_shop_id: this.props.row.main_shop_id
            }, function(resp: any) {
                if (resp.code === 0) {
                    that.optionList = resp.data.list
                    that.form.shop_id = that.props.row.shop_id
                    that.change_address()
                    // that.getTaskId();
                    console.log(resp)
                }
            })
        },

        addShopAddr() {
            const field_list: field_item_list_t = [
                {


                    field_type: "text",
                    field_name: "shop_name",
                    label: "店铺名字",
                    value: ""
                }, {

                    field_type: "text",
                    field_name: "address",
                    label: "地址",
                    value: ""
                }]
            const data = {}
            const me = this
            console.log(me.props.row)
            const title = "添加"
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: title,
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    if (!data.shop_name) {
                        common.alert("请填写店铺名字")
                        return
                    }
                    if (!data.address) {
                        common.alert("请填写地址")
                        return
                    }
                    data["opt_type"] = "add"
                    data["main_shop_id"] = me.props.row.main_shop_id
                    data["shop_id"] = me.props.row.shop_id
                    common.do_ajax("/daren/opt_shop", data, function() {
                        me.getTaskTime(me)
                    })
                    dlg.close()
                }
            })
        },

        change_address() {
            const _this = this
            // _this.getTaskId();
            common.do_ajax("/daren_ex/get_task_item_list", {
                page: 1,
                page_size: 10000,
                shop_id: _this.form.shop_id,
                city_task_id: this.props.row.city_task_id
            }, function(resp: any) {
                if (resp.code === 0) {
                    _this.form.task_time_id = ""
                    _this.timeOptionList = resp.data.list
                    console.log(resp)
                }
            })
        },





        addTime() {
            // var row = {
            //     shopping_time: '',
            //     need_user_count: '',
            //     enter_end_time: '',
            //     report_end_time: '',
            //     city_task_id: ''
            // };
            const field_list = [{
                field_type: "date",
                field_name: "shopping_time",
                label: "到店时间",
                value_format: "yyyy-MM-dd HH:mm",
                format: "yyyy-MM-dd HH:mm",
                type: "datetime",
                value: "",
                style: {
                    width: "200px"
                },
                span_num: 12,
                on_change: function(val: string) {
                    const end_time = `${val.substring(11, 13)}:59`
                    data.shopping_end_time = end_time
                }
            }, {
                field_type: "time",
                field_name: "shopping_end_time",
                label: "~",
                value_format: "HH:mm",
                format: "HH:mm",
                style: {
                    width: "100px"
                },
                label_width: "50px",
                span_num: 6

            }, , {
                    field_type: "text",
                    field_name: "need_user_count",
                    label: "招募人数",
                    value: 1,
                    style: {
                        width: "200px"
                    },
                    span_num: 24

                }, {
                    field_type: "date",
                    field_name: "enter_end_time",
                    label: "报名截止时间",
                    format: "yyyy-MM-dd",
                    value_format: "yyyy-MM-dd",
                    type: "date",
                    value: "",
                    style: {
                        width: "200px"
                    },
                    span_num: 24
                }, {
                    field_type: "text",
                    field_name: "report_end_time",
                    label: "作业提交截止时间,到店时间后(几个)小时",
                    value: "24",
                    style: {
                        width: "200px"
                    },
                    span_num: 24

                }]
            var data: any = {}
            const me = this
            var dlg = common.dlg.admin_show_key_table_new(field_list, data, {
                title: "新增到店时间",
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    data.opt_type = "add"
                    if (!data.shopping_time) {
                        common.alert("请选择 到店时间")
                        return
                    }

                    if (!data.enter_end_time) {
                        common.alert("请选择 报名截止时间")
                        return
                    }
                    data.shopping_end_time = common.strtotime((<string>data.shopping_time).substring(0, 11) + data.shopping_end_time)
                    data.shopping_time = common.strtotime(data.shopping_time)
                    data.enter_end_time = common.strtotime(data.enter_end_time) + 86400 - 1
                    data.report_end_time = data.shopping_time + 3600 * data.report_end_time
                    data.shop_id = me.form.shop_id
                    data.city_task_id = me.props.row.city_task_id

                    common.do_ajax("/daren_ex/opt_task_item", data, function() {
                        me.change_address()
                    })

                    dlg.close()
                }

            })

        },



        on_close() {
            this.$emit("close", {})
        }




    }

})
