import { defineComponent } from "vue"

interface IProps {
    value: Array<any>,
    opt_list: Array<any>,
}

// @Component 修饰符注明了此类为一个 Vue 组件
export default defineComponent({
    components: {
    },
    props: {
        opt_list: {
            type: Array,
            required: true
        },
        value: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }

    },
    emits: ["update:modelValue"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props
        }
    },
    data() {
        return {
            mineStatus: "",
            // data: <any>[],
            field_name: "",
            mineStatusValue: <any>[],
            checkedKeys: <any>[],
            defaultProps: {
                children: "children",
                label: "label"
            }
        }
    },

    computed: {

    },

    created() {

    },
    mounted() {
        const props: any = this.$props
        this.$data.field_name = props.field_name
        this.handleCheckChange()
    },
    methods: {
        // select框值改变时候触发的事件
        selectChange(e: any) {
            const arrNew: any = []
            const me: any = this
            const dataLength = this.mineStatusValue.length
            const eleng = e.length
            for (let i = 0; i < dataLength; i++) {
                for (let j = 0; j < eleng; j++) {
                    if (e[j] === this.mineStatusValue[i].label) {
                        arrNew.push(this.mineStatusValue[i])
                    }
                }
            }
            me.$refs.tree.setCheckedNodes(arrNew) // 设置勾选的值
            me.handleCheckChange()
        },
        handleCheckChange() {
            const me: any = this
            const res = me.$refs.tree.getCheckedNodes(true, true) // 这里两个true，1. 是否只是叶子节点 2. 是否包含半选节点（就是使得选择的时候不包含父节点）
            const arrLabel: any = []
            const arrValue: any = []
            const arr: any = []
            res.forEach((item: any) => {
                arrLabel.push(item.label)
                arrValue.push(item.value)
                arr.push(item)
            })
            this.mineStatusValue = arr
            this.mineStatus = arrLabel
            console.log(`arr:${JSON.stringify(arr)}`)
            console.log(`arrLabel:${arrLabel}`)
            me.$emit("update:modelValue", arrValue)
        }
    }
})

// export default {
//   data() {
//     return {
//       mineStatus: '',
//       mineStatusValue: [],
//       checkedKeys:[10030,10031,10032,10034,10036,10038,10042,10043,10044,10045,10046,10047,10048,10049,10050,10051,10052,10053,10054,10055,10056,10066,10067,10068,10069,10070,10071,10072,10073,10074,10085,10086,10087,10089,10090,10091,10093,10097,10098],
//       data: [
//         {
//             "value": 10030,
//             "label": "餐饮",
//             "categoryID": 226,
//             "children": []
//         },
//         {
//             "value": 10031,
//             "label": "家居",
//             "categoryID": 600,
//             "children": []
//         },
//         {
//             "value": 10032,
//             "label": "运动健身",
//             "categoryID": 206,
//             "children": []
//         },
//         {
//             "value": 10033,
//             "label": "宠物",
//             "categoryID": 1861,
//             "children": []
//         },
//         {
//             "value": 10034,
//             "label": "酒店",
//             "categoryID": 209,
//             "children": []
//         },
//         {
//             "value": 10035,
//             "label": "结婚",
//             "categoryID": 388,
//             "children": [
//                 {
//                     "value": 10042,
//                     "label": "婚纱摄影",
//                     "categoryID": 96
//                 },
//                 {
//                     "value": 10043,
//                     "label": "旅拍",
//                     "categoryID": 2300
//                 },
//                 {
//                     "value": 10044,
//                     "label": "婚纱礼服",
//                     "categoryID": 115
//                 },
//                 {
//                     "value": 10045,
//                     "label": "个性写真",
//                     "categoryID": 98
//                 },
//                 {
//                     "value": 10046,
//                     "label": "婚宴/宴会场地",
//                     "categoryID": 501
//                 },
//                 {
//                     "value": 10047,
//                     "label": "婚戒首饰",
//                     "categoryID": 503
//                 },
//                 {
//                     "value": 10048,
//                     "label": "司仪主持",
//                     "categoryID": 2179
//                 },
//                 {
//                     "value": 10049,
//                     "label": "其他结婚",
//                     "categoryID": 473
//                 },
//                 {
//                     "value": 10050,
//                     "label": "婚礼喜品",
//                     "categoryID": 471
//                 },
//                 {
//                     "value": 10051,
//                     "label": "婚庆公司",
//                     "categoryID": 119
//                 },
//                 {
//                     "value": 10052,
//                     "label": "婚车租赁",
//                     "categoryID": 502
//                 },
//                 {
//                     "value": 10053,
//                     "label": "彩妆造型",
//                     "categoryID": 2181
//                 },
//                 {
//                     "value": 10054,
//                     "label": "西服定制",
//                     "categoryID": 504
//                 },
//                 {
//                     "value": 10055,
//                     "label": "婚礼跟拍",
//                     "categoryID": 2180
//                 },
//                 {
//                     "value": 10056,
//                     "label": "情感咨询",
//                     "categoryID": 2549
//                 }
//             ]
//         },
//         {
//             "value": 10036,
//             "label": "购物",
//             "categoryID": 379,
//             "children": []
//         },
//         {
//             "value": 10037,
//             "label": "休闲娱乐",
//             "categoryID": 3,
//             "children": [
//                 {
//                     "value": 10066,
//                     "label": "密室",
//                     "categoryID": 225
//                 },
//                 {
//                     "value": 10067,
//                     "label": "轰趴",
//                     "categoryID": 2138
//                 },
//                 {
//                     "value": 10068,
//                     "label": "新奇体验",
//                     "categoryID": 2413
//                 },
//                 {
//                     "value": 10069,
//                     "label": "桌游",
//                     "categoryID": 53
//                 },
//                 {
//                     "value": 10070,
//                     "label": "酒吧",
//                     "categoryID": 263
//                 },
//                 {
//                     "value": 10071,
//                     "label": "茶馆",
//                     "categoryID": 264
//                 },
//                 {
//                     "value": 10072,
//                     "label": "采摘/农家乐",
//                     "categoryID": 57
//                 },
//                 {
//                     "value": 10073,
//                     "label": "DIY手工坊",
//                     "categoryID": 55
//                 },
//                 {
//                     "value": 10074,
//                     "label": "游戏厅",
//                     "categoryID": 54
//                 }
//             ]
//         },
//         {
//             "value": 10038,
//             "label": "旅游",
//             "categoryID": 217,
//             "children": []
//         },
//         {
//             "value": 10039,
//             "label": "丽人",
//             "categoryID": 2,
//             "children": [
//                 {
//                     "value": 10085,
//                     "label": "美发",
//                     "categoryID": 38
//                 },
//                 {
//                     "value": 10086,
//                     "label": "美甲",
//                     "categoryID": 39
//                 },
//                 {
//                     "value": 10087,
//                     "label": "美容美体",
//                     "categoryID": 42
//                 },
//                 {
//                     "value": 10088,
//                     "label": "美睫纹绣",
//                     "categoryID": 47
//                 },
//                 {
//                     "value": 10089,
//                     "label": "瑜伽",
//                     "categoryID": 702
//                 },
//                 {
//                     "value": 10090,
//                     "label": "舞蹈",
//                     "categoryID": 701
//                 },
//                 {
//                     "value": 10091,
//                     "label": "化妆品",
//                     "categoryID": 683
//                 },
//                 {
//                     "value": 10092,
//                     "label": "医疗整形",
//                     "categoryID": 768
//                 },
//                 {
//                     "value": 10093,
//                     "label": "养发",
//                     "categoryID": 2539
//                 }
//             ]
//         },
//         {
//             "value": 10040,
//             "label": "医疗",
//             "categoryID": 450,
//             "children": [
//                 {
//                     "value": 10097,
//                     "label": "体检中心",
//                     "categoryID": 45
//                 },
//                 {
//                     "value": 10098,
//                     "label": "口腔齿科",
//                     "categoryID": 46
//                 }
//             ]
//         }
//       ],
//       defaultProps: {
//         children: 'children',
//         label: 'label',
//       },
//     }
//   },
//   created(){
//   },
//   mounted(){
//     this.handleCheckChange()
//   },
//   methods: {
//     //select框值改变时候触发的事件
//     selectChange(e) {
//       var arrNew = []
//       var dataLength = this.mineStatusValue.length
//       var eleng = e.length
//       for (let i = 0; i < dataLength; i++) {
//         for (let j = 0; j < eleng; j++) {
//           if (e[j] === this.mineStatusValue[i].label) {
//             arrNew.push(this.mineStatusValue[i])
//           }
//         }
//       }
//       this.$refs.tree.setCheckedNodes(arrNew) //设置勾选的值
//     },
//     handleCheckChange() {
//       let res = this.$refs.tree.getCheckedNodes(true, true) //这里两个true，1. 是否只是叶子节点 2. 是否包含半选节点（就是使得选择的时候不包含父节点）
//       let arrLabel = []
//       let arr = []
//       res.forEach((item) => {
//         arrLabel.push(item.label)
//         arr.push(item)
//       })
//       this.mineStatusValue = arr
//       this.mineStatus = arrLabel
//       console.log('arr:' + JSON.stringify(arr))
//       console.log('arrLabel:' + arrLabel)
//     },
//   },
// }
