import { createApp, defineComponent, h, markRaw, nextTick, reactive } from "vue"
import { ElButton, ElDialog, ElForm } from "element-plus"
import common, { ON_UPDATE_MODEL_EVENT } from "./common"
import { btn_config_list_t, column_item_list_t, field_item_list_t } from "./type_def"
import util from "./sys_util"
import sys_util from "./sys_util"
import { bind_component } from "../admin_component"
import router from "/@/router/index"
import { store } from "/@/store/index"
import daren_info from "@/views/Others/daren_info.vue"
import daren_score from "@/views/Others/daren_score.vue"
import task_time_info from "@/views/Others/task_time_info.vue"
import select_shopping from "@/views/Others/select_shopping.vue"
import edit_shopping_time from "@/views/Others/edit_shopping_time.vue"

import task_steps from "@/views/Others/task_steps.vue"
import star_steps from "@/views/Others/star_steps.vue"
import bd_shop_info from "@/views/Others/bd_shop_info.vue"
import admin_field_list from "@/components/Admin/field_list.vue"
import merchant_task from "@/views/merchant/merchant_task.vue"


import ElementPlus, { ElMessage } from "element-plus"



interface admin_show_dlg_config_t {
    data?: Object;
    other_data?: Object;
    title?: string,
    submit_text?: string, // 提交按钮文字
    custom_class?: string, // Dialog 的自定义类名
    on_created?: Function,
    width?: string | number,
    btn_list?: btn_config_list_t,
    content?: Function,
    content_config?: Object,
    close_on_click_modal?: boolean,
    title_center_flag?: boolean, // 是否对头部和底部采用居中布局
}


interface admin_show_key_table_config_t {
    title?: string,
    width?: string | number,
    submit_text?: string, // 提交按钮文字
    btn_list?: btn_config_list_t,
    on_created?: Function,
    // 提交 , 返回 true ,关闭窗口
    submit?: Function, //
    // 关闭前检查 , 返回 true ,关闭窗口
    before_close?: Function,
    label_width?: string | number,
    label_position?: string,
    use_submit?: boolean,
    close_on_click_modal?: boolean,
}

interface admin_show_dlg_t {
    close(): any;

}
interface admin_ajax_table_select_dlg_t {
    close(): any;
    get_vue_data(): any;
    reload(): void
    hide(): void
    show(): void
    $refs: any
}


var dlg = {


    admin_show_key_json(title: string, json_obj: any) {

        const field_list: Array<any> = []
        for (const k in json_obj) {
            let value = json_obj[k]
            if (!(util.isString(value) || util.isNumber(value))) {
                value = JSON.stringify(value)
            }
            field_list.push({
                label: k,
                field_type: "label",
                field_name: `${k}`,
                value: `${value}`
            })
        }
        console.log(field_list)
        const data = reactive({})
        // var me = this;

        var dlg = common.dlg.admin_show_key_table(field_list, data, {
            title: title,
            width: "600px",
            // use_submit: false,
            submit: function(_data: any, _btn_config: any) {
                console.log("22222", dlg)
                dlg.close()

                return true
            }
        })

    },

    admin_show_key_table_vue(field_list: Array<any>, data: any, config: admin_show_key_table_config_t, _fieldset: any = [], spans = 2) {

        const default_config = {
            width: "500px",
            title: "编辑",
            submit_text: "提 交",
            // 提交 , 返回 true ,关闭窗口
            submit: function(_data: any, _btn_config: any, _dlg: any) { },
            btn_list: [],
            before_close: function() { return true },
            on_created: function() { },
            label_width: "120px",
            label_position: "right",
            use_submit: true

        }
        const default_span_num: number = 24 / spans
        const obj_config: admin_show_key_table_config_t = Object.assign(default_config, config)

        const rules: any = {}

        var gen_data_func = function(field_list: Array<any>) {
            field_list.forEach(function(item, _i: any) {
                console.log("XXXX ", item.field_name)
                if (item.field_type == "group") {
                    gen_data_func(item.field_list)
                } else if (sys_util.isString(item.field_name)) {
                    console.log("save data:", item.field_name, item.value)
                    data[item.field_name] = item.value
                    if (item.rules) {
                        rules[item.field_name] = item.rules
                    }
                }
            })
        }
        gen_data_func(field_list)

        data = reactive(data)



        const content = function(data: any) {



            let label_width: string = <string>(obj_config["label_width"])
            if (common.in_phone()) {
                if (parseInt(label_width) > 120) {
                    label_width = "120px"
                }
            }


            const node = h(ElForm,
                {
                    "label-width": label_width,
                    "label-position": obj_config["label_position"],
                    model: data,
                    rules: rules,
                    "inline-message": true,
                    ref: "form"
                }, {
                    default: () => [
                        h(admin_field_list, {
                            field_list: field_list,
                            data: data,
                            default_span_num: default_span_num
                        })
                    ]
                })


            return [node]
        }

        const other_data = {
            field_list: field_list
        }
        // var btn_list = obj_config.btn_list;

        const back_btn = {
            text: "返 回",
            click: function() {
                return true
            }
        }


        let btn_list: Array<any> = []
        if (obj_config.use_submit) {
            btn_list.push(back_btn)
        }
        btn_list = btn_list.concat(obj_config.btn_list!)



        if (obj_config.use_submit) {
            btn_list = btn_list!.concat([{
                text: obj_config.submit_text,
                type: "primary",
                click: function(data: any, btn_config: any, dlg: any) {
                    const ret = obj_config.submit!(data, btn_config, dlg)
                    if (ret == true) {
                        dlg.$data.visible = false
                    }

                }
            }])

        } else {
            btn_list.push(back_btn)
        }



        return common.dlg.admin_show_dlg({
            title: obj_config.title,
            width: obj_config.width,
            content: content,
            data: data,
            btn_list: btn_list,
            other_data: other_data,
            on_created: obj_config.on_created,
            submit_text: obj_config.submit_text,
            content_config: {
                rules: rules
            }
        })
    },

    admin_show_key_table_new(
        field_list: Array<any>, data: any, config: admin_show_key_table_config_t, fieldset: any = [], spans = 2
    ) {
        return this.admin_show_key_table_vue(field_list, data, config, fieldset, spans)
    },

    admin_show_key_table(field_list: Array<any>, data: any, config: admin_show_key_table_config_t) {
        return this.admin_show_key_table_vue(field_list, data, config, [], 1)
    },

    admin_show_dlg(config: admin_show_dlg_config_t): admin_show_dlg_t {
        const default_config = {
            data: {},
            other_data: {},
            content_config: {},
            width: null,
            title: "编辑",
            btn_list: [],
            content: null,
            custom_class: undefined,
            submit_text: "提 交",
            close_on_click_modal: true,
            title_center_flag: false,
            on_created: function() { }
        }

        const obj_config: admin_show_dlg_config_t = Object.assign(default_config, config)
        if (common.in_phone()) {
            obj_config.width = "95%"
        }





        let dlg: any = null

        this.admin_new_vue_app(function(dlg_remove_func: any) {
            const el_data = {
                data: obj_config.data,
                visible: true
            }
            Object.assign(el_data, obj_config.other_data)
            console.log("el_data", el_data)

            return defineComponent({
                data: function() {
                    return el_data
                },
                watch: {
                },


                created() {
                    console.log("1113333333 000000000")
                    // 挂接外面
                    dlg = this
                    obj_config.on_created!()
                },
                mounted() {
                    window.addEventListener("popstate", this.add_pop_state, false)
                },

                unmounted() {
                    window.removeEventListener("popstate", this.add_pop_state, false)
                },


                methods: {
                    add_pop_state(_ev: PopStateEvent) {
                        this.close()
                        window.history.go(1)
                    },
                    on_other_btn_click(btn_config: any) {
                        console.log("bbbbbbbbbbbbbb", btn_config)

                        if (btn_config.click(this.$data.data, btn_config, this)) {
                            this.close()
                        }
                    },
                    close() {
                        this.$data.visible = false
                    },
                    get_content_config() {
                        return obj_config.content_config
                    },


                    recheck() {
                        (<any>this)["$refs"].form["validate"]((_valid: any) => { })
                    }

                },


                render() {
                    console.log("dlg render..................")
                    const me = this
                    const btn_vnode_list: Array<any> = []


                    obj_config.btn_list!.forEach(function(btn_config: any, _i: any) {
                        btn_vnode_list.push(h(ElButton, {
                            onClick: function() {
                                me.on_other_btn_click(btn_config)
                            },
                            type: btn_config.type,
                            icon: btn_config.icon,
                            title: btn_config.title
                        }, {
                            default: () => btn_config.text
                        }))

                    })



                    const footer = h("div", {
                        class: "dialog-footer"
                    }, {
                        default: () => [btn_vnode_list]
                    })

                    const dlg_content: Array<any> = obj_config.content!(this.$data.data, h)

                    return (<any>h)(ElDialog, {
                        width: obj_config.width,
                        "close-on-click-modal": obj_config.close_on_click_modal,
                        center: obj_config.title_center_flag,
                        "model-value": this.$data.visible,
                        [ON_UPDATE_MODEL_EVENT]: function(value: any) {
                            me.$data.visible = value
                        },

                        "custom-class": obj_config.custom_class + (common.in_phone() ? " in_phone" : ""),
                        top: common.in_phone() ? "1vh" : "15vh",

                        title: obj_config.title,
                        onClose: function() {
                            dlg_remove_func()

                        }
                    },
                    {
                        default: () => [dlg_content],
                        footer: () => footer
                    }
                    )
                }
            })
        })

        return dlg
    },

    admin_ajax_table_select(
        url: any, url_args_ex: any, query_filter_item_list: any, column_list: column_item_list_t, callback: ((row: any, use_lru_flag?: boolean) => void) | null, config = {}, title = ""
    ): admin_ajax_table_select_dlg_t {
        const default_config = {
            width: null,
            title: "选择",
            table_height: "",
            use_page_bar: true,
            with_ajax_flag: true,
            data: [],
            deal_ajax_result_func: null,
            display_type: "select",
            select_type: "single_select",
            selected_column_list: [],
            lru_list_field_name: "", // 最近选项列表 , single_select 有效
            lru_list_url: "",

            show_download: true, // 是否显示下载
            select_value: null,
            url_query_field_name: null,
            class_name: "",
            toolbar_html_str: "",
            query_input_com: {},
            default_sort: undefined,

            // 多选的生成
            gen_select_item_list_callback: null,
            other_btn_list: [],
            // 异步,多选, 显示多选框,
            use_checkbox_select_in_ajax_multi_select_flag: false,
            on_row_click: function(_row: any) { },
            on_created: function() { },
            on_close: function() { },
            on_query: function(_args: any) { }
        }

        var obj_config: any = Object.assign(default_config, config)
        if (title) {
            obj_config = Object.assign(obj_config, { title })
        }
        if (common.in_phone()) {
            obj_config.width = "95%"
        }

        let dlg: any = null
        const template =
            "<admin-ajax-table-select  @query=\"on_select\"  ref=\"ajax_table\" :class=\"class_name\" v-show=\"show_flag\"  "
            + " :query_filter_item_list=\"query_filter_item_list\" :column_list=\"column_list\""
            + " :url=\"url\" :url_args_ex=\"url_args_ex\"  :width=\"width\" :title=\"title\" "
            + "  :table_height=\"table_height\" :use_page_bar=\"use_page_bar\" :data=data"
            + " :other_btn_list=\"other_btn_list\" :with_ajax_flag=\"with_ajax_flag\" "
            + " :deal_ajax_result_func=\"deal_ajax_result_func\"  "
            + " :gen_select_item_list_callback=\"gen_select_item_list_callback\"  "
            + " :display_type=\"display_type\"  "
            + " :select_value=\"select_value\"  "
            + " :show_query_button=\"true\"  "
            + " :lru_list_field_name=\"lru_list_field_name\"  "
            + " :lru_list_url=\"lru_list_url\"  "
            + " :show_download=\"show_download\"  "

            + ":use_checkbox_select_in_ajax_multi_select_flag=\"use_checkbox_select_in_ajax_multi_select_flag\""

            + " :selected_column_list=\"selected_column_list\"  "
            + " :url_query_field_name=\"url_query_field_name\"  "
            + " :toolbar_html_str=\"toolbar_html_str\"  "
            + " :query_input_com=\"query_input_com\"  "
            + " :default_sort=\"default_sort\"  "

            + " @close=\"on_close\"  "
            + " @query_inputlist_query=\"on_query\"  "
            + " @row-click=\"on_row_click\"  "

            + " :select_type=\"select_type\"  "
            + " ></admin-ajax-table-select>"



        this.admin_new_vue_app(function(dlg_remove_func: any) {
            return defineComponent({
                data: function() {
                    return {
                        show_flag: true,
                        url: url,
                        width: obj_config.width,
                        title: obj_config.title,
                        table_height: obj_config.table_height,
                        use_page_bar: obj_config.use_page_bar,
                        url_args_ex: url_args_ex,
                        column_list: column_list,
                        query_filter_item_list: query_filter_item_list,
                        data: obj_config.data,
                        other_btn_list: obj_config.other_btn_list,
                        with_ajax_flag: obj_config.with_ajax_flag,
                        deal_ajax_result_func: obj_config.deal_ajax_result_func,
                        toolbar_html_str: obj_config.toolbar_html_str,
                        query_input_com: obj_config.query_input_com,
                        lru_list_field_name: obj_config.lru_list_field_name,
                        lru_list_url: obj_config.lru_list_url,
                        show_download: obj_config.show_download,
                        display_type: obj_config.display_type,
                        select_type: obj_config.select_type,
                        select_value: obj_config.select_value,
                        selected_column_list: obj_config.selected_column_list,
                        url_query_field_name: obj_config.url_query_field_name,
                        gen_select_item_list_callback: obj_config.gen_select_item_list_callback,
                        use_checkbox_select_in_ajax_multi_select_flag: obj_config.use_checkbox_select_in_ajax_multi_select_flag,
                        on_query: obj_config.on_query,
                        on_row_click: obj_config.on_row_click,
                        class_name: obj_config.class_name,
                        default_sort: obj_config.default_sort
                    }
                },
                created: function() {
                    dlg = this
                    obj_config.on_created()
                },
                mounted: function() {
                },
                updated: function() {


                },
                methods: {
                    on_select: function(args: any) {
                        if (callback) {
                            callback(args.value, args.use_lru_flag)
                        }
                    },

                    reload: function() {
                        (<any>this.$refs.ajax_table)["reload"]()
                    },

                    hide: function() {
                        this.$data.show_flag = false
                    },

                    show: function() {
                        this.$data.show_flag = true
                    },

                    get_cur_select_data: function() {
                        return (<any>this.$refs.ajax_table).get_cur_select_data()
                    },

                    get_vue_data() {
                        return this.$data
                    },

                    close() {
                        return (<any>this.$refs.ajax_table).close()
                    },
                    on_close() {

                        obj_config.on_close()
                        dlg_remove_func()

                    }



                },
                template: template
            })

        })



        return dlg
    },
    admin_new_vue_app(gen_component_func: any) {

        const dom = document.createElement("div")
        document.getElementsByTagName("body")[0].appendChild(dom)
        const dlg_remove = function() {
            nextTick(() => {
                try {
                    (<any>vue_app).unmount(dom)
                    dom.remove()
                } catch (e) {
                    console.error("KK", e)
                }
                vue_app = <any>undefined
            })
        }
        const v = markRaw(gen_component_func(dlg_remove))

        var vue_app = createApp(v)

        vue_app.use(ElementPlus)
        vue_app.use(router)
        vue_app.use(store)

        bind_component(vue_app)

        vue_app.mount(dom)

    },

    admin_table_select(data_list: any, column_list: any, callback: ((row: any) => void) | null, config = {}) {
        const default_config = {
            width: null,
            title: "选择",
            table_height: "",
            use_page_bar: false,
            with_ajax_flag: false,
            data: data_list,
            deal_ajax_result_func: null,
            display_type: "select",
            url_query_field_name: "",
            select_type: "single_select", // multi_select
            // 多选的生成
            gen_select_item_list_callback: null,
            on_row_click: function(_row: any) { },
            other_btn_list: []
        }

        const obj_config: any = Object.assign(default_config, config)
        if (common.in_phone()) {
            obj_config.width = "95%"
        }
        let dlg = null

        this.admin_new_vue_app(function(dlg_remove_func: any) {
            const template =
                "<admin-ajax-table-select  @query=\"on_select\"  ref=\"ajax_table\" "
                + " :query_filter_item_list=\"[]\" :column_list=\"column_list\""
                + "    :width=\"width\" :title=\"title\" "
                + "  :table_height=\"table_height\" :use_page_bar=\"use_page_bar\" :data=data"
                + " :other_btn_list=\"other_btn_list\" :with_ajax_flag=\"with_ajax_flag\" "
                + " :deal_ajax_result_func=\"deal_ajax_result_func\"  "
                + " :gen_select_item_list_callback=\"gen_select_item_list_callback\"  "
                + " @row-click=\"on_row_click\"  "
                + " :url_query_field_name=\"url_query_field_name\"  "
                + " :display_type=\"display_type\"  "
                + " @close=\"on_close\"  "
                + " :select_type=\"select_type\"  "
                + " > </admin-ajax-table-select>"
            return defineComponent({
                data: function() {
                    return {
                        width: obj_config.width,
                        title: obj_config.title,
                        table_height: obj_config.table_height,
                        use_page_bar: obj_config.use_page_bar,
                        column_list: column_list,
                        data: obj_config.data,
                        other_btn_list: obj_config.other_btn_list,
                        with_ajax_flag: obj_config.with_ajax_flag,
                        deal_ajax_result_func: obj_config.deal_ajax_result_func,
                        display_type: obj_config.display_type,
                        select_type: obj_config.select_type,
                        on_row_click: obj_config.on_row_click,
                        url_query_field_name: obj_config.url_query_field_name,
                        gen_select_item_list_callback: obj_config.gen_select_item_list_callback
                    }
                },
                mounted: function() { },
                created() {
                    dlg = this
                },
                methods: {
                    on_select: function(args: any) {
                        if (callback) {
                            callback(args.value)
                        }
                    },
                    reload: function() {
                        (<any>this.$refs.ajax_table)["reload"]()
                    },
                    on_close() {
                        dlg_remove_func()
                    }
                },
                template: template
            })

        })




        return dlg

    },

    admin_select_user(user_type = "daren", callback: (value: number) => void, config = {}) {

        const default_config = {
            dlg_title: undefined,
            main_type: undefined
        }

        const obj_config = Object.assign(default_config, config)
        let dlg: any = null

        this.admin_new_vue_app(function(dlg_remove_func: any) {
            const template =
                "<admin-user-select :v-modal=\"value\" display_type=\"select_one_user\" :user_type=\"user_type\" :main_type=\"main_type\" :dlg_title=\"dlg_title\"  @query=\"on_select\" @close=\"on_close\" > </admin-user-select>"
            return defineComponent({
                data: function() {
                    return {
                        value: 0,
                        user_type: user_type,
                        dlg_title: obj_config.dlg_title,
                        main_type: obj_config.main_type
                    }
                },
                created: function() {
                    dlg = this
                },
                mounted: function() { },
                methods: {
                    on_select: function(args: any) {
                        callback(args.value)
                    },
                    on_close() {
                        dlg_remove_func()
                    }
                },
                template: template
            })
        })

        return dlg


    },

    admin_show_flow_info(flowid: any, btn_list: any = [], callback?: any) {

        const ajax_url = "/core/flow/get_table_data"
        if (flowid > 0) {
            common.do_ajax(ajax_url, { flowid: flowid }, function(resp: any) {
                console.log(resp)

                resp = resp.data
                // if (common.check_env_is_dev()) {
                //     resp = resp.out;
                // }

                let data: Array<any> = []
                const last_data: Array<any> = []
                resp.table_data.forEach(function(item: any, _i: any) {
                    if (item["is_last"] == true) {
                        last_data.push({
                            key: item[0] ? item[0] : item.key,
                            value: item[1] ? item[0] : item.value
                        })
                    } else {
                        data.push({
                            key: item[0] ? item[0] : item.key,
                            value: item[1] ? item[0] : item.value
                        })
                    }
                })

                data.push({
                    key: "<font color=\"blue\">审核人/时间 </font>	",
                    value: "<font color=\"blue\">审核状态/说明 </font>"
                })

                resp.node_list.forEach(function(item: any, _i: any) {
                    console.log(`nodeid is ${item["nodeid"]}`)
                    data.push({
                        key: `${item["admin_nick"]}<br/>${item["check_time"]}`,
                        value: `${item["node_name"]}<br/>状态：${item["flow_check_flag_str"]}<br/>${item["check_msg"]}`,
                        nodeid: item["nodeid"]
                    })
                })

                if (last_data.length > 0) {
                    data = data.concat(last_data)
                }

                var ajax_dlg = dlg.admin_ajax_table_select(
                    "", {}, [], [{
                        label: "项目",
                        prop: "key"
                    }, {
                        label: "值",
                        prop: "value"
                    }],
                    null, {
                        data: data,
                        display_type: "list",
                        use_page_bar: false,
                        with_ajax_flag: false,
                        table_height: "",
                        other_btn_list: [{
                            icon: "",
                            text: "重置当前审批人",
                            type: "danger",
                            click: function() {
                            // common.do_ajax("/self_manage/reset_flow_node_adminid", { flowid: flowid });
                                common.do_ajax("/core/flow/reset_flow_node_adminid", { flowid: flowid }, function(resp: any) {
                                    if (resp.ret == 0) {
                                        ajax_dlg.close()
                                        common.reload_page()
                                    } else {
                                        common.alert(resp.info)
                                    }
                                })
                            }
                        }, {
                            icon: "",
                            text: "预期审批流程",
                            type: "primary",
                            click: function() {
                                dlg.admin_show_defalut_flow_info(flowid)

                            }
                        }].concat(btn_list)
                    })
                if (callback) {
                    callback(ajax_dlg)
                }

            })
        } else {

            ElMessage({
                message: "没有审批信息",
                type: "error",
                duration: 5 * 1000
            })
        }

    },
    admin_show_defalut_flow_info(flowid: any) {
        dlg.admin_ajax_table_select(
            "/core/flow/get_default_node_list", {
                flowid: flowid
            }, [], [{
                label: "编号",
                prop: "node_type"
            }, {
                label: "说明",
                prop: "name"

            }, {
                label: "审核者",
                prop: "admin_nick"
            }],
            null, {
                use_page_bar: false,
                table_height: ""
            })
    },


    show_daren_info(userid: any, config: any = {}, no_check_power: any = 0) {
        // var me = this;
        common.do_ajax("/daren/get_daren_info", {
            userid: userid,
            no_check_power: no_check_power
        }, function(resp: any) {
            const custom_class = "__daren_info_plane"
            const resp_data = resp.data
            const { base_info } = resp_data
            let com_props: any = { data: resp_data }
            if (config["props"]) {
                com_props = Object.assign(com_props, config["props"])
            }
            const content = function(_data: any) {
                return [h(daren_info, {

                    ...com_props,
                    onClose: function() {
                        dlg.close()
                    },

                    onUpdate_daren_info: function(args: any) {
                        if (config !== undefined) {
                            config.on_update && config.on_update(args)
                        }
                    }
                })]
            }
            nextTick(function() {
                const $dlg = $(`.${custom_class}`)
                $dlg.find(".el-dialog__header").hide()
                nextTick(function() {
                    $dlg.find(".el-dialog__body").css({
                        padding: "0px"
                    })
                })
            })

            var dlg = common.dlg.admin_show_dlg({
                title: `学员:${base_info.real_name}`,
                width: "1000px",
                content: content,
                data: {},
                btn_list: [],
                other_data: {},
                custom_class: custom_class,
                content_config: {}
            })

            return dlg
        })
    },

    show_daren_score(config: any = {}, me: any) {
        const com_props: any = { data: config }
        const content = function(_data: any) {
            return [h(daren_score, {

                ...com_props,
                onClose: function() {
                    dlg.close()
                },

                onUpdate_list: function(_args: any) {
                    (<any>me.$refs.admin_table)["load"]()
                    dlg.close()

                }
            })]
        }
        nextTick(function() {
            nextTick(function() {
            })
        })

        var dlg = common.dlg.admin_show_dlg({
            title: "评分:",
            width: "1000px",
            content: content,
            data: {},
            btn_list: [

            ],
            other_data: {},
            content_config: {}
        })

        return dlg
    },

    show_dp_info(dp_id: string) {
        const url = `https://m.dianping.com/userprofile/${dp_id}`
        common.wopen(url)
    },


    show_task_time_info(city_task_id: any, config: any = {}) {
        common.do_ajax("/daren_ex/get_task_time_info", {
            city_task_id: city_task_id
        }, function(resp: any) {
            const custom_class = "__task_time_info_plane"
            const resp_data = resp.data
            const { base_info } = resp_data
            let com_props: any = { data: resp_data }
            if (config["props"]) {
                com_props = Object.assign(com_props, config["props"])
            }
            const content = function(_data: any) {
                return [h(task_time_info, {
                    ...com_props,
                    onClose: function() {
                        dlg.close()
                    },

                    onUpdate_task_time_info: function(args: any) {
                        if (config !== undefined) {
                            config.on_update && config.on_update(args)
                        }
                    }
                })]
            }
            nextTick(() => {
                const $dlg = $(`.${custom_class}`)
                $dlg.find(".el-dialog__header").hide()
                nextTick(() => {
                    $dlg.find(".el-dialog__body").css({
                        padding: "0px"
                    })
                })
            })

            var dlg = common.dlg.admin_show_dlg({
                title: `任务:${base_info.real_name}`,
                width: "1200px",
                content: content,
                data: {},
                btn_list: [],
                other_data: {},
                custom_class: custom_class,
                content_config: {}
            })

            return dlg
        })
    },


    show_task_steps(me: any, row?: any) {
        const content = function(_data: any) {
            return h(task_steps, {
                row,
                onClose: function() {
                    dlg.close()
                },
                onUpdate_list: function(_args: any) {
                    me.reload("", true)
                }
            })
        }

        var dlg = common.dlg.admin_show_dlg({
            title: "创建任务",
            width: "800px",
            content: content,
            data: {},
            btn_list: [],
            other_data: {},
            custom_class: "",
            content_config: {},
            submit_text: "提交"
        })
        return dlg
    },

    show_select_shopping(item: any, ajax_dlg: any, config: any = {}, page_count: any = 10000) {
        common.do_ajax("/user_manage/get_user_list", {
            main_type: item.main_shop_id,
            page: 1,
            page_count,
            type: "shop"
        }, function(resp: any) {
            const custom_class = "__select_shopping"
            const resp_data = resp.data
            let com_props: any = { data: resp_data }
            com_props = Object.assign(com_props, {
                row: item
            })
            console.log(com_props)
            const content = function(_data: any) {
                return [h(select_shopping, {
                    ...com_props,
                    onClose: function() {
                        dlg.close()
                        ajax_dlg.reload()

                    },
                    onUpdate_daren_info: function(args: any) {
                        if (config !== undefined) {
                            config.on_update && config.on_update(args)
                        }
                    }
                }, [])]
            }

            var dlg = common.dlg.admin_show_dlg({
                title: "选择店铺",
                width: "1000px",
                content: content,
                data: {},
                btn_list: [],
                other_data: {},
                custom_class: custom_class,
                content_config: {},
                submit_text: "提交"
            })

            return dlg
        })
    },

    show_edit_shopping_time(row: any, ajax_dlg: any = "") {
        const content = function(_data: any) {
            return [h(edit_shopping_time, {
                row,
                onClose: function() {
                    dlg.close()
                    if (ajax_dlg) {
                        ajax_dlg.reload()
                    }

                },
                onUpdate_daren_info: function(_args: any) {

                }
            })]
        }

        var dlg = common.dlg.admin_show_dlg({
            title: "选择店铺",
            width: "500px",
            content: content,
            data: {},
            btn_list: [],
            other_data: {},
            custom_class: "",
            content_config: {},
            submit_text: "提交"
        })
        return dlg
    },

    show_dlg_iframe(config: any = {}) {
        var default_config = {
            url: "",
            height: "600px",
            width: "300px",
            title: ""

        }

        var obj_config = Object.assign(default_config, config)


        var content = function(_data: any) {
            return [h("div", {}, [
                `地址:${obj_config.url}`,
                h("iframe", {
                    src: obj_config.url,
                    height: obj_config.height,
                    width: "100%",
                    name: "dp"
                })

            ])]
        }

        common.dlg.admin_show_dlg({
            content: content,

            width: obj_config.width,
            title: obj_config.title,
            data: {},
            btn_list: [],
            other_data: {},
            content_config: {}
        })

    },

    show_star_steps(row: any, me: any) {
        var content = function(_data: any) {
            return [h(star_steps, {
                row,
                onClose: function() {
                    dlg.close()
                },
                onUpdate_list: function(_args: any) {
                    me.reload("", true)
                }
            }, [])]
        }

        var dlg = common.dlg.admin_show_dlg({
            title: "创建任务",
            width: "800px",
            content: content,
            data: {},
            btn_list: [],
            other_data: {},
            custom_class: "",
            content_config: {},
            submit_text: "提交"
        })
        return dlg
    },

    show_merchant_task(row: any, me: any) {
        var content = function(_data: any) {
            return [h(merchant_task, {
                row,
                onClose: function() {
                    dlg.close()
                },
                onUpdate_list: function(_args: any) {
                    me.reload("", true)
                }
            }, [])]
        }

        var dlg = common.dlg.admin_show_dlg({
            title: "商户任务",
            width: "1200px",
            content: content,
            data: {},
            btn_list: [],
            other_data: {},
            custom_class: "",
            content_config: {},
            submit_text: "提交"
        })
        return dlg
    },

    // 权限组选择
    show_power_group_select(def_role_groupid: any, enable_role_groupid_list: any, callback: any) {
        var field_list = [{
            field_type: "select",
            field_name: "role_groupid",
            label: "分类",
            enum_type: "account_role",
            multiple: false,
            show_item_all: false,
            item_list: enable_role_groupid_list,
            value: def_role_groupid,
            on_change: function(value: any) {
                common.set_object_property_by_field_name(field_list, "power_groupid",
                    "ajax_url_args", { role_groupid: value })
            }
        }, {
            field_type: "select",
            field_name: "power_groupid",
            ajax_url: "/admin_manage/get_power_group_list",
            ajax_url_args: {
                role_groupid: def_role_groupid
            },
            multiple: false,
            value: 0,
            label: "权限组",
            enum_type: "subject",
            show_item_all: false, // [全部] 不显示
            style: {
                width: "200px"
            }
        }]
        var data: any = {}
        var dlg = common.dlg.admin_show_key_table(field_list, data, {
            submit: function() {
                callback(data)
                dlg.close()
            }
        })



    },

    show_dlg_img(config: any = {}) {
        var default_config = {
            url: "",
            width: "800px",
            title: ""

        }

        var obj_config = Object.assign(default_config, config)


        var content = function(_data: any) {
            return [h("div", {
            }, [
                `地址:${obj_config.url}`,
                h("img", {
                    src: obj_config.url,
                    style: {
                        height: obj_config.height,
                        width: "100%"
                    },

                    onClick: function() {
                        dlg.close()
                    }
                }, [])

            ])
            ]
        }

        var dlg = common.dlg.admin_show_dlg({
            content: content,

            width: obj_config.width,
            title: obj_config.title,
            data: {},
            btn_list: [],
            other_data: {},
            content_config: {}
        })

    },

    show_bd_shop_info(this: any, bd_shop_id: any, me?: any) {
        common.do_ajax("/bd_info/get_bd_shop_info", {
            bd_shop_id: bd_shop_id
        }, function(resp: any) {
            const custom_class = "__bd_shop_info_plane"
            const resp_data = resp.data
            const com_props: any = { data: resp_data }
            const content = function(_data: any) {
                return [h(bd_shop_info, {
                    ...com_props,
                    onClose: function() {
                        dlg.close()
                    },
                    onUpdate_info: function(_obj: any) {
                        if (me) {
                            me.reload("", true)
                        }
                    }
                })]
            }

            var dlg = common.dlg.admin_show_dlg({
                title: "店铺",
                width: "1000px",
                content: content,
                data: {},
                btn_list: [],
                other_data: {},
                custom_class: custom_class,
                content_config: {}
            })

            return dlg
        })
    },

    show_dp_shop_info(url: string) {

        var content = function(_data: any) {
            return [h("iframe", {
                attrs: {
                    src: url,
                    height: "1000px",
                    width: "800px",
                    name: "dp"
                }
            }, [])]
        }

        common.dlg.admin_show_dlg({
            content: content,

            width: "820px",
            title: "点评店铺信息",
            data: {},
            btn_list: [],
            other_data: {},
            content_config: {}
        })

    }
}


export default dlg
