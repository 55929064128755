import common from "./common"

var test = {
    common: common,
    do_ajax: common.do_ajax,
    get_app_main() {
        return common.$root["$refs"]["view"]["$refs"]["app_main"]

    },
    get_admin_table(): any {
        var ret = this.get_app_main()["$children"][0]["$children"][0]
        if (ret["$refs"]["table"]) {
            return ret
        } else if (ret["$children"][0]["$refs"]["table"]) {
            return ret["$children"][0]
        } else if (this.get_app_main()["$children"][0]["$refs"]["sub_component"]) { // sub_component
            return this.get_app_main()["$children"][0]["$refs"]["sub_component"]["$children"][0]
        } else { // sub_component
            return this.get_app_main()["$children"][0]["$children"][0]["$refs"]["sub_component"]["$children"][0]
        }
    },

    /*
    get_dlg_root() {
        return common.$dlg_root!;
    },
    get_dlg_ajax_admin_table() {
        return this.get_dlg_root()["$refs"]["ajax_table"]["$refs"]["admin_table"];
    },
    */

    // 得到data 数据
    get_admin_table_total_count(table: any) {
        return table["total"]
    },

    get_admin_table_data_list(table: any) {
        return table["data_list"]
    }


}

export default test
