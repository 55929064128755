import { defineComponent, nextTick } from "vue"


interface IProps {
}



export default defineComponent({
    components: {
    },
    props: {
        title: {
            type: String,
            required: true
        },

        data: {
            type: Array,
            required: true
        },
        modelValue: {
            type: Array,
            required: true
        }


    },
    // event
    emits: ["update:modelValue", "save"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            data_list: <Array<any>>[],
            select_list_inter: <Array<any>>[]
        }

    },
    computed: {




    },

    watch: {


    },


    created() {
        // this.on_created();
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        reset_data_list() {
            const selected_map: any = {}


            const all_map: any = {}
            this.$props.data.forEach(function(item: any, _i: any) {
                item.selected = false
                all_map[item["key"]] = item
            })
            const data_list: Array<any> = []
            this.$data.select_list_inter.forEach(function(key: any, _i: any) {
                const item = all_map[key]
                if (item) {
                    item.selected = true
                    data_list.push(item)
                }
                selected_map[key] = true
            })
            // var selected_item_list = data_list.slice();
            this.$props.data.forEach(function(item: any, _i: any) {
                if (selected_map[item["key"]] == undefined) {
                    data_list.push(item)
                }
            })
            // 处理 下拉列表

            data_list.forEach(function(cur_item, cur_i) {
                cur_item["up_list"] = []
                cur_item["down_list"] = []
                data_list.forEach(function(item, i) {
                    if (i < cur_i) {
                        cur_item["up_list"].push({
                            icon: item.icon,
                            key: item.key,
                            label: item.label
                        })
                    }
                    if (i > cur_i + 1) {
                        cur_item["down_list"].push({
                            icon: item.icon,
                            key: item.key,
                            label: item.label
                        })

                    }

                })


            })

            this.$data.data_list = data_list

        },

        on_mounted() {

            this.$data.select_list_inter = []

            const me = this;
            (<Array<any>>this.$props.modelValue).forEach(function([key, check_flag]) {
                if (check_flag) {
                    return me.$data.select_list_inter.push(key)
                }
            })
            this.reset_data_list()

        },
        check_selectable(_row: any, index: any) {
            if (index == 0) {
                return true
            } else {
                return true
            }
        },

        on_select(item: any) {

            let select_list_inter: Array<any> = this.$data.select_list_inter.slice()

            nextTick(() => {

                if (item.selected) {
                    select_list_inter.push(item.key)
                    // this.$props.selected
                } else {
                    const ret_list: Array<any> = []
                    select_list_inter.forEach(function(key, _i: any) {
                        if (key != item.key) {
                            ret_list.push(key)
                        }
                    })
                    select_list_inter = ret_list
                }


                return this.do_change(select_list_inter)
            })


        },
        on_move_up(row: any, i: any, count: any) {
            const select_list_inter: Array<any> = this.$data.select_list_inter.slice()
            // count 向上移动 几个


            let ret_list = select_list_inter.slice(0, i - count)
            ret_list.push(row.key)
            ret_list = ret_list.concat(select_list_inter.slice(i - count, i))
            ret_list = ret_list.concat(select_list_inter.slice(i + 1))

            return this.do_change(ret_list)


        },
        do_change(select_list_inter: any) {

            this.$data.select_list_inter = select_list_inter
            this.reset_data_list()
        },

        on_move_down(row: any, i: any, count: any) {

            const select_list_inter: Array<any> = this.$data.select_list_inter.slice()

            let ret_list = select_list_inter.slice(0, i)
            ret_list = ret_list.concat(select_list_inter.slice(i + 1, i + 1 + count))
            ret_list.push(row.key)
            ret_list = ret_list.concat(select_list_inter.slice(i + 1 + count))

            return this.do_change(ret_list)

        },
        on_save() {
            const me = this
            const ret_list: Array<any> = [];
            (<Array<any>>this.$data.data_list).forEach(function(item, _i: any) {
                const check_flag = (<Array<any>>me.$data.select_list_inter).findIndex(function(value) {
                    return item.key == value
                }) !== -1
                ret_list.push([item.key, check_flag])
            })
            this.$emit("update:modelValue", ret_list)
            this.$emit("save")
        },

        on_set_default() {
            this.$emit("update:modelValue", undefined)
            this.$emit("save")
        },

        on_set_null() {
            // this.$emit("select_change", []);
            (<Array<any>>this.$data.data_list).forEach(function(item) {
                item.selected = false
            })

            this.$data.select_list_inter = []

            // this.$emit("save");
        },

        on_set_all() {
            const list: Array<any> = [];
            (<Array<Object>>this.$data.data_list).forEach(function(item: any) {
                list.push([item["key"], true])
            })

            this.$emit("update:modelValue", list)
            this.$emit("save")
        }

    }

})


/*
import Vue from 'vue'
import Component from 'vue-class-component'

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({



    computed: {

    },
    mounted: function() {
        (<config_item_table>this).on_mounted();
    }

})
export default class config_item_table extends Vue {
    reset_data_list() {
        var selected_map = {};


        var all_map = {};
        this.$props.data.forEach(function(item: any, _i: any) {
            item.selected = false;
            all_map[item["key"]] = item;
        });
        var data_list: Array<any> = [];
        this.$data.select_list_inter.forEach(function(key: any, _i: any) {
            var item = all_map[key];
            if (item) {
                item.selected = true;
                data_list.push(item);
            }
            selected_map[key] = true;
        });
        //var selected_item_list = data_list.slice();
        this.$props.data.forEach(function(item: any, _i: any) {
            if (selected_map[item["key"]] == undefined) {
                data_list.push(item);
            }
        });
        //处理 下拉列表

        data_list.forEach(function(cur_item, cur_i) {
            cur_item["up_list"] = [];
            cur_item["down_list"] = [];
            data_list.forEach(function(item, i) {
                if (i < cur_i) {
                    cur_item["up_list"].push({
                        icon: item.icon,
                        key: item.key,
                        label: item.label,
                    });
                }
                if (i > cur_i + 1) {
                    cur_item["down_list"].push({
                        icon: item.icon,
                        key: item.key,
                        label: item.label,
                    });

                }

            });


        });

        this.$data.data_list = data_list;

    }

    on_mounted() {

        this.$data.select_list_inter = [];

        var me = this;
        (<Array<any>>this.$props.modelValue).forEach(function([key, check_flag]) {
            if (check_flag) {
                return me.$data.select_list_inter.push(key);
            }
        });
        this.reset_data_list();

    }
    check_selectable(_row: any, index: any) {
        if (index == 0) {
            return true;
        } else {
            return true;
        }
    }

    on_select(item: any) {

        var select_list_inter: Array<any> = this.$data.select_list_inter.slice();

        this.$nextTick(function() {

            if (item.selected) {
                select_list_inter.push(item.key);
                //this.$props.selected
            } else {
                var ret_list: Array<any> = [];
                select_list_inter.forEach(function(key, _i: any) {
                    if (key != item.key) {
                        ret_list.push(key);
                    }
                });
                select_list_inter = ret_list;
            }


            return this.do_change(select_list_inter);
        });


    }
    on_move_up(row: any, i: any, count: any) {
        var select_list_inter: Array<any> = this.$data.select_list_inter.slice();
        //count 向上移动 几个


        var ret_list = select_list_inter.slice(0, i - count);
        ret_list.push(row.key);
        ret_list = ret_list.concat(select_list_inter.slice(i - count, i));
        ret_list = ret_list.concat(select_list_inter.slice(i + 1));

        return this.do_change(ret_list);


    }
    do_change(select_list_inter: any) {

        this.$data.select_list_inter = select_list_inter;
        this.reset_data_list();
    }

    on_move_down(row: any, i: any, count: any) {

        var select_list_inter: Array<any> = this.$data.select_list_inter.slice();

        var ret_list = select_list_inter.slice(0, i);
        ret_list = ret_list.concat(select_list_inter.slice(i + 1, i + 1 + count));
        ret_list.push(row.key);
        ret_list = ret_list.concat(select_list_inter.slice(i + 1 + count));

        return this.do_change(ret_list);

    }
    on_save() {
        var me = this;
        var ret_list: Array<any> = [];
        (<Array<any>>this.$data.data_list).forEach(function(item, _i: any) {
            var check_flag = (<Array<any>>me.$data.select_list_inter).findIndex(function(value) {
                return item.key == value;
            }) !== -1;
            ret_list.push([item.key, check_flag]);
        });
        this.$emit("select_change", ret_list);
        this.$emit("save");
    }

    on_set_default() {
        this.$emit("select_change", undefined);
        this.$emit("save");
    }

    on_set_null() {
        //this.$emit("select_change", []);
        (<Array<any>>this.$data.data_list).forEach(function(item) {
            item.selected = false;
        });

        this.$data.select_list_inter = [];

        //this.$emit("save");
    }

    on_set_all() {
        var list: Array<any> = [];
        (<Array<Object>>this.$data.data_list).forEach(function(item) {
            list.push([item["key"], true]);
        });

        this.$emit("select_change", list);
        this.$emit("save");
    }

}

*/
