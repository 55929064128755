import components from "../router/components"

console.log("FFFFFFFFFFFFFFFF ", components["bd__components__m_bd_shop_info"])
const path_sub_page_config = {
    "/main_page/system_info": {
        test: {
            title: "测试",
            component: components["admin_manage__admin_user_list"]
        }
    },
    "/admin_manage/admin_user_list": {
        admin_info: {
            title: "管理员信息",
            component: components["admin_manage__components__m_admin_info"]
        }

    },
    "/dx/user_order_list": {
        create_daren_task: {
            title: "创建达人任务",
            component: components["dx__components__create_daren_task"]
        }

    },

    "/daren/city_task_list": {
        m_task_info: {
            title: "任务详情",
            component: components["daren__components__m_task_info"]
        },

        opt_task_info: {
            title: "编辑任务",
            component: components["daren__components__opt_task_info"]

        }

    },

    "/bd/shop_m_list": {
        m_bd_shop_info: {
            title: "店铺详情",
            component: components["bd__components__m_bd_shop_info"]
        },

        opt_bd_shop_info: {
            title: "编辑店铺",
            component: components["bd__components__opt_bd_shop_info"]

        },
        opt_bd_shop_follow: {
            title: "跟进",
            component: components["bd__components__opt_bd_shop_follow"]

        }


    },



    "/main_page/mobile": {
        _config_: {
            default_page_type: "mobile" // 默认 desktop
        }
    },
    "/bd_mobile/main_page": {
        _config_: {
            default_page_type: "mobile" // 默认 desktop
        }
    }

}

export default path_sub_page_config
