import admin_vue from "@/components/Admin/admin_vue"

import { field_item_list_t, column_item_list_t, row_item_list_t } from "@/utils/type_def"
import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    bd_shop_id: any,
    config_fix: any,
}



export default defineComponent({
    components: {
    },
    extends: admin_vue,

    props: {
        bd_shop_id: Number,
        config_fix: String
    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

        }
    },

    data: function() {
        return {
            query_filter_item_list: <field_item_list_t>[],
            row_opt_btn_config: <row_item_list_t>[],
            column_list: <column_item_list_t>[],

            default_sort: {
                prop: "assigned_time",
                order: "desc"
            },

            url_args_ex: {
                bd_shop_id: 0
            }
        }
    },

    created() {
        this.on_created()
    },
    methods: {
        get_row_opt_btn_config(): row_item_list_t {
            return [
            ]
        },

        get_query_filter_item_list(): field_item_list_t {
            return []
        },

        // 配置列信息
        get_column_list(): column_item_list_t {
            return [{
                label: "分配时间",
                prop: "assigned_time",
                sortable: true
            },
            common.gen_column_account_item("分配给", "adminid", "admin_nick"),
            common.gen_column_account_item("分配者", "assigned_adminid", "assigned_admin_nick"),
            common.gen_column_enum("is_new_flag", "是否是新店铺")

            ]
        },

        on_created() {
            this.$data.url_args_ex.bd_shop_id = this.$props.bd_shop_id || common.get_query_args().bd_shop_id
            this.$data.query_filter_item_list = this.get_query_filter_item_list()
            this.$data.row_opt_btn_config = this.get_row_opt_btn_config()
            this.$data.column_list = this.get_column_list()
        }


    }

})