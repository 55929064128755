import { createApp } from "vue"
import App from "/@/App.vue"

import ElementPlus from "element-plus"
import direct from "/@/directive/action"
import router from "/@/router/index"
import { store } from "/@/store/index"
// import '/@/mock/index'
import "/@/permission"

import Vue3Autocounter from "vue3-autocounter"
import * as echarts from "echarts"
import "element-plus/lib/theme-chalk/index.css"
import "element-plus/lib/theme-chalk/display.css"
import "nprogress/nprogress.css"
import "/@/assets/css/index.css"
import common from "@/utils/common"
import Vant from "vant"
import "vant/lib/index.css"
/**
 * iconfont 的 css 生成地址
 * https://www.iconfont.cn/manage/index?manage_type=myprojects&projectId=2222936&keyword=&project_type=&page=
 */
const icon_css_file = "font_2222936_6vyhw4636bl.css"

common.load_css_file(`//at.alicdn.com/t/${icon_css_file}`)

import { bind_component } from "./admin_component"
import test_cmd from "./utils/test_cmd"



export const app = createApp(App).component("vue3-autocounter", Vue3Autocounter)
app.config.globalProperties.$echarts = echarts

direct(app)
app.use(ElementPlus, {})
app.use(router)
app.use(store)
app.use(Vant)

bind_component(app)

if ((/iphone|ipod|ipad/i.test(navigator.appVersion))) {
    document.addEventListener(
        "blur",
        (event: any) => {
            // 当页面没出现滚动条时才执行，因为有滚动条时，不会出现这问题
            // input textarea 标签才执行，因为 a 等标签也会触发 blur 事件
            console.log(" document.body.scrollIntoView()  check ",
                document.documentElement.offsetHeight,
                document.documentElement.clientHeight,
                event.target.localName
            )
            document.body.scrollIntoView() // 回顶部
            /*
            if (
                document.documentElement.offsetHeight <=
                document.documentElement.clientHeight &&
                ['input', 'textarea'].includes(event.target.localName)
            ) {
                console.log(" document.body.scrollIntoView() DO ");
                document.body.scrollIntoView() // 回顶部
            }
            */
        },
        true
    )
}


function isMobile() {
    const flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    return flag
}
if (isMobile()) {
    var ohtml = document.documentElement
    getSize()
    function getSize() {
        var screenWidth = ohtml.clientWidth
        if (screenWidth <= 320) {
            ohtml.style.fontSize = "17px"
        } else if (screenWidth >= 750) {
            ohtml.style.fontSize = "40px"
        } else {
            ohtml.style.fontSize = `${screenWidth / (750 / 40)}px`
        }
    }

    window.addEventListener("resize", function() {
        getSize()
    })
}
(<any>window)["test_cmd"] = test_cmd

if (common.check_env_is_prod()) {
    document.write(
        unescape(
            "%3Cspan id='cnzz_stat_icon_1279951751'%3E%3C/span%3E%3Cscript src='https://s9.cnzz.com/z_stat.php%3Fid%3D1279951751%26show%3Dpic1' type='text/javascript'%3E%3C/script%3E"
        )
    )
    if (localStorage.getItem("test_flag") == "true") {
        const src = "//cdn.jsdelivr.net/npm/eruda"
        document.write(`${"<scr" + "ipt src=\""}${src}"></scr` + "ipt>")
        document.write("<scr" + "ipt>eruda.init();</scr" + "ipt>")
    }

}


app.mount("#app")
