import { defineComponent } from "vue"
import common from "@/utils/common"
import util from "@/utils/sys_util"
import { admin_table_t } from "@/utils/type_def"



// @Component 修饰符注明了此类为一个 Vue 组件
export default defineComponent({
    methods: {
        do_ajax(url: any, args = {}, callback?: any, show_loading = false) {
            const me = this



            common.do_ajax(url, args, function(resp: any) {
                if (util.isFunction(callback)) {
                    callback(resp)
                } else {// reload
                    me.reload()
                }

            }, show_loading)
        },


        reload(page_num?: any) {
            if (!this.van_list_query()) {

                if (this.get_admin_table()) {
                    this.get_admin_table().load(page_num)
                }
                common.eventHub.$emit("table-query", {
                    page_num: page_num
                })
            }
        },


        // btn 信息变更
        row_opt_set_info(btn_config_list: any, opt_key: any, field_name: any, value: any) {
            btn_config_list.forEach(function(item: any, _i: any) {
                if (item["opt_key"] == opt_key) {
                    console.log(" XXXX row_opt_set_info :", opt_key, field_name, value)
                    item[field_name] = value
                }
            })
        },

        set_query_str(args: any) {
            const ret_args: any = {}
            for (const k in args) {
                const v = args[k]

                if (util.isArray(v)) {
                    ret_args[k] = v.join(",")
                } else {
                    ret_args[k] = v
                }
            }

            const query_str = common.queryString.stringify(ret_args)
            const url = `${window.location.hash.replace(/\?.*/, "")}?${query_str}`
            console.log(`query_str_url${url}`)
            common.no_need_check_change_url = true
            window.history.replaceState(null, "", url)
        },

        get_table_query_args() {
            const table: any = this.$refs.admin_table
            return table.get_query_args()
        },

        get_admin_table(): admin_table_t {
            return <any>this.$refs.admin_table
        },

        van_query_do_item_click(ref: string) {
            (<any>this.$refs[ref]).do_click()
        },
        van_set_query_args() {
            var args = common.copy_obj((<any>this.$data).args)
            var ex_args = (<any>this.$data).ex_args

            if (args) {
                if (ex_args) {
                    args = Object.assign(args, ex_args)
                }
                if (args.sort_args) {
                    args = Object.assign(args, args.sort_args)
                    delete args.sort_args
                }

                common.set_query_str(args)
            }
        },

        van_list_query() {
            if (this.$refs["admin_list"]) {
                this.van_set_query_args();
                (<any>this.$refs["admin_list"]).query()
                return true
            } else {
                return false
            }
        },

        van_do_back() {

            var back_url = common.get_query_arg_str_value("back_url")
            var goto_url = ""
            if (back_url) {
                goto_url = back_url
            } else {
                goto_url = "/main_page/mobile"
                common.wopen("/main_page/mobile", true)
            }
            console.log(`goto_url: ${goto_url}`)

            if (window.history.length == 1) {
                common.wopen(goto_url, true)
            } else { // ../../permission.ts 中跳转
                window.localStorage.setItem("tmp_next_open_page", goto_url)
                window.history.go(-1)
            }

        },
        get_opt_type_str(opt_type: string) {

            if (opt_type == "add") {
                return "新增"
            } else if (opt_type == "set") {
                return "编辑"
            }
            return "未知[{$opt_type}]"

        },
        enum_get_desc(enum_type: string, value: number) {
            return common.get_enum_desc(enum_type, value)
        },
        get_html_str(txt: string) {
            txt = `${txt}`
            return txt.replace(/\n/g, "<br/>")
        },
        show_switch_accout_wiki() {
            common.wopen("https://wiki.mydaren.cn/pages/viewpage.action?pageId=7111539")
        }



    },
    template: "<template><template>"
})
