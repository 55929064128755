import admin_vue from "@/components/Admin/admin_vue"

import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    field_name: string,
    modelValue: string,
    placeholder: string,

}



export default defineComponent({
    components: {
    },
    extends: admin_vue,

    props: {
        field_name: {
            type: String,
            required: false
        },

        modelValue: {
            type: String,
            required: true
        },

        placeholder: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        }

    },

    // event
    emits: ["update:modelValue", "query"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props,
            show_list_flag: true

            // 自定义的

        }
    },

    data: function() {
        return {
            value_inter: ""
        }
    },
    watch: {
        modelValue: {
            handler: function(new_val) {
                if (this.$data.value_inter != new_val) {
                    this.$data.value_inter = new_val
                }
            },
            immediate: true
        },
        value_inter: {
            handler: function(new_val) {
                this.$emit("update:modelValue", new_val)
            }
        }

    },
    created() {
        // this.on_created()
    },
    mounted() {
        // this.on_mounted()
    },
    methods: {
        save_autocomplete_lru_list(field_name: string, value: string) {
            value = $.trim(value)
            var key = `autocomplete-${field_name}`
            var lru_list: Array<string> = common.page_store_get_key(key, [])
            var ret_list: Array<string> = []
            ret_list.push(value)
            lru_list.forEach(function(str) {
                if (str != value) {
                    ret_list.push(str)
                }
            })

            if (ret_list.length > 100) {
                ret_list.pop()
            }

            common.page_store_set_key(key, ret_list)

        },
        do_autocomplete_key_down(e: any) {
            var me = this
            if (e.keyCode == 13) { // enter
                var value = this.$data.value_inter
                this.show_list_flag = false
                me.$emit("update:modelValue", value)
                me.$emit("query")
                me.save_autocomplete_lru_list(this.props.field_name, value);
                (<any>(this.$refs.autocomplete))["getData"]("")
                this.show_list_flag = true
            } else {
                this.show_list_flag = true
            }
        },

        query_search(query_str: string, cb: Function) {

            if (this.show_list_flag) {
                var lru_list: Array<string> = common.page_store_get_key(`autocomplete-${this.props.field_name}`, [])
                var ret_list: Array<any> = []
                lru_list.forEach(function(str, _i: any) {
                    if (str.indexOf(query_str) !== -1) {
                        if (ret_list.length < 20) {
                            ret_list.push({ value: str })
                        }
                    }
                })
                cb(ret_list)

            } else {
                cb([])
            }
        },

        on_select() {
            var me = this
            var value = this.$data.value_inter

            me.$emit("update:modelValue", value)
            me.$emit("query")
            me.save_autocomplete_lru_list(this.props.field_name, value)

        }



    }


})
