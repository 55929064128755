import enum_map from "./enum_map"
export default {

    boolean: {
        [enum_map.boolean.V_0]: "red",
        [enum_map.boolean.V_1]: "green"
    },
    boolean__v2: { // 第二版本的
        [enum_map.boolean.V_0]: "green",
        [enum_map.boolean.V_1]: "red"
    },
    wm_shipping_service: {
        [enum_map.wm_shipping_service.V_1040]: "red"
    },
    wm_order_check_service_flag: {
        [enum_map.wm_order_check_service_flag.V_0]: "green",
        [enum_map.wm_order_check_service_flag.V_1]: "red"
    },
    approval_status: {
        [enum_map.approval_status.V_1]: "blue",
        [enum_map.approval_status.V_2]: "green",
        [enum_map.approval_status.V_3]: "red"
    }




}
