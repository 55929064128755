import { isNumber } from "lodash"
import { defineComponent, nextTick } from "vue"
import common from "/@/utils/common"


interface IProps {
    field_name: string
    on_change: Function,
    modelValue: Array<any>
    ajax_url: string,
    ajax_url_args: any,
    merger_node_flag: boolean,
}





export default defineComponent({
    components: {
    },

    props: {

        modelValue: Array,
        field_name: String,
        placeholder: {
            type: String,
            default: function() {
                return "全部"
            },
            required: false
        },
        ajax_url: String,
        ajax_url_args: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },

        cascader_props: {
            type: Object,
            required: false,
            default: function() {
                return {
                    multiple: false, //
                    checkStrictly: false // 任意选项
                }

            }
        },

        merger_node_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },
        clearable: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },


        on_change: {
            type: Function,
            required: false
        },



        width: {
            type: [String, Number],
            required: false,
            default: function() {
                return common.in_phone() ? "180px" : "300px"
            }
        }


    },
    // event
    emits: ["update:modelValue", "query"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props,

            // 自定义的

            data_last_select_value: <any>null


        }
    },

    data: function() {
        return {

            data_cascader_value: <Array<any>>[],
            // data_cascader_value_1: <Array<any>>[],

            data_options: <Array<any>>[],
            origin_data_options: <Array<any>>[],


            merger_node_value_map: <any>{}
        }
    },

    watch: {
        data_cascader_value: {

            handler: function(_new_val: any, _old_val: any) {


            },

            immediate: false
        },
        ajax_url_args(val) {
            console.log("watch success")
            this.props.ajax_url_args = val
            this.load_options(true)
        },

        modelValue: {
            handler: function(_new_val) {
                this.set_data_cascader_value()

            },
            immediate: false
        }


    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        set_data_cascader_value: function() {
            // 前缀去掉
            const me = (this)
            const { modelValue } = this.props



            me.$data.data_cascader_value = modelValue
            me.reset_key()

        },

        query() {
            var modelValue: Array<any> = this.$data.data_cascader_value.join(",").split(",")
            if (isNumber(this.$data.data_cascader_value[0])) {

                modelValue = modelValue.map(function(v) {
                    return parseInt(v)
                })

            }


            this.$emit("update:modelValue", modelValue)


            this.$emit("query", {
                field_name: this.props.field_name
            })
            if (this.props.on_change) {
                this.props.on_change(modelValue, this.$data.origin_data_options)
            }




        },
        init_options() {
            const options: Array<any> = []
            this.$data.data_options = options


        },
        load_options(force_flag = false) {
            const me = this
            if (me.data_options.length == 0 || force_flag) {
                console.log("LLKKKKKKKKKKK 11")
                common.do_ajax(this.props.ajax_url,
                    this.props.ajax_url_args,
                    function(resp: any) {
                        me.origin_data_options = common.copy_obj(resp.data.list)
                        if (me.props.merger_node_flag) {
                            var node = me.merger_node({
                                children: resp.data.list
                            })
                            console.log("KKNODE", node)
                            me.data_options = node.children
                            me.merger_node_value_map = {}
                            me.gen_merger_node_value_map(me.data_options, [])
                            me.reset_key()
                        } else {
                            me.data_options = resp.data.list
                        }
                    })
            }
        },

        reset_key() {
            if (this.props.merger_node_flag) {
                var real_key = this.$data.merger_node_value_map[this.data_cascader_value.join(",")]
                if (real_key) {
                    this.$data.data_cascader_value = real_key
                }
            }

        },
        on_created() {
            this.set_data_cascader_value()
            console.log("LLKKKKKKKKKKK 00")
            this.load_options()
        },
        on_mounted() {
            this.init_options()
        },

        on_cascader_menu_create(flag: boolean) {

            nextTick(() => {
                if (flag) {
                    // alert("xx");
                } else {
                    this.query()
                }
            })
        },
        merger_node(node: any) {
            if (!node.children) {
                return node

            } else {
                node.children = (<Array<any>>node.children).map((sub_node) => {
                    return this.merger_node(sub_node)

                })

                if (node.children.length == 1) {
                    var tmp_value = node.value
                    node = node.children[0]
                    node.value = `${tmp_value},${node.value}`
                }
                return node
            }

        },
        gen_merger_node_value_map(list: Array<any>, fix_arr: Array<any>) {
            list.forEach((item) => {
                var next_fix_arr = [...fix_arr, item.value]
                this.merger_node_value_map[next_fix_arr.join(",")] = next_fix_arr
                if (item.children) {
                    this.gen_merger_node_value_map(item.children, next_fix_arr)
                }
            })

        },
        on_cascader_change() {
            if (this.$data.data_cascader_value === null) {
                console.log(" on_cascader_change: query ...")
                this.$data.data_cascader_value = []
                this.query()
            }
            if (this.$refs.cascader) {
                (<any>this.$refs.cascader)["togglePopperVisible"](false)
            }
        }

    }

})
