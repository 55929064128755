import admin_vue from "@/components/Admin/admin_vue"
import { field_item_list_t, column_item_list_t, row_item_list_t } from "@/utils/type_def"

import { defineComponent } from "vue"
import common from "/@/utils/common"
import { admin_table_t } from "/@/utils/type_def"



interface IProps {
    userid: number
}



export default defineComponent({
    components: {
    },
    extends: admin_vue,

    props: {

        userid: {
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        },


        config_fix: String

    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {

        return {
            props: <IProps>props, // 代码补全用的,

            // 自定义的

            // websocket
            ws: <any>null,
            wx_open_flag: false,
            need_deal_liveid_list: <Array<any>>[]

        }
    },

    data: function() {

        return {
            query_filter_item_list: <field_item_list_t>[],
            row_opt_btn_config: <row_item_list_t>[],
            column_list: <column_item_list_t>[],

            url_args_ex: {
                userid: 0
            },


            source_options: [],
            default_sort: {
                prop: "user_active_time",
                order: "desc"
            },
            visible_date_show: true,
            visible_recovery_show: false,
            group_allo_flag: false,
            group_allo_flag_publish: false,
            admin_show_flag: false,
            select_type_str: "single_select",
            opt_pull_publish_flag: false,
            recovery_list_flag: false,
            opt_pull_publish_time: 0,
            new_user_count: "",
            color_str: "blue",
            user_phases_options: [],
            is_cc_show: true

        }
    },
    computed: {




    },

    watch: {



    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {




        on_mounted() {
        },


        on_created() {

            this.$data.url_args_ex.userid = this.props.userid || common.get_query_args().userid


            this.$data.query_filter_item_list = this.get_query_filter_item_list()
            this.$data.row_opt_btn_config = this.get_row_opt_btn_config()
            this.$data.column_list = this.get_column_list()




        },





        get_row_opt_btn_config(): row_item_list_t {
            // var me = this;
            return [

            ]

        },


        get_column_list(): column_item_list_t {

            return [
                {
                    label: "id",
                    prop: "daren_task_request_id",
                    sortable: true,
                    default_show: true
                },


                common.gen_column_task_time_item("任务名称", "city_task_id", "task_name")

                , {
                    label: "报名时间",
                    sortable: true,
                    prop: "create_time"

                }, {
                    label: "到店时间",
                    sortable: true,
                    min_width: "150px",
                    prop: "shopping_time"

                }, {
                    label: "报名截止时间",
                    sortable: true,
                    prop: "enter_end_time"

                },
                common.gen_column_enum("task_status", "任务状态")
                , {
                    label: "审批状态",
                    sortable: true,
                    prop: "approval_flag",
                    default_show: false,
                    formater: function(_value: string, row: any) {
                        return row.approval_flag_str
                    }



                }, {
                    label: "审批人",
                    sortable: true,
                    prop: "approval_adminid",
                    default_show: false,
                    formater: function(_value: string, row: any) {
                        return row.approval_admin_nick
                    }
                }, {
                    label: "审批时间",
                    default_show: false,
                    sortable: true,
                    prop: "approval_time"
                }]
        },


        get_query_filter_item_list(): field_item_list_t {
            const me = this

            return [
                {
                    field_type: "select",
                    field_name: "enable_flag",
                    enum_type: "boolean",
                    multiple: false, // 不多选
                    default_show: true,
                    style: {
                        width: "100px"
                    },
                    value: -1,
                    label: "启用"
                }, {
                    field_type: "date",
                    label: "时间",
                    value: {
                        date_type: "create_time",
                        opt_date_type: 100,
                        start_time: common.date_get_day(-100),
                        end_time: common.date_get_day(1),
                        date_type_config: {
                            create_time: "创建时间",
                            opt_time: "修改时间",
                            enter_end_time: "报名截止时间"
                        }
                    },
                    default_show: me.$data.visible_date_show
                }, {
                    field_type: "user_select",
                    field_name: "opt_adminid",
                    user_type: "account",
                    label: "操作人",
                    value: -1
                }


            ]
        }










    }

})


