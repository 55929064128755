import { admin_table_t, field_item_list_t } from "@/utils/type_def"
// import do_ajax from "@/utils/do_ajax"
import { defineComponent, nextTick } from "vue"
import { ElMessageBox } from "element-plus"
import common from "/@/utils/common"
import sys_util from "/@/utils/sys_util"


interface IProps {
    select_type: string
    with_ajax_flag: boolean
    modelValue: any
    query_filter_item_list: Array<any>
    display_type: string,
    selected_column_list: Array<any>
    url_args_ex: any,
    url: string,
    use_checkbox_select_in_ajax_multi_select_flag: boolean,
    lru_list_field_name: string
    lru_list_url: string
    url_query_field_name: string
    field_name: string
    other_btn_list: any
    gen_desc_callback: Function
    gen_select_item_list_callback: Function
    gen_value_callback: Function
    deal_ajax_result_func: Function
    data: any
}



export default defineComponent({
    components: {
    },
    props: {
        default_sort: {
            type: Object,
            required: false,
            default: function() {
                return undefined
            }

        },

        show_query_button: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },

        show_download: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }

        },
        lru_list_url: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },

        lru_list_field_name: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        url_query_field_name: { // 查询数据时使用
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },

        // ajax 拉取数据时, 多选使用checkbox ,
        use_checkbox_select_in_ajax_multi_select_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }

        },
        toolbar_html_str: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        query_input_com: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },



        field_name: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        modelValue: [Number, String, Array],
        // modelValue: {
        //     type: [Number, String, Array], // select,select_for_input, list,query
        //     required: false,
        //     default: function() {
        //         return "";
        //     }
        // },
        display_type: {
            type: String, // select,select_for_input, list,query
            required: false,
            default: function() {
                return "select"
            }
        },
        select_for_input_style: {
            type: Object, // select,select_for_input, list,query
            required: false,
            default: function() {
                return { width: "300px" }
            }
        },

        gen_value_callback: {
            type: Function,
            required: false,
            default: null
        },

        data: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        },
        with_ajax_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        query_filter_item_list: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        },

        selected_column_list: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        },
        column_list: {
            type: Array,
            required: true
        },
        deal_ajax_result_func: {
            type: [Function, Object],
            required: false,
            default: null
        },

        width: {
            type: String,
            required: false
        },

        use_page_bar: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        select_type: {
            type: String, // list , single_select, multi_select
            required: false,
            default: function() {
                return "single_select"
            }
        },
        // 显示说明
        gen_desc_callback: {
            type: Function,
            required: false,
            default: null
        },

        gen_select_item_list_callback: {
            type: Function,
            required: false,
            default: null
        },

        url: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },

        table_height: {
            type: String,
            required: false,
            default: function() {
                return "400px;"
            }
        },


        title: {
            type: String,
            required: false,
            default: function() {
                return "请选择"
            }
        },



        /*
        [{
          value: -1,
          "text": "[全部]",
        }, {
          value: 0,
          "text": "[未分配]",
        }, {
          value: -2,
          "text": "[已分配]",
        }]*/
        other_btn_list: {
            type: Array,
            required: false,
            default: function() {
                return [
                ]
            }
        },


        // 扩展参数
        url_args_ex: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        }
    },
    // event
    emits: ["update:modelValue", "query", "close", "query_inputlist_query", "row-click"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },


    data: function() {
        return {
            desc: "",
            value: <any>"",
            ajax_multi_select_list: <Array<any>>[],
            visable: false,
            dlg_modal: true,
            col_span: 24,
            selected_url_args_ex: <any>{},
            selected_column_list_inter: <Array<any>>[],
            cur_select_data: <any>[],

            cur_lru_select_data: <any>null

        }
    },
    computed: {




    },

    watch: {

        modelValue(_new_val, _old_val) {
            this.$data.value = this.props.modelValue;
            (this).reset_selected_url_args_ex();
            (this).set_desc(this.$data.value)
        },
        visable(new_val, old_val) {
            console.log("visable:", new_val, old_val)
        }


    },


    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {

        on_created() {

            this.$data.cur_select_data = (this.props.select_type == "multi_select") ? [] : null;

            (<field_item_list_t>this.props.query_filter_item_list).forEach(function(item) {
                if (item.default_show === undefined) {
                    item.default_show = true
                }

            })

            const me = this
            if (!this.check_is_select_for_input()) {
                this.$data.visable = true
            }
            this.$data.selected_column_list_inter = common.copy_obj(this.props.selected_column_list)

            this.$data.selected_column_list_inter.push({
                label: "操作",
                prop: "option",
                min_width: "50px",
                component: defineComponent({
                    props: { row: Object },
                    data: function() {
                        return {
                        }
                    },
                    computed: {
                    },
                    methods: {
                        on_click() {

                            const { row } = this.$props
                            if (row) {
                                const check_value = row[me.get_url_query_field_name()]

                                ElMessageBox.confirm(`要取消选择: ${me.get_url_query_field_name()}:${check_value}吗?`, {
                                    callback: function(action: string) {
                                        if (action == "confirm") {
                                            const find_index = (<Array<any>>me.$data.ajax_multi_select_list).findIndex(function(value) {
                                                return value == check_value
                                            })
                                            if (find_index !== -1) {
                                                (<Array<any>>me.$data.ajax_multi_select_list).splice(find_index, 1)
                                                me.reset_selected_url_args_ex()
                                            }
                                        }
                                    }
                                })
                            }
                        }

                    },
                    template: "<el-button  @click=\"on_click\"  title=\"移除选择\" icon=\"iconfont icon-guanbi1\"  type=\"text\" style=\"font-weight: bold;\"  > </el-button>"
                })



            })


            if (this.props.display_type == "select_for_input") {
                this.$data.dlg_modal = false
            }
            this.$data.selected_url_args_ex = common.copy_obj(this.props.url_args_ex)

            let tmp_modelValue = this.props.modelValue

            if (sys_util.isArray(this.props.modelValue)) {
                tmp_modelValue = common.copy_obj(this.props.modelValue)
            }

            this.$data.value = tmp_modelValue


            if (this.check_ajax_and_multi_select() && !this.props.use_checkbox_select_in_ajax_multi_select_flag) {
                this.$data.col_span = 16
                this.$data.ajax_multi_select_list = tmp_modelValue
                this.reset_selected_url_args_ex()
            }
            if (this.get_props_lru_list_field_name()) {
                this.$data.col_span = 16
            }



            this.set_desc(this.$data.value)

        },
        get_props_lru_list_field_name() {
            return this.props.lru_list_field_name
        },

        get_url_query_field_name() {
            return this.props.url_query_field_name || this.props.field_name
        },
        set_desc(modelValue: any) {
            const me = this
            const { other_btn_list } = this.props

            const find_item = other_btn_list.find(function(item: any) {
                if (sys_util.isArray(item.value)) {
                    return item.value.toString() == modelValue.toString()
                } else {
                    return item.value == modelValue
                }
            })
            if (find_item) {
                this.$data.desc = find_item.text
                return
            }




            const { gen_desc_callback } = this.props
            if (gen_desc_callback) {
                const url_args = Object.assign({}, this.props.url_args_ex)
                if (sys_util.isArray(modelValue)) {
                    if (modelValue.length == 0) {
                        modelValue = [0]
                    }
                    url_args[this.get_url_query_field_name()] = modelValue.join(",")
                } else {
                    url_args[this.get_url_query_field_name()] = modelValue
                }

                url_args["page_count"] = 10000
                common.do_ajax(this.props.url, url_args, function(resp: any) {
                    me.$data.desc = gen_desc_callback(resp.data.list, modelValue)
                })

            } else {
                this.$data.desc = modelValue
            }

        },

        on_mounted() {
            // 默认数据,不使用ajax
            if (!this.props.with_ajax_flag) {
                if (this.props.select_type == "multi_select") {
                    // HACK
                    nextTick(() => {
                        nextTick(() => {
                            nextTick(() => {
                                const select_list = this.props.gen_select_item_list_callback(this.props.data, this.props.modelValue);
                                (<any>this.$refs.admin_table).set_selected_list(select_list)
                            })
                        })
                    })
                }
            }

        },
        on_selected_select(row: any) {
            const me = this
            if (row) {
                const check_value = row[this.get_url_query_field_name()]
                ElMessageBox.confirm(`要取消选择: ${this.get_url_query_field_name()}:${check_value}吗?`, {
                    callback: function(action: string) {
                        if (action == "confirm") {
                            const find_index = (<Array<any>>me.$data.value).findIndex(function(value) {
                                return value == check_value
                            })
                            if (find_index !== -1) {
                                (<Array<any>>me.$data.value).splice(find_index, 1)
                                me.reset_selected_url_args_ex()
                            }
                        }
                    }
                })
            }
        },

        check_ajax_and_multi_select() {
            return this.props.with_ajax_flag && this.props.select_type == "multi_select"
        },

        show_select_dlg() {

        },
        reload() {
            console.log(" ajax reload");
            (<admin_table_t>this.$refs.admin_table).load()
        },

        onSelect(row: any) {
            this.$data.cur_select_data = row
            if (row) {
                if (this.$refs.lru_table) {
                    (<any>this.$refs.lru_table)["clear_select"]()
                }
            }
            if (row) {
                const me = this

                const check_value = row[me.get_url_query_field_name()]
                // 异步多选
                if (this.check_ajax_and_multi_select() && !this.props.use_checkbox_select_in_ajax_multi_select_flag) {
                    if ((<Array<any>>this.$data.ajax_multi_select_list).findIndex(function(value) {
                        return value == check_value
                    }) === -1) {
                        this.$data.ajax_multi_select_list.push(check_value)
                        this.reset_selected_url_args_ex()
                    }
                }

            }
        },

        get_cur_select_data() {
            return this.$data.cur_select_data
        },
        reset_selected_url_args_ex() {
            if (this.check_ajax_and_multi_select()) {
                const value = this.$data.ajax_multi_select_list.join(",")
                if (value != this.$data.selected_url_args_ex[this.get_url_query_field_name()]) {
                    this.$data.selected_url_args_ex[this.get_url_query_field_name()] = value
                    nextTick(() => {
                        if (this.$refs.selelcted_table) {
                            (<admin_table_t>this.$refs.selelcted_table).load()
                        }
                    })
                }
            }
        },

        opt_close(e: MouseEvent) {
            e.stopImmediatePropagation()
            e.stopPropagation()
            this.$data.visable = false
        },
        on_submit() {

            const { gen_value_callback } = this.props
            let value: any = ""

            let use_lru_flag = false
            let { cur_select_data } = this.$data
            if (!cur_select_data) {
                cur_select_data = this.$data.cur_lru_select_data
                if (cur_select_data) {
                    use_lru_flag = true
                }
            }
            if (this.check_ajax_and_multi_select() && !this.props.use_checkbox_select_in_ajax_multi_select_flag) {
                value = this.$data.ajax_multi_select_list
            } else if (gen_value_callback) {
                value = gen_value_callback(cur_select_data)
                if (!value) {
                    ElMessageBox.alert("还没有选择")
                    return
                }
            } else {
                if (this.check_is_select_for_input()) {
                    if (this.props.select_type != "multi_select") {
                        value = cur_select_data.id
                    } else {
                        value = cur_select_data.map(function(item: any, _i: any) {
                            return item.id
                        }).join(",")

                    }
                }
                if (this.props.display_type == "select") {
                    if (!cur_select_data) {
                        ElMessageBox.alert("还没有选择")
                        return
                    }
                }
            }
            this.$emit("update:modelValue", value)
            this.$data.value = value
            this.$data.visable = false

            this.set_desc(this.$data.value)

            this.$emit("query", { value: cur_select_data, use_lru_flag: use_lru_flag })
        },


        do_select_deal_ajax_result(res: any) {
            return {
                total: res.data["total"],
                list: res.data["list"]
            }
        },
        do_deal_ajax_result(res: any) {
            let { data } = res
            if (sys_util.isArray(res.list)) {
                data = res
            }


            if (this.props.gen_select_item_list_callback) {
                // HACK
                nextTick(() => {
                    nextTick(() => {
                        const select_list = this.props.gen_select_item_list_callback(data["list"], this.props.modelValue);
                        (<any>this.$refs.admin_table).set_selected_list(select_list)
                    })
                })
            }
            let { total } = data
            if (data.page_info && data.page_info.total) {
                total = data.page_info.total
            }
            total = total * 1

            if (this.props.deal_ajax_result_func) {


                const ret = this.props.deal_ajax_result_func(res)
                if (sys_util.isObject(ret)) { // 有返回数据
                    return ret
                } else {
                    return {
                        total: total,
                        list: data["list"]
                    }
                }

            } else {
                return {
                    total: total,
                    list: data["list"]
                }
            }
        },
        check_is_select() {
            return this.props.display_type == "select"
        },

        check_is_select_for_input() {
            console.log("this.props.select_type:", this.props.select_type)
            return this.props.display_type == "select_for_input" || this.props.display_type == "query"
        },


        on_close() {
            this.$emit("close")
            if (this.check_is_select()) {
                /*
                nextTick(() => {
                    this.$destroy();
                    if (this.$el.parentNode) {
                        this.$el.parentNode.removeChild(this.$el);
                    }
                });
                */
            }
        },
        on_other_btn_click(btn_config: any) {
            if (btn_config.click) {
                if (btn_config.click(btn_config, this)) {
                    this.$data.visable = false
                }
            } else {
                this.$data.value = common.copy_obj(btn_config.value)
                this.set_desc(this.$data.value)
                this.$emit("update:modelValue", this.$data.value)
                this.$emit("query", { value: this.$data.cur_select_data })

                this.$data.visable = false
            }

        },

        onClick() {
            this.$data.visable = true
            nextTick(() => {
                this.reload()
                this.reset_selected_url_args_ex()
            })
        },
        close() {
            this.$data.visable = false
        },
        onQuery(args: any) {
            this.$emit("query_inputlist_query", args)
        },
        on_lru_list_select(row: any) {
            this.$data.cur_lru_select_data = row
            if (row) {
                (<any>this.$refs.admin_table)["clear_select"]()
            }

        },

        on_row_click(row: any) {
            this.$emit("row-click", row)
        }

    }

})
