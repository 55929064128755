import admin_vue from "@/components/Admin/admin_vue"
import {
    field_item_list_t,
    column_item_list_t,
    row_item_list_t
} from "@/utils/type_def"

import { defineComponent } from "vue"
import common from "/@/utils/common"

interface IProps {
  userid: number;
  config_fix: string;
}

export default defineComponent({
    components: {},
    extends: admin_vue,

    props: {
        config_fix: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        userid: {
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        }
    },
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props 
        }
    },

    data: function() {
        return {
            query_filter_item_list: <field_item_list_t>[],
            row_opt_btn_config: <row_item_list_t>[],
            column_list: <column_item_list_t>[],

            url_args_ex: {
                userid: 0
            },
            default_sort: {
                prop: "log_time",
                order: "desc"
            }
        }
    },
    computed: {},

    watch: {},
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_mounted() {},

        on_created() {
            this.$data.url_args_ex.userid = this.props.userid || common.get_query_args().userid
            this.$data.query_filter_item_list = this.get_query_filter_item_list()
            this.$data.row_opt_btn_config = this.get_row_opt_btn_config()
            this.$data.column_list = this.get_column_list()
        },

        get_row_opt_btn_config(): row_item_list_t {
            const me = this
            return [
                {
                    text: "编辑",
                    opt_key: "opt_edit",
                    callback: function(row) {
                        me.opt_edit(row, "edit")
                    }
                }
            ]
        },

        get_query_filter_item_list(): field_item_list_t {
            const me = this
            return [{
                field_type: "button",
                text: "新增",
                type: "primary",
                click: function() {
                    me.opt_edit([], "add")
                }
            }]
        },

        get_column_list(): column_item_list_t {
            return [
                {
                    label: "平台",
                    prop: "platform_type",
                    sortable: true,
                    formater: function(_v: any, row: any) {
                        return row.platform_type_str
                    }
                },
                {
                    label: "昵称",
                    prop: "platform_nickname"
                },
                {
                    label: "平台id",
                    prop: "platform_userid"
                },
                {
                    label: "粉丝数",
                    prop: "platform_fans_count"
                },
                {
                    label: "url",
                    prop: "platform_main_page_url"
                }
            ]
        },

        opt_edit(row:any, opt_type:string) {
            const me: any = this
            const field_list: field_item_list_t = [
                {
                    field_type: "select",
                    field_name: "platform_type",
                    label: "平台类型",
                    enum_type: "platform_type",
                    value: row.platform_type
                }, {
                    field_type: "text",
                    field_name: "platform_nickname",
                    label: "平台昵称",
                    type: "input",
                    value: row.platform_nickname
                }, {
                    field_type: "text",
                    field_name: "platform_userid",
                    label: "平台id",
                    type: "input",
                    value: row.platform_userid
                }, {
                    field_type: "text",
                    field_name: "platform_fans_count",
                    label: "平台粉丝",
                    type: "input",
                    value: row.platform_fans_count
                }, {
                    field_type: "text",
                    field_name: "platform_main_page_url",
                    label: "平台主页地址",
                    type: "input",
                    value: row.platform_main_page_url
                }
            ]

            const data: any = {}
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: "设置达人平台信息",
                submit_text: "确认",
                submit: function(data: any, _btn_config: any) {
                    data.opt_type = opt_type
                    data.platform_id = row.id
                    data.userid = me.$data.url_args_ex.userid
                    me.do_ajax("/daren/opt_platform_info", data, (resp: any) => {
                        if (resp.ret == 0) {
                            me.reload()
                            dlg.close()
                        } else {
                            common.alert(resp.info)
                        }
                    })
                }
            })
        }
    }
})
