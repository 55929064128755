import { defineComponent } from "vue"
import { store } from "/@/store"

import layoutState from "/@/store/module/layout"

interface IProps {
}



export default defineComponent({
    components: {
    },

    props: {


    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {

        return {
            menubar: layoutState.state.menubar
        }
    },
    computed: {




    },

    watch: {


    },
    created() {
        this.on_created()
    },
    mounted() {
        // this.on_mounted();
    },
    methods: {
        on_created() {
        },
        changeCollapsed() {
            store.commit("layout/changeCollapsed")
        }
    }

})
