
// 引入动态路由页面
const modules = import.meta.glob("../views/**/**.vue")
const components: IObject<() => Promise<typeof import("*.vue")>> = {
    Layout: (() => import("/@/layout/index.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    404: (() => import("/@/views/ErrorPage/404.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    LayoutBlank: (() => import("/@/layout/blank.vue")) as unknown as () => Promise<typeof import("*.vue")>
}
const componentsIngore: Array<string> = ["login", "404"] // 忽略的页面
Object.keys(modules).forEach(key => {
    // console.log("key:" + key);
    const nameMatch = key.match(/^\.\.\/views\/([^\/]*)\/([^\/]*)(\/([^\/]*))?\.vue/)
    if (nameMatch) {
        const [, ctrl, action, _, sub_page] = nameMatch
        // 如果页面以Index命名，则使用父文件夹作为name
        var name = ""
        if (sub_page) { // 内部页面
            name = `${ctrl}__${action}__${sub_page}`
        } else {
            name = `${ctrl}__${action}`
        }
        // console.log("name" + name);


        if (!componentsIngore.includes(name)) {
            components[name] = modules[key] as () => Promise<typeof import("*.vue")>
        }

    }
})


export default components
