
import { field_item_list_t } from "@/utils/type_def"
import common from "/@/utils/common"
var daren_task = {
    opt_assign_daren(row: any, succ_callback: Function) { // 分配达人


        const field_list: field_item_list_t = [{
            field_type: "label",
            label: "达人",
            value: row.dp_nick_name
        }, {
            /*
              field_type: 'date',
              type: "datetime",
              format: "yyyy-MM-dd HH:mm",
            */
            field_type: "label",
            field_name: "real_shopping_time",
            label: "到店时间",
            value: row.real_shopping_time

        }]
        var data: any = {}

        var dlg = common.dlg.admin_show_key_table(field_list, data, {
            title: `设置[${row.dp_nick_name}]报名成功`,
            width: "400px",
            submit: function(data: any, _btn_config: any) {

                common.do_ajax("/daren_ex/task_time_assign", {
                    task_time_id: row.task_time_id,
                    daren_task_request_id_list: row.daren_task_request_id,
                    real_shopping_time: common.strtotime(data.real_shopping_time)
                }, function() {
                    succ_callback()
                }, true)

                dlg.close()
            }
        })


    },
    opt_enter_fail(row: any, succ_callback: Function) { // 设置报名失败
        common.confirm(`设置[${row.dp_nick_name}]报名失败?!`, function() {
            common.do_ajax("/daren_ex/daren_task_enter_set_fail", {
                daren_task_request_id: row.daren_task_request_id
            }, function() {
                succ_callback()
            }, true)
        })
    }


}

export default daren_task
