import axios from "axios"
import common from "./common"
import queryString from "query-string"
import util from "./sys_util"
import { getToken } from "./auth"
import { ElMessage, ElMessageBox } from "element-plus"




// const CLSContext = require('zipkin-context-cls');
// const ctxImpl= new CLSContext('zipkin'),




function new_service() {
    // 创建axios实例
    const service = axios.create({
        baseURL: import.meta.env.VITE_APP_BASE_API as string || "/api",
        timeout: 30000 // 请求超时时间
    })

    // request拦截器
    service.interceptors.request.use(config => {
        // alert( "xxx:"+ store.getters.token );
        const token = getToken()
        if (token) {
            config.headers["Session-Id"] = token // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.headers["Call-Server"] = window.location.hostname // 让每个请求携带自定义token 请根据实际情况自行修改
        return config
    }, error => {
        // Do something with request error
        console.log("FFFFFFFF ", error) // for debug
        Promise.reject(error)
    })

    // respone拦截器
    service.interceptors.response.use(
        response => {
            /**
            * code为非20000是抛错 可结合自己业务进行修改
            */
            const res = response.data
            if (util.isObject(res.data)) {
                response.data.status = 0
            }
            if (res.code) {
                res.status = res.code
            } else if (res.ret) {
                res.status = res.ret
            }
            if (res.status == 0) {
                res.status = 200
            }

            console.log("resp", response)


            const data = JSON.parse(response.config.data)
            const query_str = queryString.stringify(data)
            let url: string = <string>response.config.baseURL + response.config.url
            if (!url.startsWith("http")) {
                url = `${window.location.protocol}//${window.location.host}${url}`
            }
            url = `${url}?var_session_id=${getToken()}&${query_str}`
            console.log(`%c ${url}`, "background-color:#000;color:#FFF ")
            console.log("IN:", data)
            console.log("OUT:", res)
            if (url[0] != "h") { // http 开头
                url = `${window.location.protocol}//${window.location.hostname}${url}`
            }


            if (util.isObject(res)) {
                if (res.ret === undefined) {
                    res.ret = res.code
                }



                if (util.isArray(res.__log__)) {


                    (<Array<string>>res.__log__).forEach(function(logline) {
                        console.log(logline)
                    })

                    res.__log__ = undefined
                }

                if (!(common.in_array(res.ret, [0, 998, 997]) || res.ret >= 10000)) { // 998 :流量控制


                    ElMessage({
                        dangerouslyUseHTMLString: true,
                        message: `<a target="_blank" href="${url}">出错:${res.info || "非JSON "} ,点击查看! </a> <br/>`,
                        type: "error",
                        showClose: true,
                        duration: 5 * 1000
                    })


                    // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
                    if (res.ret == 1005) {
                        ElMessageBox.confirm("太久没操作, 需要重新登录", "确定登出", {
                            confirmButtonText: "重新登录",
                            cancelButtonText: "取消",
                            type: "warning"
                        }).then(() => {
                            /*
                            store.dispatch('FedLogOut').then(() => {
                                location.reload()// 为了重新实例化vue-router对象 避免bug
                            })
                            */
                        })
                    }
                    return Promise.reject("error")
                } else {
                    return response.data
                }
            } else {

                ElMessage({
                    dangerouslyUseHTMLString: true,
                    message: `<a target="_blank" href="${url}">出错:${res.info || "非JSON "} ,点击查看 11 </a> <br/>`,
                    type: "error",
                    showClose: true,
                    duration: 5 * 1000
                })


                // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
                if (res.ret == 1005) {
                    ElMessageBox.confirm("太久没操作, 需要重新登录", "确定登出", {
                        confirmButtonText: "重新登录",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        /*
                        store.dispatch('FedLogOut').then(() => {
                            location.reload()// 为了重新实例化vue-router对象 避免bug
                        })
                        */
                    })
                }
                return Promise.reject("error")

            }
        },
        error => {
            console.log(error)

            let url: any = <string>error.config.baseURL + error.config.url

            const data = JSON.parse(error.config.data)
            const query_str = queryString.stringify(data)
            url = `${url}?var_session_id=${getToken()}&${query_str}`

            console.log(`err${error}`)// for debug
            ElMessage({
                dangerouslyUseHTMLString: true,
                message: `<a target="_blank" href="${url}">出错:${error} ,点击查看22 </a> <br/>`,
                type: "error",
                showClose: true,
                duration: 5 * 1000
            })
            return Promise.reject(error)
        }
    )
    return service

}



const zipkinServeice = function() {

    return new_service()

}
export default zipkinServeice
