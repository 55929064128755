import admin_vue from "@/components/Admin/admin_vue"
import field_ex_config from "./field_ex_config.vue"

import { defineComponent } from "vue"
import { field_item_list_t } from "@/utils/type_def"
import common from "/@/utils/common"


interface IProps {
    row: any,
    data: any,


}



export default defineComponent({
    components: {
        fieldExConfig: field_ex_config
    },
    extends: admin_vue,

    props: {

        row: Object,
        data: Object


    },
    // event
    emits: ["close", "update_list"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            mainOptionList: [],
            childOptionList: [],
            optionList: [],
            timeOptionList: [],
            config: [],
            orderInfo: null, // 点评订单详情
            field_ex_config: "",
            stepsActive: 1,
            // task_id: '',
            form: {
                shop_addr: "",
                shop_name: "",
                shop_id: "",
                main_id: "",
                task_time_id: ""
            },
            timeForm: {},
            pic_file_list: [],
            taskForm: {
                task_name: "",
                enable_flag: 0,
                use_score: "0",
                get_score: "0",
                get_money: "0",
                pic_file_list: [],
                daren_group_type: 0,
                min_fans_count: "0",
                promise_exposure: "0",
                enter_request: ""
            },
            rows: <any>{}

        }
    },
    created() {
        this.on_created()
    },
    mounted() {
    },
    methods: {
        on_created() {
            var me = this
            console.log("this", this)
            console.log("props", this.$props)
            console.log("shopping props", this.$props.data)
            me.$data.rows = this.$props.row
            if (me.$data.rows.main_shop_id !== "0") {
                // me.stepsActive = 2
            }
            me.taskForm = Object.assign(me.taskForm, {
                task_name: me.rows.dp_shop_name,
                pic_file_list: [me.rows.dp_shop_default_pic_url],
                min_fans_count: me.rows.min_fans_count,
                
                enter_request: me.rows.requirement
            })
            me.timeForm = {
                opt_type: "add",
                dp_order_id: me.rows.order_id,
                shopping_time: common.date_format(me.rows.shopping_start_time, true),
                // shopping_end_time: common.date_format(me.rows.shopping_end_time, true),
                need_user_count: me.rows.need_user_count,
                enter_end_time: common.date_format(me.props.row.shopping_start_time - 86400),
                report_end_time: me.rows.content_submit_time
            }

            this.getMainShopList(me, me.rows.main_shop_id)
        },

        exConfig(str: string) {
            this.field_ex_config = str
        },

        getTaskId(this: any) {
            var _this = this
            common.do_ajax("/daren_ex/get_task_id", {
                shop_id: _this.form.shop_id,
                city_task_id: this.props.row.city_task_id
            }, function(resp: any) {
                if (resp.code === 0) {
                    _this.task_id = resp.data.task_id
                    console.log(resp)
                }
            })
        },

        setMainShop(this: any) {

            const _this = this

            if (!_this.rows.main_shop_id) {
                common.alert("请选择主店铺名称")
                return
            }
            if (_this.rows.main_shop_id === "0") {
                common.alert("请选择主店铺名称")
                return
            }
            if (!_this.rows.shop_id) {
                common.alert("请选择子店铺")
                return
            }
            common.do_ajax("/daren_ex/shop_set_main_shop_id", {
                main_shop_id: _this.rows.main_shop_id,
                shop_id: _this.rows.shop_id
            }, function(_res: any) {
                _this.stepsActive += 1
            })
        },

        task_save() {
            const me: any = this
            if (me.rows.task_time_id !== null) {
                common.alert("已创建过任务, 禁止重复创建")
                return
            }
            const post_data = common.copy_obj(me.taskForm)
            var img_url = me.taskForm.pic_file_list[0] ?? ""
            const img = new Image()
            img.src = img_url

            post_data["task_pic_width"] = img.width
            post_data["task_pic_height"] = img.height
            post_data["opt_type"] = "add"
            post_data["main_shop_id"] = me.rows.main_shop_id
            post_data["field_ex_config"] = me.field_ex_config
            post_data["task_pic_url"] = me.taskForm.pic_file_list.join(",")

            const exConfig = me.field_ex_config ? JSON.parse(me.field_ex_config).filter((item: any) => item.type == "shop_date" && item.label) : []
            if (!me.taskForm.browse_limit) {
                common.alert("请填写30天浏览量")
                return
            }
            if (!me.taskForm.area) {
                common.alert("请填写任务地区")
                return
            }
            if (!me.taskForm.budget) {
                common.alert("请填写佣金预算")
                return
            }
            if (!me.timeForm.need_user_count) {
                common.alert("请填写招募人数")
                return
            }
            if (exConfig.length == 0) {
                common.alert("请选择到店日期")
                return
            }
            // if (!me.timeForm.enter_end_time) {
            //     common.alert('请选择报名截止时间')
            //     return
            // }
            me.timeForm.shopping_time = exConfig[0].dateRange[0]
            console.log("post_data-----------", post_data)
            common.do_ajax("/daren_ex/opt_city_task", post_data, function(resp: any) {
                me.addTime(resp.data.city_task_id)
            })


        },

        setSteps(this: any) {
            this.stepsActive += 1
        },

        addTime(this: any, city_task_id: any) {
            const _this = this
            let params: any = {}
            params = Object.assign({}, _this.timeForm)
            params["city_task_id"] = city_task_id
            params["shop_id"] = _this.rows.shop_id
            params["enter_end_time"] = common.datetotime(params["enter_end_time"])
            params["report_end_time"] = common.datetotime(params["report_end_time"])
            params["shopping_time"] = common.datetotime(params["shopping_time"])
            // params['shopping_end_time'] = common.datetotime(params['shopping_end_time'])
            if (!params.dp_order_id) {
                common.alert(`数据异常,请找管理员: dp_order_id= ${params.dp_order_id}`)
                return
            }

            common.do_ajax("/daren_ex/opt_task_item", params, function(resp: any) {
                if (resp.code === 0) {
                    _this.$emit("close")
                    _this.$emit("update_list")
                    _this.$message({
                        message: "创建成功",
                        type: "success"
                    })

                }
            })
        },

        async confirmTask(this: any) {
            this.task_save()
        },

        confirm(this: any) {
            const _this = this
            const fnObj: any = {
                1: _this.setMainShop,
                2: _this.confirmTask
            }
            fnObj[_this.stepsActive]()
        },

        getMainShopList(that: any, main_shop_id = 0) {

            common.do_ajax("/user_manage/get_user_list", {
                type: "main_shop",
                main_type: +that.rows.city_code,
                // main_type: that.rows.main_shop_id,
                page_count: "100000"
            }, function(resp: any) {
                if (resp.code === 0) {
                    that.mainOptionList = [
                        {
                            id: "0",
                            nick: "请选择"
                        },
                        ...resp.data.list
                    ]
                    that.rows.main_shop_id = `${main_shop_id}`
                    console.log(resp)
                }
            })
        },

        // 新增主店铺
        addMainShop(row?: any) {
            const me: any = this
            row = {
                city_code: me.rows.city_code,
                main_shop_name: me.rows.shop_name
            }
            var field_list: field_item_list_t = [{

                field_type: "text",
                field_name: "main_shop_name",
                label: "主店铺名称",
                style: {
                    width: "400px"
                },
                value: row.main_shop_name
            }, {

                field_type: "select",
                field_name: "city_code",
                ajax_url: "/ajax_deal/get_city_list",
                ajax_url_args: {
                    // "role_groupid": 2,
                },
                multiple: false,
                disabled: true,
                label: "城市",
                show_item_all: false, // [全部] 不显示

                style: {
                    width: "400px"
                },
                value: row.city_code

            }]
            var data = {}

            var title = "添加"
            // if (opt_type == "set") {
            //   title = "编辑";
            // }
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: title,
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    if (!data.main_shop_name) {
                        common.alert("请填写主店铺名称")
                        return
                    }
                    data["opt_type"] = "add"
                    common.do_ajax("/daren_ex/opt_main_shop", data, function(resp: any) {
                        me.getMainShopList(me, resp.data.main_shop_id)
                    })
                    dlg.close()
                }
            })

        },

        // 修改子店铺
        editShop() {
            var me: any = this
            var field_list: field_item_list_t = [
                {
                    field_type: "text",
                    field_name: "shop_name",
                    label: "店铺名称",
                    value: me.rows.shop_name,
                    style: {
                        width: "400px"
                    }
                }, {

                    field_type: "text",
                    field_name: "address",
                    label: "店铺地址",
                    value: me.rows.address,
                    style: {
                        width: "400px"
                    }
                }]
            var data = {}
            console.log(me.props.row)
            var title = "添加"
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: title,
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    if (!data.shop_name) {
                        common.alert("请填写店铺名称")
                        return
                    }
                    if (!data.address) {
                        common.alert("请填写店铺地址")
                        return
                    }

                    data["opt_type"] = "set"
                    // data["main_shop_id"] = me.form.main_id;
                    data["shop_id"] = me.rows.shop_id
                    common.do_ajax("/daren/opt_shop", data, function(resp: any) {
                        if (resp.code === 0) {
                            me.rows.shop_name = data.shop_name
                            me.rows.address = data.address
                        }
                    })
                    dlg.close()
                }
            })
        },


        on_close() {
            this.$emit("close", {})
        }
    }


})

