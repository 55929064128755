import { ITheme } from "/@/type/config/theme"

const theme:Array<ITheme> = [
    {
        tagsActiveColor: "#fff",
        tagsActiveBg: "#409EFF",
        mainBg: "#f0f2f5",
        sidebarColor: "#fff",
        sidebarBg: "#001529",
        sidebarChildrenBg: "#000c17",
        sidebarActiveColor: "#fff",
        sidebarActiveBg: "#409EFF",
        sidebarActiveBorderRightBG: "#1890ff"
    },
    {
        tagsActiveColor: "#fff",
        tagsActiveBg: "#409EFF",
        navbarColor: "#fff",
        navbarBg: "#393D49",
        mainBg: "#f0f2f5",
        sidebarColor: "#fff",
        sidebarBg: "#001529",
        sidebarChildrenBg: "#000c17",
        sidebarActiveColor: "#fff",
        sidebarActiveBg: "#409EFF",
        sidebarActiveBorderRightBG: "#1890ff"
    },
    {
        tagsActiveColor: "#fff",
        tagsActiveBg: "#409EFF",
        mainBg: "#f0f2f5",
        sidebarColor: "#333",
        sidebarBg: "#fff",
        sidebarChildrenBg: "#fff",
        sidebarActiveColor: "#409EFF",
        sidebarActiveBg: "#e6f7ff",
        sidebarActiveBorderRightBG: "#409EFF"
    },
    {
        logoColor: "rgba(255,255,255,.7)",
        logoBg: "#50314F",
        tagsColor: "#333",
        tagsBg: "#fff",
        tagsActiveColor: "#fff",
        tagsActiveBg: "#7A4D7B",
        mainBg: "#f0f2f5",
        sidebarColor: "rgba(255,255,255,.7)",
        sidebarBg: "#50314F",
        sidebarChildrenBg: "#382237",
        sidebarActiveColor: "#fff",
        sidebarActiveBg: "#7A4D7B",
        sidebarActiveBorderRightBG: "#7A4D7B"
    },
    {
        logoColor: "rgba(255,255,255,.7)",
        logoBg: "#50314F",
        navbarColor: "rgba(255,255,255,.7)",
        navbarBg: "#50314F",
        tagsColor: "#333",
        tagsBg: "#fff",
        tagsActiveColor: "#fff",
        tagsActiveBg: "#7A4D7B",
        mainBg: "#f0f2f5",
        sidebarColor: "rgba(255,255,255,.7)",
        sidebarBg: "#50314F",
        sidebarChildrenBg: "#382237",
        sidebarActiveColor: "#fff",
        sidebarActiveBg: "#7A4D7B",
        sidebarActiveBorderRightBG: "#7A4D7B"
    }
]

export default theme