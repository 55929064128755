<template>
    <div>
        <el-input
            v-if="display_type==&apos;select_for_input&apos; || display_type==&apos;query&apos; "
            v-model="desc"
            readonly
            :style="select_for_input_style"
            @click="onClick"
        />

        <el-dialog
            v-model="visable"
            :title="title"
            :width="width"
            :modal="dlg_modal"
            @close="on_close"
        >
            <el-row>
                <el-col
                    :sm="24"
                    :md="col_span"
                >
                    <admin-table
                        ref="admin_table"
                        :query_filter_item_list="query_filter_item_list"
                        :column_list="column_list"
                        :row_opt_component="[]"
                        :url="url"
                        :url_args_ex="url_args_ex"
                        :show_download="show_download"
                        :show_query_config="false"
                        :show_option_list="false"
                        :select_type=" with_ajax_flag&& select_type==&apos;multi_select&apos;&& !use_checkbox_select_in_ajax_multi_select_flag ?&apos;single_select&apos;: select_type"
                        bar_layout=" sizes,total,  jumper, prev, next"
                        :show_query_button="show_query_button"
                        :reset_table_height_flag="false"
                        :deal_ajax_result_func="do_deal_ajax_result"
                        :set_height="table_height"
                        :use_url_args_and_page_config="false"
                        :use_page_bar="use_page_bar"
                        :data="data"
                        :with_ajax_flag="with_ajax_flag"
                        :default_sort="default_sort"
                        @select="onSelect"
                        @query="onQuery"
                        @row-click="on_row_click"
                    >
                        <template #toolbar>
                            <div v-html="toolbar_html_str" />
                        </template>
                    </admin-table>
                </el-col>

                <el-col
                    v-if=" !use_checkbox_select_in_ajax_multi_select_flag && with_ajax_flag && select_type == &apos;multi_select&apos;"
                    :sm="24"
                    :md="8"
                    style="padding-left: 10px;"
                >
                    <admin-table
                        ref="selelcted_table"
                        :query_filter_item_list="[]"
                        :column_list="selected_column_list_inter"
                        :row_opt_component="[]"
                        :url="url"
                        :url_args_ex="selected_url_args_ex"
                        :select_type="&apos;single_select&apos;"
                        :show_option_list="false"
                        bar_layout="total, jumper, prev, next"
                        :reset_table_height_flag="false"
                        :set_height="table_height"
                        :use_url_args_and_page_config="false"
                        :use_page_bar="use_page_bar"
                        :deal_ajax_result_func="do_select_deal_ajax_result"
                        :with_ajax_flag="true"
                        @select="on_selected_select"
                    />
                </el-col>

                <el-col
                    v-else-if="lru_list_field_name"
                    :sm="24"
                    :md="8"
                    style="padding-left: 10px;"
                >
                    <admin-table
                        ref="lru_table"
                        :query_filter_item_list="[]"
                        :column_list="[{ &apos;prop&apos; : &apos;id&apos;, label: &apos;id&apos; }]"
                        :row_opt_component="[]"
                        :url="lru_list_url"
                        :url_args_ex="{ field_name: lru_list_field_name }"
                        :select_type="&apos;single_select&apos;"
                        :show_option_list="false"
                        :reset_table_height_flag="false"
                        :use_url_args_and_page_config="false"
                        :use_page_bar="false"
                        :with_ajax_flag="true"
                        @select="on_lru_list_select"
                    />
                </el-col>
            </el-row>
            <template #footer>
                <div class="dialog-footer">
                    <el-button
                        v-for=" (btn_config,index) in other_btn_list"
                        :key="index"
                        :type="btn_config.type"
                        :icon="btn_config.icon"
                        @click="on_other_btn_click(btn_config)"
                        v-html="btn_config.text"
                    />

                    <el-button
                        @click="opt_close"
                        v-text="display_type==&apos;list&apos;?&apos;返 回&apos;:&apos;取 消&apos; "
                    />
                    <el-button
                        v-if="display_type!=&apos;list&apos; "
                        type="primary"
                        @click="on_submit "
                    >
                        确 定
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import m from "./ajax_table_select.ts"
export default m
</script>


<style  lang="postcss" >
._user_select_component {
  .toolbar {
    margin-bottom: 12px;
  }

  .el-dialog__body {
    padding: 10px 20px;
  }

  .el-table td,
  .el-table th {
    padding: 6px;
  }
}

.dialog-footer {
  .el-button {
    margin-bottom: 10px;
  }
}
</style>
