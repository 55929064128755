import { defineComponent, nextTick } from "vue"
import common from "/@/utils/common"


interface IProps {
    modelValue: any
    title: string
    type: string
    display_date_format: string,
    on_change: Function

}



export default defineComponent({
    name: "AdminVanDatetime",
    components: {
    },

    props: {

        title: String,
        type: String,
        display_date_format: String,
        modelValue: String,

        on_change: {
            type: Function,
            required: false,
            default: function() {
                return null
            }
        }



    },

    // event
    emits: ["update:modelValue", "query", "change"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {

            desc: "",
            show_picker: false,
            value: <any>new Date()

        }
    },
    computed: {




    },

    watch: {
        value: {
            handler() {
                this.reset_desc()
            },
            immediate: true


        }




    },
    created() {
        this.on_created()
    },
    mounted() {
    },
    methods: {
        do_click() {
            this.$data.show_picker = true
        },

        on_created() {
            this.init()
        },


        init() {
            this.$data.value = new Date(this.props.modelValue)

            if (isNaN(this.$data.value.getTime())) {
                this.$data.value = null
            }

        },
        query() {

            // 保证 value 已经同步出去了
            nextTick(() => {
                if (this.props.on_change) {
                    this.props.on_change(this.$data.value)
                }

                this.$emit("change", this.$data.value)
                this.$emit("query", this.$data.value)

            })
        },


        reset_desc() {
            this.$data.desc = common.date_format(this.$data.value, this.props.display_date_format ?? "yyyy-MM-dd")
        },


        picker_confirm(value: any) {
            this.$data.value = value
            this.$data.show_picker = false
            this.$emit("update:modelValue", common.date_format(value, "yyyy-MM-dd"))
            this.query()
        }



    }


})
