import { defineComponent, nextTick } from "vue"
import { ElButton, ElCol, ElDialog, ElInput, ElRow, ElTable, ElTableColumn } from "element-plus"
import { admin_table } from "/@/admin_component"
import common from "/@/utils/common"
import sys_util from "/@/utils/sys_util"


interface IProps {
    display_type: string
    dlg_modal_flag: boolean
    modelValue: any
    user_type: string
    show_lru_flag: string

    main_type: any
    admin_group_list: any
    value_btn_list: any
    url_args_ex: any

    on_change: Function
}

export default defineComponent({
    components: {
        ElInput,
        ElRow,
        ElCol,
        ElDialog,
        ElTable,
        ElTableColumn,
        ElButton,
        "admin-table": admin_table

    },

    props: {

        modelValue: [Number, String],
        user_type: String,
        admin_group_list: {
            type: [Array, Boolean],
            required: false,
            default: function() {
                return true
            }
        },
        show_query_button: {
            type: [Boolean, String],
            required: false,
            default: function() {
                return false
            }
        },



        main_type: {
            type: [String, Number],
            required: false,
            default: function() {
                return ""
            }
        },

        show_lru_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }

        },
        dlg_title: {
            type: String,
            required: false,
            default: function() {
                return "选择"
            }

        },
        display_type: {
            type: String, // select_one_user, select_one_user_for_input, query
            required: false,
            default: function() {
                return "select_one_user_for_input"
            }
        },
        value_btn_list: {
            type: Object, // { "未分配" => 0,  "已分配" => 2 }
            required: false,
            default: function() {
                return {}
            }
        },
        item_style: {
            type: Object,
            required: false,
            default: function() {
                if (common.check_in_mobile_page()) {
                    return { width: "100%" }
                } else {
                    return { width: "100px" }
                }
            }
        },
        column_list: {
            type: Array,
            required: false,
            default: function() {
                return [{
                    label: "id",
                    prop: "id"
                }, {
                    label: "名字",
                    prop: "nick"
                }]
            }
        },


        on_change: {
            type: Function,
            required: false,
            default: function() {
                return null
            }
        },

        dlg_modal_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        placeholder: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        url_args_ex: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        }


    },
    // event
    emits: ["update:modelValue", "query"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props,

            // 自定义的

            url: "/user_manage/get_user_list",

            do_key_down_func: <any>undefined


        }
    },

    data: function() {
        return {
            data_url_args_ex: <any>{
                lru_flag: 1

            },
            dlg_modal: true,
            display: "none",
            lru_list: [],
            main_type_str: "",
            desc: "",
            nick: "",
            phone: "",
            value: <any>"",
            user_data: [],
            dialog_width: "",
            dialog_top: "15vh",
            dlg_user_list_visable: false,

            query_filter_item_list: [{
                default_show: true,
                field_type: "text",
                label: "名字/id",
                field_name: "query_text",
                value: ""
            }]
        }

    },
    computed: {

        desc_class() {
            if (common.check_in_mobile_page()) {
                return "mobile-select-desc"
            } else {
                return ""
            }
        }


    },

    watch: {

        value: function(new_val, old_val) {
            this.check_value(new_val, old_val)
        },

        modelValue: function(new_val) {
            this.$data.value = new_val
            this.reload_desc()
        },
        main_type: function(new_val) {
            this.$data.data_url_args_ex["main_type"] = new_val
        }

    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_created() {

            this.do_key_down_func = sys_util.debounce((e: any) => {
                if (e.target.type != "textarea") {
                    if (e.keyCode == 13) { // enter
                        this.onClick()
                    }
                }
            })

            Object.assign(this.$data.data_url_args_ex, this.props.url_args_ex)
            console.log("2222222222222222222222222222222222222", this.props.url_args_ex, this.$data.data_url_args_ex)

            const { display_type } = this.props
            if (display_type == "select_one_user") {
                this.show_select_dlg()
            }
            this.$data.dlg_modal = this.props.dlg_modal_flag

            if (display_type == "select_one_user_for_input") {
                this.$data.dlg_modal = false
            }

            // dlg_modal
            this.$data.value = this.props.modelValue

            this.$data.dialog_top = common.in_phone() ? "1vh" : "2vh"
            if (!common.in_phone()) {
                this.$data.dialog_width = "900px"
            } else {
                this.$data.dialog_width = "95%"
            }
            // 请求的其它参数
            this.$data.data_url_args_ex["type"] = this.props.user_type
            if (this.props.user_type == "console_admin") {
                this.url = "/route__php_tars_admin/ajax_deal/get_user_list"

            }

            this.$data.data_url_args_ex["main_type"] = this.props.main_type
            if (sys_util.isArray(this.props.admin_group_list)) {
                this.$data.data_url_args_ex["admin_group_list"] = this.props.admin_group_list.join(",")
            } else if (this.props.admin_group_list) {
                this.$data.data_url_args_ex["admin_group_list"] = common.get_admin_group_list().join(",")
            }

            // 得到desc
            this.reload_desc()
        },

        // 刷新显示
        reload_desc() {
            const me = this
            console.log(me.$data.value)
            if (me.$data.value > 0) {
                common.do_ajax(me.url, {
                    type: this.props.user_type,
                    id: this.$data.value

                }, function(res: any) {
                    if (res.data.list[0] !== undefined) {
                        me.$data.desc = res.data.list[0].nick
                    } else {
                        me.$data.desc = "[没找到]"
                    }

                })
            } else if (me.$data.value == -1) {
                me.$data.desc = "[全部]"
            } else {
                const title_list = Object.getOwnPropertyNames(me.props.value_btn_list)
                title_list.forEach(function(title) {
                    const value = me.props.value_btn_list[title]
                    if (value == me.$data.value) {
                        me.$data.desc = `[${title}]`
                    }
                })
            }

            console.log(me.$data.desc)

        },

        check_value(new_val: number, _old_val: number) {
            // 推送
            this.$emit("update:modelValue", new_val)

        },

        on_mounted() {
            nextTick(function() {
                var p = $(".el-dialog").parent()
                p.addClass("el-overlay")
            })
        },
        show_select_dlg(flag = true) {
            this.$data.dlg_user_list_visable = flag
            return false

        },

        onClick() {
            if (this.$refs.admin_table) {
                (<any>this.$refs.admin_table)["load"]()
            }
            this.show_select_dlg()
        },
        on_close() {
        },




        do_deal_ajax_result(res: any) {

            console.log("KKKKKKKKKKk", res)
            this.$data.lru_list = res.lru_list


            return {
                total: res.total,
                list: res.list
            }
        },

        onSelect(row: any) {
            console.log(" on select ..... ", row)
            if (row) {
                this.$data.value = row.id
                this.$data.nick = row.nick
                this.$data.phone = row.phone;
                (<any>this.$refs.lru_table)["setCurrentRow"]()
            }
        },

        on_lru_current_change(row: any, _old_low: any) {
            if (row) {
                this.$data.value = `${row.id}`
                const arr = row.name.split("-")
                this.$data.nick = arr[0]
                this.$data.phone = arr[1];
                (<any>this.$refs.admin_table).clear_select()
            }

        },

        on_submit() {
            this.$data.dlg_user_list_visable = false
            this.$data.desc = this.$data.nick
            if (this.$data.value > 0) {

                common.do_ajax(this.url, {
                    type: this.props.user_type,
                    lru_id: this.$data.value,
                    lru_id_name: `${this.$data.nick}-${this.$data.phone}`
                })
            }
            this.$emit("update:modelValue", this.$data.value)


            this.$emit("query", { value: this.$data.value })
            if (this.props.on_change) {
                this.props.on_change(this.$data.value)
            }
        },
        on_select_all() {
            this.$data.dlg_user_list_visable = false
            this.$data.value = -1
            this.$data.desc = "[全部]"
            this.$emit("update:modelValue", this.$data.value)
            this.$emit("query", { value: this.$data.value })
            if (this.props.on_change) {
                this.props.on_change(this.$data.value)
            }

        },
        on_value_btn_click(value: any, title: any) {

            this.$data.dlg_user_list_visable = false
            this.$data.value = value
            this.$data.desc = `[${title}]`
            this.$emit("update:modelValue", value)
            this.$emit("query", { value: value })

        },

        get_row_opt_component() {
            return common.gen_row_opt_component([])
        },

        do_key_down(e: any) {
            this.do_key_down_func(e)
        },
        do_click() {
            this.onClick()
        }


    }


})
