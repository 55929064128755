import { IMenubarList } from "/@/type/store/layout"
import { listToTree } from "/@/utils/index"
import { store } from "/@/store/index"
import common from "../utils/common"
import components from "../router/components"

import path_sub_page_config from "../config/path_sub_page_config"

const asyncRouter: Array<IMenubarList> = [
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: components["404"],
        meta: {
            title: "NotFound",
            icon: "",
            hidden: true
        },
        redirect: {
            name: "404"
        }
    }
]

export const generatorDynamicRouter = (data: Array<IMenubarList>): void => {
    const routerList: Array<IMenubarList> = listToTree(data, 0)
    asyncRouter.forEach(v => routerList.push(v))
    const f = (data: Array<IMenubarList>, pData: IMenubarList | null) => {
        for (let i = 0, len = data.length; i < len; i++) {
            const v: IMenubarList = data[i]
            if (typeof v.component === "string") v.component = components[v.component]
            if (!v.meta.permission || pData && v.meta.permission.length === 0) {
                v.meta.permission = pData && pData.meta && pData.meta.permission ? pData.meta.permission : []
            }
            if (v.children && v.children.length > 0) {
                f(v.children, v)
            }
        }
    }
    f(routerList, null)
    store.commit("layout/setRoutes", routerList)
}
const self_host = window.location.host
export var path_title_map = <any>{}

export const get_router_map = function(menu_node: any, name_fix_str = "", level = 0) {
    const node_name = `${name_fix_str}_${menu_node["name"]}`
    if (Array.isArray(menu_node["list"])) {
        const children: Array<any> = [];
        (<Array<any>>menu_node["list"]).forEach(function(sub_node, i) {

            const ret = get_router_map(sub_node, `${name_fix_str}_${i}`, level + 1)
            if (ret) {
                children.push(ret)
            }
        })
        var icon = menu_node["icon"]
        if (icon == "fa-folder-o") {
            icon = "icon-wenjianjia"
        }

        if (icon == "fa-star") {
            icon = "icon-biaoxingfill"
        }

        return {
            path: `/${node_name}`,
            component: level == 1 ? components["Layout"] : components["LayoutBlank"],
            name: node_name,
            open_flag: menu_node["open_flag"],
            meta: {
                icon: icon,
                title: menu_node["name"],
                alwaysShow: menu_node["alwaysShow"]
            },
            children: children
        }
    } else {
        const url = menu_node["url"]
        const url_info = url.split("#")


        const host_info: string = url_info[0]
        const path_info = url_info[1]


        var icon = menu_node["icon"]
        if (icon == "fa-circle-o") {
            icon = "icon-huan"
        }
        if (!menu_node["star_flag"]) {
            common.path_url_map[path_info] = url
            if (!common.first_path && !menu_node["hidden"]) {
                common.first_path = path_info
            }

        }


        // vue
        if (path_info && host_info.match(self_host)) {

            const path_arr = path_info.split(/[\/\?]/)
            const ctrl = path_arr[1]
            const action = path_arr[2]
            path_title_map[`/${ctrl}/${action}`] = menu_node["name"]
            var ret: any = null
            var path = `/${ctrl}/${action}`

            var component: any = null

            if (store.state.layout.menubar.isPhone) {
                component = components[`${ctrl}__m_${action}`] || components[`${ctrl}__${action}`]
            } else {
                component = components[`${ctrl}__${action}`] || components[`${ctrl}__m_${action}`]
            }
            var env_page_config: any = null
            // 两个都有
            if (components[`${ctrl}__m_${action}`] && components[`${ctrl}__${action}`]) {

                env_page_config = {
                    desktop: {
                        component: components[`${ctrl}__${action}`],
                        show_navbar: true
                    },
                    mobile: {
                        component: components[`${ctrl}__m_${action}`],
                        show_navbar: false
                    }
                }
            }

            var sub_page_config: any = (<any>path_sub_page_config)[path] || {}

            var default_page_type = sub_page_config["_config_"]?.default_page_type
            var show_navbar = true
            if (default_page_type == "mobile") {
                show_navbar = false
            } else {
                show_navbar = (component == components[`${ctrl}__${action}`])
            }
            ret = {
                path: path,
                component: component || components["404"],
                name: node_name,
                hidden: menu_node["hidden"],
                meta: {
                    icon: icon,
                    title: menu_node["name"],
                    active_path: menu_node["active_path"],
                    url: url,
                    show_navbar: show_navbar
                }
            }



            // 附加只节点
            if (env_page_config) {
                sub_page_config = Object.assign(sub_page_config, env_page_config)
            }

            var sub_page_list: Array<any> = []

            if (sub_page_config) {

                for (var sub_path in sub_page_config) {

                    var sub_path_item = sub_page_config[sub_path]
                    sub_page_list.push({
                        path: `${path}/${sub_path}`,
                        component: sub_path_item.component,
                        name: `${path}/${sub_path}`,
                        hidden: true,
                        meta: {
                            title: sub_path_item.title,
                            active_path: menu_node["active_path"] || path,
                            url: `${url}/${sub_path}`,
                            show_navbar: sub_path_item.show_navbar || false
                        }
                    })
                }

                ret = {
                    path: "",
                    component: components["LayoutBlank"],
                    name: `/${node_name}`,
                    hidden: false,
                    meta: {
                        title: ""
                    },
                    children: [ret].concat(...sub_page_list)
                }
            }
            if (level == 1) { // 顶级
                ret = {
                    component: components["Layout"],
                    path: "/__01",
                    name: "sys",
                    open_flag: menu_node["open_flag"],

                    meta: {
                        title: ""
                    },
                    children: [ret]
                }
            }

            return ret

        } else {
            return {


                path: `/__JUMP__${common.Base64.encodeURL(menu_node["url"])}`,
                name: node_name,
                hidden: menu_node["hidden"],
                meta: {
                    other_url_flag: true,
                    icon: icon,
                    title: menu_node["name"],
                    active_path: menu_node["active_path"],
                    url: url
                },
                redirect: menu_node["url"]
            }
        }
    }
}
