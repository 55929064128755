import { defineComponent, nextTick } from "vue"
import common from "/@/utils/common"
import sys_util from "/@/utils/sys_util"


interface IProps {
    use_search_flag: boolean
    use_self_flag: boolean
    start_level_value_list: Array<any>
    field_name: string
    on_cascader_change_func: Function,
    endpoint_level: number
    modelValue: Array<any>
}





export default defineComponent({
    components: {
    },

    props: {

        modelValue: Array,
        field_name: String,
        placeholder: {
            type: String,
            default: function() {
                return "全部"
            },
            required: false
        },

        // 开始
        start_level_value_list: {
            type: Array,
            default: function() {
                return []
            },
            required: false
        },

        on_cascader_change_func: {
            type: Function,
            required: false
        },
        endpoint_level: {
            type: Number,
            default: function() {
                return 0
            },
            required: false
        },

        change_on_select: {
            type: Boolean,
            default: function() {
                return false
            },
            required: false
        },
        use_self_flag:
        {
            type: Boolean,
            default: function() {
                return true
            },
            required: false
        },
        use_search_flag:
        {
            type: Boolean,
            default: function() {
                return true
            },
            required: false
        }


    },
    // event
    emits: ["update:modelValue", "query"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props,

            // 自定义的

            data_last_select_value: <any>null


        }
    },

    data: function() {
        return {

            data_cascader_value: <Array<any>>[],
            init_data_cascader_succ_flag: false,

            data_options: <Array<any>>[],
            width: common.in_phone() ? "180px" : "300px",
            cascader_props: {
                multiple: false,
                checkStrictly: true
            }
        }
    },

    watch: {
        data_cascader_value: {

            handler: function(new_val: any, _old_val: any) {
                console.log("KKKK", JSON.stringify(new_val), JSON.stringify(_old_val))
                if (this.$data.init_data_cascader_succ_flag == false) {
                    this.$data.init_data_cascader_succ_flag = true
                    return
                }
                this.on_cascader_change(new_val)
            },

            immediate: false
        },


        modelValue: {
            handler: function() {
                this.set_data_cascader_value()

            },
            immediate: false
        }


    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        set_data_cascader_value: function() {
            // 前缀去掉
            const me = (this)
            const { modelValue } = this.props


            if (me.check_show_all_level()) {
                if (modelValue.length > 0 && modelValue[0] == "-3") { // self
                    me.$data.data_cascader_value = ["-3"]
                } else if (modelValue.length > 0 && modelValue[0] == "-2") {
                    me.$data.data_cascader_value = ["-2"]
                } else if (modelValue.length == 1 && modelValue[0] == "0") {
                    // me.data_cascader_value = ['0']
                } else if (this.check_mutli_start_level()) {
                    me.data_cascader_value = ["-1"].concat(modelValue)
                } else {
                    me.data_cascader_value = ["-1"].concat(modelValue.slice(me.props.start_level_value_list.length))
                }

            } else if (this.check_mutli_start_level()) {
                me.$data.data_cascader_value = modelValue
            } else {
                me.$data.data_cascader_value = modelValue.slice(me.props.start_level_value_list.length)
            }

        },
        check_show_all_level() {
            return this.props.use_search_flag || this.props.use_self_flag || common.check_url_desc_power("opt_admin")
        },

        check_mutli_start_level() {
            return sys_util.isArray(this.props.start_level_value_list[0])
        },

        query() {

            const modelValue = this.data_cascader_value || []
            // 可能选择特殊选项
            if (modelValue.length == 1) {
                var check_value = modelValue[0]
                if (check_value == -5) { // 模糊搜索
                    return
                }
            }


            let ret: Array<any> = []
            if (this.check_show_all_level() && modelValue.length > 0) {
                var check_value = modelValue[0]
                if (check_value == "-3" || check_value == "0" || check_value == "-2") { // 自己,未设置,已设置
                    ret = [check_value] //
                } else {
                    // 去掉第一项
                    if (this.check_mutli_start_level()) {
                        ret = modelValue.slice(1)
                    } else {
                        ret = this.props.start_level_value_list.concat(modelValue.slice(1))
                    }

                }
            } else if (this.check_mutli_start_level()) {
                ret = modelValue
            } else {
                ret = this.props.start_level_value_list.concat(modelValue)
            }
            this.$emit("update:modelValue", ret)

            this.$emit("query", {
                field_name: this.props.field_name
            })
            console.log("this.props.on_cascader_change_func", this.props.on_cascader_change_func)
            if (this.props.on_cascader_change_func) {
                this.props.on_cascader_change_func()
            }


        },
        init_options() {
            const options: Array<any> = [{
                label: "全部",
                value: "-1",
                children: undefined
            }]
            if (this.props.use_self_flag) {
                options.push({
                    label: "[自己]",
                    value: "-3"
                })
            }
            options.push({
                label: "[未设置]",
                value: "0"
            })
            options.push({
                label: "[已设置]",
                value: "-2"
            })


            if (this.props.use_search_flag) {
                options.push({
                    label: "[模糊查询]",
                    value: "-5"
                })
            }
            this.$data.data_options = options


        },
        load_options() {
            const me = this
            if (!me.data_options[0].children) {

                let start_level_value_list = ""
                if (common.check_url_desc_power("opt_admin")) {
                    start_level_value_list = ""
                } else if (sys_util.isArray(me.props.start_level_value_list[0])) {
                    const item_list: Array<any> = []
                    me.props.start_level_value_list.forEach(function(item: any) {
                        item_list.push(item.join(","))
                    })
                    start_level_value_list = item_list.join(";")
                } else {
                    start_level_value_list = me.props.start_level_value_list.join(",")
                }


                common.do_ajax("/route__php_common/ajax_deal/get_admin_group_for_select", {
                    endpoint_level: me.props.endpoint_level,
                    start_level_value_list: start_level_value_list
                }, function(resp: any) {
                    me.data_options[0].children = resp.data
                    me.merger_node(me.data_options[0])
                })
            }
        },
        // 合并单独节点
        merger_node(node: any) {
            if (!node.children) {
                return node

            } else {
                node.children = (<Array<any>>node.children).map((sub_node) => {
                    return this.merger_node(sub_node)

                })

                if (node.children.length == 1) {
                    var tmp_value = node.value
                    node = node.children[0]
                    node.value = `${tmp_value},${node.value}`
                }
                return node
            }

        },

        on_created() {
            this.set_data_cascader_value()
            this.$data.init_data_cascader_succ_flag = false
            nextTick(() => {
                nextTick(() => {
                    this.$data.init_data_cascader_succ_flag = true
                })
            })
        },
        on_mounted() {
            this.init_options()
            if (this.data_cascader_value.length > 1) {
                this.load_options()
            }
        },

        on_cascader_menu_create(flag: boolean) {

            if (flag) {
                this.load_options()
            }
        },




        // 检查 最终节点
        check_end_node(value_list: Array<any>, tree_node_list: Array<any>): any {
            const check_value = value_list.shift()
            let find_item: any = null
            tree_node_list.findIndex(function(item) {
                if (item.value == check_value) {
                    find_item = item
                    return true
                } else {
                    return false
                }
            })
            if (find_item !== null) {
                if (value_list.length == 0) {
                    return find_item["children"] === undefined
                } else {
                    return this.check_end_node(value_list, find_item["children"])
                }

            } else {
                return false
            }

        },

        get_select_tree_desc(value_list: Array<any>, tree_node_list: Array<any>): any {
            const check_value = value_list.shift()
            let find_item: any = null
            tree_node_list.findIndex(function(item) {
                if (item.value == check_value) {
                    find_item = item
                    return true
                } else {
                    return false
                }
            })
            if (find_item !== null) {
                if (value_list.length == 0) {
                    return find_item.label
                } else {
                    return this.get_select_tree_desc(value_list, find_item["children"])
                }

            } else {
                return "[未知]"
            }

        },

        on_cascader_change(cur_value: any) {

            // 可能选择特殊选项
            if (this.$data.data_cascader_value && this.$data.data_cascader_value.length == 1) {
                const check_value = this.$data.data_cascader_value[0]
                if (check_value == -5) {
                    this.show_query_dlg()
                    return
                }
            }


            // 两次一样,直接查询: 选择组织时,点击两次
            if (!common.array.equals(cur_value, this.data_last_select_value)) {

                this.data_last_select_value = cur_value

                if (this.$data.data_cascader_value && this.$data.data_cascader_value.length > 1) {
                    const tmp_value_list = common.copy_obj(this.$data.data_cascader_value)
                    const desc_str = this.get_select_tree_desc(tmp_value_list, this.data_options)
                    this.add_lru(desc_str, JSON.stringify(this.$data.data_cascader_value))
                }

                this.query()
                console.log("cascader", this.$refs.cascader);
                (<any>this.$refs.cascader)["togglePopperVisible"](false)
                return
            }

        },
        get_query_list_options(query_str: string) {
            const ret_list: Array<any> = []
            query_str = query_str.toLowerCase()

            var deal_next_node = function(node: any, fix_value_arr: any, fix_str: any) {

                const cur_fix_value_arr = fix_value_arr.slice()
                cur_fix_value_arr.push(node.value)

                fix_str += node.label

                if (node.label) {
                    if ((<string>node.label).toLowerCase().indexOf(query_str) !== -1) {
                        ret_list.push({
                            key: node.label,
                            desc: fix_str,
                            value: cur_fix_value_arr
                        })
                    }
                }
                if (node.children) {
                    (<Array<any>>node.children).forEach(function(sub_node) {

                        deal_next_node(sub_node, cur_fix_value_arr, `${fix_str}/`)
                    })
                }

            }
            const all_node = this.data_options.filter(function(item) {
                return item.value == -1
            })[0]
            if (all_node.children) {
                all_node.children.forEach(function(sub_node: any) {
                    deal_next_node(sub_node, [], "")
                })
            }


            return ret_list
        },

        add_lru(lru_id: string, lru_id_desc: string) {
            const lru_list_url = "/route__php_common/ajax_deal/get_account_lru_list"
            common.do_ajax(lru_list_url, {
                opt_type: "add",
                field_name: "admin_group",
                lru_id_desc: lru_id_desc,
                lru_id_name: "",
                lru_id: lru_id
            })
        },


        show_query_dlg() {
            const query_filter_item_list = [{
                field_type: "autocomplete",
                field_name: "admin_group_query_text",
                label: "模糊查询",
                value: ""
            }]
            const column_list = [
                { label: "关键字", prop: "key" },
                { label: "说明", prop: "desc" }
            ]
            const data = this.get_query_list_options("").slice(0, 10)
            console.log("data-------", JSON.stringify(data))
            const me = this
            const lru_list_url = "/route__php_common/ajax_deal/get_account_lru_list"

            var dlg = common.dlg.admin_ajax_table_select("", {}, query_filter_item_list, column_list, function(row, use_lru_flag) {
                if (use_lru_flag) {
                    me.$data.data_cascader_value = JSON.parse(row.desc)
                    me.add_lru(row.id, row.desc)
                    me.query()

                } else if (sys_util.isArray(row.value)) {
                    me.$data.data_cascader_value = row.value
                    me.$data.data_cascader_value.unshift("-1")

                    me.add_lru(row.key, JSON.stringify(me.$data.data_cascader_value))
                    me.query()
                }
            },
            {
                data: data,
                lru_list_field_name: "admin_group",
                lru_list_url: lru_list_url,
                width: "800px",



                with_ajax_flag: false,
                on_query: function(args: any) {
                    const query_txt = $.trim(args.admin_group_query_text)
                    const vue_data = dlg.get_vue_data()
                    if (query_txt == "") {
                        vue_data.data = me.get_query_list_options(query_txt).slice(0, 10)
                    } else {
                        vue_data.data = me.get_query_list_options(query_txt)
                    }
                }

            })

        },

        on_cascader_visible_change(_flag: boolean) {

        }
    }

})
