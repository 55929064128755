import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import { IMenubarList } from "/@/type/store/layout"

const components: IObject<() => Promise<typeof import("*.vue")>> = {
    Layout: (() => import("/@/layout/index.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    index: (() => import("/@/views/index/index.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    Redirect: (() => import("/@/layout/redirect.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    LayoutBlank: (() => import("/@/layout/blank.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    404: (() => import("/@/views/ErrorPage/404.vue")) as unknown as () => Promise<typeof import("*.vue")>,

    login: (() => import("/@/views/login/index.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    login__register: (() => import("/@/views/login/register.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    account_login: (() => import("/@/views/login/account_login.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    common__batch_dp_info: (() => import("/@/views/common/batch_dp_info.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    common__batch_dy_info: (() => import("/@/views/common/batch_dy_info.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    common__case_dy_info: (() => import("/@/views/common/case_dy_info.vue")) as unknown as () => Promise<typeof import("*.vue")>,
    main_page__mobile: (() => import("/@/views/main_page/mobile.vue")) as unknown as () => Promise<typeof import("*.vue")>

}

// 静态路由页面
export const allowRouter: Array<IMenubarList> = [

    {
        name: "ErrorPage",
        path: "/ErrorPage",
        meta: { title: "公共", icon: "el-icon-eleme" },
        component: components["Layout"],
        redirect: "/ErrorPage/404",
        hidden: true,

        children: [
            {
                name: "404",
                path: "/ErrorPage/404",
                hidden: true,
                component: components["404"],
                meta: { title: "404", icon: "el-icon-s-tools" }
            }, {
                name: "blank",
                path: "/blank",
                hidden: true,
                component: components.LayoutBlank,
                meta: { title: "空白", icon: "el-icon-s-tools" }
            }
        ]
    },
    {
        name: "RedirectPage",
        path: "/redirect",
        component: components["Layout"],
        meta: { title: "重定向页面", icon: "el-icon-eleme" },
        hidden: true,
        children: [
            {
                name: "Redirect",
                path: "/redirect/:pathMatch(.*)*",
                meta: {
                    title: "重定向页面",
                    icon: ""
                },
                component: components.Redirect
            }, {
                name: "mobile",
                path: "/main_page/mobile",
                component: components.main_page__mobile,
                meta: {
                    title: "移动端首页",
                    icon: ""
                }

            }
        ]
    }, {
        name: "login",
        path: "/login",
        component: components.login,
        meta: { title: "登录", icon: "el-icon-eleme" },
        hidden: true
    }, {
        name: "login/register",
        path: "/login/register",
        component: components.login__register,
        meta: { title: "注册", icon: "el-icon-eleme" },
        hidden: true

    }, {
        name: "/common/batch_dp_info",
        path: "/common/batch_dp_info",
        component: components.common__batch_dp_info,
        meta: { title: "幸哉达人展示", icon: "el-icon-eleme" },
        hidden: true


    }, {
        name: "/common/batch_dy_info",
        path: "/common/batch_dy_info",
        component: components.common__batch_dy_info,
        meta: { title: "幸哉达人展示", icon: "el-icon-eleme" },
        hidden: true
    },{
        name: "/common/case_dy_info",
        path: "/common/case_dy_info",
        component: components.common__case_dy_info,
        meta: { title: "幸哉达人展示", icon: "el-icon-eleme" },
        hidden: true
    }, {
        name: "account_login",
        path: "/account_login",
        component: components.account_login,
        meta: { title: "跳转", icon: "el-icon-eleme" },
        hidden: true


    }, {
        name: "index",
        path: "/index/index",
        component: components.index,
        meta: { title: "主页", icon: "el-icon-eleme" },
        hidden: true

    }

    /*
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: components['404'],
        hidden: true,
        meta: {
            title: 'NotFound',
            icon: '',
        },
        redirect: {
            name: '404'
        }
    }
    */

]

const router = createRouter({
    history: createWebHashHistory(), // createWebHistory
    routes: allowRouter as RouteRecordRaw[]
})

export default router
