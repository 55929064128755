import common, { ON_UPDATE_MODEL_EVENT } from "/@/utils/common"
import { getToken } from "/@/utils/auth"
import util from "/@/utils/sys_util"
import noti_message from "@/utils/noti_message"

import ElementPlus from "element-plus"
import FileSaver from "file-saver"

// import query_input_list from "./query_inputlist"
import { createApp, defineComponent, h, nextTick, ref, toRaw } from "vue"
import { ElButton, ElCol, ElDropdown, ElDropdownItem, ElDropdownMenu, ElLoading, ElMessageBox, ElPagination, ElRow, ElTable, ElTableColumn } from "element-plus"
import { admin_row_option_list, admin_config_item_table, bind_component } from "/@/admin_component"
import sys_util from "/@/utils/sys_util"

interface IProps {
    select_type: string
    table_height: string
    reset_table_height_flag: boolean
    fixed: boolean
    use_table_height_flag: boolean
    config_field_select_flag: boolean
    show_query_input: boolean
    type: string
    default_sort: any
    default_page_size: number
    row_opt_btn_config: Array<any>
    data: Array<any>
    column_list: Array<any>
    query_filter_item_list: Array<any>
    use_url_args_and_page_config: boolean
    bar_layout: string
    url: string
    autoload_on_mounted: boolean
    config_fix: string
    set_url_history: boolean
    with_ajax_flag: boolean
    url_args_ex: any
    deal_ajax_result_func: Function
    show_download: boolean
    show_option_list: boolean
    data_show_table_flag: boolean
}


export default defineComponent({
    name: "AdminTable",
    directives: {
        loading: ElLoading.directive

    },
    components: {
        ElRow,
        ElTable,
        ElTableColumn,
        ElCol,
        ElButton,
        ElDropdown,
        ElDropdownItem,
        ElDropdownMenu,
        ElPagination,
        "admin-row-option-list": admin_row_option_list
    },

    props: {

        // 是否开启配置
        config_field_select_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        // 是否显示 query input
        show_query_input: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }

        },
        default_page_size: {
            type: Number,
            required: false,
            default: function() {
                return 10
            }
        },

        page_size_list: {
            type: Array,
            required: false,
            default: function() {
                return [10, 20, 50, 100, 500 ]
            }

        },

        show_query_button: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },


        show_download: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        // 是否立即查询
        query_at_time_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        col_span: {
            type: Number,
            required: false,
            default: function() {
                return 24
            }
        },

        bar_layout: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },

        autoload_on_mounted: { // 是否自动加载数据
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        show_table_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        set_url_history: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        default_sort: {
            type: Object,
            required: false,
            default: function() {
                return {
                    prop: "",
                    sort: "asc"
                }
            }
        },

        // 下载权限
        download_power: {
            type: Object,
            required: false,
            default: function() {
                return {
                    url: "",
                    power_flag: ""
                }
            }
        },
        reset_table_height_flag: {

            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        opt_min_width: {
            type: String,
            required: false,
            default: function() {
                return "200px"
            }
        },


        show_query_config: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        show_option_list: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        show_option_max_row_count: {
            type: Number,
            required: false,
            default: function() {
                return 101
            }

        },

        data: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        },
        type: { // table, tree-table
            type: String,
            required: false,
            default: function() {
                return "table"
            }
        },

        query_filter_item_list: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        },

        url: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        config_fix: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },


        url_args_ex: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },
        with_ajax_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        deal_ajax_result_func: {

            type: Function,
            required: false,
            default: undefined
        },
        table_row_class_name: {

            type: Function,
            required: false,
            default: undefined
        },
        cell_style: {

            type: Function,
            required: false,
            default: undefined
        },
        row_style: {
            type: Function,
            required: false,
            default: undefined
        },

        header_row_style: {
            type: Function,
            required: false,
            default: undefined

        },



        row_opt_btn_config: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        },


        column_list: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        },

        use_table_height_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },

        // 行选择类型
        select_type: {
            type: String, // list , single_select, multi_select
            required: false,
            default: function() {
                return "list"
            }
        },

        fixed: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },


        use_url_args_and_page_config: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        use_page_bar: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        table_height: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        }


    },
    emits: ["loading_change", "query", "select", "row-click"],
    setup: function(props: any, _ctx: any) {


        return {
            props: <IProps>props,

            // 自定义的
            store_key_option_selected: "option_selected_list",
            store_key_field_selected: "field_selected_list",
            store_key_field_width: "filed_width_list",
            store_key_use_table_height: "use_table_height",
            store_key_table_fixed: "table_fixed",

            // 字段宽度配置
            field_width_map: <any>{},

            // 当前 ajax 加载的 index ,防止数据加载顺序不一致
            cur_ajax_load_index: 0,

            data_show_table_flag: true

        }
    },

    data: function() {
        return {
            config_field_selected_list: <Array<any>>[],
            config_option_selected_list: <Array<any>>[],
            loading_text: "加载中",
            data_use_page_bar: false,
            table_class: `admin_table_${Math.floor(Math.random() * 1000000)}`,
            in_phone: common.in_phone(),
            total: 0,
            cur_column_list: <Array<any>>[],
            listLoading: false,
            page_size: 10,
            page: 1,
            data_list: <Array<any>>[],
            tree_data_list: <Array<any>>[],
            sort: "",
            order: "asc",
            data_table_height: <any>undefined,
            use_table_height: true,
            table_fixed: true,
            bar_layout_info: "",
            selected_list: <Array<any>>[],
            error_msg_list: <Array<any>>[],
            download_all_data: {
                url: "",
                query_args: <any>{},
                column_list: <any>{},
                title: "",
                down_type: 1
            }
        }
    },
    computed: {
        row_opt_col_width() {
            const opt_count = this.props.row_opt_btn_config.length
            if (opt_count == 0) {
                return 60
            } else if (opt_count == 1) {
                return 120
            } else {
                return null
            }

        }
    },

    watch: {
        total() { // 防止 page 无效
            var tmp_page = this.$data.page
            this.$data.page = 0
            nextTick(() => {
                this.$data.page = tmp_page
            })
        },

        data_list(_new_val: any) {
            const me = this
            if (me.props.select_type == "single_select") {
                if (me.$data.data_list.length == 1) {
                    (<any>me).select(me.$data.data_list[0])
                }
            }
        },


        default_sort(_new_val: any) {
            const me = this
            me.$data.sort = me.props.default_sort.prop
            me.$data.order = me.props.default_sort.order
        },

        config_field_selected_list(new_val: any) {
            this.set_config_field_select_list(new_val)
        },

        config_option_selected_list(new_val: any) {
            this.set_option_field_select_list(new_val)
        },
        use_table_height: function(new_val) {

            this["set_use_table_height"](new_val)
            this["reset_table_height"]()
        },

        table_fixed: function(new_val) {
            this["set_table_fixed"](new_val)
        },
        data: function(new_val) {
            this.$data.data_list = new_val
        },

        column_list: function(new_val) { // for table query.ts
            this.$data.cur_column_list = new_val
            this.$data.config_field_selected_list = this.get_config_field_selected_list()
        }

    },
    created() {

        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        check_tree_table() {
            return this.props.type === "tree-table"
        },
        tree_show(all_flag: boolean) {

            if (all_flag) {
                this.$data.data_list = this.$data.tree_data_list
                nextTick(() => {

                    const { treeData } = (<any>this.$refs.table)["store"]["states"]
                    console.log(treeData)
                    for (const index in treeData) {
                        const item = treeData[index]
                        item.display = true
                        if (item.children) {
                            item.expanded = true
                        }
                    }
                    nextTick(() => {
                        (<any>this.$refs.table)["doLayout"]()
                    })
                })
            } else {
                this.get_init_tree(this.$data.tree_data_list)
            }

        },


        load_config() {

            this.field_width_map = this.page_store_get_key(this.store_key_field_width, {})

            this.$data.sort = this.props.default_sort.prop
            this.$data.order = this.props.default_sort.order
            this.$data.page_size = this.props.default_page_size
            if (this.$data.order == "ascending") {
                this.$data.order = "asc"
            } else if (this.$data.order == "descending") {
                this.$data.order = "desc"
            }
            common.gen_row_opt_component(this.props.row_opt_btn_config)

            if (this.props.data) {
                this.$data.data_list = this.props.data
            }
            const me = this

            this.$data.cur_column_list = this.props.column_list;
            (<Array<any>>this.$data.cur_column_list).forEach(function(item: any) {
                if (item.filter_enum_type) {
                    const check_field_name = item.prop
                    const find_index = (<Array<any>>me.props.query_filter_item_list).findIndex(function(query_item: any) {
                        return query_item.field_name == check_field_name
                    })
                    if (find_index === -1) {
                        (<Array<any>>me.props.query_filter_item_list).push({
                            label: check_field_name,
                            field_type: "text",
                            field_name: check_field_name,
                            query_from_table: true,
                            value: item.filter_default_value === undefined ? -1 : item.filter_default_value,
                            always_hide: true
                        })
                    }
                }

            })
            console.log("KK********KKKK", this.$data.cur_column_list);

            //
            (<Array<any>>this.props.query_filter_item_list).forEach(function(item: any) {
                if (item.field_type == "date" && !item.field_name) {
                    item.field_name = "date"
                }
            })


            if (common.get_account_role() == common.enum_map.account_role.V_DEVELOP) {
                (<Array<any>>me.props.query_filter_item_list).push({
                    label: "where语句",
                    field_type: "text",
                    field_name: "_where_ex",
                    value: "",
                    default_show: false
                })
            }



            this.$data.config_field_selected_list = this.get_config_field_selected_list()
            this.$data.config_option_selected_list = this.get_config_option_selected_list()
            // 处理 args
            if (this.props.use_url_args_and_page_config) {
                const args = common.get_query_args()
                if (args.page_count > 0) {
                    this.$data.page_size = parseInt(args.page_count)
                }

                if (args.page_num > 0) {
                    this.$data.page = parseInt(args.page_num)
                    this.$data.total = this.$data.page_size * this.$data.page
                }


                if (args.sort) {
                    this.$data.sort = args.sort
                    this.$data.order = args.order
                }
            }


        },
        on_created() {




            this.load_use_table_height()


            if (this.props.bar_layout) {
                this.$data.bar_layout_info = this.props.bar_layout
            } else if (common.in_phone()) {
                this.$data.bar_layout_info = " total,  jumper, prev, next , sizes"
            } else {
                this.$data.bar_layout_info = "total, sizes,  prev, pager,   next, jumper"
            }


            this.load_config()


        },

        get_ajax_url() {
            let path: string = this.props.url
            if (!path) {
                path = common.get_location_path()
                var arr = path.split("/")
                if (arr[0].match(/route__/)) {
                    arr.shift()
                }
                if (arr.length == 4 && arr[3] == "desktop") {
                    path = path.replace(/\/desktop$/, "")
                }

            }
            return path
        },



        set_table_fixed(val: any) {
            // if (val != this.props.fixed) {
            this.page_store_set_key(this.store_key_table_fixed, val)
            // }
        },

        set_use_table_height(val: any) {
            // if (val != this.props.use_table_height_flag) {
            this.page_store_set_key(this.store_key_use_table_height, val)
            // }
        },

        load_use_table_height() {
            this.$data.use_table_height = this.page_store_get_key(this.store_key_use_table_height, this.props.use_table_height_flag)
            this.$data.table_fixed = this.page_store_get_key(this.store_key_table_fixed, this.props.fixed)
        },


        reset_table_height() {
            if (!this.props.reset_table_height_flag) {
                if (this.props.table_height != "") {
                    this.$data.data_table_height = this.props.table_height
                } else {

                    this.$data.data_table_height = ""
                }
                return
            }

            let use_table_height_show_flag = this.$data.use_table_height
            if (!this.props.use_table_height_flag) {
                use_table_height_show_flag = false
            }
            if (!common.in_phone() && use_table_height_show_flag && this.props.table_height == "") {
                const vnode = (<any>this.$refs.table)["$vnode"]
                if (vnode) {
                    // top offset
                    nextTick(() => {

                        const top_offset = 80 + (<any>this.$refs.query_input_list)["$vnode"].elm.offsetHeight

                        let real_height = window.innerHeight - vnode.elm["offsetTop"] - top_offset
                        if (real_height < 500) {
                            real_height = 500
                        }
                        this.$data.data_table_height = real_height

                    })
                }


            } else if (this.props.table_height != "") {
                this.$data.data_table_height = this.props.table_height
            } else {

                this.$data.data_table_height = undefined
            }

        },

        on_mounted() {
            this.reset_table_height()
            if (this.props.autoload_on_mounted) {
                var page_num = common.get_query_args().page_num ?? 1
                this.load(page_num)
            }
        },

        get_config_option_selected_list() {

            let save_ret: Array<any> = []
            save_ret = this.page_store_get_key(this.store_key_option_selected, [])

            const selected_map: any = {}
            const all_map: any = {}
            const me = this
            const ret: Array<any> = []

            this.props.row_opt_btn_config.forEach(function(item: any, _i: any) {
                all_map[item.opt_key] = true
            })
            // 得到有效的列表
            save_ret.forEach(function([field_name, show_flag]: any, _i: any) {
                if (all_map[field_name]) {
                    selected_map[field_name] = show_flag
                }
                ret.push([field_name, show_flag])
            })




            this.props.row_opt_btn_config.forEach(function(item: any, _i: any) {
                const field_name = item.opt_key
                if (selected_map[field_name] === undefined) { //
                    let { default_show } = item
                    if (default_show === undefined) {
                        default_show = true
                    } else if (default_show === "desktop" && !me.$data.in_phone) { //
                        default_show = true
                    }
                    ret.push([field_name, default_show])

                }
            })


            // 检查一下

            return ret
        },
        get_config_field_selected_list() {

            let save_ret: Array<any> = []
            if (this.props.config_field_select_flag) {
                save_ret = this.page_store_get_key(this.store_key_field_selected, [])
            }

            const { cur_column_list } = this.$data

            const selected_map: any = {}
            const column_map: any = {}
            const me = this
            const ret: Array<any> = []

            cur_column_list.forEach(function(item: any, _i: any) {
                column_map[item.label] = item
                item.config_visible = false

            })
            // 得到有效的列表
            save_ret.forEach(function([field_name, show_flag]: any, _i: any) {
                if (column_map[field_name]) {
                    selected_map[field_name] = show_flag
                }
                ret.push([field_name, show_flag])
            })




            cur_column_list.forEach(function(item: any, _i: any) {
                const field_name = item.label
                if (selected_map[field_name] === undefined) { // 没有配置
                    let { default_show } = item
                    if (default_show === undefined) {
                        default_show = true
                    } else if (default_show === "desktop" && !me.$data.in_phone) { //
                        default_show = true
                    }
                    ret.push([field_name, default_show])
                }

            })

            // 设置宽度
            return ret
        },

        change_option_show(select_list: any) {
            const cur_list = this.props.row_opt_btn_config
            const selected_map: any = {}

            const all_map: any = {}




            cur_list.forEach(function(item: any, _i: any) {
                all_map[item.opt_key] = item
            })
            select_list.forEach(function([label, check_flag]: any, _i: any) {
                if (check_flag) {
                    const item = all_map[label]
                    if (item) {
                        selected_map[item.opt_key] = true
                    }
                }
            })

            const selected_list: Array<any> = []
            select_list.forEach(function([label, check_flag]: any, _i: any) {

                if (check_flag) {
                    const item = all_map[label]
                    if (item) {
                        selected_list.push([item.opt_key, true])
                    }
                }
            })

            cur_list.forEach(function(item: any, _i: any) {
                if (!selected_map[item.opt_key]) {
                    selected_list.push([item.opt_key, false])
                }
            })

            return selected_list
        },


        change_field_show(select_list: any) {
            const { cur_column_list } = this.$data
            const selected_map: any = {}

            const column_map: any = {}

            cur_column_list.forEach(function(item: any, _i: any) {
                column_map[item.label] = item
            })

            const selected_list: Array<any> = []
            const ret_list: Array<any> = []
            select_list.forEach(function([label, select_flag]: any, _i: any) {
                if (select_flag) {
                    const item = column_map[label]
                    if (item) {
                        ret_list.push(item)
                        // 计算
                        item.config_visible = true
                        selected_map[item.label] = true
                        selected_list.push([item.label, true])
                    }
                }
            })

            cur_column_list.forEach(function(item: any, _i: any) {
                if (!selected_map[item.label]) {
                    item.config_visible = false
                    ret_list.push(item)
                    selected_list.push([item.label, false])
                }

            })



            const me = this
            ret_list.forEach(function(item: any, _i: any) {
                if (item.filter_enum_type) {
                    const index = (<Array<any>>me.props.query_filter_item_list).findIndex(function(q_item: any) {
                        return q_item["field_name"] == item.prop
                    })
                    if (index !== -1) {
                        item.filter_query_item = me.props.query_filter_item_list[index]
                    }
                }
            })

            this.$data.cur_column_list = ret_list


            this.reset_cur_column_width()


            // HACK  更新界面
            const { data_list } = this.$data
            this.$data.data_list = []
            nextTick(() => {
                this.$data.data_list = data_list
            })


            return selected_list
        },

        reset_cur_column_width() {
            const me = this

            this.$data.cur_column_list.forEach(function(item: any, _i: any) {
                item.real_width = me.field_width_map[item.label]
            })


        },
        check_page_store_key_opt_flag(_key: string): boolean {
            if (this.props.use_url_args_and_page_config) {
                return true
            }
            return false
        },
        page_store_get_key(key: string, default_value: any) {


            if (!this.check_page_store_key_opt_flag(key)) {
                return default_value
            }

            return common.page_store_get_key(key, default_value, this.props.config_fix, this.get_ajax_url())

        },

        page_store_set_key(key: string, value: any, save_now_flag?: any) {

            if (!this.check_page_store_key_opt_flag(key)) {
                return null
            }

            return common.page_store_set_key(key, value, this.props.config_fix, this.get_ajax_url(), save_now_flag)
        },


        set_option_field_select_list(val: any) {
            this.change_option_show(val)
        },





        set_config_field_select_list(val: any) {
            this.change_field_show(val)
        },


        get_option_field_list() {
            const ret: Array<any> = []
            this.props.row_opt_btn_config.forEach(function(item: any, _i: any) {
                const title = util.isString(item.title) ? item.title : item.default_title
                const text = util.isString(item.text) ? item.text : ""
                const icon = util.isString(item.icon) ? item.icon : ""
                ret.push({
                    key: item.opt_key,
                    label: `${title}-${text}`,
                    icon: icon
                })
            })
            return ret
        },

        get_config_field_list() {
            const ret: Array<any> = []
            this.$data.cur_column_list.forEach(function(item: any, _i: any) {
                ret.push({
                    key: item.label,
                    label: item.label
                })
            })
            return ret
        },

        get_col_bind(col: any) {
            const bind = Object.assign({}, col)
            if (bind.sortable === true) {
                bind.sortable = "custom"
            }

            if (bind.sortable === "local") {
                bind.sortable = true
            }


            delete bind.component
            delete bind.listeners
            return bind
        },

        get_cpt_bind({ row, column }: any, col: any) {
            let props = { col, row, column }
            if (col.component_props) {
                props = Object.assign(toRaw(col.component_props), props)
            }

            // props = Object.assign({ row: row }, props)
            return props
        },
        get_opt_bind(score: any) {
            const { row } = score
            const { column } = score
            const index = score.$index
            const props = { row, column, index }
            return props
        },

        onQuery(args: any) {
            this.load(1)
            this.$emit("query", args)
        },
        set_query_str(args: any) {
            const ret_args: any = {}
            for (const k in args) {
                const v = args[k]
                if (util.isArray(v)) {
                    ret_args[k] = v.join(",")
                } else {
                    ret_args[k] = v
                }
            }


            const query_str = common.queryString.stringify(ret_args)

            const url = `/${window.location.hash.replace(/\?.*/, "")}?${query_str}`
            if (this.props.set_url_history) {
                window.history.replaceState(null, "", url)
            }
        },
        reload_config_and_data() {
            this.load_config()
            nextTick(() => {
                nextTick(() => {
                    this.load()
                })
            })
        },
        get_init_tree(tree_data_list: any) {
            const me = this
            me.$data.data_list = [];
            (<Array<any>>tree_data_list).forEach(function(item) {
                const tmp_item = common.copy_obj(item)
                if (tmp_item["children"]) {
                    delete tmp_item["children"]
                    tmp_item["hasChildren"] = true
                }
                me.$data.data_list.push(tmp_item)
            })
            // 去多余 张开 按钮
            nextTick(function() {
                const table_item: Element = document.querySelector(`.${me.$data.table_class}`)!
                table_item.querySelectorAll(".el-table__expand-icon").forEach(function(html_item: any) {
                    html_item["style"].display = "none"
                })
            })

        },


        load(page_num?: any, load_succ_callback?: any) {
            if (!this.props.with_ajax_flag) {
                return
            }
            const args: any = {
                page_num: this.$data.page,
                page: this.$data.page,
                page_count: this.$data.page_size,
                sort: this.$data.sort,
                order: this.$data.order
            }
            const me = this
            if (page_num) {
                args.page_num = page_num
                args.page = page_num
                this.$data.page = page_num
            }
            Object.assign(args, me.props.url_args_ex)
            // 处理参数

            if (page_num == 0xFFFFFFFF + 2) {
                if (this.$refs.query_input_list) {
                    var query_input_args = (<any>this.$refs.query_input_list).get_args()
                    Object.assign(args, query_input_args)
                }

                window.open(`${common.get_api_url()}/cc${this.get_ajax_url()}?` + `var_session_id=${getToken()}&_userid=${common.get_adminid()}&${common.queryString.stringify(args)}`)
            } else {
                if (this.$refs.query_input_list) {
                    var query_input_args = (<any>this.$refs.query_input_list).get_args()
                    Object.assign(args, query_input_args)
                }

                me.$data.listLoading = true
                me.$emit("loading_change", me.$data.listLoading)

                // 清空选择
                this.$data.selected_list = []
                if (this.props.use_url_args_and_page_config) {
                    this.set_query_str(args)
                }
                this.cur_ajax_load_index++
                const cur_index = this.cur_ajax_load_index
                noti_message.set_loading_message_handle(this.get_ajax_url(), function(message: any) {
                    me.$data.loading_text = message.message
                })

                common.do_ajax(this.get_ajax_url(), args, (res: any) => {


                    let data_list: any = null
                    if (cur_index != this.cur_ajax_load_index) {
                        console.log("加载数据 无效 index: ", cur_index)
                        return
                    }

                    if (res.out) {
                        res = res.out
                    }

                    if (res.code === 0 && res.data && res.data.list !== undefined) {
                        res = res.data
                    }

                    let resp_total = res["total"]
                    if (resp_total === undefined) {
                        if (res["page_info"]) {
                            resp_total = res["page_info"]["total"]
                        }
                    }
                    resp_total = resp_total * 1
                    const next_func = function(res: any) {
                        me.$data.total = res.total
                        data_list = res["list"]

                        if (util.isArray(res.error_msg_list)) {
                            me.$data.error_msg_list = res.error_msg_list
                        } else {
                            me.$data.error_msg_list = []
                        }

                        if (me.check_tree_table()) {
                            me.$data.data_list = []
                            me.$data.tree_data_list = data_list
                            me.get_init_tree(me.$data.tree_data_list)
                        } else {
                            me.$data.data_list = data_list
                        }

                        noti_message.set_loading_message_handle(me.get_ajax_url())
                        me.$data.loading_text = "加载中..."

                        me.$data.listLoading = false
                        me.$emit("loading_change", me.$data.listLoading)

                    }

                    if (util.isFunction(me.props.deal_ajax_result_func)) {
                        const ret = me.props.deal_ajax_result_func(res, next_func)
                        if (ret == "do_with_call_back") {
                            return
                        }
                        if (util.isObject(ret)) { // 有返回数据
                            me.$data.total = ret.total || resp_total
                            data_list = ret.list
                        } else {
                            me.$data.total = resp_total
                            data_list = res["list"]
                        }
                    } else {
                        let total = resp_total

                        if (res.page_info && res.page_info.total) {
                            total = res.page_info.total
                        }
                        me.$data.total = total * 1
                        data_list = res["list"]
                    }
                    next_func({
                        total: me.$data.total,
                        list: data_list
                    })
                    me.$data.data_use_page_bar = true
                    if (load_succ_callback) {
                        load_succ_callback(data_list)
                    }
                })
            }
        },

        handleCurrentChange(val: any) {
            this.$data.page = val
            this.load()
        },

        handle_col_formater(row: any, col_config: any, index: any) {
            let cellValue = row[col_config.prop]
            if (col_config.formater) {
                cellValue = col_config.formater(cellValue, row, index)
            }
            return cellValue == undefined ? "" : `${cellValue}`
        },

        on_sort_change(order_info: any) {

            if (order_info.column.sortable == "custom") {
                const sort = order_info.prop
                let order = ""
                if (order_info.order == "ascending") {
                    order = "asc"
                } else {
                    order = "desc"
                }


                if (sort != this.$data.sort || order != this.$data.order) {
                    this.$data.sort = sort
                    this.$data.order = order
                    this.load(1)
                }
            }

        },
        test() {
            alert("xx")
        },

        show_inter_data(row: any) {

            const data: Array<any> = []
            const field_list: Array<any> = []
            for (const key in row) {
                field_list.push({
                    type: "label",
                    label: key,
                    field_name: key,
                    value: row[key]
                })
            }
            common.dlg.admin_show_key_table(field_list, data, {
                title: "数据",
                label_width: "200px"

            })

        },

        opt_in_phone(row: any, index: any) {
            const dom = document.createElement("div")
            document.getElementsByTagName("body")[0].appendChild(dom)

            const data: Array<any> = []
            const me = this

            const btn_config = this.props.row_opt_btn_config
            const check_col_show = function(item: any) {
                if (util.isFunction(item.visible)) {
                    return item.visible(row, item, index)
                } else if (item.visible === false) { return false }
                else return true
            }


            // array(12, "", "研发"),
            if (common.get_account_role() == common.enum_map.account_role.V_1001 ||
                common.check_is_root_admin() || (<any>window)["g_t"] || true // 全局开启调试
            ) {
                data.push({
                    key: "inter data",
                    value: {
                        callback: function() {
                            me.show_inter_data(row)
                        },
                        text: "inter data",
                        button_type: "text"
                    }
                })

            }
            btn_config.forEach(function(item: any, i: any) {
                item.index = i
                data.push({
                    key: "操作",
                    value: item
                })
            })

            data.push({
                key: "序号",
                value: index + 1
            })
            this.$data.cur_column_list.forEach(function(item: any, index: any) {
                // console.log('item', item)
                // console.log('index', index)
                if (item.component) {
                    data.push({
                        key: item.label,
                        component: item.component,
                        col: item
                    })
                } else {
                    data.push({
                        key: item.label,
                        value: me.handle_col_formater(row, item, index)
                    })
                }
            })
            console.log(" end push data")


            const html_str = " <el-table :data=\"data\"> "
                + "<el-table-column label =\"属性\" width = \"100px\" >"
                + "<template #default=\"scope\" > {{scope.row.key}}  " + "</template>"
                + "</el-table-column>"
                + "<el-table-column label=\"值\"   >"
                + "<template #default=\"scope\"  > "
                + "<div v-if=\"isString(scope.row.value)\" v-html=\"scope.row.value\"> </div>"

                + "<component :is=\"scope.row.component\"    v-else-if=\"scope.row.component\"     :row='row'  > </component>"

                + "<el-button  v-else-if=\" scope.row.value.component==undefined  && check_col_show(scope.row.value )\" :type=\"scope.row.value.button_type\" @click=\"on_click(scope.row.value )\"  :key=\"scope.row.value.opt_key\"   :icon=\"'iconfont ' + get_icon( scope.row.value)  \"  > <span v-html=\"get_text(scope.row.value)\"></span> {{get_title(scope.row.value )}}</el-button>"
                + "<component :is=\"scope.row.value.component\"    v-else-if=\"check_col_show(scope.row.value )\"     :row=\"row\" > </component>"
                + "</template>"
                + "</el-table-column>"
                + " </el-table> ";
            + " </el-table> "
            let width = 600
            if (this.$data.in_phone) {
                width = screen.width - 20
            }

            const top = common.in_phone() ? "1vh" : "15vh"

            const template =
                `<el-dialog title="提示"  v-model="dialogVisible" width="${width}px"  top="${top}" ref="show-row-info"  > ${html_str}   <template #footer > <span  class="dialog-footer">  <el-button type="primary" @click="callback() ">返回</el-button> </span> </template> </el-dialog>`
            const dlg_component = defineComponent({
                data: function() {
                    return {
                        dialogVisible: true,
                        modal: true,
                        close_on_press_escape: false,
                        show_close: true,
                        show: true,
                        row: row,
                        data: data
                    }
                },

                watch: {




                },

                mounted: function() {
                    // 重新加载页面是,要关掉
                    common.eventHub.$on("table-query", () => {
                        this.dialogVisible = false
                    })

                    common.eventHub.$on("close-dialog-on-new-url", () => {
                        this.dialogVisible = false
                    })


                },
                methods: {
                    on_click(item: any) {
                        if (item.callback) {
                            item.callback(row, item, index)
                        }

                    },
                    isString: function(v: any) {
                        return sys_util.isString(v) || sys_util.isNumber(v)
                    },
                    callback: function() {
                        this.dialogVisible = false
                    },

                    check_col_show(item: any) {
                        return check_col_show(item)
                    },

                    get_title(item: any) {
                        if (sys_util.isFunction(item.title)) {
                            return item.title(row, item, index)
                        } else {
                            return item.title
                        }
                    },


                    get_icon(item: any) {
                        if (sys_util.isFunction(item.icon)) {
                            return item.icon(row, item, index)
                        } else {
                            return item.icon
                        }
                    },

                    get_text(item: any) {
                        if (sys_util.isFunction(item.text)) {
                            return item.text(row, item, index)
                        } else {
                            return item.text
                        }
                    }


                },
                template: template

            })

            const vue_app = createApp(dlg_component)

            vue_app.use(ElementPlus)

            bind_component(vue_app)


            vue_app.mount(dom)

        },

        download_all(raw: boolean) {
            var me = this


            // var old_page_size = this.$data.page_size;
            // var old_page = this.$data.page
            // if (old_page_size < 100) {
            this.$data.page_size = 100
            // }
            var end_page_id = Math.ceil(this.$data.total / this.$data.page_size)

            var list: Array<any> = []
            for (var i = 1; i <= end_page_id; i++) {
                list.push(i)
            }

            const div = document.createElement("div")
            const table_item: Element = document.querySelector(`.${me.$data.table_class}`)!
            var end_deal = () => {
                var dlg = common.dlg.admin_show_dlg({
                    submit_text: "",
                    title: "提示",
                    content: function(data: any) {
                        return [h("span", {
                            style: {
                                "font-size": "20px"
                            }
                        }, [
                            "生成xlsx 文件中... "
                        ])]
                    }
                })


                setTimeout(() => {
                    const { utils, write } = (<any>window)["XLSX"]
                    const wb = utils.table_to_book(div, {
                        display: true,
                        raw: raw
                    })

                    const percent_flag_map: any = {}
                    for (const key in wb.Sheets.Sheet1) {
                        const item = wb.Sheets.Sheet1[key]

                        const match = /[A-Z]+/.exec(key)
                        if (match) {
                            if (util.isObject(item) && item["t"] == "n") {
                                const col_name = match[0]
                                if (percent_flag_map[col_name] === undefined) {
                                    const title: string = wb.Sheets.Sheet1[`${col_name}1`].v
                                    percent_flag_map[col_name] = (title[title.length - 1] == "%")
                                }
                                if (percent_flag_map[col_name]) {
                                    item["z"] = "0.00%"
                                }

                            }
                        }

                    }

                    const wbout = write(wb, { bookType: "xlsx", bookSST: true, type: "array" })
                    try {
                        const file_name = `${common.get_page_store_key()}-${common.date_format(new Date, "yyyy_MM_dd_hh_mm_ss")}`
                        FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${file_name}.xlsx`)
                    } catch (e) { if (typeof console !== "undefined") console.log(e, wbout) }
                    // 加载第一也
                    this.$data.page_size = 10
                    this.load(1)
                    setTimeout(function() {
                        dlg.close()
                    }, 3000)

                }, 1000)


            }
            var save_to_div = (page_id: any) => {
                const item_list = table_item.querySelectorAll(".el-table__header-wrapper,.el-table__body-wrapper")
                if (page_id == 1) { // header
                    div.appendChild(item_list[0].cloneNode(true))
                }
                div.appendChild(item_list[1].cloneNode(true))
                if (page_id == end_page_id) {// save
                    nextTick(function() {
                        nextTick(function() {
                            end_deal()
                        })
                    })
                }

            }


            var batch_load = () => {


                common.do_batch_list(list, 1,
                    function(item_list: any, do_next: () => void) {

                        var page_id = item_list.data[0]
                        me.load(page_id, function() {
                            nextTick(function() {
                                nextTick(function() {
                                    nextTick(function() {
                                        nextTick(function() {
                                            save_to_div(page_id)
                                            do_next()

                                        })
                                    })
                                })
                            })

                        })


                    },
                    {
                        show_dlg: true
                    })

            }
            const downlaod_func = function() {
                const xlsx_url = "https://lib.mydaren.cn/xlsx/0.15.1/xlsx.core.min.js"
                common.load_js_list([xlsx_url], function() {
                    batch_load()
                })
            }
            downlaod_func()

            // this.load()
        },
        download_no_raw() {
            this.download(false)
        },
        download_raw() {
            this.download()
        },



        download(raw = true) {
            const me = this
            const downlaod_func = function() {
                const xlsx_url = "https://lib.mydaren.cn/xlsx/0.15.1/xlsx.core.min.js"
                common.load_js_list([xlsx_url], function() {
                    const { utils, write } = (<any>window)["XLSX"]

                    const table_item: Element = document.querySelector(`.${me.$data.table_class}`)!
                    const item_list = table_item.querySelectorAll(".el-table__header-wrapper,.el-table__body-wrapper")
                    const div = document.createElement("div")

                    div.appendChild(item_list[0].cloneNode(true))
                    div.appendChild(item_list[1].cloneNode(true))

                    const wb = utils.table_to_book(div, {
                        display: true,
                        raw: raw
                    })

                    const percent_flag_map: any = {}
                    for (const key in wb.Sheets.Sheet1) {
                        const item = wb.Sheets.Sheet1[key]

                        const match = /[A-Z]+/.exec(key)
                        if (match) {
                            if (util.isObject(item) && item["t"] == "n") {
                                const col_name = match[0]
                                if (percent_flag_map[col_name] === undefined) {
                                    const title: string = wb.Sheets.Sheet1[`${col_name}1`].v
                                    percent_flag_map[col_name] = (title[title.length - 1] == "%")
                                }
                                if (percent_flag_map[col_name]) {
                                    item["z"] = "0.00%"
                                }

                            }
                        }

                    }

                    const wbout = write(wb, { bookType: "xlsx", bookSST: true, type: "array" })
                    try {
                        const file_name = `${common.get_page_store_key()}-${common.date_format(new Date, "yyyy_MM_dd_hh_mm_ss")}`
                        FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${file_name}.xlsx`)
                    } catch (e) { if (typeof console !== "undefined") console.log(e, wbout) }
                    return wbout

                })
            }
            downlaod_func()
        },
        config_row_field() {

            /*
              var me = this;
              var dlg = common.dlg.admin_show_dlg({
              title: "配置操作列表",
              content: function(_data: any, h: CreateElement) {

              var config_node = h("admin-config-item-table", {
              props: {
              title: "可选操作",
              selected_list: me.$data.config_option_selected_list,
              data: me.get_option_field_list(),
              },

              on: {
              "select_change": function(value: any) {
              if (value == undefined) {
              me.page_store_set_key(me.store_key_option_selected, undefined);
              me.page_store_set_key(me.store_key_field_width, undefined, true);
              window.location.reload();
              } else {
              me.$data.config_option_selected_list = value;

              me.page_store_set_key(me.store_key_option_selected, value);
              dlg.close();
              }
              }
              }
              });
              return [config_node];
              }

              });
            */

        },
        config_table_field() {


            const me = this
            var dlg = common.dlg.admin_show_dlg({
                title: "显示列表",
                content: function(_data: any) {

                    const config_node = h(admin_config_item_table, {
                        title: "可选列",
                        modelValue: me.$data.config_field_selected_list,
                        data: me.get_config_field_list(),
                        [ON_UPDATE_MODEL_EVENT]: function(value: any) {
                            if (value == undefined) {
                                me.page_store_set_key(me.store_key_field_selected, undefined, true)
                                me.page_store_set_key(me.store_key_field_width, undefined, true)
                                window.location.reload()
                            } else {
                                me.$data.config_field_selected_list = value

                                me.page_store_set_key(me.store_key_field_selected, value, true)
                                window.location.reload()
                                dlg.close()
                            }
                        }
                    })
                    const form_node = h("el-form", {
                        props: {
                            inline: true
                        }
                    }, [
                        h(
                            "el-form-item", {},
                            [
                                h("el-switch", {
                                    modelValue: me.$data.use_table_height,
                                    "active-text": "固定表头",
                                    "inactive-text": "不固定表头",
                                    [ON_UPDATE_MODEL_EVENT]: function(value: any) {
                                        me.$data.use_table_height = value
                                    }
                                }, []),

                                h("el-switch", {
                                    modelValue: me.$data.table_fixed,
                                    "active-text": "固定列",
                                    "inactive-text": "不固定列",
                                    [ON_UPDATE_MODEL_EVENT]: function(value: any) {
                                        me.$data.table_fixed = value
                                    }
                                }, [])

                            ]
                        )
                    ]
                    )
                    return [form_node, config_node]
                }

            })
        },





        on_current_change(row: any, _old_row: any) {
            this.$emit("select", row)
        },

        clear_select() {
            (<any>this.$refs.table)["setCurrentRow"]()
        },

        select(row: any) {
            (<typeof ElTable>this.$refs.table).setCurrentRow(row)
        },
        get_table() {
            return (<typeof ElTable>this.$refs.table)
        },
        on_page_size_change(val: any) {
            const old_size = this.$data.page_size
            if (val > 100) {
                ElMessageBox.confirm("超过100条,可能会很慢, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    // succ
                    this.$data.page_size = val
                    this.load()
                })
                    .catch(() => {
                        // fail
                        this.$data.page_size = val
                        nextTick(() => { // HACK
                            this.$data.page_size = old_size
                        })
                    })

            } else {
                this.$data.page_size = val
                this.load()
            }
            return false

        },
        on_row_click(row: any) {
            this.$emit("row-click", row)
        },

        on_cell_click(row: any, column: any, cell: any, event: any) {
            // console.log(column);
            if (column.label == "__LEFT_OP") {
                console.log("FFFFFFFFFFF ", row, column, cell, event)
                this.opt_in_phone(row, -1)
            } else {
                this.props.column_list.forEach(function(item: any, _i: any) {
                    // console.log(column.property, item.prop);
                    if (column.property == item.prop) {
                        if (item.on_click) {
                            item.on_click(row)
                        }
                    }
                })
            }
        },

        on_cell_mouse_leave(_row: any, _column: any, _cell: any, _event: any) {
            /*
              if (column.property == "__OPT") {

              }
            */
        },

        handleSelectionChange(val: any) {
            this.$data.selected_list = val
            this.$emit("select", val)
        },

        // 得到选择列表
        get_selected_list(): Array<any> {
            return this.$data.selected_list
        },

        get_data_list(): Array<any> {
            return this.$data.data_list
        },


        get_page_info(): any {
            return {
                page: this.$data.page,
                page_size: this.$data.page_size
            }
        },
        get_query_args(): any {
            return (<any>this.$refs.query_input_list).get_args()
        },

        // 设置选择列表
        set_selected_list(selected_list: any) {
            if (selected_list) {
                selected_list.forEach((row: any) => {
                    (<any>this.$refs.table)["toggleRowSelection"](row)
                })
            } else {
                (<any>this.$refs.table)["toggleRowSelection"]()
            }
        },

        on_header_dragend(newWidth: any, _oldWidth: any, column: any, _event: any) {
            if (newWidth > 50) {
                this.field_width_map[column.label] = newWidth
            }

            this.page_store_set_key(this.store_key_field_width, this.field_width_map)

        },


        toggle_tree(_row: any, _e: any) {
            /*
              $(e.target).parent().find(".el-table__expand-icon").click();

              //重新布局
              this.$nextTick(() => {
              this.$refs.table["doLayout"]();
              })
            */
        },
        get_level_fix_str(level: any) {
            let ret_str = ""
            for (let i = 0; i < level; i++) {
                ret_str += "　"
            }
            return ret_str
        },

        show_download_flag() {
            return common.check_url_desc_power_opt_download()
        },

        filter_change(args: any) {

            for (const field_name in args) {
                common.set_object_property_by_field_name(this.props.query_filter_item_list, field_name,
                    "value", args[field_name])
            }

            const me = this
            nextTick(() => {
                const query_input_args = (<any>me.$refs.query_input_list).get_args()
                me.onQuery(query_input_args)
            })


        },

        get_filters(col: any) {
            const { filter_enum_type } = col

            if (util.isFunction(filter_enum_type)) {

                const filter_list_fun = filter_enum_type
                if (col.filter_list === undefined) {


                    col.filter_list = ref([])
                    // col.filter_list = [];
                    // Vue.set(col, "filter_list", []);

                    filter_list_fun(function(filter_list: any) {
                        // Vue.set(col, "filter_list", filter_list);
                        col.filter_list = filter_list
                    })
                }

                return col.filter_list


            } else {
                const enum_info = (<any>common.enum_map)[filter_enum_type]
                if (enum_info) {
                    const ret_list: Array<any> = []
                    for (const i in enum_info.desc_map) {

                        ret_list.push({
                            value: i,
                            text: enum_info.desc_map[i]
                        })
                    }
                    return ret_list
                } else {
                    return null
                }
            }
        },
        check_slot_query_input() {
            const ret = common.check_slot(this.$slots["query-input"])

            return ret

        },
        check_slot_toolbar() {


            return common.check_slot(this.$slots["toolbar"])
        },


        check_slot_row_list() {
            return common.check_slot(this.$slots["row-list"])

        },




        gen_render_header(col: any, index: any, cur_column_list: any) {
            if (col.render_header) {
                return col.render_header
            }
            if (col.label_title) {
                return this.gen_render_header_title_func(col)
            }
            let cur_column_length = 0
            for (const i in cur_column_list) {
                const item = cur_column_list[i]
                if (item.default_show !== false) {
                    cur_column_length += 1
                }
            }

            // console.log("cur_lenth---", cur_column_length)
            if (this.props.show_download && !this.props.show_option_list && index == cur_column_length - 1) {// 最后一个
                console.log(`do cloumn:===== DO:${index}`)
                return this.gen_render_header_not_show_option_list(col)
            }

            return null
        },
        get_col_min_width(col: any) {
            console.log("min_width", col.min_width)
            return col.min_width || null
        },

        gen_render_header_title_func(col: any) {


            return function() {

                return h("span", {
                    title: col.label_title
                },
                [
                    // 标题
                    col.label,
                    h("span", {
                        style: "color:#E6A23C;cursor: pointer;",
                        class: "el-icon-question",



                        onClick: function() {
                            const field_list = [
                                ["列名", col.label],
                                ["说明", col.label_title]
                            ]
                            const data = {}

                            common.dlg.admin_show_key_table(field_list, data, {
                                title: "列说明",
                                width: "400px",
                                use_submit: false

                            })

                        }
                    }, [
                    ])
                ])
            }
        },
        gen_render_header_not_show_option_list(col: any) {

            const me = this
            return function(/* , { column, $index }*/) {

                return h("span", {
                }, [
                    // 标题
                    col.label,
                    h(ElButton, {
                        type: "text",
                        onClick: function() {
                            me.download_no_raw()
                        }
                    }, {
                        default: function() {
                            return "下载"
                        }
                    }),

                    h(ElButton, {
                        type: "text",
                        onClick: function() {
                            me.download_raw()
                        }
                    }, {
                        default: function() {
                            return "下载-文本"
                        }
                    }

                    )


                ])
            }
        },




        check_obj_test({ row }: any, col: any) {
            if (col.show_obj_test_flag) {
                return col.show_obj_test_flag(row)
            } else {
                return false
            }
        },

        get_obj_comonent({ row }: any, col: any) {
            return col.obj_component(row)
        },
        tree_lazy_load(tree_row: any, _treeNode: any, resolve: any) {
            const id = tree_row["_id"]

            let id_list: any = []
            if (tree_row["id_list"]) {
                id_list = tree_row["id_list"].slice()
            }
            id_list.push(id)
            console.log(" id_list:", id_list)
            var get_node: any = function(tree_node_list: any, sub_id_list: Array<number>) {
                const check_index = sub_id_list.shift()

                if (check_index === undefined) {
                    return tree_node_list
                }

                const node = (<Array<any>>tree_node_list).find(function(item) {

                    return item["_id"] == check_index
                })
                console.log("node:", node)
                if (node) {
                    return get_node(node["children"], sub_id_list)

                } else {
                    return null
                }
            }

            console.log(" node ", node_list)
            var node_list = get_node(this.$data.tree_data_list, id_list.slice())
            if (node_list) {
                (<Array<any>>node_list).forEach(function(item) {
                    if (item["children"]) {
                        item["hasChildren"] = true
                        item["id_list"] = id_list
                    } else {
                        item["id_list"] = id_list
                    }
                })
                console.log("node_list", node_list)
                resolve(node_list)
            }

        },
        get_loading_status() {

            if (this.$data.listLoading === null) {
                return "init"
            } else if (this.$data.listLoading == false) {
                return "end"
            } else {
                return "start"
            }
        },

        get_filtered_value(col: any) {
            if (col.filter_query_item) {

                if (col.filter_query_item.value == "-1" || col.filter_query_item.value == "") {
                    return []
                } else {
                    var ret = (`${col.filter_query_item.value}`).split(",")
                    console.log("KKKKK", ret)
                    return ret
                }

            } else {
                return []
            }

        }

    }

})
