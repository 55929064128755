import enum_map from "./enum_map"
import queryString from "query-string"
import { computed, defineComponent, markRaw } from "vue"
import { ILayout } from "/@/type/store/layout"
import do_ajax from "./do_ajax"
import dlg from "./dlg"
import array from "./array"
import util from "./sys_util"
import { sprintf } from "sprintf-js"
import router from "@/router/index"


import { ElLoading, ElMessage, ElMessageBox, ElNotification } from "element-plus"

import { row_item_t, column_item_t, row_item_list_t } from "./type_def"


import common_ex from "./common_ex"
import { Base64 } from "js-base64"

import Cookies from "js-cookie"
import bus from "./bus"
import { store } from "../store"
import sys_util from "./sys_util"
import { DispatchOptions } from "vuex"
import enum_color_config from "./enum_color_config"
import { isObject } from "lodash"
import { INotificationOptions } from "element-plus/lib/el-notification/src/notification.type"
export var UPDATE_MODEL_EVENT = "update:modelValue"
export var ON_UPDATE_MODEL_EVENT = "onUpdate:modelValue"

var common = {

    // 菜单信息
    path_url_map: <any>{},
    first_path: "",
    $root: <any>null,

    //
    Base64: Base64,

    enum_map: enum_map,
    eventHub: bus,
    dlg: dlg,
    no_need_check_change_url: false,
    array: array,


    struct_map: <any>{},
    cmd_return_map: <any>{},
    proto_validator: [],
    cmd_map: <any>{},
    route_fix_config: <any>{},
    login_account: "[开发]",
    detect_browser(ua_flag = true) {
        var Sys: any = {}
        var ua = navigator.userAgent.toLowerCase()
        if (!ua_flag) {
            ua = (<any>navigator).appVersion.toLowerCase()
        }
        var s;
        (s = ua.match(/firefox\/([\d.]+)/)) ? Sys.firefox = s[1] :
            (s = ua.match(/chrome\/([\d.]+)/)) ? Sys.chrome = s[1] :
                (s = ua.match(/opera.([\d.]+)/)) ? Sys.opera = s[1] :
                    (s = ua.match(/rv:([\d.]+)/)) ? Sys.ie = s[1] :
                        (s = ua.match(/msie ([\d.]+)/)) ? Sys.ie = s[1] :
                            (s = ua.match(/version\/([\d.]+).*safari/)) ? Sys.safari = s[1] : 0

        var browser = "Unknown"
        if (Sys.ie) { browser = "IE" }
        if (Sys.firefox) { browser = "Firefox" }
        if (Sys.chrome) { browser = "Chrome" }
        if (Sys.opera) { browser = "Opera" }
        if (Sys.safari) { browser = "Safari" }

        return browser
    },

    text2html(str: string) {
        return str.replace(/\n([ \t]*)/g, function(_word, v1: any) {
            let fix = ""
            for (let i = 0; i < v1.length; i++) {
                if (v1[i] == " ") {
                    fix += "&ensp;"
                } else {
                    fix += "&emsp;"
                }
            }

            return `<br/>${fix}`
        })
    },

    gen_stuct_info(fix_str: any, deal_struct_info: any, list: any, find_list: any) {
        $.each(deal_struct_info, function(_i: any, item) {
            const field_type = item[1]
            let desc = item[4]
            const rule_list: Array<any> = item[6]
            const enum_info_arr = desc.match(/.*ENUM[ \t]*=[ \t]*([a-z0-9_A-Z]*)/)
            let enum_type = ""
            if (enum_info_arr) {
                enum_type = enum_info_arr[1]
            }
            let required = (desc.indexOf("[required]") >= 0)
            if (required) {
                const res = /(.*)\[required\](.*)/.exec(desc)
                if (res) {
                    desc = res[1] + res[2]
                }
            }

            const new_rule_list: Array<any> = []
            rule_list.forEach(function(item: any) {
                if (item["type"] == "required") {
                    required = true
                } else {
                    if (item["type"] == "enum" || item["type"] == "enum_list") {
                        enum_type = item["config"]["enum_type"]
                    }
                    new_rule_list.push(item)
                }
            })


            list.push({
                fix: fix_str,
                field_name: item[2],
                field_type: field_type,
                array_flag: item[0] == "repeated",
                map_key_type: item[5],
                desc: desc,
                enum_type: enum_type,
                pb_index: item[3],
                required: required ? "是" : "",
                rule_list: new_rule_list,
                value: ""
            })
            const type_strcut_info = common.struct_map[field_type]
            if (type_strcut_info) {
                if (!find_list[field_type]) {
                    const tmp_list: any = { ...find_list }
                    tmp_list[field_type] = true
                    common.gen_stuct_info(`${fix_str}&emsp;&emsp;`, type_strcut_info, list, tmp_list)
                }

            }

        })
    },


    get_store_layout(): ILayout {

        return store.state.layout
    },



    queryString: queryString,

    // 是不是小字体
    check_main_page_mini_size() {
        return window.localStorage.getItem("main_ui_size") == "mini"
    },

    // 得到当前账号id
    get_adminid() {
        return common.get_store_layout().adminid

    },
    // 0:组员, 1:组长, 2: ..  10:管理员
    get_admin_level() {
        return common.get_store_layout().admin_level
    },
    get_admin_level_str() {
        return common.get_store_layout().admin_level_str
    },


    // 是否是 tlt
    get_sub_master_flag() {
        return common.get_store_layout().sub_master_flag

    },



    // 所在组的前缀,: [1, 3 ]
    get_admin_group_list(url = "", top: any = []): Array<string> {
        if (common.check_url_desc_power({
            url: url,
            power_flag: "opt_admin"

        }) || common.check_is_root_admin()) {
            return top
        }
        const ret: Array<any> = common.get_store_layout().admin_level_group_list.slice()
        if (ret.length) { // 有部门信息
            if (util.isArray(ret[0])) {
            } else {
                ret.unshift(`${this.get_account_role()}`)// 加角色
            }
        }

        return ret
    },

    // 得到当前账号
    get_account() {
        return common.get_store_layout().name
    },


    check_url_desc_power_opt_admin(): boolean {
        return this.check_url_desc_power("opt_admin") || this.check_url_desc_power({
            url: "/common/power",
            power_flag: "opt_admin"
        })
    },

    // 检测当前页面是否有下载权限
    check_url_desc_power_opt_download(): boolean {
        return this.check_url_desc_power("opt_download") || this.check_is_root_admin()
    },

    get_url_desc_power_value: common_ex.get_url_desc_power_value,


    page_store_set_key: common_ex.page_store_set_key,
    check_url_desc_power: common_ex.check_url_desc_power,

    check_only_dx_flag() {

        return common.check_url_desc_power({
            url: "/dx/user_order_list",
            power_flag: "opt_only_dx"

        })

    },
    // 得到当前账号角色
    get_account_role() {
        return common.get_store_layout().account_role
    },

    get_power_role() {
        return common.get_store_layout().power_role
    },


    get_url_to_id_map() {
        return common.get_store_layout().url_to_id_map
    },

    get_target_account_role() {
        return common.get_store_layout().target_account_role
    },




    notify(options: INotificationOptions) {

        options.duration = options.duration || 3000
        options.title = options.title || "提示"
        options.type = options.type || "success"
        ElNotification(options)
    },

    alert(msg: any, show_confirm_button = true) {
        return ElMessageBox({
            message: msg,
            customClass: common.in_phone() ? "in_phone_message_box" : "",
            dangerouslyUseHTMLString: true,
            showConfirmButton: show_confirm_button
        })
    },

    /**
     * https://element.eleme.cn/#/zh-CN/component/message-box#que-ren-xiao-xi
     * 消息确认框的相关操作
     *
     * @param message 弹窗展示的消息内容
     * @param ok_callback 确认按钮的回调方法
     * @param fail_callback 取消按钮的回调方法
     * @param title 弹窗展示的标题
     * @param confirm_button_text 确认按钮的展示文案
     * @param cancel_button_text  取消按钮的展示文案
     * @param other_config 其他 MessageBox 的可用配置
     */
    confirm(message: any, ok_callback: Function, fail_callback?: any, title?: any, confirm_button_text?: any, cancel_button_text?: any, other_config?: any) {
        const default_config = {
            confirmButtonClass: ""
        }

        Object.assign(default_config, other_config)

        ElMessageBox.confirm(message, "message", {
            confirmButtonText: confirm_button_text ? confirm_button_text : "确定",
            cancelButtonText: cancel_button_text ? cancel_button_text : "取消",
            type: "warning",
            title: title ? title : "message",
            dangerouslyUseHTMLString: true, // 此配置为true时，message会被当做html来处理
            customClass: common.in_phone() ? "in_phone_message_box" : "",
            confirmButtonClass: default_config.confirmButtonClass != undefined ? default_config.confirmButtonClass : ""
        }).then(() => {
            ok_callback()
        })
            .catch(() => {
                console.log(arguments)

                if (fail_callback) {
                    fail_callback()
                }
            })
    },


    alert_json(val: any, title = " title:") {
        common.dlg.admin_show_key_json(title, val)
        return
    },



    log_json(val: any, title = "log_json") {
        console.log(title, ":", JSON.parse(JSON.stringify(val)))
    },


    reload_page() {
        // return this.$root["reload"]();
    },

    /**
     * 重置页面所有选项，重新载入页面，获取默认数据
     */
    reset_page() {
        const url = window.location.href.split("?")[0]

        console.log("reset_page_url:", url)
        window.open(url, "_self")
        this.reload_page()
    },

    get_api_url() {
        const url = import.meta.env.VITE_APP_BASE_API || "/api"

        console.log("get_api_url:", url)
        if (url) {
            return url
        } else {
            return `//${window.location.hostname}`
        }

    },
    get_base_url() {
        return `${window.location.href.split("#")[0]}#`
    },
    get_phone_hide_str(phone: string) {
        if (typeof phone != "string") {
            return phone
        }
        if (phone.length == 0) {
            return phone
        }
        return `${phone.substring(0, 3)}****${phone.substring(7)}`
    },
    wopen_api(url: string) {
        window.open(this.get_api_url() + url)
    },

    wopen: common_ex.wopen,

    copy_obj<T>(v: T): T {
        if (util.isArray(v)) {
            var ret: any = [];
            (<any>v).forEach(function(sub_v: any) {
                if (sys_util.isArray(sub_v) || sys_util.isObject(sub_v)) {
                    ret.push(common.copy_obj(sub_v))
                } else {
                    ret.push(sub_v)
                }
            })
            return ret

        } else if (util.isObject(v)) {
            var ret: any = Object.assign({}, v)
            for (const k in ret) {
                const sub_v: any = ret[k]
                if (sys_util.isArray(sub_v) || sys_util.isObject(sub_v)) {
                    ret[k] = common.copy_obj(sub_v)
                }
            }
            return ret

        } else {
            return v
        }
    },
    location_farce_reload() {
        // window.location.reload(true);
        window.location.href = window.location.href
    },

    do_ajax(url: string, args: any, callback?: any, show_loading = false) {
        let new_url = url

        const item_arr = url.split("?")[0].split(/\//)
        if (item_arr[0] == "") { // 以 "/"开头
            // 匹配: /control/action 或 /control/action/
            if (item_arr.length == 3 || (item_arr.length > 3 && item_arr[3] == "")) {
                new_url = `/route__daren_default${url}`
            }
        }

        if (show_loading == true) {
            var loading = this.show_loading()
        }

        do_ajax().post(new_url, args, {
        })
            .then((res: any) => {
                if (res.ret === undefined) {
                    res.ret = res.code
                }
                if (res.ret == 997) { // 需要重新加载页面

                    common.notify({
                        title: "系统信息",
                        message: "系统刷新中...",
                        type: "warning",
                        duration: 1000
                    })

                    setTimeout(function() {
                        common.location_farce_reload()
                    }, 1000)
                    return

                } else if (res.ret == 998) { // 需要重新请求

                    common.notify({
                        title: "系统信息",
                        message: "太多人请求,请等待,3秒后重新加载数据",
                        type: "warning",
                        duration: 3000
                    })

                    setTimeout(function() {
                        common.do_ajax(url, args, callback)
                    }, 3000)
                    return


                } else { // 正常 请求
                    if (util.isFunction(callback) && !(<any>window)["g_t"]) {
                        callback(res)
                    }
                }
            })
            .finally(function() {
                if (show_loading == true) {
                    (<any>loading).close()
                }
            })
    },


    // 检查是否一致
    check_same_array(arr1: Array<any>, arr2: Array<any>) {
        if (arr1.length != arr2.length) {
            return false
        }
        arr1.forEach(function(v, i) {
            if (arr2[i] !== v) {
                return false
            }
        })
        return true
    },


    in_phone() {
        return store.state.layout.menubar.isPhone
    },

    // 得到enum 数据
    get_enum_option_list: common_ex.get_enum_option_list,

    // 将数组转化成select opt_list
    get_opt_list_from_arr(list: any, value: any, label: any) {
        const ret_list = []
        for (const key in list) {
            ret_list.push({
                label: list[key][label],
                value: parseInt(list[key][value])
            })
        }

        return ret_list

    },

    // 得到明天的日期 字符串
    date_get_tomorrow: function() {
        return common.date_format(Math.floor((new Date()).getTime() / 1000) + 86400)
    },
    // 得到n个月前的时间
    date_get_month_ago: function(num: number) {
        return common.date_format(Math.floor((new Date()).getTime() / 1000) - num * 86400 * 30)
    },



    date_get_day: function(add_days: number) {
        return common.date_format(Math.floor((new Date()).getTime() / 1000) + 86400 * add_days)
    },

    // 得到今天的日期 字符串
    date_get_today: function() {
        return common.date_format(new Date())
    },
    // 当前时间
    date_get_now: function() {
        return common.date_format(new Date(), true)
    },
    strToFixed(value: any) {
        return (+value >= 10000 ? `${(+value / 10000).toFixed(1)}万` : value)
    },

    trans_number(value: any) {
        var param: any = {}
        var k = 10000,
            sizes = ["", "万", "亿", "万亿"],
            i
        if (value < k) {
            param.value = value
            param.unit = ""
        } else {
            i = Math.floor(Math.log(value) / Math.log(k))

            param.value = ((value / Math.pow(k, i))).toFixed(1)
            param.unit = sizes[i]
        }
        return param
    },
    prevDate(date: any) {

        let now: any = new Date(date)
        const year: any = now.getFullYear()
        let month: any = now.getMonth() + 1
        let day: any = now.getDate() + 1
        if (parseInt(month) < 10) {
            month = `0${month}`
        }
        if (parseInt(day) < 10) {
            day = `0${day}`
        }

        now = `${+ month}-${day}`

        if (parseInt(month) == 1) {
            return `12月${day}日`
        }

        var preSize = new Date(year, parseInt(month) - 1, 0).getDate()
        if (preSize < parseInt(day)) {
            return `${year}-${month}-01`
        }

        if (parseInt(month) <= 10) {
            return `${parseInt(month) - 1}月${day}日`
        } else {
            return `${parseInt(month) - 1}月${day}日`
        }

    },
    /**
     * @description: 转换年月
     * @return: 2020年10月
     */
    getRangeDate(time: any) {
        if (!time) {
            return ""
        }
        const Dates = new Date(time)
        const month = (Dates.getMonth() + 1) < 10 ? `0${Dates.getMonth() + 1}` : (Dates.getMonth() + 1)
        const day = Dates.getDate() < 10 ? `0${Dates.getDate()}` : Dates.getDate()
        return `${month}月${day}日`
    },

    // 2018-01-10
    date_get_week_end: function(date: string) {

        const opt_date = new Date(
            common.strtotime(date) * 1000
        )
        let week = opt_date.getDay()
        if (week == 0) {
            week = 7
        }
        let tmp_time = opt_date.getTime() / 1000
        tmp_time = (tmp_time - (week - 1) * 86400) + 6 * 86400
        return common.date_format(tmp_time, "yyyy-MM-dd")
    },

    date_get_cur_week: function() {

        const opt_date = new Date()
        let week = opt_date.getDay()
        if (week == 0) {
            week = 7
        }
        let tmp_time = opt_date.getTime() / 1000
        tmp_time = (tmp_time - (week - 1) * 86400)
        return common.date_format(tmp_time, "yyyy-MM-dd")
    },
    // = "yyyy-MM-dd hh:mm:ss";

    date_get_cur_month: function() {
        return common.date_format(new Date(), "yyyy-MM-01")
    },

    date_get_month: function(unixtime: any, change_month_count = 1) {

        const start_time = common.strtotime(common.date_format(unixtime, "yyyy-MM-01"))
        const date_v = new Date(start_time * 1000)
        const year = date_v.getFullYear()
        const month = date_v.getMonth() + change_month_count
        const d = new Date(year, month, 1)
        return common.date_format(d.getTime() / 1000)
    },


    date_format_lesson_time: function(lesson_start: number, lesson_end: number) {

        const m_count = (lesson_end - lesson_start) / 60

        return `${this.date_format(lesson_start, "MM-dd hh:mm")}~${this.date_format(lesson_end, "hh:mm")
        }-[${m_count}]`

    },
    // = "yyyy-MM-dd hh:mm:ss";
    // = "yyyy-MM-dd hh:mm:ss";
    // = "yyyy-MM-dd hh:mm:ss";
    date_format: common_ex.date_format,
    datetotime: function(date: any) {
        return new Date(date).getTime() / 1000
    },
    strtotime: function(str: any) {
        let tmp_datetime = str.replace(/:/g, "-")
        tmp_datetime = tmp_datetime.replace(/ /g, "-")
        const arr = tmp_datetime.split("-")
        let hh = arr[3]
        let mm = arr[4]
        let ss = arr[5]
        if (hh == undefined) {
            hh = 0
        }
        if (mm == undefined) {
            mm = 0
        }
        if (ss == undefined) {
            ss = 0
        }

        const now = new Date(Date.UTC(arr[0], arr[1] - 1, arr[2], hh - 8, mm, ss))
        return Math.floor(now.getTime() / 1000)
    },

    // 获取 url中的参数
    get_query_arg_str_value(field_name: any, default_value: any = ""): any {
        const query_str = window.location.hash.replace(/^[^?]*\?/, "")
        const args = queryString.parse(query_str)
        let value: any = args[field_name]
        if (value === undefined) {
            return default_value
        }

        if (value === true) {
            value = ""
        }
        return value

    },
    get_query_arg_str_one_value(field_name: any, default_value: any = "") {
        const value = common.get_query_arg_str_value(field_name, default_value)
        if (value == -1) {
            value == ""
        }
        return value
    },

    gen_query_str_by_args(args: any) {
        return queryString.stringify(args)
    },
    get_query_date_args(): any {
        const args = common.get_query_args()
        return {
            opt_date_type: args.opt_date_type,
            date_type: args.date_type,
            start_time: args.start_time,
            end_time: args.end_time
        }

    },

    get_query_args(): any {
        // 设置参数
        const query_str = window.location.hash.replace(/^[^?]*\?/, "")
        if (query_str == window.location.hash) {
            return {}
        } else {
            return queryString.parse(query_str)
        }
    },
    get_location_path(): string {
        return window.location.hash.replace(/\?.*/, "").replace(/#/, "")
    },

    get_action_str() {
        const url = window.location.hash.replace(/\?.*/, "").replace(/#/, "")
        const arr = url.split("/")
        let action = arr[2]
        if (!action) action = "index"
        return action.toLowerCase()
    },

    get_page_store_key(fix = "", url = "") {
        if (!url) {
            url = window.location.hash.replace(/\?.*/, "").replace(/#/, "")
        }
        const arr = url.split("/")
        let ctrl: string = arr[1]
        let action = arr[2]
        if (arr.length > 3) {
            ctrl = arr[arr.length - 2]
            action = arr[arr.length - 1]
        }
        if (!ctrl) ctrl = "index"
        if (!action) action = "index"


        ctrl = ctrl.toLowerCase()
        action = action.toLowerCase()
        if (fix) {
            return `${"page" + "-"}${ctrl}-${action}-${fix}`
        } else {
            return `${"page" + "-"}${ctrl}-${action}`
        }
    },

    get_page_store(fix = "", url = ""): any {
        const store_key = this.get_page_store_key(fix, url)
        const json_str = window.localStorage.getItem(store_key)
        let save_ret = {}
        if (json_str) {
            try {
                save_ret = JSON.parse(json_str)
            } catch {
                save_ret = {}
            }
            if (!util.isObject(save_ret)) {
                save_ret = {}
            }
        }
        return save_ret
    },


    page_store_get_key: common_ex.page_store_get_key,

    websocket: common_ex.websocket,
    check_env_is_dev(): boolean {
        return import.meta.env.DEV
    },

    check_env_is_prod(): boolean {
        return import.meta.env.PROD
    },
    local_storage_get_item(key: any, default_value: string): string {
        const value = window.localStorage.getItem(key)
        if (value == null) {
            return default_value
        } else {
            return value
        }

    },

    // 对象转数组
    get_arr_from_obj(obj: any) {
        const arr = []
        for (const i in obj) {
            arr.push(obj[i])
        }
        return arr


    },

    set_object_property_by_field_name(item_list: Array<any>, field_name: any, key: any, value: any) {
        item_list.forEach(function(item, _i) {
            if (item["field_name"] == field_name) {
                item[key] = value
            }
        })
    },

    get_object_property_by_field_name(query_filter_item_list: Array<any>, field_name: any, key: any): any {
        let out_value = ""
        query_filter_item_list.forEach(function(item, _i) {
            if (item["field_name"] == field_name) {
                out_value = item[key]
            }
        })
        return out_value
    },

    set_object_property_by_prop(colum_list: Array<any>, prop: any, key: any, value: any) {
        colum_list.forEach(function(item, _i) {
            if (item["prop"] == prop) {
                item[key] = value
            }
        })
    },

    // 判断数组中是否存在某个值
    check_contains(list: Array<any>, str: any) {
        let i = list.length
        while (i--) {
            if (list[i] === str) {
                return true
            }
        }
        return false
    },

    // 获取枚举变量对应的值
    get_str_from_enum(value: any, str: any, default_value: any = "") {
        const desc_map = this.get_enum_option_list(str, false)
        let data = default_value
        desc_map!.forEach(function(element: any, _index: any) {
            if (element.value == value) {
                data = element.label
            }
        })
        return data

    },
    get_enum_desc(enum_type: string, value: number) {
        return (<any>common.enum_map)[enum_type]["desc_map"][value] || ""

    },

    load_echarts_files_bak(callback: any) {

        if (!(<any>window)["echarts"]) {
            const base_url = "https://xx.admin.cn"
            // common.load_css_file(base_url + "/js/fullcalendar-3.9.0/fullcalendar.css");

            common.load_js_list([
                `${base_url}/other/js/echarts.min.js`
            ], function() {
                callback()
            })
        } else {
            callback()
        }
    },

    load_fullcalendar_files(callback: any) {

        if (!(<any>$)["fullCalendar"]) {
            const base_url = "https://cc.mydaren.cn"
            common.load_css_file(`${base_url}/other/js/fullcalendar-3.9.0/fullcalendar.css`)

            common.load_js_list([
                `${base_url}/other/js/fullcalendar-3.9.0/lib/moment.min.js`,
                `${base_url}/other/js/fullcalendar-3.9.0/fullcalendar.js`,
                `${base_url}/other/js/fullcalendar-3.9.0/locale/zh-cn.js`
            ], function() {
                callback()
            })
        } else {
            callback()
        }
    },
    load_css_file(file: any) {
        const link = document.createElement("link")
        link.href = file
        link.rel = "stylesheet"
        document.getElementsByTagName("head")[0].appendChild(link)
    },


    // 异步加载 js
    load_js_list: common_ex.load_js_list,
    get_check_xlsx_field_value(table_list: Array<any>, regex: any) {

        var ret_list: Array<any> = []
        table_list.forEach(function(item) {
            var table_name = item[0]
            var table_data = item[1]
            var col_list: Array<string> = [
                "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
                "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ",
                "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ",
                "CA", "CB", "CC", "CD", "CE", "CF", "CG", "CH", "CI", "CJ", "CK", "CL", "CM", "CN", "CO", "CP", "CQ", "CR", "CS", "CT", "CU", "CV", "CW", "CX", "CY", "CZ",
                "DA", "DB", "DC", "DD", "DE", "DF", "DG", "DH", "DI", "DJ", "DK", "DL", "DM", "DN", "DO", "DP", "DQ", "DR", "DS", "DT", "DU", "DV", "DW", "DX", "DY", "DZ"
            ]
            for (var i = 0; i < col_list.length; i++) {
                var col_name: string = col_list[i]
                var key = `${col_name}1`
                if (table_data[key]) { // 存在该列
                    for (var j = 2; j < 1000; j++) {
                        var data_key = col_name + j
                        var cell = table_data[data_key]

                        if (cell && cell.v) {
                            if ((`${cell.v}`).match(regex)) {
                                ret_list.push({
                                    table_name: table_name,
                                    col: col_name,
                                    col_name: table_data[key].v
                                })
                                break
                            } else {
                            }
                        }
                    }
                } else {
                    break
                }
            }
        })
        return ret_list
    },

    read_xlsx(file: File, data_regex: any, cb: (table_list: Array<any>) => void) {

        console.log("KKKKKK", file)


        const xlsx_url = "https://libs.mydaren.cn/src/xlsx/0.15.1/xlsx.core.min.js"
        common.load_js_list([xlsx_url], function() {

            var fileReader = new FileReader()
            fileReader.onload = function(ev: any) {
                var table_list: Array<any> = []
                var XLSX = (<any>window)["XLSX"]
                try {

                    var data = ev.target.result,
                        workbook = XLSX.read(data, {
                            type: "binary"
                        }), // 以二进制流方式读取得到整份excel表格对象
                        table_list = [] // 存储获取到的数据
                } catch (e) {
                    console.log("文件类型不正确")
                    return
                }

                // 表格的表格范围，可用于判断表头是否数量是否正确
                // 遍历每张表读取
                for (var sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        workbook.Sheets[sheet]["!ref"]
                        // console.log(sheet, workbook.Sheets[sheet]),
                        table_list.push([
                            sheet,
                            workbook.Sheets[sheet]
                        ])
                        // break; // 如果只取第一张表，就取消注释这行
                    }
                }

                var field_list = common.get_check_xlsx_field_value(table_list, data_regex)
                var column_list = [{
                    label: "表格",
                    prop: "table_name"
                }, {
                    label: "字段",
                    prop: "col_name"
                }, {
                    label: "位置",
                    prop: "col"

                }]


                common.dlg.admin_table_select(field_list, column_list, function(row_list: Array<any>) {
                    var ret_list: Array<any> = []
                    row_list.forEach(function(item) {
                        var table_data = workbook.Sheets[item.table_name]
                        var col: string = item.col
                        console.log(table_data)
                        var data_list: Array<any> = []
                        var col_key_reg = new RegExp(`^${col}([0-9]+)`)
                        var max_row_index = 2
                        for (var col_key in table_data) {
                            var match_arr = col_key.match(col_key_reg)
                            if (match_arr) {
                                max_row_index = Math.max(max_row_index, parseInt(match_arr[1]))
                            }
                        }

                        var i = 2
                        while (i <= max_row_index) {
                            var cell_data = table_data[col + i]
                            if (cell_data) {
                                data_list.push(cell_data.v)
                            } else {
                                data_list.push("")
                            }
                            i++
                        }
                        ret_list.push({
                            name: `${item.table_name}--${item.col_name}`,
                            data_list: data_list
                        })

                    })
                    // XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
                    cb(ret_list)
                }, {
                    select_type: "multi_select",
                    gen_select_item_list_callback: function() {
                        return []
                    }

                })



            }

            // 以二进制方式打开文件
            fileReader.readAsBinaryString(file)
        })

    },

    do_ajax_get_nick: function(user_type: any, id: any, func: any) {
        common.do_ajax("/user_manage/get_nick", {
            type: user_type,
            id: id
        }, function(result: any) {
            const { nick } = result.data
            func(id, nick)
        })
    },

    row_opt_gen_link(gen_url_func: any, text: any, opt_key: any, target = "_self", config = {
    }): row_item_t {
        const default_config = {
            icon: "",
            title: "",
            visible: true
        }
        const obj_config = Object.assign(default_config, config)

        return {
            opt_key: opt_key,
            title: `${text}-${obj_config.title}`,
            visible: obj_config.visible,
            component: markRaw(common.row_opt_gen(function(row: any) {
                let url = gen_url_func(row)
                if (url[0] == "/") { // 本地
                    url = `/#${url}`
                }
                let icon_str = ""
                if (obj_config.icon) {
                    icon_str = `<i class="iconfont ${obj_config.icon} " />`
                }
                return `<a class="el-button el-button--text" style="margin-left:8px; margin-right:8px" href ="${url}" target="${target}" title="${obj_config.title}" >${icon_str}${text}</a>`
            }))
        }
    },

    // 简单的文本
    row_opt_gen_simgle_text: common_ex.row_opt_gen_simgle_text,
    // 生成 操作列,

    row_opt_gen(gen_html_str_func: any, click_callback: any = null) {

        return defineComponent({
            props: {
                row: Object
            },
            setup(props) {
                const html_str = computed(() => {
                    return gen_html_str_func(props.row)
                })
                const on_click = function() {
                    if (click_callback) {
                        click_callback(props.row)
                    }
                }

                return {
                    html_str,
                    on_click
                }
            },
            template: "<div v-html=\"html_str\" style=\"display:inline-block;\" @click=\"on_click\" ></div>"

        })

    },



    get_enum_list(desc_map: any) {

        const ret_list: Array<any> = []

        for (const key in desc_map) {

            const index = parseInt(key)
            if (index != 0) {
                ret_list.push(index)
            }

        }
        return ret_list
    },

    get_batch_info(data_list: Array<any>, step = 50) {
        const ret_list: Array<any> = []
        const count = data_list.length
        for (let i = 0; i < data_list.length; i = i + step) {
            ret_list.push({
                start_index: i,
                data: data_list.slice(i, i + step)
            })
        }
        return {
            count: count,
            step: step,
            list: ret_list
        }

    },
    do_batch_list: common_ex.do_batch_list,

    tree_row_to_origin(row: any) {
        return row.check_value
    },




    str_as_int_array: array.str_as_int_array,
    in_array: array.in_array,
    as_int_array: array.as_int_array,

    CONFIG: {
        SUCCESS_OK: 200 // 请求成功的状态码
    },


    gen_row_opt_component(btn_config: Array<any>) {
        const map: any = {}
        btn_config.forEach(function(item, _i) {
            const title = item.title ? item.title : ""
            const text = item.text ? item.text : ""
            const button_type = item.button_type ? item.button_type : "primary"
            const icon = item.icon ? item.icon : "question"
            item.title = title
            item.text = text
            item.button_type = button_type
            item.icon = icon
            if (!item.opt_key) {
                console.error("操作 按钮没有 opt_key 配置", common.copy_obj(item))
                return
            }

            if (map[item.opt_key] === undefined) {
                map[item.opt_key] = item
            } else { // 冲突
                console.error("两个操作 按钮没有 opt_key 配置 冲突", common.copy_obj(map[item.opt_key]), common.copy_obj(item))
                return
            }

        })
        return btn_config
    },

    // 检查等级
    // 0:组员, 1:组长, 2: ..  10:管理员
    check_min_admin_level(min_level: any) {
        const admin_level = this.get_admin_level()
        console.log(`admin level is :${admin_level}`)
        return this.get_admin_level() >= min_level
    },

    // 当前是不是管理员等级
    check_is_root_admin() {
        return this.get_admin_level() == enum_map.admin_level.V_10
    },

    safe_div_percent(v1: any, v2: any) {
        if (!(v2 > 0)) {
            return "--"
        }

        if (!(v1 > 0)) {
            return "0.00%"
        }

        return sprintf("%.2f%%", parseFloat(v1) / v2 * 100)
    },
    safe_div_percent_int(v1: any, v2: any) {
        if (!(v2 > 0)) {
            return "--"
        }

        if (!(v1 > 0)) {
            return "0%"
        }

        return sprintf("%d%%", parseFloat(v1) / v2 * 100)
    },

    safe_div_percent_intvalue(v1: any, v2: any) {
        if (!(v2 > 0)) {
            return -1
        }

        if (!(v1 > 0)) {
            return 0
        }

        return Math.floor(parseFloat(v1) / v2 * 100)
    },



    safe_div_percent_value(v1: any, v2: any) {
        if (!(v2 > 0)) {
            return 0
        }

        if (!(v1 > 0)) {
            return 0
        }
        return parseFloat(v1) / v2 * 100
    },
    safe_div(v1: any, v2: any): any {
        if (!(v2 > 0)) {
            return 0
        }

        if (!(v1 > 0)) {
            return 0
        }
        return parseFloat(v1) / v2
    },
    php_round(decimal: number, decimalPoints: number): number {
        const roundedValue = Math.round(decimal * Math.pow(10, decimalPoints)) / Math.pow(10, decimalPoints)

        return roundedValue
    },


    sprintf(format: string, ...args: any) {
        return sprintf(format, ...args)
    },


    // get_admin_group_query_list: common_ex.get_admin_group_query_list,

    gen_column_tip_item(label: any, prop: any, sortable: boolean, gen_text_func: any,
        tip_info_callback: any, config: Object = {}): column_item_t {
        const obj_config = Object.assign(
            {
                default_show: true,
                label_title: "",
                click_callback: null
            }
            , config)

        return {
            label: label, prop: prop,
            align: "right",
            sortable: sortable,
            default_show: obj_config.default_show,
            label_title: obj_config.label_title,
            component: common.row_opt_gen_simgle_text(
                gen_text_func,
                obj_config.click_callback, tip_info_callback, prop)
        }
    },

    gen_column_div_percent(label: any, v1_prop: string, v1_title: string, v2_prop: string, v2_title: string, data_sortable: any): column_item_t {
        const prop = `${v1_prop}__${v2_prop}`

        return common.gen_column_tip_item(label, prop, data_sortable, function(row: any, prop: string) {

            return common.row_div_percent_format(row, prop, row[v1_prop], row[v2_prop])
        }, function(row: any) {
            return `${v1_title}(${row[v1_prop]})/${v2_title}(${row[v2_prop]}) `
        }, {
            label_title: `${v1_title}/${v2_title}`,

            click_callback: function(row: any) {

                const msg = `${label}(${common.row_div_percent_format(row, prop, row[v1_prop], row[v2_prop])
                })=${v1_title}(${row[v1_prop]})/${v2_title}(${row[v2_prop]}) `
                common.alert(msg)
            }
        })
    },


    gen_column_div_percent_ex(label: any, v1_prop: string, v1_title: string, v2_prop: string, v2_title: string, data_sortable: any, flag: any = ""): column_item_t {
        const prop = `${v1_prop}__${v2_prop}`

        return common.gen_column_tip_item(label, prop, data_sortable, function(row: any, prop: string) {

            return common.row_div_percent_format_ex(row, prop, row[v1_prop], row[v2_prop], flag)
        }, function(row: any) {
            return `${v1_title}(${row[v1_prop]})/${v2_title}(${row[v2_prop]}) `
        }, {
            label_title: `${v1_title}/${v2_title}`,

            click_callback: function(row: any) {

                const msg = `${label}(${common.row_div_percent_format_ex(row, prop, row[v1_prop], row[v2_prop], flag)
                })=${v1_title}(${row[v1_prop]})/${v2_title}(${row[v2_prop]}) `
                common.alert(msg)
            }
        })
    },



    date_gen_time_format(seconds: any) {
        if (!(seconds > 0)) {
            seconds = 0
        }
        const hour = Math.floor(seconds / 3600)
        const min = Math.floor((seconds % 3600) / 60)
        const sec = seconds % 60

        if (hour) {
            return sprintf("%02d:%02d:%02d", hour, min, sec)
        } else {
            return sprintf("%02d:%02d", min, sec)
        }

    },
    // 相除,百分比
    row_div_percent_format(row: any, prop: any, v1: any, v2: any) {

        if (!(v2 > 0)) {
            row[prop] = null
            return "--"
        }

        if (!(v1 > 0)) {
            row[prop] = 0
        } else {
            row[prop] = parseFloat(v1) / v2 * 100
        }
        return common.sprintf("%.2f%%", row[prop])
    },

    // 相除,百分比
    row_div_percent_format_ex(row: any, prop: any, v1: any, v2: any, flag: any = "") {

        if (!(v2 > 0)) {
            row[prop] = null
            return "--"
        }

        // if (!(v1 > 0)) {
        //     row[prop] = 0;
        // } else {
        if (flag == "") {

            row[prop] = parseFloat(v1) / v2 * 100
            return common.sprintf("%.2f%%", row[prop])
        } else {

            row[prop] = parseFloat(v1) / v2
            return common.sprintf("%.2f", row[prop])
        }
        // }
    },
    row_div_int(row: any, prop: any, v1: any, v2: any) {

        if (!(v2 > 0)) {
            row[prop] = null
            return "--"
        }

        if (!(v1 > 0)) {
            row[prop] = 0
        } else {
            row[prop] = Math.round((parseFloat(v1) / v2))
        }
        return row[prop]
    },

    gen_column_url_with_dlg(label: any, prop: any, config: any = {}): column_item_t {



        const obj_config = Object.assign({
            gen_text_func: function(row: any) {
                if (row[prop]) {
                    return row[prop]
                } else {
                    return ""
                }
            },
            check_enable_click: function(row: any) {
                return !!row[prop]
            }

        }, config)
        return common.gen_column_text(label, prop, function(row: any) {
            common.dlg.show_dlg_iframe({
                url: row[prop]
            })
        }, obj_config)


    },




    gen_column_download(label: any, prop: any, text = "下载"): column_item_t {

        return common.gen_column_text(label, prop, function(row: any) {
            common.wopen(row[prop])
        }, {
            gen_text_func: function(row: any) {
                if (row[prop]) {
                    return text
                } else {
                    return ""
                }
            },

            check_enable_click: function(row: any) {
                return !!row[prop]

            }
        })


    },
    gen_column_img(label: any, prop: any, width: number, height: number): column_item_t {


        return common.gen_column_text(label, prop, function(row: any) {

            common.dlg.show_dlg_img({
                url: row[prop]
            })

        }, {

            gen_text_func: function(row: any) {
                const v = row[prop]
                if (v) {
                    return `<img src="${v}" style="width:${width}px; height: ${height}px;  " />`
                } else {
                    return "无"
                }

            },
            min_width: width + 20
        })


    },

    gen_column_text(label: any, prop: any, click_callback?: any, config: any = {}): column_item_t {
        const obj_config = Object.assign(
            {
                gen_text_func: function(row: any, prop: string) { return row[prop] },
                sortable: true,
                label_title: undefined,

                filter_enum_multiple: undefined,
                filter_enum_type: undefined,
                filter_default_value: undefined,

                check_enable_click: function(_row: any, _prop: string) { return true },
                align: "right",
                click_temlplate: undefined,
                min_width: undefined

            }
            , config)


        return {
            label: label, prop: prop,
            align: obj_config.align,
            sortable: obj_config.sortable,
            visible: obj_config.visible,
            label_title: obj_config.label_title,
            component: markRaw(common.row_opt_gen_simgle_text(
                obj_config.gen_text_func,

                {
                    click_callback: click_callback,
                    prop: prop,
                    check_enable_click: obj_config.check_enable_click,
                    click_temlplate: obj_config.click_temlplate,
                    show_null_flag: true

                })),

            filter_enum_multiple: obj_config.filter_enum_multiple,
            filter_enum_type: obj_config.filter_enum_type,
            filter_default_value: obj_config.filter_default_value,
            min_width: obj_config.min_width


        }
    },

    gen_column_number_item: common_ex.gen_column_number_item,

    gen_column_account_item(label: string, userid_prop: string, text_prop: string, filter_enum_type?: string): any {

        return {
            label: label, prop: userid_prop,
            width: "120px",
            filter_enum_type: filter_enum_type,
            component: markRaw(common.row_opt_gen_simgle_text(
                function(row: any) { return row[text_prop] },
                function(_row: any) {
                    // common.dlg.show_teacher_info(row[userid_prop]);
                }, null, userid_prop))
        }
    },

    gen_column_enum: function(field_name: string, label: string, config?: any): column_item_t {
        const default_config = {
            sortable: true,
            width: null,

            filter_enum_multiple: undefined,// 用于过滤的默认值
            filter_default_value: <any>-1,// 用于过滤的默认值
            filter_enum_type: undefined,// 用于过滤的默认值
            color_config: null // 或者  枚举名 ,在  ./enum_color_config.ts 中的key
        }

        const obj_config = Object.assign(default_config, config)
        if (obj_config.filter_enum_type === undefined) {
            obj_config.filter_enum_type = field_name
        }

        return {
            label: label,
            prop: field_name,
            sortable: obj_config.sortable,
            width: obj_config.width,
            formater: function(v: any, row: any) {

                var ret = row[`${field_name}_str`]
                if (ret === undefined) {
                    ret = (<any>common.enum_map)[obj_config.filter_enum_type]["desc_map"][v] || `未定义-(${v})`

                }
                ret = common.enum_get_color_str(ret, v, obj_config.filter_enum_type, obj_config.color_config)
                return ret
            },

            filter_enum_multiple: obj_config.filter_enum_multiple,
            filter_enum_type: obj_config.filter_enum_type,
            filter_default_value: obj_config.filter_default_value

        }
    },
    enum_get_color_str(desc: string, value: any, enum_name: string, color_config: any) {
        if (sys_util.isString(color_config)) {
            color_config = (<any>enum_color_config)[color_config]
        } else if (sys_util.isNull(color_config)) {
            color_config = (<any>enum_color_config)[enum_name]
        }
        if (sys_util.isObject(color_config)) {

            var color = color_config[value]
            if (color) {
                return `<span style="color:${color};">${desc}</span>`
            }
        }
        return desc
    },
    gen_column_level_list(item_list: Array<string>): any {
        return item_list.map(function(item: any, i) {
            return {
                label: item,
                prop: `l_${i}_node_name`
            }
        })

    },

    gen_column_daren_item: common_ex.gen_column_daren_item,
    gen_column_bd_shop: common_ex.gen_column_bd_shop,
    gen_column_id_text_item: common_ex.gen_column_id_text_item,
    gen_column_task_time_item: common_ex.gen_column_task_time_item,



    gen_tree_field_render_header: common_ex.gen_tree_field_render_header,

    cookie_set(key: string, value: string) {

        // return Cookies.set(TokenKey, token, { domain: '.mydaren.cn' })
        return Cookies.set(key, value, { domain: ".mydaren.cn" })

    },
    check_test() {
        const host = window.location.hostname.toString()
        // 测试是 sho-cc/shd-cc/sht-cc /self.admin
        if (host.indexOf("-cc.mydaren.cn") != -1 || host.indexOf("self.admin.mydaren.cn") != -1) {
            return true
        } else {
            return false
        }
    },


    check_release() {


        const host = window.location.hostname.toString()
        if (host.indexOf("admin.mydaren.cn") != -1) {
            return true
        } else {
            return false
        }


    },

    is_exits_variable(variable_name: any) {
        if (typeof (variable_name) == "undefined") {
            return false
        } else {
            return true
        }
    },



    // 自动消失的弹框
    auto_close_alert(type: any = "", msg: any = "", duration: any = 3000, cbfunc: any = {}) {

        if (type === "") {
            type = "success"
        }
        if (msg === "") {
            msg = "操作成功!"
        }
        const options: any = {
            duration: duration,
            type: type,
            message: msg
        }
        if (sys_util.isFunction(cbfunc)) {
            options["onClose"] = cbfunc
        }
        return ElMessage(options)

    },
    /**
     * 更多参数配置参考
     * https://element.eleme.cn/#/zh-CN/component/loading#loading-jia-zai
     *
     * text 展示 loading 的默认文案
     */
    show_loading(text = "请求数据中，请稍后") {
        const loading = ElLoading.service({
            lock: true,
            text: text,
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.5)"
        })

        return loading
    },

    get_field_list_str_from_array(arr: Array<any>, field_name: string) {
        return array.get_field_list_str_from_array(arr, field_name)
    },

    array_as_map: array.array_as_map,


    col_date_formater(value: any) {
        return common.date_format(value)
    },

    download_excel: common_ex.download_excel,



    check_in_weixin() {
        const ua = navigator.userAgent.toLowerCase()
        if (ua.indexOf("micromessenger") > 0) {
            return true
        } else {
            return false
        }
    },

    /** 图片压缩，默认同比例压缩
     *  @param {Object} fileObj
     *  @param {Object} config
     *  图片对象
     *  回调函数有一个参数，base64的字符串数据
     */
    compress(fileObj: any, config: any = {}, callback: Function) {
        const me = this
        try {
            const image = new Image()
            image.src = URL.createObjectURL(fileObj)
            image.onload = function() {
                const that: any = this
                // 默认按比例压缩
                let w = config.upload_width || that.width
                let h = config.upload_height || that.height
                const scale = w / h
                w = fileObj.width || w
                h = fileObj.height || (w / scale)
                let quality = 0.7 // 默认图片质量为0.7
                // 生成canvas
                const canvas = document.createElement("canvas")
                const ctx: any = canvas.getContext("2d")
                // 创建属性节点
                const anw = document.createAttribute("width")
                anw.nodeValue = w
                const anh = document.createAttribute("height")
                anh.nodeValue = h
                canvas.setAttributeNode(anw)
                canvas.setAttributeNode(anh)
                ctx.drawImage(that, 0, 0, w, h)
                // 图像质量
                if (fileObj.quality && fileObj.quality <= 1 && fileObj.quality > 0) {
                    quality = fileObj.quality
                }
                // quality值越小，所绘制出的图像越模糊
                const data = canvas.toDataURL(fileObj.type, quality)
                // 压缩完成执行回调
                // const newFile = me.convertBase64UrlToBuffer(data, fileObj.name)
                const newFile = me.base64ConvertFile(data)
                callback(newFile)
            }
        } catch (e) {
            console.log("压缩失败!")
        }
    },
    base64ConvertFile(this: any, urlData: any) { // 64转file
        const me: any = this
        if (typeof urlData != "string") {
            me.$toast("urlData不是字符串")
            return
        }
        const arr: any = urlData.split(",")
        const type = arr[0].match(/:(.*?);/)[1]
        const fileExt = type.split("/")[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], `filename.${fileExt}`, {
            type: type
        })
    },
    convertBase64UrlToBuffer(urlData: any, _name: any) {
        const bytes = window.atob(urlData.split(",")[1]) // 去掉url的头，并转换为byte

        const mimeString = urlData.split(",")[0].split(":")[1].split(";")[0]
        // 处理异常,将ascii码小于0的转换为大于0
        const ab = new ArrayBuffer(bytes.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i)
        }
        return new Blob([ab], { type: mimeString })
    },
    tree_row_to_admin_group_list(row: any, admin_group_tongji_level: any, tongji_type?: number) {
        let value_list: Array<any> = common.copy_obj(row.value_list)
        if (row.name == "[全部]" || (tongji_type != 1 && tongji_type !== undefined)) { // 全部,或者统计分类不是组织
            return common.get_query_args().admin_group_list
        } else if (!value_list) {
            value_list = [0, 0, 0, row.check_value] // 个人
        } else {
            switch (admin_group_tongji_level * 1) {
            case common.enum_map.admin_group_tongji_level.V_2:
                value_list.unshift("-1")
                break
            case common.enum_map.admin_group_tongji_level.V_1:
                value_list.unshift("-1")
                value_list.unshift("-1")
                break
            }

        }

        value_list.unshift("-1")
        return value_list.join(",")
    },

    // 创建并下载文件
    create_and_download_file(fileName: string, content: any) {
        const aTag = document.createElement("a")
        const blob: any = new Blob([content])
        aTag.download = fileName
        aTag.href = URL.createObjectURL(blob)
        aTag.click()
        URL.revokeObjectURL(blob)
    },

    create_download_link(fileName: string, content: any) {
        fetch(content).then(res => res.blob())
            .then(blob => {
                const a = document.createElement("a")
                document.body.appendChild(a)
                a.style.display = "none"
                const url = window.URL.createObjectURL(blob)
                a.href = url
                a.download = fileName
                a.click()
                document.body.removeChild(a)
                window.URL.revokeObjectURL(url)
            })
    },

    get_col_img_formater(width: any, height: any) {
        return function(v: any) {
            if (v) {
                return `<img src="${v}" style="width:${width}px; height: ${height}px;  " />`
            } else {
                return "无"
            }
        }
    },

    check_slot(slot: any) {
        if (slot) {
            slot = slot()
            return slot.length > 0
        }
        return false
    },
    get_router() {
        return router
        // useRouter
    },
    store_dispatch(type: string, payload?: any, options?: DispatchOptions) {
        store.dispatch(type, payload, options)
    },
    store_dispatch_tool_bar_opt_config(tool_bar_opt_config: row_item_list_t) {
        this.store_dispatch("layout/set_tool_bar_opt_config", common.gen_row_opt_component(tool_bar_opt_config))
    },

    get_full_url(path: string) {
        return common.path_url_map[path] || path

    },
    JsonFormatStyle(json: any) {
        if (typeof json != "string") {
            json = JSON.stringify(json, undefined, 2)
        }
        var color_config = <any>{
            string: "green",
            number: "darkorange",
            boolean: "blue",
            null: "magenta",
            key: "red"
        }


        json = json.replace(/&/g, "&").replace(/</g, "<")
            .replace(/>/g, ">")
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match: any) {
            var cls = "number"
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = "key"
                } else {
                    cls = "string"
                }
            } else if (/true|false/.test(match)) {
                cls = "boolean"
            } else if (/null/.test(match)) {
                cls = "null"
            }

            return `<span style="color:${color_config[cls]}">${match}</span>`
        })
    },

    get_pic_base64(content: any) {
        var canvas = document.createElement("canvas")

        var textAlign = "center"
        var textBaseline = "middle"
        var font = "20px microsoft yahei"
        var fillStyle = "rgba(184, 184, 184, 0.1)"
        var rotate: any = "30"


        canvas.setAttribute("width", "100px")
        canvas.setAttribute("height", "100px")
        var ctx: any = canvas.getContext("2d")

        ctx.textAlign = textAlign
        ctx.textBaseline = textBaseline
        ctx.font = font
        ctx.fillStyle = fillStyle
        ctx.rotate(Math.PI / 180 * rotate)
        ctx.fillText(content, 100 / 2, 100 / 2)
        var base64Url = canvas.toDataURL()
        return base64Url

    },

    get_vue_route(_this: any): any {
        return _this["$route"]
    },
    approval_column_formater(control_type: any) {
        var formater = function(v: any) {
            return v
        }
        var control_type = control_type
        if (control_type == "Date") {
            formater = function(v: any) {
                return common.date_format(v, true)
            }
        } else if (control_type == "Selector") {
            formater = function(v: any) {
                try {
                    var list: Array<any> = JSON.parse(v)
                    return list.join(",")
                } catch (e) {
                    return `<font color=red>异常</font>：${v}`
                }
            }
        } else if (control_type == "Vacation") {
            formater = function(v: any) {
                // "date_range":{"type":"hour","new_begin":1631266200,"new_end":1631274600,"new_duration":8640}

                try {
                    var obj = JSON.parse(v)
                    var date_range = obj.attendance.date_range

                    var type_str = obj.selector.options[0].value[0].text
                    var ret = `<span  style="color:blue;" >${type_str}</span><br/>`

                    var dare_range_type = date_range.type
                    if (dare_range_type == "hour") { // 按小时
                        ret += ` 时长:<span style="color:red;">  ${common.sprintf("%.1f", date_range.new_duration / (3600.0))}</span>小时<br/>${common.date_format(date_range.new_begin, "MM-dd hh:mm ")} 到 ${common.date_format(date_range.new_end, "MM-dd hh:mm")}`

                    } else {

                        ret += ` 时长: <span style="color:red;" > ${common.sprintf("%.1f", date_range.new_duration / (3600.0 * 24))} </span>天<br/ > ${common.date_format(date_range.new_begin, "MM-dd ")}${common.date_format(date_range.new_begin, "hh:mm") == "00:00" ? "上午" : "下午"} 到 ${common.date_format(date_range.new_end, "MM-dd ")}${common.date_format(date_range.new_end, "hh:mm") == "00:00" ? "上午" : "下午"} `

                    }
                    return ret





                } catch (e) {
                    return `< font color = red > 异常 < /font>：${v}`
                }


            }


        } else if (control_type == "Attendance") {
            formater = function(v: any) {
                // "date_range":{"type":"hour","new_begin":1631266200,"new_end":1631274600,"new_duration":8640}

                try {
                    var obj = JSON.parse(v)
                    var date_range = obj.date_range

                    return ` 时长:<span style="color:red;">${common.sprintf("%.1f", date_range.new_duration / 3600.0)}</span>小时<br/>${common.date_format(date_range.new_begin, "MM-dd hh:mm")
                    }~${common.date_format(date_range.new_end, "MM-dd hh:mm")}`

                } catch (e) {
                    return `<font color=red>异常</font>：${v}`
                }


            }

        } else if (control_type == "File") {
            formater = function(v: any) {
                if (v.length < 3) {
                    return `${v}个`
                } else {
                    return ""
                }
            }
        }
        return formater

    },
    gen_args_with_query_args<T>(default_args: T): T {
        var args: any = {}
        for (var key in default_args) {
            var item = default_args[key]
            if (isObject(item)) {
                args[key] = this.gen_args_with_query_args(item)
            } else {
                args[key] = this.get_query_arg_str_value(key, item)
            }
        }
        return args

    },
    set_query_str(args: any) {
        const ret_args: any = {}
        for (const k in args) {
            const v = args[k]
            if (util.isArray(v)) {
                ret_args[k] = v.join(",")
            } else {
                ret_args[k] = v
            }
        }


        const query_str = common.queryString.stringify(ret_args)

        const url = `/${window.location.hash.replace(/\?.*/, "")}?${query_str}`
        window.history.replaceState({}, "", url)
    },
    open_sub_page_with_back_url(path: string, args: any) {
        args["back_url"] = window.location.hash.substring(1)

        const url = `${path}?${common.queryString.stringify(args)}`
        // var brower = common.detect_browser(false)
        var open_self_window: any = "new_window"

        var platform = (<any>navigator).platform
        // 在浏览器里使用
        if (this.in_array(platform, ["MacIntel", "Linux x86_64", "Win32"])) {
            open_self_window = true
        }
        this.wopen(url, open_self_window)
    },


    check_in_mobile_page() { // 是否在专门的移动端页面
        return !store.state.layout.menubar.show_navbar
    },

    ex_send_message(message: any) {

        var event = document.createEvent("MessageEvent");
        (<any>event).initMessageEvent("account-extend-message", true, true, message)
        console.log("disp", event)
        document.dispatchEvent(event)
    },

    chrome_ex_call_map: <any>{},
    chrome_ex_g_request_id: 0,

    chrome_ex_min_version: {
        dp_sync_user: 5
    },
    chrome_ex_init(min_version: number, cb: any) {
        var need_timeout_flag = true
        document.addEventListener("chrome_ex_api_callback", (e: any) => {
            console.log("get chrome_ex_api_callback", e)
            var message = e.data
            var request_id = message.request_id
            if (this.chrome_ex_call_map[request_id]) {
                console.log("call  function ")
                this.chrome_ex_call_map[request_id](message)
                delete this.chrome_ex_call_map[request_id]
            } else {
                console.log("未处理:", e)
            }

        })
        common.chrome_ex_call_api("getVersion", {}, (resp: any) => {
            console.log("call back getVersion ")
            need_timeout_flag = false
            if (resp.version >= min_version) {
                cb({
                    flag: true,
                    msg: ""
                })
            } else {
                cb({
                    flag: false,
                    msg: "版本不匹配"
                })
            }
        })

        setTimeout(() => {
            if (need_timeout_flag) {
                cb({
                    flag: false,
                    msg: "未安装或版本太低"
                })
            }
        }, 2000)


    },
    // 扩展插件
    chrome_ex_call_api(msg_type: "getVersion" | "fetch" | "getCookiesByDomain", params: any, cb: any) {
        this.chrome_ex_g_request_id++

        params = params || {}
        params.msg_type = msg_type
        var request_id = this.chrome_ex_g_request_id
        params.request_id = request_id
        this.chrome_ex_call_map[request_id] = cb

        var event = document.createEvent("MessageEvent");
        (<any>event).initMessageEvent("chrome_ex_api", true, true, params)
        console.log("disp", event)
        document.dispatchEvent(event)


    },

    id_to_name_from_tree(val_list: Array<any>, options: Array<any>) {
        val_list = common.copy_obj(val_list)

        var val = val_list.shift()
        var item = {}
        var ret: any = []
        options.some(function(node) {
            if (node.value == val) {
                var sub_list: any = []
                if (node.children) {
                    sub_list = common.id_to_name_from_tree(val_list, node.children)
                }
                item = node
                ret = [item, ...sub_list]
                return true // 不用继续
            }
            return false
        })
        return ret
    },
    open_download_dialog(url: string | Blob, saveName: string) {
        if (typeof url == "object" && url instanceof Blob) {
            url = URL.createObjectURL(url) // 创建blob地址
        }
        var aLink = document.createElement("a")
        aLink.href = url
        aLink.download = saveName || "" // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
        var event
        if (window.MouseEvent) {
            event = new MouseEvent("click")
        }
        else {
            event = document.createEvent("MouseEvents")
            event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
        }
        aLink.dispatchEvent(event)
    }


}



export default common
