import pic_list_item from "./pic_list_item.vue"
import admin_alioss_upload from "./alioss_upload.vue"
import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    upload_width: number
    upload_height: number
    pic_width: number
    pic_height: number
    modelValue: Array<any>
    limit: number
    with_title_flag: boolean
}




export default defineComponent({
    components: {
        "pic-list-item": pic_list_item,
        "admin-alioss-upload": admin_alioss_upload
    },

    props: {
        modelValue: {
            type: Array,
            required: true,
            default: function() {
                return []
            }
        },
        with_title_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }

        },
        limit: {
            type: Number,
            required: false,
            default: function() {
                return 5
            }

        },
        pic_width: {
            type: Number,
            required: false,
            default: function() {
                return 200
            }
        },

        file_name_fix: {
            type: String,
            required: false,
            default: function() {
                return "pic_"
            }
        },



        pic_height: {
            type: Number,
            required: false,
            default: function() {
                return 100
            }

        },
        upload_width: {
            type: Number,
            required: false,
            default: function() {
                return 300
            }
        },

        upload_height: {
            type: Number,
            required: false,
            default: function() {
                return 300
            }

        }

    },
    // event
    emits: ["update:modelValue"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            pic_one_file: ""
        }
    },
    computed: {
        limit_file_width_height: function(): any {
            if (this.props.upload_width && this.props.upload_height) {
                return [this.props.upload_width, this.props.upload_height]
            } else {
                return []
            }
        },
        card_btn_style: function(): any {
            return {
                "margin-top": `${this.props.pic_height / 2 - 20}px`,
                "margin-bottom": `${this.props.pic_height / 2 - 20}px`
            }
        },
        card_style: function(): any {
            return {
                width: `${this.props.pic_width}px`,
                height: `${this.props.pic_height}px`,
                display: "inline-block",
                padding: "0px"
            }

        }


    },

    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_created() {
        },
        on_mounted() {
        },

        on_pic_success() {
            if (this.props.with_title_flag) {
                this.props.modelValue.push({
                    url: this.$data.pic_one_file,
                    title: ""
                })
            } else {
                this.props.modelValue.push(this.$data.pic_one_file)
            }
            this.emit_change(this.props.modelValue)
        },
        emit_change(modelValue: any) {
            // this.props.modelValue = modelValue;
            this.$emit("update:modelValue", modelValue)
        },
        title_change() {
            this.$emit("update:modelValue", this.props.modelValue)
        },


        item_opt(opt_info: any) {
            const { opt_type } = opt_info
            const { index } = opt_info
            let url_list: Array<string> = common.copy_obj(this.props.modelValue)
            if (opt_type == "del") {
                url_list.splice(index, 1)
            } else if (opt_type == "left") {
                var tmp = url_list[index - 1]
                url_list[index - 1] = url_list[index]
                url_list[index] = tmp
                url_list = common.copy_obj(url_list)
            } else if (opt_type == "right") {
                var tmp = url_list[index + 1]
                url_list[index + 1] = url_list[index]
                url_list[index] = tmp
                url_list = common.copy_obj(url_list)
            }

            this.emit_change(url_list)
        }


    }


})
