<template>
    <div
        v-if="show_item_list_flag || always_show_list"
        class="_row_opt"
    >
        <el-button
            v-if="!use_in_toolbar"
            type="text"
            title="竖向显示"
            icon="el-icon-setting"
            @click="on_show_desc"
        />

        <template
            v-for=" item in cur_option_list "
            :key="item.opt_key"
        >
            <component
                :is="item.component?item.component:button"
                v-if="check_col_show(item )"
                :type="item.button_type?item.button_type:&apos;primary&apos;"
                :title="get_title(item )"
                :icon="&apos;iconfont &apos; + get_icon( item) "
                :size="item.size"
                :row="row"
                :class="&apos;_row_opt__&apos;+item.opt_key + &apos; &apos; + item.class "
                href="javascript:;"

                style="margin-left:8px;"
                @click="on_click(item)"
            >
                <span v-html="get_text(item)" />
            </component>
        </template>


        <el-button
            v-if="show_more_type==&apos;inline&apos; && option_list.length>selected_length"
            type="text"
            :title="value?&apos;收缩&apos;:&apos;展开&apos;"
            :icon="value?&apos;el-icon-d-arrow-left&apos;: &apos;el-icon-d-arrow-right&apos; "
            @click="do_open"
        />


        <el-popover
            v-else-if=" (!use_in_toolbar && option_list.length>selected_length) || (in_phone && (use_in_toolbar && option_list.length>0 ) ) "
            placement="bottom"
            width="200"
            trigger="click"
            content=""
        >
            <div class="_popover_btn_list">
                <template
                    v-for=" (item, index) in cur_option_list "
                    :key="item.opt_key"
                >
                    <component
                        :is="item.component?item.component:button"
                        v-if="check_dropdown_col_show(item )"
                        class="_opt_item"
                        :type="item.button_type?item.button_type:&apos;primary&apos;" 
                        :title="get_title(item )"
                        :icon="&apos;iconfont &apos; + get_icon( item) "
                        :row="row"
                        :size="item.size"
                        :class="item.class"
                        href="javascript:;"
                        :style="{ &apos;margin-left&apos;:&apos;10px;&apos; }"
                        @click="on_click(item)"
                    >
                        <span v-html="get_text(item)||get_title(item)" />
                    </component>
                </template>
            </div>
            <template #reference>
                <el-button
                    type="text"
                    style="margin-left:8px"
                    icon="iconfont iconxiangxia6 "
                />
            </template>
        </el-popover>
    </div>
    <div v-else>
        <el-button
            type="text"
            icon=" el-icon-d-arrow-right "
            @click="always_show_list=true;"
        />
    </div>
</template>

<script>
import m from "./row_opt_list.ts"
export default m
</script>
<style scoped >
 ._opt_item  {
     margin-left: 10px;
 }
</style>
