import admin_vue from "@/components/Admin/admin_vue"

import {
    field_item_list_t,
    column_item_list_t,
    row_item_list_t
} from "@/utils/type_def"
import { defineComponent } from "vue"
import common from "/@/utils/common"
import enum_map from "/@/utils/enum_map"

interface IProps {
  bd_shop_id: any;
}

export default defineComponent({
    components: {},
    extends: admin_vue,

    props: {
        bd_shop_id: Number
    },
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props
        }
    },

    data: function() {
        return {
            query_filter_item_list: <field_item_list_t>[],
            row_opt_btn_config: <row_item_list_t>[],
            column_list: <column_item_list_t>[],
            default_sort: {
                prop: "create_time",
                order: "desc"
            },
            url_args_ex: {
                bd_shop_id: 0
            },
            location_position: ""
        }
    },

    created() {
        this.on_created()
    },

    methods: {
        on_created() {
            var me = this
            var bd_shop_id = this.props.bd_shop_id || common.get_query_args().bd_shop_id
            me.$data.url_args_ex.bd_shop_id = bd_shop_id
            this.$data.query_filter_item_list = me.get_query_filter_item_list()
            me.$data.row_opt_btn_config = me.get_row_opt_btn_config()
            me.$data.column_list = me.get_column_list()
            const url = "//api.map.baidu.com/api?v=2.0&ak=2Q3sjom3q5g0xv7DiBm7GIdIERhLti0b&callback=initialize"

            if (window.hasOwnProperty("BMap")) {
                me.get_location()
            } else {
                common.load_js_list([url], () => {
                    me.get_location()
                })
            }
        },

        get_bd_shop_id() {
            return this.$data.url_args_ex.bd_shop_id
        },

        get_row_opt_btn_config() {
            var me = this
            return [{
                title: "编辑",
                text: "编辑",
                opt_key: "opt_edit",
                callback: (row:any) => {
                    console.log("row is ")
                    console.log(row)
                    me.opt_edit("set", row)
                }
            }]
        },

        get_location() {
            const me = this
            var b_map = (<any>window)["BMap"]
            if (b_map.hasOwnProperty("Geolocation")) {
                var geolocation = new b_map.Geolocation()
                // 开启SDK辅助定位
                geolocation.enableSDKLocation()
                geolocation.getCurrentPosition(function(this: any, r: any) {
                    if (this.getStatus() == 0) {
                        me.$data.location_position = `${r.point.lat},${r.point.lng}`
                        console.log(me.$data.location_position)
                    } else {
                    }
                })
            } else {
                setTimeout(() => {
                    this.get_location()
                }, 300)
            }
        },

        get_column_list(): column_item_list_t {
            return [
                {
                    label: "跟进时间",
                    sortable: true,
                    prop: "create_time",
                    min_width: "160px"
                },
                {
                    label: "跟进人",
                    prop: "admin_nick"
                },
                {
                    label: "意愿",
                    prop: "bd_shop_will_level",
                    formater: (value) => {
                        return enum_map.bd_shop_will_level.desc_map[value]
                    }
                },
                {
                    label: "拜访状态",
                    prop: "follow_type",
                    formater: (value) => {
                        return enum_map.follow_type.desc_map[value] ?? "未设置"
                    }
                },
                {
                    label: "跟进内容 ",
                    prop: "info"
                },
                {
                    label: "拜访人",
                    prop: "follow_user"
                },
                {
                    label: "拜访人职位",
                    prop: "follow_user"
                },
                {
                    label: "拜访人电话",
                    prop: "follow_mobile"
                },
                {
                    label: "跟进图片",
                    prop: "shop_img_url",
                    min_width: "150px",
                    formater: (value) => {
                        const pic_list = common.array.str_as_array(value)
                        let ret = ""
                        pic_list.forEach((item: string) => {
                            ret += `<img src="${item}" width=100 height=100 style="margin-left:5px;object-fit:cover;" />`
                        })
                        return ret
                    }
                },
                {
                    label: "下次跟进时间",
                    sortable: true,
                    min_width: "160px",
                    prop: "next_follow_time"
                }
            ]
        },

        get_query_filter_item_list(): field_item_list_t {
            return [
                {
                    field_type: "user_select",
                    field_name: "oper_id",
                    user_type: "account",
                    default_show: true,
                    value: -1,
                    label: "跟进人"
                }
            ]
        },

        opt_add() {
            this.opt_edit("add")
        },

        opt_edit(opt_type:string, row:any = []) {
            var field_list: field_item_list_t = [
                {
                    field_type: "select",
                    field_name: "bd_shop_will_level",
                    value: row.bd_shop_will_level,
                    enum_type: "bd_shop_will_level",
                    label: "意愿"
                },
                {
                    field_type: "select",
                    field_name: "follow_type",
                    value: row.follow_type,
                    enum_type: "follow_type",
                    label: "拜访状态"
                },
                {
                    field_type: "text",
                    field_name: "info",
                    value: row.info,
                    type: "textarea",
                    rows: 5,
                    label: "跟进信息"
                },
                {
                    field_type: "pic_list",
                    field_name: "shop_img_url",
                    value: common.array.str_as_array(row.shop_img_url),
                    label: "跟进图片",
                    pic_height: 200,
                    pic_width: 200,
                    limit: 1
                },
                {
                    field_type: "text",
                    field_name: "follow_user",
                    value: row.follow_user,
                    label: "拜访人"
                },
                {
                    field_type: "text",
                    field_name: "follow_position",
                    value: row.follow_position,
                    label: "拜访人职位"
                },
                {
                    field_type: "text",
                    field_name: "follow_mobile",
                    value: row.follow_mobile,
                    label: "拜访人电话"
                },
                {
                    field_type: "date",
                    field_name: "next_follow_time",
                    value: row.next_follow_time,
                    label: "下次跟进时间",
                    format: "yyyy-MM-dd",
                    type: "date"
                }
            ]
            var data = {}
            var me = this
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: "跟进",
                width: "800px",
                submit: function(data: any, _btn_config: any) {
                    data.bd_shop_id = me.get_bd_shop_id()
                    data.shop_img_url = data.shop_img_url.join(",")
                    data.opt_type = opt_type
                    data.bd_shop_follow_id = row.bd_shop_follow_id
                    me.do_ajax("/bd_deal/add_follow", data, function() {
                        me.reload()
                    })
                    dlg.close()
                }
            })
        }
    }
})
