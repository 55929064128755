import { store } from "/@/store/index"
import axios from "axios"
import { AxiosResponse } from "axios"
import { ElLoading, ElNotification } from "element-plus"
import { getToken } from "./auth"

let loading: { close(): void }
console.log("KKKKKK", import.meta.env)
// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: import.meta.env.VITE_APP_BASE_API as string || "/api",
    timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error: { message: string }) => {
    loading.close()
    console.log(`err${error}`)
    ElNotification({
        title: "请求失败",
        message: error.message,
        type: "error"
    })
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    loading = ElLoading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.4)"
    })

    config.headers["Session-Id"] = getToken()
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response: AxiosResponse<IResponse>) => {
    const { data } = response
    loading.close()
    if (data.code !== 0) {
        let title = "请求失败"
        if (data.Code === 401) {
            if (getToken()) {
                store.commit("layout/logout")
            }
            title = "身份认证失败"
        }
        ElNotification({
            title,
            message: data.Msg,
            type: "error"
        })
        return Promise.reject(new Error(data.Msg || "Error"))
    }
    return response
}, errorHandler)

export default request
