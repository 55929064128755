<template>
    <div>
        <admin-query-input-list
            v-if=" show_query_input && ((query_filter_item_list.length>0 && error_msg_list.length==0) || check_slot_query_input()) "
            ref="query_input_list"
            :query_filter_item_list="query_filter_item_list"
            :show_config="show_query_config"
            :url="get_ajax_url()"
            :config_fix="config_fix"
            :use_url_args_and_page_config="use_url_args_and_page_config"
            :query_at_time_flag="query_at_time_flag"
            :show_query_button="show_query_button"
            @query="onQuery"
        >
            <slot name="query-input" />
        </admin-query-input-list>

        <el-row v-if="error_msg_list.length>0">
            <div style="border-radius: 4px; padding: 20px; margin: 5px 0; height: 74px; box-sizing: border-box; color: #fff; font-size: 20px; background-color: #f56c6c;">
                <div 
                    v-for="(msg, index) in error_msg_list"
                    :key="index"
                >
                    {{ msg }}
                </div>
            </div>
        </el-row>

        <el-row
            v-if="show_table_flag && data_show_table_flag && error_msg_list.length==0 "
            :gutter="20"
        >
            <slot name="left" />
            <el-col
                :span="col_span"
                :xs="24"
            >
                <el-row>
                    <el-col
                        :span="24"
                        class="toolbar"
                        style="padding: 5px;"
                    >
                        <slot name="toolbar" />
                    </el-col>
                </el-row>

                <div v-if="check_slot_row_list()">
                    <slot
                        name="row-list"
                        :data_list="data_list"
                    />
                </div>

                <el-table
                    v-else
                    id="_id_admin_table"
                    ref="table"
                    v-loading="listLoading"
                    :data="data_list"
                    :element-loading-text="loading_text"
                    :data-loading-status="listLoading===null?&quot;init&quot;:(listLoading?&quot;start&quot;:&quot;end&quot;)"
                    border
                    fit
                    :default-sort="{prop: sort , order: order==&quot;asc&quot; ? &quot;ascending&quot; : &quot;descending&quot;}"
                    :default-expand-all="true"
                    :cell-style="cell_style"
                    :row-style="row_style"
                    :header-row-style="header_row_style"
                    :height="data_table_height?data_table_height:null"
                    :row-key=" check_tree_table()? &quot;_id&quot;:&quot;&quot; "
                    lazy
                    :load="tree_lazy_load"
                    :indent="8"
                    :row-class-name="table_row_class_name"
                    :highlight-current-row="select_type==&quot;single_select&quot;"
                    :class="table_class"
                    @sort-change="on_sort_change"
                    @current-change="on_current_change"
                    @cell-click="on_cell_click"
                    @row-click="on_row_click"
                    @cell-mouse-leave="on_cell_mouse_leave"
                    @header-dragend="on_header_dragend"
                    @selection-change="handleSelectionChange"
                    @filter-change="filter_change"
                >
                    <el-table-column
                        v-if="show_option_list && in_phone && !check_tree_table() "
                        label="__LEFT_OP"
                        width="40px"
                        :fixed="true"
                    >
                        <template #header="scope">
                            <el-dropdown>
                                <span class="el-dropdown-link primary el-button--text">
                                    <i class="el-icon-arrow-down el-icon--right" />
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item
                                            :disabled="!config_field_select_flag"
                                            @click="config_table_field"
                                        >
                                            列配置
                                        </el-dropdown-item>
                                        <div 
                                            v-if="show_download_flag()"
                                        >
                                            <div v-if="total > page_size ">
                                                <el-dropdown-item
                                                    divided
                                                    @click="download_all(false)"
                                                >
                                                    下载
                                                </el-dropdown-item>


                                                <el-dropdown-item @click="download_all(true)">
                                                    下载(纯文本)
                                                </el-dropdown-item>
                                            </div>

                                            <div v-else>
                                                <el-dropdown-item
                                                    divided
                                                    @click="download_no_raw"
                                                >
                                                    当前页面下载
                                                </el-dropdown-item>
                                                <el-dropdown-item @click="download">
                                                    当前页面下载(纯文本)
                                                </el-dropdown-item>
                                            </div>
                                            <!-- <el-dropdown-item
                                                v-if=' false && !check_tree_table()'
                                                @click='download_all'
                                            >
                                                全部下载(最多10000行,内部数据)
                                            </el-dropdown-item> -->
                                        </div>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                        <template #default="scope">
                            <el-button
                                type="text"
                                title="竖向显示"
                                icon="el-icon-setting"
                            />
                        </template>
                    </el-table-column>

                    <el-table-column
                        v-if="select_type==&quot;multi_select&quot;"
                        type="selection"
                        width="55"
                    />

                    <template
                        v-for=" (col,index) in cur_column_list"
                        :key="col.prop"
                    >
                        <el-table-column
                            v-if="col.visible!==false && col.config_visible !== false "
                            :class-name="&quot;class_&quot;+ col.prop "
                            :min-width=" col.min_width || null "
                            :width=" col.real_width? col.real_width: col.width || null "
                            :render-header="gen_render_header(col, index, cur_column_list ) "
                            :align="col.align"
                            :column-key="col.prop"
                            :filter-multiple=" !(col.filter_enum_multiple===false)"
                            :filters=" col.filter_enum_type? get_filters( col ) :null "
                            :filtered-value=" get_filtered_value (col) "
                            :show-overflow-tooltip=" !check_tree_table() && col.show_overflow_tooltip===true "
                            v-bind="get_col_bind(col)"
                            :fixed=" !in_phone &&( table_fixed && page_size< 100 && (index==0 || col.fixed)) "
                        >
                            <template #default="scope">
                                <template v-if="!check_tree_table()">
                                    <component
                                        :is="get_obj_comonent(scope,col)"
                                        v-if="check_obj_test(scope,col)"
                                        v-bind="get_cpt_bind(scope, col)"
                                        v-on="col.listeners"
                                    />

                                    <component
                                        :is="col.component"
                                        v-else-if="col.component"
                                        v-bind="get_cpt_bind(scope, col)"
                                        v-on="col.listeners?col.listeners:{}"
                                    />
                                    <div
                                        v-else
                                        v-html="handle_col_formater(scope.row, col, scope.$index )"
                                    />
                                </template>
                                <template v-else>
                                    <a
                                        v-if=" index === 0 && ( (scope.row .children && scope.row .children.length > 0) || scope.row.hasChildren) "
                                        href="javascript:;"
                                        class="el-button--text "
                                        @click="toggle_tree(scope.row, $event)"
                                    >{{ scope.row[col.prop]? scope.row[col.prop]: "---" }}</a>

                                    <component
                                        :is="col.component"
                                        v-else-if="col.component"
                                        v-bind="get_cpt_bind(scope, col)"
                                        v-on="col.listeners"
                                    />
                                    <span
                                        v-else
                                        v-html="handle_col_formater(scope.row, col, scope.$index )"
                                    />
                                </template>
                            </template>
                        </el-table-column>
                    </template>

                    <el-table-column
                        v-if=" show_option_list && !( in_phone && !check_tree_table()) "

                        label="操作"
                        :fixed=" table_fixed && page_size< 100 ?&quot;right&quot;:false"
                        :min-width="check_tree_table()? (in_phone ? 50: 85) : opt_min_width"
                        :width="in_phone ? 100 : row_opt_col_width"
                        prop="__OPT"
                    >
                        <template #header="scope">
                            <el-dropdown>
                                <span class="el-dropdown-link primary el-button--text">
                                    操作
                                    <i class="el-icon-arrow-down el-icon--right" />
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item
                                            :disabled="!config_field_select_flag"
                                            @click="config_table_field"
                                        >
                                            列配置
                                        </el-dropdown-item>
                                        <div 
                                            v-if="show_download_flag()"
                                        >
                                            <div v-if="total > page_size ">
                                                <el-dropdown-item
                                                    divided
                                                    @click="download_all(false)"
                                                >
                                                    下载
                                                </el-dropdown-item>


                                                <el-dropdown-item @click="download_all(true)">
                                                    下载(纯文本)
                                                </el-dropdown-item>
                                            </div>

                                            <div v-else>
                                                <el-dropdown-item
                                                    divided
                                                    @click="download_no_raw"
                                                >
                                                    当前页面下载
                                                </el-dropdown-item>
                                                <el-dropdown-item @click="download">
                                                    当前页面下载(纯文本)
                                                </el-dropdown-item>
                                            </div>
                                            <!-- <el-dropdown-item
                                                v-if=' false && !check_tree_table()'
                                                @click='download_all'
                                            >
                                                全部下载(最多10000行,内部数据)
                                            </el-dropdown-item> -->
                                        </div>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>

                        <template #default="scope">
                            <admin-row-option-list
                                v-model="scope.row.__opt_open_flag"
                                :row="scope.row"
                                :index="scope.$index"
                                :option_list="row_opt_btn_config"
                                :selected_list="config_option_selected_list"
                                :show_item_list_flag="page_size<show_option_max_row_count"
                                @row-opt-show-desc="opt_in_phone( scope.row, scope.$index)"
                            />
                        </template>
                    </el-table-column>
                </el-table>
                <el-row>
                    <el-col
                        :span="24"
                        class="toolbar"
                        style="float: none; overflow: auto; margin-top: 5px;"
                    >
                        <div style="display: inline-block;">
                            <slot name="page_toolbar" />
                        </div>

                        <template v-if="!listLoading">
                            <el-pagination
                                v-if="(use_page_bar || data_use_page_bar ) && (total > page_size || page>1) "
                                v-model:currentPage="page"
                                style="float: right;"
                                background
                                :layout="bar_layout_info"
                                :page-size="page_size"
                                :total="total"
                                :pager-count="5"
                                :page-sizes="page_size_list"
                                @current-change="handleCurrentChange"
                                @size-change="on_page_size_change"
                            />
                            <div
                                v-else
                                style=" float:right ; display: inline-block; margin-right:50px; margin-top:8px; "
                            >
                                共: {{ total?total: data_list.length }} 条
                            </div>
                        </template>
                    </el-col>
                </el-row>
            </el-col>

            <slot name="right" />
        </el-row>
    </div>
</template>

<script>
import m from "./table.ts"
export default m
</script>

<style rel="stylesheet/" lang="css" >
._page_size_component {
  .el-input--suffix .el-input__inner {
    height: 32px;
  }
}

.el-table .cell,
.el-table th div {
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}
</style>
