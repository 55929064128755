import admin_vue from "@/components/Admin/admin_vue"
import { field_item_list_t, row_item_t, column_item_list_t, row_item_list_t } from "@/utils/type_def"
import { isEmpty } from "lodash"

import { defineComponent, reactive } from "vue"
import daren_task from "/@/service/daren/daren_task"
import common from "/@/utils/common"
import enum_map from "/@/utils/enum_map"


interface IProps {
    config_fix: string
    task_time_id: number
    platform_type: number
}



export default defineComponent({
    components: {
    },
    extends: admin_vue,

    props: {
        config_fix: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        task_time_id: {
            type: Number,
            required: false,
            default: function() {
                return 0
            }
        },
        platform_type: {
            type: Number,
            required: false,
            default: function() {
                return -1
            }
        }
    },
    emits: [],
    setup: function(props: any, _ctx: any) {

        return {
            props: <IProps>props // 代码补全用的,

            // 自定义的

        }
    },

    data: function() {
        return {
            task_money_file_url: `${common.get_api_url()}/core/file/upload_task_money_file`,
            query_filter_item_list: <field_item_list_t>[],
            row_opt_btn_config: <row_item_list_t>[],
            column_list: <column_item_list_t>[],
            url_args_ex: {
                task_time_id: 0,
                platform_type: -1
            },
            default_sort: {
                prop: "log_time",
                order: "desc"
            },
            daren_platform_list: <any>[]
        }
    },
    computed: {
    },

    watch: {
    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_mounted() {
        },

        on_created() {
            this.$data.url_args_ex.task_time_id = this.props.task_time_id || common.get_query_args().task_time_id
            this.$data.query_filter_item_list = this.get_query_filter_item_list()
            this.$data.row_opt_btn_config = this.get_row_opt_btn_config()
            this.$data.column_list = this.get_column_list()
        },

        get_row_opt_btn_config(): row_item_list_t {
            return [{
                text: "修改佣金",
                opt_key: "opt_change_real_money",
                callback: this.opt_change_real_money
            }, {
                text: "更改报名状态",
                opt_key: "opt_change_task_status",
                callback: this.opt_change_task_status,
                visible: function(row: any, _row_item_config: row_item_t, _index): boolean {
                    return common.in_array(row.task_status, [enum_map.task_status.V_1, enum_map.task_status.V_10])
                }
            }, {
                text: "更改为待发布",
                opt_key: "opt_task_publish",
                callback: this.opt_change_task_publish,
                visible: function(row: any, _row_item_config: row_item_t, _index): boolean {
                    return common.in_array(row.task_status, [enum_map.task_status.V_1])
                }
            }, {
                title: "设置报名成功",
                icon: "iconshenhetongguo",
                button_type: "success",
                callback: this.opt_assign,
                visible: function(row: any, _row_item_config: row_item_t, _index): boolean {
                    return row.task_status == common.enum_map.task_status.V_TASK_ENTER
                }
            }, {
                title: "设置报名失败",
                opt_key: "opt_enter_fail",
                icon: "iconshenhebutongguo",
                button_type: "warning",
                callback: this.opt_enter_fail,
                visible: function(row: any, _row_item_config: row_item_t, _index): boolean {
                    return row.task_status == common.enum_map.task_status.V_TASK_ENTER
                }

            }]
        },

        opt_enter_fail(row: any) {
            const me = this
            common.confirm(`设置[${row.show_name}]报名失败?!`, function() {
                common.do_ajax("/daren_ex/daren_task_enter_set_fail", {
                    daren_task_request_id: row.daren_task_request_id
                }, function() {
                    me.reload()
                }, true)
            })
        },

        opt_change_task_publish(row: any) {
            const me = this
            common.confirm(`设置[${row.show_name}]任务状态为：待发布 么?!`, function() {
                common.do_ajax("/task/change_task_status", {
                    daren_task_request_id: row.daren_task_request_id,
                    task_status: enum_map.task_status.V_TASK_PUBLISH
                }, function() {
                    me.reload()
                }, true)
            })
        },

        opt_assign(row: any) {
            const me = this
            daren_task.opt_assign_daren(row, function() {
                me.reload()
            })
        },

        opt_change_real_money(row: any) {
            const field_list: field_item_list_t = [{
                field_type: "text",
                field_name: "real_get_money",
                label: "佣金",
                value: row.real_get_money
            }]
            const data = {}
            const me = this

            const title = "修改佣金"
            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: title,
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    data["daren_task_request_id_list"] = row.daren_task_request_id
                    me.do_ajax("/daren_ex/change_real_get_money", data, (resp: any) => {
                        if (resp.code == 0) {
                            dlg.close()
                            me.reload()
                        } else {
                            common.alert(resp.info)
                        }
                    }, true)
                }
            })
        },

        opt_multi_change_real_money() {
            var me = this
            var list = (<any>this.$refs.admin_table).get_selected_list()
            var id_list: any = []
            if (list.length == 0) {
                common.alert("请先选择达人")
                return false
            }

            list.forEach((val: any) => {
                id_list.push([val.daren_task_request_id])
            })

            var row = {
                daren_task_request_id: id_list.join(",")
            }
            me.opt_change_real_money(row)
        },

        get_query_filter_item_list(): field_item_list_t {
            return [{
                field_type: "user_select",
                field_name: "userid",
                user_type: "daren",
                label: "达人",
                default_show: true,
                value: -1
            }, {
                field_type: "select",
                field_name: "approval_flag",
                enum_type: "approval_flag",
                label: "报名状态",
                default_show: true,
                value: -1,
                can_change_multiple: false
            }, {
                field_type: "text",
                field_name: "userid_list",
                label: "平台id搜索",
                default_show: true,
                value: "",
                placeholder: "多个id用英文逗号隔开"
            }, {
                field_type: "text",
                field_name: "fans_list",
                label: "粉丝数查询",
                default_show: true,
                value: "",
                placeholder: "单个数字默认为大于，其他情况用区间来查询：0-500"
            }]
        },

        // 配置列信息
        get_column_list(): column_item_list_t {
            return [
                {
                    label: "报名id",
                    prop: "daren_task_request_id",
                    fixed: true
                },
                {
                    label: "佣金",
                    prop: "real_get_money",
                    fixed: true,
                    sortable: true
                },
                {
                    label: "达人id",
                    prop: "userid"
                },
                common.gen_column_daren_item("平台昵称", "userid", "platform_nickname", {
                    fixed: true,
                    sortable: false
                }),
                {
                    label: "报名状态",
                    prop: "approval_flag_str"
                },
                common.gen_column_enum("task_status", "任务状态"),
                {
                    label: "到店时间",
                    prop: "real_shopping_time"
                }, {
                    label: "平台id",
                    prop: "platform_userid"
                }, {
                    label: "平台粉丝数",
                    prop: "platform_fans_count"
                }, {
                    label: "平台主页",
                    prop: "platform_main_page_url"
                }, {
                    label: "笔记类型",
                    prop: "content_type_str"
                }
            ]
        },

        set_fail_all() {
            const me = this
            common.confirm(" 设置 全部 \"报名中\" 为 \"报名失败\"报名失败?!", function() {
                common.do_ajax("/daren_ex/get_task_time_request_list", {
                    task_status: common.enum_map.task_status.V_0, // 报名中
                    task_time_id: me.$data.url_args_ex.task_time_id,
                    page_size: 10000
                }, function(resp: any) {
                    common.do_batch_list(resp.data.list, 1, function(item: any, do_next: any) {
                        const id = item.data[0].daren_task_request_id

                        common.do_ajax("/daren_ex/daren_task_enter_set_fail", {
                            daren_task_request_id: id
                        }, function() {
                            do_next()
                        })
                    }, {
                        show_dlg: true,
                        end_callback: function() {
                            me.reload()
                        }
                    })
                    me.reload()
                })
            })
        },

        opt_change_task_status(row: any) {
            const field_list: field_item_list_t = [{
                field_type: "select",
                field_name: "approval_flag",
                label: "报名审批状态",
                enum_type: "approval_flag",
                value: row.approval_flag
            }]

            const data = {}
            const me = this

            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: "修改报名状态",
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    data["daren_task_request_id"] = row.daren_task_request_id
                    me.do_ajax("/task/change_daren_task_approval_flag", data, (resp: any) => {
                        if (resp.ret == 0) {
                            dlg.close()
                            me.reload()
                        } else {
                            common.alert(resp.info)
                        }
                    }, true)
                }
            })
        },

        opt_add_extra_daren() {
            const me = this
            const data: any = reactive({ opt_list: {} })
            const field_list: field_item_list_t = reactive([{
                field_type: "user_select",
                field_name: "userid",
                label: "达人",
                user_type: "daren",
                on_change: (userid: any) => {
                    common.do_ajax("/daren/platform_list", {
                        userid: userid,
                        platform_type: me.props.platform_type
                    }, (resp: any) => {
                        const opt_list: any = []
                        resp.data.list.forEach((val: any) => {
                            opt_list.push({
                                label: val.platform_nickname,
                                title: val.platform_nickname,
                                value: val.id
                            })
                        })

                        common.set_object_property_by_field_name(field_list, "platform_id", "opt_list", opt_list)
                    })
                },
                value: -1
            }, {
                field_type: "select",
                field_name: "platform_id",
                label: "账号",
                opt_list: data.opt_list,
                multiple: true,
                value: ""
            }])

            var dlg = common.dlg.admin_show_key_table(field_list, data, {
                title: "选择达人",
                width: "600px",
                submit: function(data: any, _btn_config: any) {
                    data.task_time_id = me.$data.url_args_ex.task_time_id
                    data.platform_id_list = data.platform_id.join(",")
                    me.do_ajax("/task/opt_add_extra_daren", data, (resp: any) => {
                        if (resp.ret == 0) {
                            dlg.close()
                            me.reload()
                        } else {
                            common.alert(resp.info)
                        }
                    }, true)
                }
            })
        },

        opt_multi_pass() {
            var me = this
            var list = (<any>this.$refs.admin_table).get_selected_list()
            var id_list: any = []
            if (list.length == 0) {
                common.alert("请先选择达人")
                return false
            }

            list.forEach((val: any) => {
                id_list.push([val.daren_task_request_id])
            })

            common.confirm(`批量通过[${list.length}]名达人，是否确认?`, function() {
                common.do_ajax("/daren_ex/task_time_assign", {
                    task_time_id: me.$data.url_args_ex.task_time_id,
                    daren_task_request_id_list: id_list.join(",")
                }, function() {
                    me.reload()
                }, true)
            })
        },

        opt_confirm_daren_money() {
            var me = this
            var list = (<any>this.$refs.admin_table).get_selected_list()
            var id_list: any = []
            if (list.length == 0) {
                common.alert("请先选择达人")
                return false
            }

            list.forEach((val: any) => {
                id_list.push([val.daren_task_request_id])
            })

            common.confirm(`批量确认[${list.length}]名达人的佣金?`, () => {
                common.do_ajax("/task/confirm_daren_money", {
                    daren_task_request_id_list: id_list.join(",")
                }, (resp: any) => {
                    me.reload()
                    if (!isEmpty(resp.data)) {
                        common.alert(resp.data)
                    } else {
                        common.notify({
                            title: "成功",
                            message: "确认成功"
                        })
                    }
                }, true)
            })
        },

        opt_task_money_file(response: any, _file: any, _fileList: any) {
            if (response.code == 0) {
                common.notify({
                    title: "上传内容列表",
                    message: "上传完成"
                })
                this.reload()
            } else {
                common.alert(`${response.info}`)
            }
        },

        download_task_money_file() {
            var url = "https://daren-public-hz.oss-cn-hangzhou.aliyuncs.com/1641464358219.xlsx"
            window.open(url)
        }



    }

})
