import { defineComponent, reactive, watch } from "vue"
import common from "./utils/common"
import test from "./utils/test"
import { useStore } from "/@/store/index"

import { ElConfigProvider } from "element-plus"

import zhCn from "element-plus/lib/locale/lang/zh-cn"

export default defineComponent({
    name: "App",
    components: {
        [ElConfigProvider.name]: ElConfigProvider
    },
    setup() {
        const store = useStore()
        console.log("LLLLLL", store)
        var water_mark_style = reactive(
            {
                position: "absolute", top: 0, left: 0, width: "100%",
                height: "100%", "z-index": 9999,
                "pointer-events": "none",
                "background-repeat": "repeat",
                "background-image": `url('${common.get_pic_base64("")}')`
            }

        );
        // 挂载 test 模块
        (<any>window)["test"] = test
        var data = {
            water_mark_style: water_mark_style,
            locale: zhCn

        }
        watch(() => store.state.layout.adminid, function(new_val: any, _old_val: any) {
            data.water_mark_style["background-image"] = `url('${common.get_pic_base64(`${new_val}`)}')`
        })
        return data
    },

    created() {
        common.$root = this
    },


    methods: {

    }


})
