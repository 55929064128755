import router from "/@/router"
import { store } from "/@/store/index"
import { configure, start, done } from "nprogress"
import { RouteRecordRaw } from "vue-router"
import common from "./utils/common"
import { path_title_map } from "./router/asyncRouter"
import { IMenubarStatus } from "/@/type/store/layout"

configure({ showSpinner: false })
const whiteList = [
    "/login", 
    "/login/register", 
    "/account_login", 
    "/common/batch_dp_info",
    "/common/case_dy_info",
    "/common/batch_dy_info"
]// no redirect whitelist

const loginRoutePath = "/login"
const defaultRoutePath = "/"

router.beforeEach(async(to, from) => {
    var tmp_next_open_page = localStorage.getItem("tmp_next_open_page")
    if (tmp_next_open_page != "" && tmp_next_open_page != null) {
        localStorage.setItem("tmp_next_open_page", "")
        common.wopen(tmp_next_open_page, true)
    }

    start()
    console.log("00000000000", to)
    const { layout } = store.state
    // 判断当前是否在登陆页面
    if (to.path.toLocaleLowerCase() === loginRoutePath) {
        done()
        if (layout.token.ACCESS_TOKEN) return typeof to.query.to_url === "string" ? to.query.to_url : defaultRoutePath
        return
    }
    // 每次重置 是否显示基础的 navbar
    if (to.meta.show_navbar === false) {
        layout.menubar.show_navbar = false
    } else { // 默认显示
        layout.menubar.show_navbar = true
    }
    if (!layout.menubar.show_navbar) { //
        layout.menubar.status = IMenubarStatus.PHN

    }

    common.eventHub.$emit("close-dialog-on-new-url")
    // // 判断是否登录
    if (whiteList.indexOf(to.path) === -1 && !layout.token.ACCESS_TOKEN) {
        return loginRoutePath + (to.fullPath ? `?to_url=${encodeURIComponent(to.fullPath)}` : "")
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            if (to.path != loginRoutePath) {
                return
            }

        }
    }
    if (to.meta.active_path) {
        document.title = path_title_map[<string>to.meta.active_path] || to.meta.active_path
    } else {
        document.title = <string>to.meta.title
    }
    // 判断是否还没添加过路由
    if (layout.menubar.menuList.length === 0) {
        // await store.dispatch('layout/GenerateRoutes')
        await store.dispatch("layout/getUser")
        for (let i = 0; i < layout.menubar.menuList.length; i++) {
            router.addRoute(layout.menubar.menuList[i] as RouteRecordRaw)
        }
        store.commit("layout/concatAllowRoutes")

        return to.fullPath
    }

    store.commit("layout/changeTagNavList", to) // 切换导航，记录打开的导航(标签页)

    // 离开当前页面时是否需要添加当前页面缓存
    !new RegExp(/^\/redirect\//).test(from.path)
        && store.state.layout.tags.tagsList.some(v => v.name === from.name)
        && !store.state.layout.tags.cachedViews.some(v => v === from.name)
        && store.commit("layout/addCachedViews", { name: from.name, noCache: from.meta.noCache })

})

router.afterEach(() => {
    done()
})
