import { defineComponent } from "vue"

import { getToken } from "@/utils/auth"
import { ElMessageBox } from "element-plus"
import common from "/@/utils/common"
import sys_util from "/@/utils/sys_util"

interface IProps {
    on_success: Function
    modelValue: string
}



export default defineComponent({
    components: {
    },

    props: {


        action: String,


        post_data: {
            type: Object,
            default: function() {
                return {}
            }
        },
        show_btn: {
            type: Boolean,
            default: false
        },

        multiple: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: 1
        },
        name: {
            type: String,
            default: "file"
        },


        modelValue: String,

        accept: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        show_upload_process: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        file_name_fix: {
            type: [String, Function],
            required: false,
            default: function() {
                return ""
            }




        },
        on_error: {
            type: [Function],
            required: false,
            default: function() {
                return function(err: any, _file: any) {
                    ElMessageBox.alert(`原因:${err.message}`, "上传失败", {
                        type: "error"
                    })
                }

            }
        },
        on_success: {
            type: [Function],
            required: false,
            default: function() {
                return function(_response: any, _file: any, _fileList: any) {
                    console.log("upload success")
                }

            }

        }



    },
    // event
    emits: ["update:modelValue"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            upload_process_visible: false,
            upload_process_percentage: 0,
            bucket_info: {},
            upload_headers: {
                "Session-id": getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
            }

        }
    },
    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_created() {
        },
        on_mounted() {
        },


        before_upload(file: File) {

            console.log("before_upload", file)

            this.$emit("update:modelValue", "")

            return true
        },

        el_upload_on_success(response: any, file: any, fileList: any) {

            if (response.code == 0) {
                this.$emit("update:modelValue", response.data.file_url)
                console.log("ssssssssssssss", response, file, fileList)
                if (sys_util.isFunction(this.props.on_success)) {
                    this.props.on_success(response, file, fileList)
                }
            } else {
                common.alert(`出错:${response.info}`)

            }
        },

        show_file() {
            common.wopen(this.props.modelValue)
        }



    }

})
