<template>
    <div>
        <el-table
            ref="table"
            :data="data_list"
            border
            fit
            selection
        >
            <el-table-column width="55">
                <template #default="scope">
                    <el-checkbox
                        v-model="scope.row.selected"
                        @change="on_select(scope.row)"
                    />
                </template>
            </el-table-column>
            <el-table-column :label="title">
                <template #default="scope">
                    <admin-icon
                        v-if="scope.row.icon"
                        :name="scope.row.icon"
                        style="color: #66b1ff;"
                    /> {{ scope.row.label }}
                </template>
            </el-table-column>

            <el-table-column
                label="操作"
                min-width="200px"
            >
                <template #default="scope">
                    <el-dropdown
                        split-button
                        trigger="click"
                        style="display:inline-block"
                        @click=" !(!scope.row.selected || scope.$index==0) && on_move_up( scope.row, scope.$index, 1 )"
                    >
                        上
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item
                                    v-for="( item, i ) in scope.row.up_list"
                                    :key="item.key"
                                    @click="on_move_up( scope.row, scope.$index, scope.$index - i ) "
                                >
                                    移动到[{{ item.label }}]之前
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>



                    <el-dropdown
                        split-button
                        trigger="click"
                        style="display:inline-block"
                        @click=" !( !scope.row.selected || scope.$index==select_list_inter.length-1 ) && on_move_down( scope.row, scope.$index, 1 )"
                    >
                        下
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item
                                    v-for="( item, i ) in scope.row.down_list"
                                    :key="item.key"
                                    @click="on_move_down( scope.row, scope.$index, i+1 ) "
                                >
                                    移动到[{{ item.label }}]之前
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <div style="padding-top:10px; text-align:center ">
            <el-button
                type="primary"
                @click="on_set_all"
            >
                全选
            </el-button>
            <el-button
                type="primary"
                @click="on_set_null"
            >
                全不选
            </el-button>
            <el-button
                type="warning"
                @click="on_set_default"
            >
                恢复默认
            </el-button>
            <el-button
                type="primary"
                @click="on_save"
            >
                保存顺序
            </el-button>
        </div>
    </div>
</template>

<script>
import m from "./config_item_table.ts"
export default m
</script>
