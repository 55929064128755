// import { isBoolean, isNumber, isString, isNull, isFunction, isArray } from "util";

import lodash from "lodash"
import { isProxy, toRaw } from "vue"
import array from "./array"


export default {
    isNumber: function(value: any) {
        return typeof value === "number"
    },

    isString: function(value: any) {
        return typeof value === "string"
    },

    isFunction: function(value: any) {
        return typeof value === "function"
    },
    isNull: function(value: any) {
        return value === null
    },

    isArray: function(value: any) {
        if (isProxy(value)) {
            value = toRaw(value)
        }
        return Array.isArray(value)

    },
    isObject: function(value: any) {

        return value !== null && typeof value === "object"
    },

    isBoolean: function(value: any) {
        return typeof value === "boolean"
    },

    debounce: function(func: any, wait = 200) {
        return lodash.debounce(func, wait, {
            leading: true,
            trailing: false
        })

    }





}
