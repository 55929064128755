import { defineComponent } from "vue"
import { store } from "/@/store"
import common from "/@/utils/common"
import admin_vue from "@/components/Admin/admin_vue"
import { locale } from "element-plus"


interface IProps {
    left_type: string
}



export default defineComponent({
    components: {
    },

    extends: admin_vue,

    props: {

        left_type: {
            type: String,
            default: function() {
                return "back" // "menu"
            }
        }

    },
    // event
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            showPopover: false,
            user_info: store.state.layout.userInfo,
            adminid: store.state.layout.adminid
        }
    },
    computed: {


    },

    watch: {


    },
    created() {
        this.on_created()
    },
    mounted() {
        // this.on_mounted();
    },
    methods: {
        on_created() {
        },
        logout() {
            common.do_ajax("/route__php_tars_admin/login/logout", {
            }, function() {
                location.reload() // 为了重新实例化vue-router对象 避免bug
            })

        },
        switch_desktop() {
            var path = this.$router.currentRoute.value.path
            var arr = path.split("/")
            var url = ""
            if (arr.length == 4) {
                url = `/${arr[1]}/${arr[2]}/desktop`
            } else {
                url = `${path}/desktop`
            }
            common.wopen(url, true)


        },
        reset_power() {

            common.do_ajax("/route__php_tars_admin/login/reset_power", {}, function() {
                window.location.reload()
            })

        },
        opt_test_flag() {
            if (localStorage.getItem("test_flag") == "true") {
                localStorage.setItem("test_flag", "false")
            } else {
                localStorage.setItem("test_flag", "true")
            }
            window.location.reload()

        }
    }

})
