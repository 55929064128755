import { defineComponent } from "vue"
import { ElFormItem } from "element-plus"
import common from "/@/utils/common"
import sys_util from "/@/utils/sys_util"

import { field_item_t } from "/@/utils/type_def"


interface IProps {
    use_url_args_and_page_config: boolean
    config_fix: string
    url: string
    query_at_time_flag: boolean
    use_last_query_args_flag: boolean
    query_filter_item_list: Array<any>
}




export default defineComponent({
    components: {
    },

    props: {
        show_query_button: {
            type: [Boolean, String],
            required: false,
            default: function() {
                return false
            }
        },


        query_at_time_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        // 是否使用最后一次的查询
        use_last_query_args_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },

        url: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },
        use_url_args_and_page_config: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },


        config_fix: {
            type: String,
            required: false,
            default: function() {
                return ""
            }
        },


        show_config: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },
        query_filter_item_list: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        }

    },
    // event
    emits: ["query"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props,

            // 自定义的
            store_key_query_at_time: "query_at_time",
            store_key_query_field_selected: "query_field_selected_list",
            store_key_last_query_args: "last_query_args",
            store_key_use_last_query_args: "use_last_query_args",

            use_last_query_args: false,


            last_query_time: 0,
            last_query_time_check_fail_count: 0,
            do_key_down_func: <any>undefined


        }
    },

    data: function() {
        return {
            query_at_time: false,
            query_field_selected_list: <Array<any>>[],
            cur_query_filter_item_list: <Array<any>>[],
            show_all_flag: false,
            cascader_data_options: <Array<any>>[{
                label: "全部",
                value: "-1"
            }]

        }
    },
    computed: {




    },

    watch: {

        query_field_selected_list: function(new_val, _old_val) {
            this.set_query_field_select_list(new_val)
        },
        query_at_time: function(new_val, _old_val) {
            this.set_query_at_time(new_val)
        },

        use_last_query_args: function(new_val, _old_val) {
            this.set_use_last_query_args(new_val)
        },
        query_filter_item_list: function(_new_val) {
            this.on_change_query_filter_item_list()
        },
        cur_query_filter_item_list: function(_new_val) {
            // common.alert_json(_new_val);
        }


    },

    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_query(args: any) {
            let query_onchange_flag: any = undefined
            let use_as_query = true
            if (sys_util.isObject(args)) {
                const field_name = args["field_name"]
                if (field_name) {
                    (this.props.query_filter_item_list).findIndex(function(item: field_item_t) {
                        if (item.field_name === field_name) {

                            query_onchange_flag = item.query_onchange_flag
                            if (item.use_as_query === false) {
                                use_as_query = false
                            }
                            return true
                        } else {
                            return false
                        }
                    })
                }
            }
            if (use_as_query &&
                (
                    (query_onchange_flag === undefined && this.$data.query_at_time)
                    || query_onchange_flag

                )
            ) {
                this.query()
            }
        },


        on_cascader_menu_create(item: any) {
            item._created_menu = true
        },
        on_cascader_focus(item: any) {
            if (item._created_menu) {
                this.query()
            }
            item._created_menu = false
        },

        get_args() {

            const ret: any = {}
            this.$data.cur_query_filter_item_list.forEach(function(item: any, _i: any) {


                if (item.field_type == "label") {

                } else if (item.field_type == "date") {

                    let opt_date_type_field_name = "opt_date_type"
                    let start_time_field_name = "start_time"
                    let end_time_field_name = "end_time"
                    let date_type_field_name = "date_type"
                    if (item.field_index > 0) {
                        opt_date_type_field_name += `_${item.field_index}`
                        start_time_field_name += `_${item.field_index}`
                        end_time_field_name += `_${item.field_index}`
                        date_type_field_name += `_${item.field_index}`

                    }

                    const { value } = item
                    ret[opt_date_type_field_name] = value.opt_date_type
                    ret[date_type_field_name] = value.date_type
                    if (value.opt_date_type == 0 || value.opt_date_type == 100) {
                        if (sys_util.isArray(value.start_time)) {
                            ret[start_time_field_name] = value.start_time[0]
                            ret[end_time_field_name] = value.start_time[1]
                        } else {
                            // console.log(333333333333333333333, ret);
                            ret[start_time_field_name] = value.start_time
                            ret[end_time_field_name] = value.end_time
                        }
                    } else {
                        ret[start_time_field_name] = value.start_time
                        ret[end_time_field_name] = value.end_time
                    }

                    if (item["picker_options"] === undefined) {
                        item["picker_options"] = {}
                    }


                } else if (sys_util.isArray(item.value)) {
                    ret[item.field_name] = item.value.join(",")
                } else {
                    ret[item.field_name] = item.value
                }
            })
            return ret
        },

        check_top_visible(item: any) {
            if (item.top_flag === true) {
                return true
            }

            /*
            if (item.visible) {
              return true;
            }
            //有条件的都显示
            switch (item.field_type) {
              case "text":
                return !(item.value == "" || item.value == "-1");
              case "select":
                return item.value != -1;
            }
            */
            return false

        },
        check_field_show(_item: any) {
            return true


        },
        // 设置参数 显示
        change_field_show(select_list: any) {
            const query_filter_item_list = this.$data.cur_query_filter_item_list
            const selected_map: any = {}

            const column_map: any = {}

            query_filter_item_list.forEach(function(item: any, _i: any) {
                column_map[item.label] = item
            })


            const selected_list: Array<any> = []
            const ret_list: Array<any> = []
            select_list.forEach(function([label, check_flag]: any, _i: any) {
                if (check_flag) {
                    const item = column_map[label]
                    if (item) {
                        item.visible = true
                        ret_list.push(item)
                        selected_map[item.label] = true
                        selected_list.push([item.label, true])
                    }
                }
            })

            query_filter_item_list.forEach(function(item: any, _i: any) {
                if (!selected_map[item.label]) {
                    item.visible = false
                    ret_list.push(item)
                    selected_list.push([item.label, false])
                }
            })


            this.$data.cur_query_filter_item_list = ret_list

            return selected_list
        },
        query_input_clear() {
            this.query()
        },


        // 不输出
        check_slot() {
            return (this.$slots.default && (<any>this.$slots.default)[0].children)
        },

        get_query_field_list() {
            const ret: Array<any> = []
            this.$data.cur_query_filter_item_list.forEach(function(item: any, _i: any) {
                if (item.always_hide !== true) {
                    ret.push({
                        key: item.label,
                        label: item.label
                    })
                }
            })
            return ret
        },

        set_query_field_select_list(val: any) {
            this.change_field_show(val)
        },
        check_visible(item: any) {

            if (item.field_type == "admin_group" && common.get_admin_level() == 0 && !common.check_url_desc_power("opt_admin")) {// 组员不显示组织
                return false
            }

            // 放到头部
            if (item.top_flag == true) {
                return false
            }


            if (item.always_hide === true) {
                return false
            }

            // 全部
            if (this.$data.show_all_flag) {
                return true
            }



            if (item.visible) {
                console.log("KKKKKKKKKKKKKKKKKK item_", item.label, item.field_type, "show ")
                return true
            }
            // 有条件的都显示
            switch (item.field_type) {
            case "date":
                var ret = !(sys_util.isObject(item.value) && item.value.date_type == "__unused__")
                return ret
            case "text":
                return !(item.value == "" || item.value == "-1")
            case "select":
                return item.value != -1 || (sys_util.isArray(item.value) && item.value[0] != -1)
            case "user_select":
                return item.value != -1

            case "cascader":
            case "admin_group":
            case "origin_select":
                var ret: boolean = (item.value.length > 0) && (item.value[0] != -1) && (item.value[0])
                // console.log("KKKKKKKKKKKKKKKKKK item_", item.label, item.field_type, ret, item.value);
                return ret
            default:
                return item.value != -1
            }

        },

        on_change_query_filter_item_list() {
            this.$data.query_field_selected_list = this.get_query_field_selected_list()

            this.change_field_show(this.$data.query_field_selected_list)

            this.set_value_from_query()

        },

        // 从查询参数中设置值
        set_value_from_query() {
            const me = this
            if (this.props.use_url_args_and_page_config) {
                // 设置参数
                let args = common.get_query_args()

                if (Object.getOwnPropertyNames(args).length == 0) { // 空的
                    if (this.use_last_query_args) { // 使用最后一次查询
                        args = this.page_store_get_key(this.store_key_last_query_args, {})
                        if (args.opt_date_type == 1) { // 按天
                            args.start_time = common.date_get_today()
                        } else if (args.opt_date_type == 2) {
                            args.start_time = common.date_get_cur_week()
                        } else if (args.opt_date_type == 3) {
                            args.start_time = common.date_get_cur_month()
                        }
                        // 特殊处理
                    }
                }

                this.$data.cur_query_filter_item_list.forEach(function(item: any, _i: any) {

                    const set_value: string = args[item.field_name]
                    if (item.field_type == "date") {
                        const value: any = {
                            opt_date_type: item.value.opt_date_type,
                            date_type_config: item.value.date_type_config,
                            date_type: item.value.date_type,
                            start_time: item.value.start_time,
                            end_time: item.value.end_time
                        }
                        let opt_date_type_field_name = "opt_date_type"
                        let start_time_field_name = "start_time"
                        let end_time_field_name = "end_time"
                        let date_type_field_name = "date_type"
                        if (item.field_index > 0) {
                            opt_date_type_field_name += `_${item.field_index}`
                            start_time_field_name += `_${item.field_index}`
                            end_time_field_name += `_${item.field_index}`
                            date_type_field_name += `_${item.field_index}`

                        }
                        if (args[opt_date_type_field_name] !== undefined) { value.opt_date_type = args[opt_date_type_field_name] }

                        if (args[start_time_field_name] !== undefined) {
                            if (value.opt_date_type == 0 || value.opt_date_type == 100) { // 按时段
                                value.start_time = [args[start_time_field_name], args[end_time_field_name]]
                            } else {
                                value.start_time = args[start_time_field_name]
                            }
                        }
                        if (args[date_type_field_name] != undefined) { value.date_type = args[date_type_field_name] }
                        item.value = value
                        if (item["picker_options"] === undefined) {
                            item["picker_options"] = {}
                        }

                    } else if (item.field_type == "cascader") {
                        if (set_value !== undefined) {
                            item.value = set_value.split(",")
                        }
                        if (item.ajax_url) {
                            console.log("ajax_url", item.ajax_url)
                            common.do_ajax(item.ajax_url, {}, function(resp: any) {
                                me.$data.cascader_data_options = [{
                                    label: "全部",
                                    value: "-1",
                                    children: resp.list
                                }]

                            })
                        }

                    } else if (item.field_type == "admin_group") {
                        if (set_value !== undefined) {
                            item.value = set_value.split(",")
                        } else {
                            item.value = []
                        }
                    } else if (item.field_type == "origin_select" || item.field_type == "admin_cascader" || item.field_type == "address_select" || item.field_type == "shop_group" || item.field_type == "cascader") {
                        if (set_value !== undefined && set_value != "") {
                            item.value = set_value.split(",").map(function(v: any) { return parseInt(v) })
                        } else {
                            item.value = []
                        }

                    } else if (item.field_type == "user_select") {
                        if (set_value !== undefined) {
                            item.value = parseInt(set_value)
                        }

                    } else if (item.field_type == "select") {
                        if (item.deal_ajax_resp_for_opt_list === undefined) {
                            item.deal_ajax_resp_for_opt_list = function(resp: any) {
                                const opt_list: Array<any> = [];

                                (<Array<any>>resp.data.list).forEach(function(item) {
                                    opt_list.push({
                                        label: item.label, value: item.value
                                    })
                                })
                                return opt_list
                            }


                        }

                        if (set_value !== undefined) {
                            item.value = set_value
                        }


                    } else if (set_value !== undefined) {
                        item.value = set_value
                    }
                })
            }
        },
        on_mounted() {
            this.$data.query_field_selected_list = this.get_query_field_selected_list()


        },
        set_query_at_time(val: any) {
            this.page_store_set_key(this.store_key_query_at_time, val)
        },

        set_use_last_query_args(val: any) {
            this.page_store_set_key(this.store_key_use_last_query_args, val)
        },



        get_query_field_selected_list() {
            const save_ret = this.page_store_get_key(this.store_key_query_field_selected, [])

            const { cur_query_filter_item_list } = this.$data

            const selected_map: any = {}
            const column_map: any = {}
            const ret: Array<any> = []

            cur_query_filter_item_list.forEach(function(item: any, _i: any) {
                column_map[item.label] = item
                item.visible = false
            })

            // 得到有效的列表
            save_ret.forEach(function([field_name, show_flag]: any, _i: any) {
                if (column_map[field_name]) {
                    selected_map[field_name] = show_flag
                }
                ret.push([field_name, show_flag])
            })


            cur_query_filter_item_list.forEach(function(item: any, _i: any) {
                const field_name = item.label
                if (selected_map[field_name] === undefined) { //
                    let { default_show } = item
                    if (default_show === undefined) {
                        default_show = false
                    } else if (default_show === "desktop" && !common.in_phone()) { //
                        default_show = true
                    }
                    ret.push([field_name, default_show])
                }
            })
            return ret
        },

        page_store_get_key(key: string, default_value: any) {
            if (!this.props.use_url_args_and_page_config) {
                return default_value
            }
            return common.page_store_get_key(key, default_value, this.props.config_fix, this.props.url)

        },

        page_store_set_key(key: string, value: any, save_now_flag = false) {
            if (!this.props.use_url_args_and_page_config) {
                return null
            }
            return common.page_store_set_key(key, value, this.props.config_fix, this.props.url, save_now_flag)
        },


        get_field_item(item: any) {
            if (item.field_type == "new_line") {
                return "br"
            } else {
                return ElFormItem
            }
        },
        get_form_item_stype(item: any) {
            const ret = {
                float: item.float ? item.float : "null"
            }
            return ret
        },

        query() {
            const now = (new Date()).getTime()
            console.log("diff", now - this.last_query_time)
            if (now - this.last_query_time > 1000) {
                this.last_query_time = now
                this.last_query_time_check_fail_count = 0
            } else {
                this.last_query_time_check_fail_count++
                if (this.last_query_time_check_fail_count > 2) { // 联系两次异常.
                    this.last_query_time = now
                    common.notify({
                        title: "提示",
                        message: "请求过于频繁",
                        type: "warning"
                    })
                    return
                }
            }

            const args = this.get_args()
            if (this.use_last_query_args) {
                this.page_store_set_key(this.store_key_last_query_args, args, true)
            }
            // alert(JSON.stringify(args));
            this.$emit("query", args)
        },

        load_query_at_time() {
            this.$data.query_at_time = this.page_store_get_key(this.store_key_query_at_time,
                this.props.query_at_time_flag)
        },

        load_use_last_query_args() {
            this.use_last_query_args = this.page_store_get_key(this.store_key_use_last_query_args,
                this.props.use_last_query_args_flag)
        },

        on_created() {
            this.do_key_down_func = sys_util.debounce((e: any) => {
                if (e.target.type != "textarea") {
                    if (e.keyCode == 13) { // enter
                        this.query()
                    }
                }

            }),

            this.load_query_at_time()
            this.load_use_last_query_args()
            this.$data.cur_query_filter_item_list = this.props.query_filter_item_list
            this.set_value_from_query()
        },
        do_key_down(e: any) {
            this.do_key_down_func(e)
        },

        on_cascader_change(item: any) {
            if (item.on_cascader_change) {
                const change_function = item.on_cascader_change
                change_function(item.value)
            }

            if (item.value.length == 0) { // clear
                this.query()
            }
        },
        on_show_all() {
            this.$data.show_all_flag = !this.$data.show_all_flag
        },

        is_string(value: any) {
            return sys_util.isString(value)
        },
        check_main_page_mini_size() {
            return common.check_main_page_mini_size()
        }



    }

})
