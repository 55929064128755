import admin_vue from "@/components/Admin/admin_vue"

import { field_item_list_t, column_item_list_t, row_item_list_t } from "@/utils/type_def"
import { defineComponent } from "vue"
import common from "/@/utils/common"


interface IProps {
    bd_shop_id: any,
    config_fix: any,
}



export default defineComponent({
    components: {
    },
    extends: admin_vue,

    props: {
        bd_shop_id: Number,
        config_fix: String
    },
    emits: [],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

        }
    },

    data: function() {
        return {
            query_filter_item_list: <field_item_list_t>[],
            row_opt_btn_config: <row_item_list_t>[],
            column_list: <column_item_list_t>[],

            default_sort: {
                prop: "create_time",
                order: "desc"
            },
            url_args_ex: {
                bd_shop_id: 0
            }
        }
    },

    created() {
        this.on_created()
    },
    methods: {
        get_row_opt_btn_config(): row_item_list_t {
            return [
            ]
        },

        get_query_filter_item_list(): field_item_list_t {
            return []
        },

        get_column_list(): column_item_list_t {
            var me = this
            return [{
                label: "订单id",
                prop: "bd_order_id",
                sortable: true
            },
            common.gen_column_account_item("下单人", "adminid", "admin_nick"),
            {

                label: "下单时间",
                prop: "create_time",
                sortable: true
            }, {
                label: "金额",
                prop: "money",
                sortable: true
            }, {
                label: "首付款",
                prop: "first_payment",
                sortable: true
            },
            common.gen_column_text("完成审批状态", "order_complete_status",
                function(row: any) {

                    if (!row.order_complete_flowid) {
                        common.confirm(`[${row.shop_name}]金额[${row.money}],任务已完成,提交审批?`, function() {
                            common.do_ajax("/flow/add_flow", {
                                flow_type: common.enum_map.flow_type.V_ORDER_COMPLETE,
                                from_key_int: row.bd_order_id,
                                adminid: common.get_adminid()

                            }, function() {
                                me.reload()

                            })
                        })
                    } else {
                        common.dlg.admin_show_flow_info(row.order_complete_flowid)
                    }

                }, {

                    gen_text_func: function(row: any) {
                        if (!row.order_complete_flowid) {
                            return "提交审批"
                        }
                        return row.order_complete_status_str
                    },

                    filter_enum_multiple: false,
                    filter_enum_type: "flow_status",
                    filter_default_value: -1


                }),
            {
                label: "合同",
                prop: "order_doc_url",
                sortable: true

            }]
        },

        on_created() {
            this.$data.url_args_ex.bd_shop_id = this.$props.bd_shop_id || common.get_query_args().bd_shop_id
            this.$data.query_filter_item_list = this.get_query_filter_item_list()
            this.$data.row_opt_btn_config = this.get_row_opt_btn_config()
            this.$data.column_list = this.get_column_list()
        }


    }

})