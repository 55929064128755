
import { defineComponent, ref, shallowRef } from "vue"
import { ElLink } from "element-plus"
import sys_util from "/@/utils/sys_util"
import common from "/@/utils/common"



interface IProps {
    option_list: Array<any>
    selected_list: Array<any>
    row: any
    index: number
    value: boolean
    use_in_toolbar: boolean
}



export default defineComponent({

    props: {




        value: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },
        show_more_type: {
            type: String, // dropdown, inline
            required: false,
            default: function() {
                return "dropdown" // 下拉
            }
        },
        show_item_list_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true
            }
        },

        use_in_toolbar: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            }
        },


        selected_list: {
            type: Array,
            required: false,
            default: function() {
                return []
            }
        },
        option_list: {
            type: Array,
            required: false,

            default: function() {
                return []
            }
        },
        row: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },
        index: {
            type: Number,
            required: false,
            default: 0
        }
    },
    // event
    emits: ["row-opt-show-desc", "input"],
    setup: function(props: any, _ctx: any) {
        return {
            props: <IProps>props

            // 自定义的

        }
    },

    data: function() {
        return {
            in_phone: false,
            button: shallowRef(ElLink),
            cur_option_list: <Array<any>>[],
            selected_length: 0,
            always_show_list: false

        }
    },
    computed: {




    },

    watch: {

        selected_list: function() {
            this.do_change_selected_list()
        },
        value: function() {
            this.reset_btn_list()
        },
        option_list: function() {
            this.$data.cur_option_list = this.props.option_list
            this.reset_option_list()
        }


    },



    created() {
        this.on_created()
    },
    mounted() {
        this.on_mounted()
    },
    methods: {
        on_created() {
            this.$data.cur_option_list = this.props.option_list
            this.reset_option_list()
            this.$data.in_phone = common.in_phone()
        },

        do_change_selected_list() {
            this.reset_option_list()
        },
        on_show_desc() {
            this.$emit("row-opt-show-desc")
        },

        reset_option_list() {
            const option_list: Array<any> = []
            const all_map: any = {}
            const selected_map: any = {}

            this.props.option_list.forEach(function(item: any) {
                all_map[item.opt_key] = item
            })
            let selected_length = 0
            this.props.selected_list.forEach(function([opt_key, check_flag]: any) {
                const item = common.copy_obj(all_map[opt_key])
                if (check_flag) {
                    selected_length++
                }
                if (item !== undefined) {
                    item._visible = check_flag
                    item.selected = true
                    option_list.push(item)
                }
                selected_map[opt_key] = check_flag
            })
            this.$data.selected_length = selected_length
            // xx
            this.props.option_list.forEach(function(tmp_item: any) {
                const item = common.copy_obj(tmp_item)
                if (selected_map[item.opt_key] === undefined) {
                    item._visible = false
                    item.selected = false
                    option_list.push(item)
                }
            })

            this.$data.cur_option_list = option_list
        },

        on_click(opt_config: any) {
            if (opt_config.callback) {
                opt_config.callback(this.props.row, opt_config, this.props.index)
            }
        },

        on_mounted() {

        },
        reset_btn_list() {
            if (this.props.value) {
                this.$data.cur_option_list.forEach(function(item: any) {
                    item._visible = true
                })
            } else {
                this.$data.cur_option_list.forEach(function(item: any) {
                    if (item.selected == false) {
                        item._visible = false
                    }
                })
            }
        },

        do_open() {
            this.$emit("input", !this.props.value)
        },

        check_dropdown_col_show(item: any) {

            // 手动配置
            if (!(item._visible == false)) {
                return false
            }

            if (this.props.use_in_toolbar) {
                if (this.$data.in_phone) {
                    return true
                }
            }


            if (item.visible === undefined) {
                return true
            } else if (sys_util.isBoolean(item.visible)) {
                return item.visible
            } else {
                return item.visible(this.props.row, item, this.props.index)
            }

        },

        check_col_show(item: any) {

            if (this.props.use_in_toolbar && !this.$data.in_phone) {
                return true
            }

            // console.log("item:" + item.visible);
            if (item._visible == false) {
                return false
            }

            if (item.visible === undefined) {
                return true
            } else if (sys_util.isBoolean(item.visible)) {
                return item.visible
            } else {
                return item.visible(this.props.row, item, this.props.index)

            }

        },

        get_title(item: any) {
            if (sys_util.isFunction(item.title)) {
                return item.title(this.props.row, item, this.props.index)
            } else {
                return item.title
            }
        },


        get_icon(item: any) {
            if (sys_util.isFunction(item.icon)) {
                return item.icon(this.props.row, item, this.props.index)
            } else {
                return item.icon
            }
        },

        get_text(item: any) {
            if (sys_util.isFunction(item.text)) {
                return item.text(this.props.row, item, this.props.index)
            } else {
                return item.text
            }

        },

        get_style(item: any) {
            if (item.style) {
            }
            // .button_style? : {'margin-left':'8px'}
        }

    }

})


/*
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
    created: function() {
        this["on_created"]();
    },

    data: function() {
        return {
            in_phone: false,
            button: Link,
            cur_option_list: [],
            selected_length: 0,
            always_show_list: false,

        };
    },


    props: {



        value: {
            type: Boolean,
            required: false,
            default: function() {
                return false;
            }
        },
        show_more_type: {
            type: String, // dropdown, inline
            required: false,
            default: function() {
                return "dropdown"; //下拉
            }
        },
        show_item_list_flag: {
            type: Boolean,
            required: false,
            default: function() {
                return true;
            }
        },

        use_in_toolbar: {
            type: Boolean,
            required: false,
            default: function() {
                return false;
            }
        },


        selected_list: {
            type: Array,
            required: false,
            default: function() {
                return [];
            },
        },
        option_list: {
            type: Array,
            required: false,

            default: function() {
                return [];
            },
        },
        row: {
            type: Object,
            required: false,
            default: function() {
                return {};
            },
        },
        index: {
            type: Number,
            required: false,
            default: 0,
        }
    },



    watch: {
        selected_list: function() {
            this["do_change_selected_list"]();
        },
        value: function() {
            this["reset_btn_list"]();
        },
        option_list: function() {
            this.$data.cur_option_list = this.$props.option_list;
            (<row_opt_list>this).reset_option_list();
        }

    },

    mounted: function() {

    },
})


export default class row_opt_list extends Vue {
    on_created() {
        this.$data.cur_option_list = this.$props.option_list;
        this.reset_option_list();
        this.$data.in_phone = common.in_phone();
    }

    do_change_selected_list() {
        this.reset_option_list();
    }
    on_show_desc() {
        this.$emit("row-opt-show-desc");
    }

    reset_option_list() {
        var option_list: Array<any> = [];
        var all_map = {};
        var selected_map = {};

        this.$props.option_list.forEach(function(item: any) {
            all_map[item.opt_key] = item;
        });
        var selected_length = 0;
        this.$props.selected_list.forEach(function([opt_key, check_flag]: any) {
            var item = common.copy_obj(all_map[opt_key]);
            if (check_flag) {
                selected_length++;
            }
            if (item !== undefined) {
                item._visible = check_flag;
                item.selected = true;
                option_list.push(item);
            }
            selected_map[opt_key] = check_flag;
        });
        this.$data.selected_length = selected_length;
        //xx
        this.$props.option_list.forEach(function(tmp_item: any) {
            var item = common.copy_obj(tmp_item);
            if (selected_map[item.opt_key] === undefined) {
                item._visible = false;
                item.selected = false;
                option_list.push(item);
            }
        });

        this.$data.cur_option_list = option_list;
    }

    on_click(opt_config: any) {
        if (opt_config.callback) {
            opt_config.callback(this.$props.row, opt_config, this.$props.$index);
        }
    }

    on_mounted() {

    }
    reset_btn_list() {
        if (this.$props.value) {
            this.$data.cur_option_list.forEach(function(item: any) {
                item._visible = true;
            });
        } else {
            this.$data.cur_option_list.forEach(function(item: any) {
                if (item.selected == false) {
                    item._visible = false;
                }
            });
        }
    }

    do_open() {
        this.$emit("input", !this.$props.value);
    }

    check_dropdown_col_show(item: any) {

        //手动配置
        if (!(item._visible == false)) {
            return false;
        }

        if (this.$props.use_in_toolbar) {
            if (this.$data.in_phone) {
                return true;
            }
        }


        if (item.visible === undefined) {
            return true;
        } else if (isBoolean(item.visible)) {
            return item.visible;
        } else {
            return item.visible(this.$props.row, item, this.$props.index);
        }

    }

    check_col_show(item: any) {

        if (this.$props.use_in_toolbar && !this.$data.in_phone) {
            return true;
        }

        // console.log("item:" + item.visible);
        if (item._visible == false) {
            return false;
        }

        if (item.visible === undefined) {
            return true;
        } else if (isBoolean(item.visible)) {
            return item.visible;
        } else {
            return item.visible(this.$props.row, item, this.$props.index);

        }

    }

    get_title(item: any) {
        if (isFunction(item.title)) {
            return item.title(this.$props.row, item, this.$props.index);
        } else {
            return item.title;
        }
    }


    get_icon(item: any) {
        if (isFunction(item.icon)) {
            return item.icon(this.$props.row, item, this.$props.index);
        } else {
            return item.icon;
        }
    }

    get_text(item: any) {
        if (isFunction(item.text)) {
            return item.text(this.$props.row, item, this.$props.index);
        } else {
            return item.text;
        }

    }

    get_style(item: any) {
        if (item.style) {
        }
        //.button_style? : {'margin-left':'8px'}
    }

}
*/
